import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Paper, Typography } from "@mui/material";
import {
  getEmissionsByScopeByYear,
  getTicketStatus,
} from "../../../redux/actions";
import useStyles from "./styles";
import StackedColumnChart from "./StackedColumnChart";
import { getCookie } from "../../../services/cookie";
import TicketStatusChart from "./TicketStatusChart";

const FuelSourceGraph = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const role = getCookie("role");
  const companyId = getCookie("companyId");
  const isAuditor = getCookie("role") === "auditor";
  

  const scopeByYear = useSelector(
    (state) => state.dashboard.getEmissionByScopeByYear.data
  );
  const ticketStatus = useSelector(
    (state) => state.dashboard.getTicketStatus.data
  );

  const ticketStatusStatus = useSelector(
    (state) => state.dashboard.getTicketStatus.status
  );

  if (role === "auditor") {
    var userRole = "auditor";
  } else {
    userRole = "business";
  }

  useEffect(() => {
    !props.refreshContent && dispatch(getEmissionsByScopeByYear(props.filter, role, companyId));
    if (role !== "read_only_user")
      !props.refreshContent && dispatch(getTicketStatus(isAuditor, props.filter, userRole));
  }, [dispatch, props.filter, userRole]);

  return (
    <Paper className={classes.container}>
      {role === "read_only_user" || role === "proxy_sustainability_manager" ||
      role === "sustainability_manager" ? (
        <>
          <Typography className={classes.graphTitle}>
            Emissions by scope
          </Typography>
          {scopeByYear.status === true ? (
            scopeByYear.total_co2e_per_year_scope?.length > 0 ? (
              <div>
                <StackedColumnChart
                  apiResponse={scopeByYear}
                  filter={props.filter}
                />
              </div>
            ) : scopeByYear.total_co2e_per_year_scope?.length === 0 ? (
              <div style={{ minHeight: 250 }}>No Data Available</div>
            ) : (
              <div style={{ minHeight: 250 }}>
                Graph view only supports filtering data based on the year
              </div>
            )
          ) : (
            "Data loading ..."
          )}
        </>
      ) : (
        <>
          <Typography className={classes.graphTitle}>Ticket Status</Typography>
          {ticketStatus ? (
            ticketStatus?.length > 0 ? (
              <div>
                <TicketStatusChart apiResponse={ticketStatus} />
              </div>
            ) : ticketStatusStatus === "running" ? (
              <div style={{ minHeight: 250 }}>Loading...</div>
            ) : (
              ticketStatus?.length === 0 && (
                <div style={{ minHeight: 250 }}>No Data Available</div>
              )
            )
          ) : (
            "Data loading ..."
          )}
        </>
      )}
    </Paper>
  );
};

export default FuelSourceGraph;
