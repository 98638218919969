import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { sampleYearArray } from "../../../../constants";
import {
  getApprovalEmission,
  getEmissionList,
} from "../../../../redux/actions";
import DashboardLayout from "../../../../layouts/DashboardLayout";
import { STATUS } from "../../../../redux/constants";
import useStyles from "./styles";
import { rolesEnum } from "../../../../layouts/DashboardLayout/pages";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CeroButton from "../../../../components/CeroButton";
import CeroInfoPair from "../../../../components/CeroInfoPair";
import EmployeeHireTable from "../../../EmissionList/EmployeeHireTable";
import ApprovalDetailsCard from "../../../ApprovalDetailsCard";
import dayjs from "dayjs";

const ApprovalStatusDetails = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const { type, subType, id } = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const selectedMonth = queryParams.get("month");
  const selectedFacility = queryParams.get("facility");
  const selectedYear = queryParams.get("year");
  const company = queryParams.get("company");
  const period = queryParams.get("period");
  const country = queryParams.get("country");
  const role = useSelector((state) => state.auth.role);
  const isAuditor = role.includes(rolesEnum.AUDITOR);
  const emissionData = useSelector(
    (state) => state.approval.approvalEmission.data
  );
  const emissionTableData = useSelector(
    (state) => state.emission.emissionList.data.emissions
  );
  const emissionDataStatus = useSelector(
    (state) => state.emission.emissionList.status
  );
  const data = emissionData.overview;

  useEffect(() => {
    if (id && type) {
      dispatch(getApprovalEmission(id, type, subType));
    }
    // const skip =(pageNumber - 1) * 20;
    // const newLimit = DEFAULT_ITEMS_PER_PAGE *  pageNumber;
    const filterRequest = {
      // limit: newLimit,
      // skip,
      month: selectedMonth,
      year: selectedYear || sampleYearArray[0].key,
      facility_id: selectedFacility,
      period: period,
      // country:country
    };
    if (!filterRequest.facility_id) {
      delete filterRequest.facility_id;
    }
    dispatch(getEmissionList(type, filterRequest, isAuditor, company, typeof selectedFacility === "string" ? selectedFacility === "null" ? null : selectedFacility.split(",") : selectedFacility, period, country));
  }, [dispatch, id, type, subType]);

//   const onLoadMore = (limit, pageNumber) => {
//     const skip = (pageNumber - 1) * 20;
//     const newLimit = DEFAULT_ITEMS_PER_PAGE * pageNumber;
//     const filterRequest = {
//       limit: newLimit,
//       skip,
//       month: selectedMonth,
//       year: selectedYear || sampleYearArray[0].key,
//       facility_id: selectedFacility,
//       period: period,
//       country: country,
//     };
//     if (!filterRequest.facility_id) {
//       delete filterRequest.facility_id;
//     }
//     dispatch(getEmissionList(type, filterRequest, isAuditor, company, typeof selectedFacility === "string" ? selectedFacility === "null" ? null : selectedFacility.split(",") : selectedFacility, period, country));
//   };

const getTopicTitle = (type) => {
  if(type === "social_engagement_human_rights_training")
  {
    return "Human Rights Training"
  }else if(type === "supplier_screening_using_enviormental_social_criteria"){
    return "Supplier Screening using Environmental & Social Criteria"
  }else if(type === "suppliers_human_rights"){
    return "Human Rights Training"
  }else {
    return type
  }
}

return (
    <DashboardLayout>
      <Box className={classes.tableTopic}>
        <Typography variant="h7" component="span">
        <span className={classes.tableTitle}>
        {type && getTopicTitle(type).replace(/_/g, " ")}
        </span>
        </Typography>
        <Box>
        <CeroButton
          buttonText="Back"
          variant="outlined"
          className={classes.buttonSecondary}
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate(-1)}
        />
        </Box>
      </Box>
      <Container className={classes.container}>
      <Grid container spacing={2}>
      <Grid item xs={6}>
        <CeroInfoPair
          title="Status"
          value={data?.status === "pending_approval" ? "Pending Approval" : data?.status}
          classes={{ container: classes.infoContainer, value: data?.status === "In Progress" || data?.status === "pending_approval" ? classes.Inprogress : classes[data?.status] , title:classes.title}}
        />
        <CeroInfoPair
          title="Audited By"
          value={data?.audited_by_name ? data?.audited_by_name : "NA"}
          classes={{ container: classes.infoContainer, title: classes.title }}
        />
        <CeroInfoPair
          title="Audited On"
          value={data?.audited_on ? dayjs(data?.audited_on).format("DD MMM YYYY") : "NA"}
          classes={{ container: classes.infoContainer, title: classes.title }}
        />
        <CeroInfoPair
          title="Approval Cycle"
          value={data?.approval_cycle_type?.split("_").join(" ")}
          classes={{ container: classes.infoContainer, title: classes.title }}
        />
        <CeroInfoPair
          title="Audit Cycle"
          value={data?.audit_cycle_type?.split("_").join(" ")}
          classes={{ container: classes.infoContainer, title: classes.title }}
        />
        </Grid>
      <Grid item xs={6}>
        <CeroInfoPair
          title="Period"
          value={data?.assessment_period}
          classes={{ container: classes.infoContainer, title: classes.title }}
        />
        <CeroInfoPair
          title="Assessment Year"
          value={data?.year}
          classes={{ container: classes.infoContainer, title: classes.title }}
        />
        <CeroInfoPair
          title="Facility"
          value={data?.facility_name}
          classes={{ container: classes.infoContainer, title: classes.title }}
        />
        <CeroInfoPair
          title="No of tickets"
          value={data?.ticket_count}
          classes={{ container: classes.infoContainer, title: classes.title }}
        />
      </Grid>
      </Grid>
      </Container>
      {type === "employees_hire_and_turnover" &&
      emissionDataStatus === STATUS.SUCCESS ? (
        <EmployeeHireTable
          emissionData={emissionTableData}
          dataStatus={emissionDataStatus}
        //   onLoadMore={onLoadMore}
          emissionType={type}
          isAuditor={isAuditor}
          company={company}
          // backToEmission={backToEmission}
          isOnlyAdmin={false}
          period={period}
        />
      ) : (
        <ApprovalDetailsCard 
        topicType={type}
        emissionDataStatus={emissionDataStatus}
        approvalData={emissionTableData}
        />
      )}
    </DashboardLayout>
  );
};

export default ApprovalStatusDetails;
