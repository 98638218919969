import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import useStyles from "./styles";
import CeroButton from "../../../components/CeroButton";
import CeroInput from "../../../components/CeroInput";
import { useEffect, useState } from "react";
import CeroSelect from "../../../components/CeroSelect";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { schemeTaskValidation } from "./schema";
import { APIEndpoints } from "../../../redux/constants";
import { useSnackbar } from "notistack";
import axios from "axios";
import { getCookie } from "../../../services/cookie";

const Task = ({ initiative_id, initiative, id }) => {
  const classes = useStyles();
   const { enqueueSnackbar } = useSnackbar();
  const [show, setShow] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [tasks, setTasks] = useState([]);

  const trackingCycleAssessmentData = useSelector(
    (state) => state.goalSettings.getTrackingCycleAssessmentPeriod.data
  );

  const assessmentOptions = Array.isArray(trackingCycleAssessmentData)
    ? trackingCycleAssessmentData.map((item) => ({
        key: item.period,
        value: item.period,
      }))
    : [];

  const formik = useFormik({
    initialValues: {
      assessmentPeriod: "",
      task: "",
    },
    validationSchema: schemeTaskValidation,
    onSubmit: () => {},
  });

  const authToken = getCookie("auth_token_admin");

   const onSaveTask = async (event) => {
     // event.preventDefault();
     const requestData = {
       name: formik.values.task,
       period: formik.values.assessmentPeriod,
       initiative_id: id,
     };
     try {
       const response = await axios.post(
         APIEndpoints.ADD_TASKS,
         requestData,
         {
           headers: {
             Authorization: `Bearer ${authToken}`,
           },
         }
       );
       enqueueSnackbar("Task added successfully", { variant: "success" });
       setTasks((tasks) => [
         ...tasks,
         {
           name: response.data.name,
           period: response.data.period,
         },
       ]);
         formik.setValues({
           task: "",
           assessmentPeriod: "",
         });
     } catch (error) {
       enqueueSnackbar(error?.message, { variant: "error" });
     }
   };

  useEffect(() => {
    return ()=>{
      setTasks([])
    }
  }, []);


  return (
    <Container className={classes.container}>
      <Grid className={classes.textContainer}>
        <Typography className={classes.textFieldLabelId}>
          Initiative ID
        </Typography>
        <Typography className={classes.textFieldLabel}>Initiative</Typography>
      </Grid>
      <Grid
        item
        container
        direction="row"
        lg={11.7}
        md={6}
        xs={12}
        className={classes.containerList}
      >
        <CeroInput
          readOnly={true}
          value={initiative_id}
          classes={{ container: classes.textAreaContainerId }}
        />
        <CeroInput
          value={initiative}
          classes={{ container: classes.textAreaContainerValue }}
        />
        <Box>
          <CeroButton
            buttonText="+ Add task"
            className={classes.addTaskButton}
            onClick={() => {
              setShow(true);
              setIsDisabled(true);
            }}
          />
        </Box>
        {tasks.length > 0 &&
          tasks.map((task, index) => (
            <Box style={{ display: "flex", width: "100%",
              justifyContent: "space-between"}}>
              <div className={classes.ceroInputContainer}>
              <Typography className={classes.textFieldLabel}>Task</Typography>
              <CeroInput
                readOnly={true}
                value={task.name}
              />
              </div>
              <div className={classes.ceroSelectContainer}>
              <Typography className={classes.textFieldLabel}>Period</Typography>
              <CeroInput
                readOnly={true}
                value={task.period}
              />
              </div>
            </Box>
          ))}
          <Divider />
        {show && (
          <Box style={{ width: "100%" }}>
            <Box
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Box className={classes.ceroInputContainer}>
              <Typography className={classes.textFieldLabel}>Task</Typography>
                <CeroInput
                  required
                  id="task"
                  name="task"
                  fullWidth
                  value={formik.values.task}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.task &&
                    formik.errors.task
                  }
                />
              </Box>
              <Box className={classes.ceroSelectContainer}>
              <Typography className={classes.textFieldLabel}>Period</Typography>
                <CeroSelect
                  required
                  id="assessmentPeriod"
                  name="assessmentPeriod"
                  fullWidth
                  options={assessmentOptions}
                  selectedValue={formik.values.assessmentPeriod}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.assessmentPeriod &&
                    formik.errors.assessmentPeriod
                  }
                />
              </Box>
            </Box>
            <Box>
              <CeroButton
                buttonText="Save"
                className={classes.saveButton}
                onClick={() => {
                  onSaveTask();
                  // dispatch(listInitiative());
                  setShow(false);
                  
                }}
                disabled={!formik.dirty || !formik.isValid }
              />
              <CeroButton
                buttonText="Cancel"
                className={classes.cancelButton}
                onClick={() => {
                  setShow(false);
                  setIsDisabled(false);
                }}
              />
            </Box>
          </Box>
        )}
      </Grid>
    </Container>
  );
};

export default Task;
