import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Grid,
  Typography,
  Box,
  Tabs,
  Tab,
  IconButton,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useSnackbar } from "notistack";
import dayjs from "dayjs";
import { STATUS } from "../../../../redux/constants";
import useStyles from "./styles";
import TicketIcon from "../../../../assets/icons/TicketIcon.png";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CeroButton from "../../../../components/CeroButton";
import ListTicketDrawer from "../../../common/ListTicketsDrawer";
import CeroConfirmDrawer from "../../../../components/CeroConfirmDrawer";
import { deleteEmissions, resetAddCombustionStatus } from "../../../../redux/actions";
import ListComments from "../../../EmissionsDetails/ListComment";
import ListAuditTrails from "../../../EmissionsDetails/ListAuditTrails";
import ListEmissionFiles from "../../../EmissionsDetails/ListEmissionFiles";
import { rolesEnum } from "../../../../layouts/DashboardLayout/pages";
import { getCookie } from "../../../../services/cookie";
import CeroInfoPair from "../../../../components/CeroInfoPair";
import CeroItemPair from "../../../../components/CeroItemPair";

const EmployeeHireDetails = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const {  emissionData, setIsDrawerOpen } = props;
  const userRole = useSelector(state => state.auth.userInfo.role);
  const isDeleteEnable = [rolesEnum.FACILITY_MANAGER, rolesEnum.BUSINESS_USER, rolesEnum.APPROVER].includes(userRole)

  const [value, setValue] = useState(0);
  const [showTickets, setShowTickets] = useState(false);
  const [displayWarning, setDisplayWarning] = useState(false);
  const readOnlyMode = (getCookie("read_only_mode") === "disable_edit")
  
  const deleteEmissionData = useSelector(
    (state) => state.emission.deleteEmissions
  );

  useEffect(() => {
    if (deleteEmissionData.status === STATUS.SUCCESS) {
      enqueueSnackbar("Employee Benefits record deleted successfully",{ variant: "success",});
      dispatch(resetAddCombustionStatus());
    } else if (deleteEmissionData.status === STATUS.ERROR) {
      enqueueSnackbar(deleteEmissionData.message.message || "We couldn't process your request. Please try again later.", { variant: "error" });
      dispatch(resetAddCombustionStatus());
    }
  }, [deleteEmissionData, enqueueSnackbar, dispatch]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onConfirmDelete = () => {
    const requestData = {
      id: emissionData.id,
    };
    dispatch(deleteEmissions(requestData));
  };

  const onclickShowTickets = () => {
    setShowTickets(true);
  };

  const onCloseTickets = () => {
    setShowTickets(false);
  };

  const onUpdate = () => {
    navigate(`/emissions/edit/employees_hire_and_turnover/${emissionData.id}`);
  };

  return (
    <>
     <div className={classes.headerContainer}>
      <div
        className={classes.backContainer}
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowBackIosIcon />
        Back
        </div>
              <Box>
                <CeroButton
                  buttonText="Raise a ticket"
                  className={classes.buttonPrimary}
                  onClick={() => setIsDrawerOpen(true)}
                  disabled={readOnlyMode}
                />
                <IconButton onClick={onclickShowTickets} disabled={readOnlyMode}>
                  <img src={TicketIcon} alt="Logo" width="25" height="25" />
                </IconButton>
              </Box>
            </div>
    <Container className={classes.container}>
      <Box className={classes.innerContainer}>
        <Box className={classes.header}>
        {emissionData.distribution === "female" || emissionData.distribution === "male" ?
          <Typography variant="h6" component="div">
            Employees Hire & Turnover by Gender
          </Typography> :
          <Typography variant="h6" component="div">
          Employees Hire & Turnover by Age Group
        </Typography>}
        </Box>
        <Box className={classes.topContainer}>
          <Grid
            container
            direction={"row"}
            wrap="nowrap"
            justifyContent={"space-between"}
            spacing={12}
          >
            <Grid item container direction={"column"} xs={10}>
            <Box className={classes.actionBox}>
            <CeroInfoPair 
                title="Status:"
                value={emissionData.status ? emissionData.status === 'pending_approval' ? 'pending approval' : emissionData.status : "NA"}
                classes={{
                  container: classes.statusContainer,
                  title: classes.statusTitle,
                  value: emissionData.status === "In Progress" || emissionData.status === "pending_approval" ? classes.Inprogress : classes[emissionData?.status]
                }}
              /></Box>
            <CeroItemPair
              title="Facility:"
              value={emissionData.facility_name ? emissionData.facility_name : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.diversityTitle,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Period:"
              value={emissionData.assessment_period ? emissionData.assessment_period : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.diversityTitle,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Year:"
              value={emissionData.year ? emissionData.year : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.diversityTitle,
                value: classes.value,
              }}
            />
            {emissionData.distribution === "null" ? "" :
              <>{emissionData.distribution === "female" || emissionData.distribution === "male" ?
            <CeroItemPair
              title="Gender:"
              value={emissionData.distribution ? emissionData.distribution : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.diversityTitle,
                value: classes.value,
              }}
            />: 
            <CeroItemPair
              title="Age Group:"
              value={emissionData.distribution ? emissionData.distribution : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.diversityTitle,
                value: classes.value,
              }}
            />}
            <CeroItemPair
              title="Employee Classification:"
              value={emissionData.classification ? emissionData.classification : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.diversityTitle,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Resigned:"
              value={emissionData.resigned || emissionData.resigned === 0 ? emissionData.resigned : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.diversityTitle,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Hired:"
              value={emissionData.hired || emissionData.hired === 0 ? emissionData.hired : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.diversityTitle,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Turnover Rate:"
              value={emissionData.turnover_rate || emissionData.turnover_rate === 0 ? `${emissionData.turnover_rate}%` : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.diversityTitle,
                value: classes.value,
              }}
            /></>}
            {emissionData?.audited_by && (<>
            <CeroItemPair
              title="Audited by:"
              value={emissionData.audited_by ? emissionData.audited_by : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.diversityTitle,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Audited on:"
              value={emissionData.audited_on
                ? dayjs(emissionData.audited_on).format("DD/MM/YYYY")
                : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.diversityTitle,
                value: classes.value,
              }}
            /></>)}
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box className={classes.buttonContainer}>
        {isDeleteEnable && (
          <CeroButton
            buttonText={<DeleteOutlineIcon />}
            className={clsx(classes.button, classes.deleteButton)}
            onClick={() => setDisplayWarning(true)}
          />
        )}
        {emissionData?.is_able_to_update === true && (
          <CeroButton
            buttonText="Update"
            className={clsx(classes.button, classes.buttonPrimary)}
            onClick={onUpdate}
          />
        )}
      </Box>
      <Box
        className={classes.tabContainer}
        sx={{ borderBottom: 1, borderColor: "divider" }}
      >
        <Tabs value={value} className={classes.tabs} onChange={handleChange} aria-label="emission tabs">
          <Tab label="Comments" id="comments" />
          <Tab label="Attachment" id="files" />
          <Tab label="Change Logs" id="audit-history" />
        </Tabs>
      </Box>
      {value === 0 && <ListComments emissionId={emissionData.id} isAuditor={props.isAuditor} company={props.company}/>}
      {value === 2 && <ListAuditTrails emissionId={emissionData.id} isAuditor={props.isAuditor} company={props.company}/>}
      {value === 1 && <ListEmissionFiles emissionId={emissionData.id} isAuditor={props.isAuditor} company={props.company}/>}
      {displayWarning && (
        <CeroConfirmDrawer
          isOpen={displayWarning}
          onClose={() => setDisplayWarning(false)}
          onConfirm={onConfirmDelete}
        />
      )}
      {showTickets && (
        <ListTicketDrawer
          isOpen={showTickets}
          scope="emission"
          scopeId={emissionData.id}
          onClose={onCloseTickets}
        />
      )}
    </Container>
    </>
  );
};

export default EmployeeHireDetails;
