import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Container,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import DashboardLayout from "../../../../layouts/DashboardLayout";
import useStyles from "./styles";
import CeroButton from "../../../../components/CeroButton";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useFormik } from "formik";
import { getLocations, getProjectDetails } from "../../../../redux/actions";

const BuyDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const { id } = useParams();

  const projectDetails = useSelector(
    (state) => state.trade.getProjectDetails.data
  );

  const formik = useFormik({
    initialValues: {
      projectName: "",
    },
    onSubmit: () => {},
  });

  useEffect(() => {
    dispatch(getLocations());
    id && dispatch(getProjectDetails(id));
  }, [dispatch, id]);

  console.log("projectDetails", projectDetails);

  return (
    <DashboardLayout classes={{ childContainer: classes.childContainer }}>
      <>
        <div
          className={classes.backContainer}
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBackIosIcon />
          Back
        </div>
        <Container className={classes.container}>
          <Box mb={2} className={classes.innerContainer}>
            <Typography className={classes.title}>
              {projectDetails?.project_type}
            </Typography>
            <Box>
              <Typography>{projectDetails.location}</Typography>
            </Box>
          </Box>
          <Typography mb={3}>{projectDetails?.additional_details}</Typography>
          <Divider />
          <Grid
                  item
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent={"space-around"}
                  padding={4}
                >
            <Stack alignItems={"center"} gap={2}>
              <Typography fontSize={12}>Project Type</Typography>
              <Typography fontWeight={500}>{projectDetails.project_type}</Typography>
            </Stack>
            <Stack alignItems={"center"} gap={2}>
              <Typography fontSize={12}>Price per ton(USD)</Typography>
              <Typography fontWeight={500}>{`$${projectDetails.price_per_ton}`}</Typography>
            </Stack>
            <Stack alignItems={"center"} gap={2}>
              <Typography fontSize={12}>Start Date</Typography>
              <Typography fontWeight={500}>{projectDetails.start_date}</Typography>
            </Stack>
            <Stack alignItems={"center"} gap={2}>
              <Typography fontSize={12}>End Date</Typography>
              <Typography fontWeight={500}>{projectDetails.end_date}</Typography>
            </Stack>
          </Grid>
          <Divider />
          <Box mt={2}>
          <Typography fontWeight={500} fontSize={12}>Contact Information</Typography>
          <Typography fontSize={12}>Contact Person: {projectDetails.email}</Typography>
          <Typography fontSize={12}>Email: {projectDetails.contact_person}</Typography>
          <Typography fontSize={12}>Phone: {projectDetails.phone}</Typography>
          <Typography fontSize={12}>Website: {projectDetails.website}</Typography>
          </Box>
        </Container>

        <Stack padding={7} gap={2}>
            <Typography className={classes.title}>Purchase Carbon Credits</Typography>
            <TextField
                    id="projectName"
                    name="projectName"
                    placeholder="Input number of Carbon Credits to purchase"
                    variant="outlined"
                    // fullWidth
                    className={classes.textField}
                    value={formik.values.projectName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
            <CeroButton
              buttonText="Contact Now"
              className={classes.buttonPrimary}
              onClick={()=>{}}
            />
        </Stack>
      </>
    </DashboardLayout>
  );
};

export default BuyDetails;
