import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import useStyles from "./styles";
import CeroChipSelect from "../../../components/CeroChipSelect";
import CountriesIcon from "../../../assets/icons/CountriesIcon.png";
import FacilitiesIcon from "../../../assets/icons/FacilitiesIcon.png";
import durationIcon from "../../../assets/icons/durationIcon.png";
import EnvironmentalIcon from "../../../assets/icons/EnvironmentalIcon.png";
import SocialIcon from "../../../assets/icons/social&governance.png";
import EnergySourceIcon from "../../../assets/icons/energy-sources.png"
import { getCookie } from "../../../services/cookie";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import  ArrowBackIosIcon  from '@mui/icons-material/ArrowBackIos';

const DashboardFilter = (props) => {
  const { onApplyFilter } = props;
  const role = getCookie("role");
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { type } = useParams()

  const classes = useStyles();
  const facilitiesData = useSelector(
    (state) => state.listings.listFacilities.data
  );
  const countryData = useSelector(
    (state) => state.listings.getOrganizationCountry.data.country_data
  );
  const facilitiesList = facilitiesData.map((item) => ({
    key: item.id,
    value: item.name,
  }));
  facilitiesList.splice(0, 0, { key: "all", value: "All" });

  const [filterPeriod, setPeriod] = useState([]);
  const [filterStartYear, setStartYear] = useState("");
  const [filterStartMonth, setStartMonth] = useState("");
  const [filterEndYear, setEndYear] = useState("");
  const [filterEndMonth, setEndMonth] = useState("");
  const [filterCountry, setCountry] = useState([]);
  const [filterCountryFacility, setCountryFacility] = useState([]);
  const [energySources,setEnergySources] = useState([]);
  const countryFacilityData = useSelector(
    (state) => state.listings.getOrganizationCountry.data.country_facility_data
  );
  const countryFacilityDataObject = {};
  for (const key in countryFacilityData) {
    Object.assign(countryFacilityDataObject, countryFacilityData[key]);
  }
  const [facilitites, setFacility] = useState(countryFacilityDataObject);
  const countryCount = filterCountry.length ? filterCountry.length : "All";
  const facilityCount = filterCountryFacility.length
    ? filterCountryFacility.length
    : "All";

  const selectionPeriodObject = {
    "Last six months": "last_six_months",
    "Last one year": "last_one_year",
    "Last five years": "last_five_years",
  };

   const selectionEnergySourceObject = {
     "Stationary Combustion": "stationary_combustion",
     "Mobile Combustion": "mobile_combustion",
     "Refrigerants": "refrigerants",
     "Transportation": "transportation",
     "Purchased Electricity": "purchased_electricity",
     "Water": "water",
     "Waste": "waste",
   };

  const iconMapping = {
    Environmental: <img src={EnvironmentalIcon} alt="Environmental" />,
    "Social & Governance": <img src={SocialIcon} alt="Social & Governance" />,
  };

  const getFacility = (countries) => {
    const countryFacilityDataObject = {};
    var availableFacility = [];
    for (const item of countries) {
      Object.assign(
        countryFacilityDataObject,
        countryFacilityData[countryData[item]]
      );
      availableFacility = availableFacility.concat(
        Object.keys(countryFacilityData[countryData[item]])
      );
    }
    const persistedfacilitySelection = availableFacility.filter((element) =>
      filterCountryFacility.includes(element)
    );
    setFacility(countryFacilityDataObject);
    setCountryFacility(persistedfacilitySelection);
  };

  const countryHandleChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setCountry(
        filterCountry.length === Object.keys(countryData).length
          ? []
          : Object.keys(countryData)
      );
      
      getFacility(
        filterCountry.length === Object.keys(countryData).length
          ? []
          : Object.keys(countryData)
      );
      return;
    }
    setCountry(value);
    getFacility(value);
  };

  const facilityHandleChange = (event) => {
    const value = event.target.value;
    var avlData = [];
    if (value[value.length - 1] === "all") {
      avlData =
        filterCountryFacility.length === Object.keys(facilitites).length
          ? []
          : Object.keys(facilitites);
    } else {
      avlData = value;
    }
    setCountryFacility(avlData);
  };

    const energySourceHandleChange = (event) => {
        const value = event.target.value;
        if (value[value.length - 1] === "all") {
           setEnergySources(
             energySources.length ===
               Object.keys(selectionEnergySourceObject).length
               ? []
               : Object.keys(selectionEnergySourceObject)
           );
          return;
        }
        setEnergySources(value);
    };

  useEffect(() => {
    onApply();
  }, [filterPeriod, energySources, filterCountryFacility, filterCountry]);

  const durationHandleChange = (event) => {
    const value = event.target.value;
    if (value.length > 0) {
      setPeriod([value[value.length - 1]]);
    } else {
      setPeriod([]);
    }
  };

  const handleChange = (event, newValue) => {
    
    props.setValue(newValue);
  };


  const onApply = () => {
    const filterValue = {};
    if (filterPeriod.length > 0) {
    const selectedPeriodKey = selectionPeriodObject[filterPeriod];
      filterValue.duration = selectedPeriodKey;
    } else {
      filterValue.duration = "last_five_years"; 
    }
    if (filterCountryFacility.length > 0) {
      filterValue.facility_id = [];
      filterCountryFacility.map((item) =>
      filterValue.facility_id.push(countryFacilityDataObject[item])
      );
    } 
      if (filterStartYear) {
      filterValue.start_year = filterStartYear;
    }
    if (filterStartMonth) {
      filterValue.start_month = filterStartMonth;
    }
    if (filterEndYear) {
      filterValue.end_year = filterEndYear;
    }
    if (filterEndMonth) {
      filterValue.end_month = filterEndMonth;
    }
    if (filterCountry.length) {
      filterValue.countries = [];
      filterCountry.map((item) =>
      filterValue.countries.push(countryData[item])
      );
    }
    if (energySources.length) {
       filterValue.emission_types = [];
       energySources.map((item) =>
         filterValue.emission_types.push(selectionEnergySourceObject[item])
       );
    }
    onApplyFilter(filterValue);
  };

  const selectedPeriod = filterPeriod.length > 0 ? filterPeriod : ["Last five years"];

  return (
    <>
      <Card className={classes.card}>
        {(role === "sustainability_manager" || role === "read_only_user" || role === "proxy_sustainability_manager") && (
          <>
            {pathname.includes("dashboard/details") ? (
              <div
                className={classes.backContainer}
                onClick={() => {
                  navigate(-1);
                }}
              >
                <ArrowBackIosIcon />
                Back
              </div>
            ) : (
              <div className={classes.tabsContainer}>
                <Tabs
                  value={props.value}
                  className={classes.tabs}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons={false}
                >
                  {["Environmental", "Social & Governance"].map(
                    (topic, index) => (
                      <Tab
                        className={classes.tabOption}
                        key={index}
                        style={{
                          backgroundColor:
                            props.value === index ? "#e4e4e4" : "transparent",
                          color: props.value === index ? "white" : "inherit",
                          border:
                            props.value === index
                              ? "1px solid #8C2148"
                              : "1px solid #E0E0E0",
                          width: "45%",
                          marginRight: "10px",
                          fontSize: "14px",
                          borderRadius: 10,
                        }}
                        label={
                          <div
                            className={classes.tabLabel}
                          >
                            {iconMapping[topic]}
                            <span className={classes.tabTitle}>{topic}</span>
                          </div>
                        }
                      />
                    )
                  )}
                </Tabs>
              </div>
            )}
          </>
        )}
        <Grid class = {role === "sustainability_manager" || role === "read_only_user" || role === "proxy_sustainability_manager"? classes.adjustedHead : classes.headClass}>
          <Grid width={type === "initiatives" ? 230 : type === "emc" ? 420 : 840} item xs = {6} display="flex" alignItems="center">
            <Typography variant="body1" className={classes.filterText}>
              Filter by:
            </Typography>
            {type !== "initiatives" && type !== "emc" && (
              <CeroChipSelect
                label={
                  <div className={classes.labels}>
                    <img
                      src={CountriesIcon}
                      alt="Countries"
                      height="16"
                      width="16"
                      style={{ marginRight: 5, marginLeft: 5 }}
                    />
                    <Typography className={classes.labelText}>
                      Country
                      <span style={{ color: "#6A6969" }}>
                        {" "}
                        ({countryCount})
                      </span>
                    </Typography>
                  </div>
                }
                formControlClass={classes.multiSelect}
                optionsde={countryData}
                selected={filterCountry}
                handleChange={countryHandleChange}
                classes={{
                  justCheckSelected: classes.justCheckSelected,
                  label: classes.label,
                }}
                shrink={false}
                renderValue={() => null}
              />
            )}
            {type !== "initiatives" && type !== "emc" && (
              <CeroChipSelect
                label={
                  <div className={classes.labels}>
                    <img
                      src={FacilitiesIcon}
                      alt="Facility"
                      height="16"
                      width="16"
                      style={{ marginRight: 5, marginLeft: 5 }}
                    />
                    <Typography className={classes.labelText}>
                      Facility
                      <span style={{ color: "#6A6969" }}>
                        {" "}
                        ({facilityCount})
                      </span>
                    </Typography>
                  </div>
                }
                formControlClass={classes.multiSelect}
                optionsde={
                  Object.keys(facilitites).length > 0
                    ? facilitites
                    : countryFacilityDataObject
                }
                selected={filterCountryFacility}
                handleChange={facilityHandleChange}
                classes={{
                  justCheckSelected: classes.justCheckSelected,
                  label: classes.label,
                }}
                shrink={false}
                renderValue={() => null}
                sortOption={true}
              />
            )}
            {type === "emc" && (
              <CeroChipSelect
                label={
                  <div className={classes.labels}>
                    <img
                      src={EnergySourceIcon}
                      alt="Energy Source"
                      height="16"
                      width="16"
                      style={{ marginRight: 5, marginLeft: 5 }}
                    />
                    <Typography className={classes.labelText}>
                      Energy Source
                      <span style={{ color: "#6A6969" }}>
                        {" "}
                        ({energySources.length || 7})
                      </span>
                    </Typography>
                  </div>
                }
                formControlClass={classes.multiSelect}
                optionsde={selectionEnergySourceObject}
                selected={energySources}
                handleChange={energySourceHandleChange}
                classes={{
                  justCheckSelected: classes.justCheckSelected,
                  label: classes.label,
                }}
                showSelectAll={true}
                shrink={false}
                renderValue={() => null}
              />
            )}
            <CeroChipSelect
              label={
                <div className={classes.labels}>
                  <img
                    src={durationIcon}
                    alt="Duration"
                    height="16"
                    width="16"
                    style={{ marginRight: 5, marginLeft: 5 }}
                  />
                  <Typography className={classes.labelText}>
                    Duration<span style={{ color: "#6A6969" }}> (1)</span>
                  </Typography>
                </div>
              }
              formControlClass={classes.multiSelect}
              optionsde={selectionPeriodObject}
              selected={selectedPeriod}
              handleChange={durationHandleChange}
              classes={{
                justCheckSelected: classes.justCheckSelected,
                label: classes.label,
              }}
              showSelectAll={false}
              shrink={false}
              renderValue={() => null}
            />
            </Grid>
            {
              props.isShowConfigButton &&
            <Grid item xs={1} width= '100%' display='flex' justifyContent= 'flex-end'>
              <Button
                                        className={classes.buttonPrimaryConfig}
                                        variant="contained"
                                        onClick={() => navigate(`/dashboard-config`)}
                                        // disabled={}
                                    >
                                        Configure Dashboard
                                    </Button>
          </Grid>

            }
          {/* <Grid item xs></Grid> */}
        </Grid>
      </Card>
    </>
  );
};

export default DashboardFilter;
