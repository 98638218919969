import { useCallback, useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { companyDetails, getAllSubscriptionPlansSuperadmin, getSuperAdminOrganizationCountry, proxySustainabilityManagerLogin } from "../../../redux/actions";
import DashboardLayout from "../../../layouts/DashboardLayout";
import useStyles from "./styles";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ActiveUserIcon from "../../../assets/icons/ActiveUserIcon.png";
import CountriesIcon from "../../../assets/icons/CountriesIcon.png";
import TotalUsersIcon from "../../../assets/icons/TotalUsersIcon.png";
import CompaniesIcon from "../../../assets/icons/CompaniesIcon.png";
import CountriesTable from "../CountriesTable";
import { onSelectCompanyData } from "../CompaniesTable/index";
import CeroButton from "../../../components/CeroButton";
import CeroPopUp from "../../../components/CeroPopUp";
import CustomerManagement from "../CustomerManagement";
import AddAdmin from "./AddAdmin";
import CeroItemPair from "../../../components/CeroItemPair";
import Reports from "./SuperAdminReports";
import CompanyTotalUsers from "./CompanyTotalUsers";

const CompanyDetail = () => {
  const classes = useStyles();
  const { companyId, tab} = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(tab || "company-details");
  const [open,setOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const companyData = useSelector(
    (state) => state.company.adminCompanyDetails.data
  );

  const planDetails = useSelector(
    (state) => state.superAdmin.getAllSubscriptionPlansSuperadmin.data.subscription_list
  );

  const handleTabChange = useCallback((tabValue) => {
    setSelectedTab(tabValue);
    onSelectCompanyData(companyData, companyId, tabValue, navigate);
  }, [companyData, navigate]);


  const handleClick = () => {
    setOpen(false);
    dispatch(proxySustainabilityManagerLogin(companyId))
  }

   const handleDrawerClose = () => {
     setOpenDrawer(false);
   };

  useEffect(() => {
    dispatch(companyDetails(companyId));
    dispatch(getAllSubscriptionPlansSuperadmin(companyId));
    dispatch(getSuperAdminOrganizationCountry(companyId))
  }, [dispatch, companyId]);

    const getApproveConfirm = () => {
      return (
        <Box className={classes.popUp}>
          <Typography variant="body1" style={{ fontWeight: 500 }}>
            Are you sure you want to Login as Sustainability Manager for company {companyData.name}?
          </Typography>
          <div className={classes.buttonContainerReview}>
            <CeroButton
              className={classes.buttonPrimary}
              variant="contained"
              onClick={() => {
                handleClick()
              }}
              buttonText="Yes"
            />
            <CeroButton
              className={classes.buttonSecondary}
              variant="outlined"
              onClick={() => setOpen(false)}
              buttonText="No"
            />
          </div>
        </Box>
      );
    };
    
  return (
    <DashboardLayout>
      <div
        className={classes.backContainer}
        onClick={() => {
          navigate(`/companies`);
        }}
      >
        <ArrowBackIosIcon />
        Back
      </div>
      <Container className={classes.container}>
        <Card className={classes.tabsCard}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h5" className={classes.companyName}>
              {companyData.name}
            </Typography>
            {companyData?.is_business_company && 
            <Box mt={6} marginRight={8}>
              <CeroButton
                buttonText="Add Business Admins"
                onClick={() => setOpenDrawer(true)}
                className={classes.buttonPrimary}
              />
              <CeroButton
                buttonText="Login"
                onClick={() => setOpen(true)}
                className={classes.buttonPrimary}
              />
            </Box>
          }
          </Box>
          <Tabs
            value={selectedTab}
            onChange={(event, newValue) => handleTabChange(newValue)}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab label="Company Details" value="company-details" />
            <Tab label="Countries & Facilities" value="countries-facilities" />
            <Tab label="Total Users" value="total-users" />
            <Tab label="Customer Management" value="customer-management" />
            <Tab label="Reports" value="reports" />
          </Tabs>
          <div></div>
        </Card>
        {selectedTab === "company-details" && (
          <>
            <Card className={classes.countCard}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Grid
                      container
                      spacing={2}
                      className={classes.gridContainer}
                    >
                      <div className={classes.countContainer}>
                        <img
                          src={CountriesIcon}
                          alt="Countries"
                          height="45"
                          width="45"
                        />
                        <Typography variant="h4" className={classes.count}>
                          {companyData.country_count}
                        </Typography>
                      </div>
                      <div className={classes.name}>
                        <Typography variant="h6" style={{ fontWeight: 500 }}>
                          Countries
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid item xs={3}>
                    <Grid
                      container
                      spacing={2}
                      className={classes.gridContainer}
                    >
                      <div className={classes.countContainer}>
                        <img
                          src={CompaniesIcon}
                          alt="Companies"
                          height="50"
                          width="45"
                        />
                        <Typography variant="h4" className={classes.count}>
                          {companyData.facility_count}
                        </Typography>
                      </div>
                      <div className={classes.name}>
                        <Typography variant="h6" style={{ fontWeight: 500 }}>
                          Facilities
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid item xs={3}>
                    <Grid
                      container
                      spacing={2}
                      className={classes.gridContainer}
                    >
                      <div className={classes.countContainer}>
                        <img
                          src={TotalUsersIcon}
                          alt="Total Users"
                          width="40"
                        />
                        <Typography variant="h4" className={classes.count}>
                          {companyData.total_user_count}
                        </Typography>
                      </div>
                      <div className={classes.name}>
                        <Typography variant="h6" style={{ fontWeight: 500 }}>
                          Total Users
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid item xs={3}>
                    <Grid
                      container
                      spacing={2}
                      className={classes.gridContainer}
                    >
                      <div className={classes.countContainer}>
                        <img
                          src={ActiveUserIcon}
                          alt="Active Users"
                          height="40"
                          width="35"
                        />
                        <Typography variant="h4" className={classes.count}>
                          {companyData.active_user_count}
                        </Typography>
                      </div>
                      <div className={classes.name}>
                        <Typography variant="h6" style={{ fontWeight: 500 }}>
                          Active Users
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Grid
              container
              columnSpacing={2}
              style={{ display: "flex", alignItems: "stretch" }}
            >
              <Grid item xs={4.8} style={{ display: "flex" }}>
                <Card className={classes.detailsCard} style={{ flex: 1 }}>
                  <div className={classes.companyCard}>
                    <img
                      src={companyData.company_logo}
                      alt="Company Logo"
                      height="80"
                      width="80"
                    />
                    <Typography
                      variant="h6"
                      style={{ color: "#333333", fontWeight: 700 }}
                    >
                      {companyData.name}
                    </Typography>
                  </div>
                  <Grid
                    container
                    style={{ padding: 5, margin: "auto", width: "60%" }}
                  >
                    <Grid item xs={6}>
                      <div>
                        <Typography
                          variant="body1"
                          style={{ lineHeight: "2.5" }}
                        >
                          Website:
                        </Typography>
                        <Typography
                          variant="body1"
                          style={{ lineHeight: "2.5" }}
                        >
                          Phone:
                        </Typography>
                        <Typography
                          variant="body1"
                          style={{ lineHeight: "2.5" }}
                        >
                          Country:
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div>
                        {companyData.website ? (
                          <Typography
                            variant="body1"
                            style={{ lineHeight: "2.5" }}
                          >
                            {companyData.website}
                          </Typography>
                        ) : (
                          "-"
                        )}
                        {companyData.phone ? (
                          <Typography
                            variant="body1"
                            style={{ lineHeight: "2.5" }}
                          >
                            {companyData.phone}
                          </Typography>
                        ) : (
                          "-"
                        )}
                        {companyData.country ? (
                          <Typography
                            variant="body1"
                            style={{ lineHeight: "2.5" }}
                          >
                            {companyData.country}
                          </Typography>
                        ) : (
                          "-"
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={7.2} style={{ display: "flex" }}>
                <Card className={classes.licenseCard} style={{ flex: 1 }}>
                  <Typography variant="h6" style={{ fontWeight: 500 }}>
                    License Model
                  </Typography>
                  {planDetails ? (
                    <Box
                      mt={8}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <CeroItemPair
                        title={`License Type:`}
                        value={planDetails.licence_type}
                        classes={{
                          container: classes.infoContainer,
                          title: classes.title,
                          value: classes.value,
                        }}
                      />
                      <CeroItemPair
                        title={`Valid Until:`}
                        value={planDetails.valid_until}
                        classes={{
                          container: classes.infoContainer,
                          title: classes.title,
                          value: classes.value,
                        }}
                      />
                      <CeroItemPair
                        title={`Features:`}
                        value={[
                          planDetails?.disclosure_reporting,
                          planDetails?.one_standard,
                          planDetails?.users_count,
                          planDetails?.auditor_count,
                          planDetails?.country_count,
                          planDetails?.facility_count,
                          planDetails?.reporting_cycle,
                          planDetails?.erp_system,
                          planDetails?.iot_devices
                        ].join("\n")}
                        classes={{
                          container: classes.infoContainer,
                          title: classes.title,
                          value: classes.value,
                        }}
                      />
                      <CeroItemPair
                        title={`Additional Features:`}
                        value={planDetails.additional_feature.map(item=>item.feature_name).join("\n")}
                        classes={{
                          container: classes.infoContainer,
                          title: classes.title,
                          value: classes.value,
                        }}
                      />
                    </Box>
                  ) : (
                    <Box
                      mt={8}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: 5,
                      }}
                    >
                      <Typography>
                        Explore Our Subscription Options to Unlock Premium
                        Features
                      </Typography>
                      <CeroButton
                        buttonText="View Subscription Plans"
                        className={classes.buttonPrimary}
                        onClick={() =>
                          navigate(
                            "/subscription-plans?company_id=" + companyId
                          )
                        }
                      />
                    </Box>
                  )}
                </Card>
              </Grid>
            </Grid>
          </>
        )}
        {selectedTab === "countries-facilities" && (
          <CountriesTable companyId={companyId} />
        )}
        {selectedTab === "total-users" && (
          <CompanyTotalUsers
            companyId={companyId}
            isActive={false}
            tab={selectedTab}
          />
        )}
        {selectedTab === "customer-management" && (
          <CustomerManagement
            companyData={companyData?.license_details}
            companyName={companyData?.name}
            companyId={companyId}
            isActive={true}
            tab={selectedTab}
          />
        )}
        {selectedTab === "reports" && (
          <Reports
            companyId={companyId}
          />
        )}
      </Container>
      <CeroPopUp
        primaryPopUp={{
          open: open,
          onClose: () => setOpen(false),
          content: getApproveConfirm(),
          header: { title: "" },
        }}
        showCloseButton={true}
        classes={{ dialog: classes.dialog }}
      />
      {openDrawer && (
        <AddAdmin isOpen={openDrawer} onClose={handleDrawerClose} />
      )}
    </DashboardLayout>
  );
};

export default CompanyDetail;
