import { Typography, Container, Grid, Box } from "@mui/material";
import React from "react";
import CeroInfoPair from "../../../components/CeroInfoPair";
import useStyles from "../styles";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import { getCookie } from "../../../services/cookie";
import CeroButton from "../../../components/CeroButton";

const ManagementDiversityApproval = (props) => {
  const classes = useStyles();
  const { emissionData, handleCallback, handleReviewCallback } = props;
    const role = getCookie('role');
    const readOnlyMode = (getCookie("read_only_mode") === "disable_edit")
    const showActionButtons = window.location.pathname.includes('/pending-approval-details') && (role === "sustainability_manager" || role === "proxy_sustainability_manager" )
    const showVerifyButtons = window.location.pathname.includes('/companies') && role === "auditor"
    const handleIconClick = (itemId) => {
      handleCallback(itemId)
    };
    
    const handleReviewClick = (itemId) => {
      handleReviewCallback(itemId)
    }

  return (
    <Container className={classes.mainContainer}>
      {emissionData && emissionData?.based_on_gender?.map((item, index) => (

          <Container className={classes.container}>
            {showVerifyButtons && 
            <Box className={classes.trainingAndProgramAction}>
                <CeroInfoPair 
                title="Status:"
                value={item.status === "pending_approval" ? "Pending Approval" : item.status ? item.status : "NA"}
                classes={{
                  container: classes.statusContainer,
                  title: classes.statusTitle,
                  value: item.status === "In Progress" || item.status === "pending_approval" ? classes.Inprogress : classes[item?.status]
                }}
              />
                {showVerifyButtons && !readOnlyMode &&<Grid container spacing={2}>
                  <Grid item xs={6}>
                  {(item.status === "approved") && 
                 <div className={classes.actionButtons} onClick={() => handleIconClick(item.id)}>
                 <CheckCircleOutlineOutlinedIcon className={classes.icon} />
                 <CeroButton
                 variant="contained"
                 buttonText="Validate"
                 className={classes.approveButton}/></div>}
                </Grid>
                <Grid item xs={6}>
                {(item.status === "approved") && 
                <div className={classes.actionButtons} onClick={() => handleReviewClick(item.id)}>
                <ArrowCircleLeftOutlinedIcon className={classes.icon} />
                <CeroButton
                 variant="contained"
                 buttonText="Review"
                 className={classes.approveButton}/></div>}
                </Grid>
                </Grid>}
                </Box>
              }
            <Typography variant='subtitle1' className={classes.heading}>Management Diversity by Gender</Typography>
            <CeroInfoPair
              title="Percentage of Management Members identifying as Male:"
              value={`${item.male}%` || "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.commonTitle,
                value: classes.value,
              }}
            />
            <CeroInfoPair
              title="Percentage of Management Members identifying as Female:"
              value={`${item.female}%` || "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.commonTitle,
                value: classes.value,
              }}
            />
            <CeroInfoPair
              title="Percentage of Management Members identifying as Others"
              value={`${item.other}%` || "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.commonTitle,
                value: classes.value,
              }}
            />
{/* ))} */}
          {/* {emissionData && emissionData?.based_on_age?.map((item, index) => ( */}
            <>
            <Typography variant='subtitle1' className={classes.heading}>Management Diversity by Age</Typography>
            <CeroInfoPair
              title="Percentage of Management Members under 30 years:"
              value={`${emissionData.based_on_age?.[index]?.under_30}%` || "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.commonTitle,
                value: classes.value,
              }}
            />
            <CeroInfoPair
              title="Percentage of Management Members between 30&50 years:"
              value={`${emissionData.based_on_age?.[index]?.between_30_50}%` || "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.commonTitle,
                value: classes.value,
              }}
            />
            <CeroInfoPair
              title="Percentage of Management Members above 50 years:"
              value={`${emissionData.based_on_age?.[index]?.over_50}%` || "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.commonTitle,
                value: classes.value,
              }}
            />
            </>
          </Container>
))}

    </Container>
  );
};

export default ManagementDiversityApproval;
