import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, CircularProgress, Container, Typography } from "@mui/material";

import { getTotalCompanies } from "../../redux/actions";

import DashboardLayout from '../../layouts/DashboardLayout'
import useStyles from "./styles";
import CompaniesTable from "./CompaniesTable";
import { STATUS } from "../../redux/constants";

const DEFAULT_ITEMS_PER_PAGE = 20;

const SuperAdminCompanies = () => {

    const dispatch = useDispatch()
    const classes = useStyles();
    const totalCompanies = useSelector(state => state.company.totalCompanies.data)

    const totalCompaniesStatus = useSelector(state => state.company.totalCompanies.status);

    useEffect(() => {
        dispatch(getTotalCompanies())
    }, [dispatch])

    const onLoadMore = (pageSize = DEFAULT_ITEMS_PER_PAGE, pageNumber) => {
       
    };

    return (
        <DashboardLayout>
            <Container className={classes.container}>
               {totalCompaniesStatus === STATUS.SUCCESS? <CompaniesTable totalCompanies={totalCompanies} onLoadMore={onLoadMore} />: <Box className={classes.loader}>
            <Typography variant="h7" component="span">
              {totalCompaniesStatus === STATUS.RUNNING
                ? <CircularProgress/>
                : totalCompaniesStatus === STATUS.ERROR
                ? "We couldn't process your request. Please try again later"
                : ""}
            </Typography>
          </Box>}
            </Container>
        </DashboardLayout>
    )
}

export default SuperAdminCompanies