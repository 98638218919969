import * as yup from 'yup';

export const notificationValidation = yup.object({
    messageType: yup
        .string()
        .required('Message type is required'),
    notificationType: yup
        .string()
        .required('Notification type is required'),
    message: yup
        .string()
        .required('Message is required'),
    startTime: yup
        .number()
        .typeError('Invalid date format'),
    endTime: yup
        .number()
        .min(yup.ref('startTime'), 'End time must be later than start time')
        .typeError('Invalid date format'),
    messageStatus: yup
        .string()
        .required('Message status is required'),
    company: yup
        .array(),
    country: yup
        .array()
});
