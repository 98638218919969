import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: 0,
        margin: 0,
        "@media (min-width: 1200px)": {
            maxWidth: "none",
          },
    },
    tableContainer: {
        width: "80%",
        margin: "auto",
        marginTop: theme.spacing(5),
        height: "auto",
        "@media (max-width: 599px)": {
            maxHeight: "calc(100vh - 100px)", 
          },
          "@media (min-width: 600px) and (max-width: 1199px)": {
            maxHeight: "calc(100vh - 140px)", 
          },
          "@media (min-width: 1200px)": {
            maxHeight: "calc(100vh - 200px)", 
          },
    },
    backContainer:{
        color: theme.palette.Primary.background,
        textTransform: "capitalize",
        display: "flex",
        paddingBottom: 20,
        alignItems: 'center',
        cursor: "pointer"
    },
}));

export default useStyles;