import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  IconButton,
  Chip,
  Typography,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import CreateIcon from "@mui/icons-material/CreateOutlined";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  StationaryColumns,
  MobileColumns,
  PurchasedElectricityColumns,
  WaterDischargeColumns,
  WaterConsumptionColumns,
  RefrigerantsColumns,
  TransportationColumns,
  WasteCombustionColumns,
  DevelopmentTrainingColumns,
  EmployeeHealthDetailsColumns,
  WorkerSafetyTrainingColumns,
  EmployeeDescriminationIncidentColumns,
  SupplierHumanRightsTrainigColumns,
  SocialHumanRightsTrainigColumns,
  SupplierScreeningColumns,
  LocalCommunitiesColumns,
  PoliticalContributionColumns,
  AntiCorruptionDisclosureColumns,
  AntiCorruptionTrainigColumns,
  AntiCompetitiveDisclosureColumns,
  FinancialAssistanceColumns,
  UploadFileEmissionColumns,
  EmployeeHireAndTurnover,
  EmployeeBenefits,
  TrainingHoursPerEmployee,
  PerformanceAndCareerDevelopmentProgramsColumns,
  EconomicImpactColumns,
} from "./TableColumns";
import {
  StationaryColumnsReport,
  MobileColumnsReport,
  PurchasedElectricityColumnsReport,
  WaterDischargeColumnsReport,
  WaterConsumptionColumnsReport,
  RefrigerantsColumnsReport,
  TransportationColumnsReport,
  WasteCombustionColumnsReport,
  DevelopmentTrainingColumnsReport,
  EmployeeHealthDetailsColumnsReport,
  WorkerSafetyTrainingColumnsReport,
  EmployeeDescriminationIncidentColumnsReport,
  SupplierHumanRightsTrainigColumnsReport,
  SocialHumanRightsTrainigColumnsReport,
  SupplierScreeningColumnsReport,
  LocalCommunitiesColumnsReport,
  PoliticalContributionColumnsReport,
  AntiCorruptionDisclosureColumnsReport,
  AntiCorruptionTrainigColumnsReport,
  AntiCompetitiveDisclosureColumnsReport,
  FinancialAssistanceColumnsReport,
  UploadFileEmissionColumnsReport,
  TrainingHoursColumnsReport,
  PerformanceCareerColumnsReport,
  EmployeeBenefitsColumnsReport,
} from "./TableColumnReport";
import {
  StationaryColumnsStakeholderReport,
  MobileColumnsStakeholderReport,
  PurchasedElectricityColumnsStakeholderReport,
  WaterDischargeColumnsStakeholderReport,
  WaterConsumptionColumnsStakeholderReport,
  RefrigerantsColumnsStakeholderReport,
  TransportationColumnsStakeholderReport,
  WasteCombustionColumnsStakeholderReport,
  DevelopmentTrainingColumnsStakeholderReport,
  EmployeeHealthDetailsColumnsStakeholderReport,
  WorkerSafetyTrainingColumnsStakeholderReport,
  EmployeeDescriminationIncidentColumnsStakeholderReport,
  SupplierHumanRightsTrainigColumnsStakeholderReport,
  SocialHumanRightsTrainigColumnsStakeholderReport,
  SupplierScreeningColumnsStakeholderReport,
  LocalCommunitiesColumnsStakeholderReport,
  PoliticalContributionColumnsStakeholderReport,
  AntiCorruptionDisclosureColumnsStakeholderReport,
  AntiCorruptionTrainigColumnsStakeholderReport,
  AntiCompetitiveDisclosureColumnsStakeholderReport,
  FinancialAssistanceColumnsStakeholderReport,
  UploadFileEmissionColumnsStakeholderReport,
} from "./TableColumns";
import CeroTable from "../../../components/CeroTable";
import useStyles from "./styles";
import { STATUS } from "../../../redux/constants";
import CeroButton from "../../../components/CeroButton";
import { deleteEmissions, resetEmissionDelete } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import EmissionDeletePrompt from "../../EmissionDeletePrompt";
import { getCookie } from "../../../services/cookie";
import { months } from "../../../constants";


const EmissionTable = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {
    emissionType,
    emissionData,
    onLoadMore,
    dataStatus,
    isAuditor,
    isStakeholder,
    company,
    headerOff,
    editOff,
    reportTable,
    isOnlyAdmin,
    isDeleteActionRequired,
    BusinessUserDelete,
    FacilityManagerDelete
  } = props;

  const getTableColumn =  isStakeholder ? 
  {
    stationary_combustion:StationaryColumnsStakeholderReport,
    mobile_combustion: MobileColumnsStakeholderReport,
    purchased_electricity: PurchasedElectricityColumnsStakeholderReport,
    water_discharge: WaterDischargeColumnsStakeholderReport,
    water_consumption: WaterConsumptionColumnsStakeholderReport,
    refrigerants: RefrigerantsColumnsStakeholderReport,
    transportation: TransportationColumnsStakeholderReport,
    waste: WasteCombustionColumnsStakeholderReport,
    development_training: DevelopmentTrainingColumnsStakeholderReport,
    employee_health_safety_incident_record: EmployeeHealthDetailsColumnsStakeholderReport,
    worker_safety_training_procedures: WorkerSafetyTrainingColumnsStakeholderReport,
    discrimination_incident_record: EmployeeDescriminationIncidentColumnsStakeholderReport,
    supplier_screening_using_enviormental_social_criteria: SupplierScreeningColumnsStakeholderReport,
    suppliers_human_rights: SupplierHumanRightsTrainigColumnsStakeholderReport,
    social_engagement_human_rights_training: SocialHumanRightsTrainigColumnsStakeholderReport,
    local_communities: LocalCommunitiesColumnsStakeholderReport,
    political_contributions: PoliticalContributionColumnsStakeholderReport,
    anti_corruption_disclosure: AntiCorruptionDisclosureColumnsStakeholderReport,
    anti_corruption_training: AntiCorruptionTrainigColumnsStakeholderReport,
    anti_competitive_disclosure: AntiCompetitiveDisclosureColumnsStakeholderReport,
    subsidies_financial_assistance: FinancialAssistanceColumnsStakeholderReport,
    employees_turnover: UploadFileEmissionColumnsStakeholderReport,
  }
   :
   {
    stationary_combustion: reportTable
      ? StationaryColumnsReport
      : StationaryColumns,
    mobile_combustion: reportTable ? MobileColumnsReport : MobileColumns,
    purchased_electricity: reportTable
      ? PurchasedElectricityColumnsReport
      : PurchasedElectricityColumns,
    water_discharge: reportTable
      ? WaterDischargeColumnsReport
      : WaterDischargeColumns,
    water_consumption: reportTable
      ? WaterConsumptionColumnsReport
      : WaterConsumptionColumns,
    refrigerants: reportTable ? RefrigerantsColumnsReport : RefrigerantsColumns,
    transportation: reportTable
      ? TransportationColumnsReport
      : TransportationColumns,
    waste: reportTable ? WasteCombustionColumnsReport : WasteCombustionColumns,
    development_training: reportTable
      ? DevelopmentTrainingColumnsReport
      : DevelopmentTrainingColumns,
    employee_health_safety_incident_record: reportTable
      ? EmployeeHealthDetailsColumnsReport
      : EmployeeHealthDetailsColumns,
    worker_safety_training_procedures: reportTable
      ? WorkerSafetyTrainingColumnsReport
      : WorkerSafetyTrainingColumns,
    discrimination_incident_record: reportTable
      ? EmployeeDescriminationIncidentColumnsReport
      : EmployeeDescriminationIncidentColumns,
    supplier_screening_using_enviormental_social_criteria: reportTable
      ? SupplierScreeningColumnsReport
      : SupplierScreeningColumns,
    suppliers_human_rights: reportTable
      ? SupplierHumanRightsTrainigColumnsReport
      : SupplierHumanRightsTrainigColumns,
    social_engagement_human_rights_training: reportTable
      ? SocialHumanRightsTrainigColumnsReport
      : SocialHumanRightsTrainigColumns,
    local_communities: reportTable
      ? LocalCommunitiesColumnsReport
      : LocalCommunitiesColumns,
    political_contributions: reportTable
      ? PoliticalContributionColumnsReport
      : PoliticalContributionColumns,
    anti_corruption_disclosure: reportTable
      ? AntiCorruptionDisclosureColumnsReport
      : AntiCorruptionDisclosureColumns,
    anti_corruption_training: reportTable
      ? AntiCorruptionTrainigColumnsReport
      : AntiCorruptionTrainigColumns,
    anti_competitive_disclosure: reportTable
      ? AntiCompetitiveDisclosureColumnsReport
      : AntiCompetitiveDisclosureColumns,
    subsidies_financial_assistance: reportTable
      ? FinancialAssistanceColumnsReport
      : FinancialAssistanceColumns,
    employees_turnover: reportTable
      ? UploadFileEmissionColumnsReport
      : UploadFileEmissionColumns,
    age_based_statistics: reportTable
      ? UploadFileEmissionColumnsReport
      : UploadFileEmissionColumns,
    gender_based_statistics: reportTable
      ? UploadFileEmissionColumnsReport
      : UploadFileEmissionColumns,
    board_diversity: reportTable
      ? UploadFileEmissionColumnsReport
      : UploadFileEmissionColumns,
    management_diversity: reportTable
      ? UploadFileEmissionColumnsReport
      : UploadFileEmissionColumns,
    tax: reportTable
      ? UploadFileEmissionColumnsReport
      : UploadFileEmissionColumns,
    direct_economic_impact: reportTable
      ? UploadFileEmissionColumnsReport
      : UploadFileEmissionColumns,
    indirect_economic_impact: reportTable
      ? UploadFileEmissionColumnsReport
      : UploadFileEmissionColumns,
    employees_hire_and_turnover: reportTable
      ? UploadFileEmissionColumnsReport
      : EmployeeHireAndTurnover,
    employees_benefit: reportTable
      ? EmployeeBenefitsColumnsReport
      : EmployeeBenefits,
    training_hours_per_employee: reportTable
      ? TrainingHoursColumnsReport
      : TrainingHoursPerEmployee,
    performance_and_career_development_programs: reportTable
      ? PerformanceCareerColumnsReport
      : PerformanceAndCareerDevelopmentProgramsColumns,
    economic_impact: reportTable
      ? UploadFileEmissionColumnsReport
      : EconomicImpactColumns,    
  }
  ;

  const queryParams = new URLSearchParams(window.location.search);
  const selectedMonth = queryParams.get("month");
  const selectedFacility = queryParams.get("facility");
  const selectedYear = queryParams.get("year");
  const [displayWarning, setDisplayWarning] = useState(false);
  const [emissionId, setEmissionId] = useState(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const readOnlyMode = (getCookie("read_only_mode") === "disable_edit")

  const role = useSelector((state) => state.auth.role);
  const onConfirmDelete = () => {
    const requestData = {
      id: emissionId,
    };
    dispatch(deleteEmissions(requestData));
  };

  const deleteEmissionData = useSelector(
    (state) => state.emission.deleteEmissions
  );

  useEffect(() => {
    if (deleteEmissionData.status === STATUS.SUCCESS) {
      enqueueSnackbar(
        "Topic deleted successfully",
        {
          variant: "success",
        }
      );
      dispatch(resetEmissionDelete());
      
    } else if (deleteEmissionData.status === STATUS.ERROR) {
      enqueueSnackbar("We couldn't process your request. Please try again later.", { variant: "error" });
      dispatch(resetEmissionDelete());
    }
    setDisplayWarning(false)
  }, [deleteEmissionData, enqueueSnackbar,  dispatch]);

  const onSelectUploadHistory = () => {
    const queryParameters = [];
    if (selectedYear) {
      queryParameters.push(`year=${selectedYear}`);
    }
    if (selectedMonth) {
      queryParameters.push(`month=${selectedMonth}`);
    }
    if (selectedFacility) {
      queryParameters.push(`facility=${selectedFacility}`);
    }
    const queryString = queryParameters.join("&");
    isAuditor
      ? navigate(
        `/emissions/${emissionType}/uploadhistory?${queryString}`
      )
      : navigate(
        `/emissions/${emissionType}/uploadhistory?${queryString}`);
  };

  const onSelectEmissionData = (emission) => {
    if(isStakeholder){
      return
    }
    isAuditor
      ? navigate(
        `/emissions/${emissionType}/details/${emission.id}/?company=${company}`
      )
      : navigate(`/emissions/${emissionType}/details/${emission.id}`);
  };

  const onEditEmissionData = (e, emission) => {
    e.stopPropagation();
    e.preventDefault();
    navigate(`/emissions/edit/${emissionType}/${emission}`);
  };

  const uploadHistoryEmissionTypes = [
  "stationary_combustion",
  "mobile_combustion",
  "transportation",
  "purchased_electricity",
  "refrigerants",
];

  const getStatus = (status) => {
    if (status === 'approved') {
      return reportTable ? <Tooltip title="Approved" arrow><Chip className={classes.signalApproved}/></Tooltip>
        : <Typography className={classes.approved}>Approved</Typography>;
    } else if (status === 'submitted') {
        return reportTable ? <Tooltip title="Submitted" arrow><Chip className={classes.signalPending}/></Tooltip>
          : <Typography className={classes.pending}>Pending Approval</Typography>;
    } else if (status === 'audited') {
        return reportTable ? <Tooltip title="Audited" arrow><Chip className={classes.signalAudited}/></Tooltip>
          : <Typography className={classes.audited}>Audited</Typography>;
    } else if (status === 'pending') {
        return reportTable ? <Tooltip title="Pending Approval" arrow><Chip className={classes.signalPending}/></Tooltip>
          : <Typography className={classes.pending}>Pending Approval</Typography>;
    }else if (status === 'added') {
        return reportTable ? <Tooltip title="Added" arrow><Chip className={classes.signalAdded}/></Tooltip>
          : <Typography className={classes.added}>Added</Typography>;
    }else if (status === 'assigned') {
      return reportTable ? <Tooltip title="Assigned" arrow><Chip className={classes.signalPending}/></Tooltip>
        : <Typography className={classes.pending}>Pending Approval</Typography>;
    }else if (status === 'review') {
      return reportTable ? <Tooltip title="Review" arrow><Chip className={classes.signalPending}/></Tooltip>
        : <Typography className={classes.review}>Review</Typography>;
    }else if (status === 'Awaiting approval') {
      return reportTable ? <Tooltip title="Awaiting approval" arrow><Chip className={classes.signalPending}/></Tooltip>
        : <Typography className={classes.pending}>Pending Approval</Typography>;
    }else if (status === 'verified') {
      return reportTable ? <Tooltip title="Verified" arrow><Chip className={classes.signalVerified}/></Tooltip>
        : <Typography className={classes.verified}>Verified</Typography>;
    }else if (status === 'pending_approval') {
      return reportTable ? <Tooltip title="Pending approval" arrow><Chip className={classes.signalPending}/></Tooltip>
        : <Typography className={classes.pending}>Pending approval</Typography>;
    }
  else {
        return reportTable ? <Tooltip title="" arrow><Chip className={classes.signalPending}/></Tooltip>
         : <Typography className={classes.pending}>{status}</Typography>;
    } 
  };

  const getDate = (month, year) => {
    const selectedMonth = months?.filter((item) => item.key === month)    
    return (
     `${selectedMonth[0]?.value?.slice(0,3)}/${year}`)
  }

  const getEmissionData = () =>
    Array.isArray(emissionData)? (emissionData && emissionData?.map((item) => ({
      ...item,
      
      year: item.month ?  getDate(item.month, item.year) : `${item.year}`,
      amount: `${item.amount}  ${item.unit ? item.unit : ""}`,
      status: getStatus(item.status),
      date: item.date,
      treatment_required: item.treatment_required ? "Yes" : "No",
      action: (
          !readOnlyMode &&
          <Box className={classes.actionContainer}>
          {item.is_able_to_update === true &&
            !editOff && isOnlyAdmin &&(
              <IconButton
                className={classes.editIcon}
                onClick={(e) => onEditEmissionData(e, item.id)}
              >
                <CreateIcon />
              </IconButton>
            )}
            {isDeleteActionRequired &&
            (
              (item.status === "added" && (BusinessUserDelete || FacilityManagerDelete)) || 
              (
                !["audited", "reviewed"].includes(item.status) && 
                (role.includes('sustainability_manager') || role.includes('proxy_sustainability_manager'))
              )
            ) &&              
            // ((item.status === "added" && (BusinessUserDelete || FacilityManagerDelete)) || (!["audited", "reviewed"].includes(item.status) && (role.includes('sustainability_manager')) || role.includes('proxy_sustainability_manager'))) &&
              <IconButton
              className={classes.editIcon}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setEmissionId(item.id);
                setDisplayWarning(true)}}
            >
              <DeleteOutlineIcon />
            </IconButton>}
        </Box>
      ),
    }))):[];

    const getHeader = (emissionType) => {
      if(emissionType === "supplier_screening_using_enviormental_social_criteria"){
        return "Supplier Screening Using Environmental & Social Criteria"
      }else if(emissionType === "employee_health_safety_incident_record"){
        return "Employee Health and Safety Incident Record"
      }else if(emissionType === "subsidies_financial_assistance"){
        return "Subsidies and Financial Assistance"
      }else{
        return emissionType?.replace(/_/g, ' ')
      }
    }

  return (
    <>
      {dataStatus === STATUS.SUCCESS ? (
        <Box>
          {!headerOff && (
            <Box className={classes.tableTopic}>
              <Typography variant="h7" component="span">
              <span className={classes.tableTitle}>
                {getHeader(emissionType)}
              </span>
              </Typography>
              <Box>
             {!isAuditor && uploadHistoryEmissionTypes.includes(emissionType) &&
             <CeroButton
                buttonText="Upload History"
                variant="contained"
                className={classes.buttonPrimary}
                onClick={onSelectUploadHistory}
              />}
              <CeroButton
                buttonText="Back"
                variant="outlined"
                className={classes.buttonSecondary}
                startIcon={<ArrowBackIcon />}
                onClick={() => navigate(-1)}
              />
              </Box>
            </Box>
          )}
          <CeroTable
            columns={getTableColumn[props.emissionType] || []}
            data={getEmissionData}
            hasMore={false}
            loading={false}
            loadMore={onLoadMore}
            onSelectRow={onSelectEmissionData}
            classes={{ tableContainer: classes.tableContainer }}
          />
        </Box>
      ) : (
        <Box className={classes.loader}>
          <Typography variant="h7" component="span">
            {dataStatus === STATUS.RUNNING ? (
              <CircularProgress />
            ) : dataStatus === STATUS.ERROR ? (
              "Something went wrong. Please try again later"
            ) : (
              ""
            )}
          </Typography>
        </Box>
      )}
      {displayWarning && (
          <EmissionDeletePrompt
            isOpen={displayWarning}
            onClose={() => setDisplayWarning(false)}
            onConfirm={onConfirmDelete}
            setOpenDeletePrompt = {displayWarning}
          />
        )}
    </>
  );
};

export default EmissionTable;
