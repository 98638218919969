import { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Box,
  Tabs,
  Tab,
  Grid,
  CardContent,
  Card,
} from "@mui/material";

import DashboardLayout from "../../layouts/DashboardLayout";
import AccountSettings from "./AccountSettings";
import useStyles from "./styles";
import CompanySettingsForm from "./CompanySettingsForm";
import CeroButton from "../../components/CeroButton";
import profileIcon from "../../assets/icons/PROFILEICON.png";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { rolesEnum } from "../../layouts/DashboardLayout/pages";
import { getAccountDetails } from "../../redux/actions";
import { getCookie } from "../../services/cookie";

const Settings = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const userInfo = useSelector(state => state.auth.userInfo);
  const isAdmin = !userInfo.role.includes(rolesEnum.AUDITOR);
  const role = getCookie('role');
  const accountData = useSelector(state => state.account.accountDetails.data)
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  useEffect(() => {
    dispatch(getAccountDetails(isAdmin ? role === 'super_admin' ? 'super_admin' : 'business' : 'auditor'))
  }, [dispatch])

  return (
    <DashboardLayout>
    <div className={classes.backContainer} onClick={()=>{navigate(-1)}}>
    <ArrowBackIosIcon />
      Back
    </div>
      <Container className={classes.container}>
        <Grid container>
          <Grid item xs={4}>
            <Card className={classes.card}>
            <Typography variant="h5" className={classes.name}>{accountData.name}</Typography>
            <img src={profileIcon} alt="Logo" height="100" width="100" />
              <CardContent>
                <CeroButton
                buttonText="Upload New Photo"
                variant="contained"
                className={classes.button}
                />                  
              </CardContent>
              <CardContent>
                <Typography variant="body2" color="textSecondary" style={{background: '#F4F4F4', padding: 5}}>
                Uploads must be in either jpg or png formats and less than 1 MB.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h5" className={classes.editTitle}>
              Edit Profile
            </Typography>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                className={classes.tabs}
                onChange={handleChange}
                aria-label="basic tabs example"
                style={{backgroundColor: "#D0C5CA", paddingLeft: 10}}
              >
                <Tab label="Profile" id="profile" />
                {role !== 'super_admin' && (<Tab label="Company" id="compnay" />)}
              </Tabs>
            </Box>
            {value === 0 && <AccountSettings />}
            {value === 1 && <CompanySettingsForm />}
          </Grid>
        </Grid>
      </Container>
    </DashboardLayout>
  );
};

export default Settings;
