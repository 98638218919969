import {
  Box,
  Container,
  Grid,
  Paper,
  Tab,
  Tabs,
} from "@mui/material";
import useStyles from "./styles";
import DashboardLayout from "../../../layouts/DashboardLayout";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { APIEndpoints } from "../../../redux/constants";
import { getCookie } from "../../../services/cookie";
import { sampleYear, sampleTimeDurations } from "../../../constants";
import CeroButton from "../../../components/CeroButton";
import CeroSelect from "../../../components/CeroSelect";
import { useFormik } from "formik";
import { schemeValidation } from "./schema";
import CeroInput from "../../../components/CeroInput";
import {
  getMission,
  getTrackingCycleAssessmentPeriod,
} from "../../../redux/actions";
import Task from "./Task";
import ViewInitiative from "./ViewInitiative/index.";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import CeroAutoComplete from "../../../components/CeroAutoComplete";

const Initiatives = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const readOnlyMode = getCookie("read_only_mode") === "disable_edit";

  const { enqueueSnackbar } = useSnackbar();
  const [show, setShow] = useState(false);
  const [value, setValue] = useState(0);
  const [addedInitiatives, setAddedInitiatives] = useState([]);
  const [previousCountry, setPreviousCountry] = useState(null);

  const formik = useFormik({
    initialValues: {
      mission: "",
      country: "",
      facility: "",
      year: "",
      tracking: "",
      initiative: "",
      select_initiative: ""
    },
    validationSchema: schemeValidation,
    onSubmit: () => {},
  });

  const countryData = useSelector(
    (state) => state.listings.getOrganizationCountry.data.country_data
  );
  const countryFacilityData = useSelector(
    (state) => state.listings.getOrganizationCountry.data.country_facility_data
  );
  const countryFacilityDataObject = {};
  for (const key in countryFacilityData) {
    Object.assign(countryFacilityDataObject, countryFacilityData[key]);
  }

  const [facilitites, setFacility] = useState(countryFacilityDataObject);
  const [filterCountryFacility, setCountryFacility] = useState([]);
  const [filterCountry, setCountry] = useState([]);
  const [showInitiativeInput, setShowInitiativeInput] = useState(false);

  const getFacility = (countries) => {
    const countryFacilityDataObject = {};
    var availableFacility = [];
    for (const item of countries) {
      Object.assign(
        countryFacilityDataObject,
        countryFacilityData[countries[0]]
      );
      availableFacility = availableFacility.concat(
        Object.keys(countryFacilityData[countries[0]])
      );
    }

    const persistedfacilitySelection = availableFacility.filter((element) =>
      filterCountryFacility.includes(element)
    );
    setFacility(countryFacilityDataObject);
    setCountryFacility(persistedfacilitySelection);
  };

  const countryHandleChange = (event) => {
    const value = event.target.value;
    setCountry(value);
    getFacility([value]);
  };

  const countryOptions = Object.keys(countryData).map((item) => ({
    key: countryData[item],
    value: item,
  }));

  const listDropdownOptions = [
    "Adopt robotized production systems for streamlined operations",
    "Automate mine ventilation for energy-efficient air control",
    "Explore absorption chillers for cooling needs with reduced energy consumption",
    "Implement continuous mining practices for improved productivity",
    "Implement energy-management systems for efficient resource allocation",
    "Install high-efficiency pumps for better energy utilization",
    "Integrate electric and hydro-powered equipment for sustainable mining",
    "Invest in advanced cooling technologies like immersion cooling",
    "Invest in smart grid technologies for enhanced energy distribution",
    "Introduce advanced analytics to optimize energy consumption",
    "Optimize truck routes and operations for mining efficiency",
    "Replace traditional lighting with energy-efficient LEDs",
    "Upgrade to high-efficiency compressors and motors",
    "Upgrade to variable speed drives for flexible energy usage",
    "Utilize liquid-desiccant systems for humidity control and energy savings",
    "Other"
  ];

  const missionData = useSelector(
    (state) => state.goalSettings.getMission.data
  );

  const missionOptions = () => {
    const missionList = missionData?.missions?.map((item) => ({
      key: item.id,
      value: item.mission,
    }));
    return missionList;
  };

  const authToken = getCookie("auth_token_admin");

  const onSaveInitiatives = async (event) => {
    const requestData = {
      mission_id: formik.values.mission,
      country: filterCountry,
      facility_id: formik.values.facility,
      assessment_year: formik.values.year,
      tracking_cycle: formik.values.tracking,
      initiative: formik.values.select_initiative !== 'Other' ? formik.values.select_initiative : formik.values.initiative ,
    };
    try {
      const response = await axios.post(
        APIEndpoints.ADD_INITIATIVE,
        requestData,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      enqueueSnackbar(response?.data.message, { variant: "success" });
      setAddedInitiatives((addedInitiatives) => [
        ...addedInitiatives,
        {
          initiative: response.data.initiative,
          initiative_id: response.data.initiative_id,
          id: response.data.id,
        },
      ]);
      formik.setValues({
        ...formik.values,
        initiative: "",
      });
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  const resetInitiativeList = () => {
    setAddedInitiatives([]);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const facilitiesArray = Object.entries(facilitites).map(([key, value]) => ({
    key: value,
    value: key,
  }));

  // const initiativeList = useSelector(
  //   (state) => state.goalSettings.listInitiative.data
  // );

  // const listOptions = (initiativeList.map(item => item.initiative))

  useEffect(() => {
    const { tracking } = formik.values;
    if (tracking) {
      dispatch(getTrackingCycleAssessmentPeriod(tracking));
    }
  }, [formik.values.tracking, dispatch]);

  useEffect(() => {
    dispatch(getMission(filterCountry));
  }, [dispatch, filterCountry]);

  useEffect(() => {
    if (previousCountry !== filterCountry) {
      formik.setValues({
        ...formik.values,
        mission: "",
        facility: "",
        year: "",
        tracking: "",
        initiative: "",
      });
      setPreviousCountry(filterCountry);
    }
  }, [filterCountry, previousCountry]);

  return (
    <DashboardLayout>
      <Container className={classes.container}>
        <Paper
          className={classes.containerBox}
          style={{
            backgroundColor: (() => {
              switch (value) {
                case 0:
                  return "#F8F7F9";
                case 1:
                  return "#F8F7F9";
                default:
                  return "#E0E0E1";
              }
            })(),
          }}
        >
          <div className={classes.tabsContainer}>
            <Tabs
              value={value}
              className={classes.tabs}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons={false}
            >
              {["Add Initiative", "View Initiatives"].map((topic, index) => (
                <Tab
                  className={classes.tabOption}
                  key={index}
                  style={{
                    backgroundColor: value === index ? "#F8F7F9" : "#E0E0E0",
                    color: value === index ? "white" : "inherit",
                    flexGrow: 1,
                    border: value === index ? "3px solid #5A404E" : "none",
                    borderBottom:
                      value === index ? "none" : "3px solid #5A404E",
                  }}
                  label={
                    <div
                      className={classes.tabLabel}
                      onClick={() =>
                        navigate(
                          `/initiatives/${topic
                            .split(" ")
                            .join("-")
                            .toLowerCase()}`
                        )
                      }
                    >
                      <span className={classes.tabTitle}>{topic}</span>
                    </div>
                  }
                />
              ))}
            </Tabs>
            <div className={classes.tabBorder}></div>
          </div>
          <div className={classes.bottomBox}>
            {value === 1 && (
              <ViewInitiative resetInitiativeList={resetInitiativeList} />
            )}
            {value === 0 && (
              <Box>
                <Grid
                  container
                  columnSpacing={0.1}
                  alignItems="center"
                  gap={1}
                  justifyContent="center"
                  wrap="nowrap"
                ></Grid>
                <Grid className={classes.inputContainer} columnSpacing={0.1}>
                  <Grid
                    item
                    container
                    direction="column"
                    lg={11.7}
                    md={6}
                    xs={12}
                    height={10}
                  >
                    <CeroSelect
                      // required
                      id="country"
                      name="country"
                      label="Country"
                      fullWidth
                      size="small"
                      options={countryOptions}
                      selectedValue={filterCountry}
                      onChange={countryHandleChange}
                      onBlur={formik.handleBlur}
                      // error={formik.touched.country && formik.errors.country}
                      classes={{ container: classes.inputFieldContainer }}
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    direction="column"
                    lg={11.7}
                    md={6}
                    xs={12}
                  >
                    <CeroSelect
                      required
                      id="facility"
                      name="facility"
                      label="Facility"
                      fullWidth
                      size="small"
                      options={facilitiesArray}
                      selectedValue={formik.values.facility}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.facility && formik.errors.facility}
                      classes={{ container: classes.inputFieldContainer }}
                      sortOption={true}
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    direction="column"
                    lg={11.85}
                    md={6}
                    xs={12}
                    style={{ padding: "0px", gridColumn: "1 / span 2 " }}
                  >
                    <CeroSelect
                      id="mission"
                      name="mission"
                      label="Choose Mission"
                      fullWidth
                      size="small"
                      options={missionOptions()}
                      selectedValue={formik.values.mission}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.mission && formik.errors.mission}
                      classes={{ container: classes.missionInput, paperRoot: classes.paperRoot }}
                      sortOption={true}
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    direction="column"
                    lg={11.7}
                    md={6}
                    xs={12}
                  >
                    <CeroSelect
                      required
                      id="year"
                      name="year"
                      label="Assessment Year"
                      fullWidth
                      size="small"
                      options={sampleYear()}
                      selectedValue={formik.values.year}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.year && formik.errors.year}
                      classes={{ container: classes.inputFieldContainer }}
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    direction="column"
                    lg={11.7}
                    md={6}
                    xs={12}
                  >
                    <CeroSelect
                      required
                      id="tracking"
                      name="tracking"
                      label="Tracking Cycle"
                      fullWidth
                      size="small"
                      options={sampleTimeDurations}
                      selectedValue={formik.values.tracking}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.tracking && formik.errors.tracking}
                      classes={{ container: classes.inputFieldContainer }}
                    />
                  </Grid>
                </Grid>
                {addedInitiatives?.length > 0 && (
                  <Grid>
                    {addedInitiatives?.map((item) => (
                      <Task
                        id={item.id}
                        initiative_id={item.initiative_id}
                        initiative={item.initiative}
                      />
                    ))}
                  </Grid>
                )}
                {show && (
                  <Grid
                    item
                    container
                    direction="column"
                    lg={11.85}
                    md={6}
                    xs={12}
                  >
                    <Grid
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: 'column',
                      }}
                    >
                      <Grid style={{ width: "100%" }}>
                        <CeroAutoComplete
                          id="select_initiative"
                          name="select_initiative"
                          options={listDropdownOptions}
                          onChange={(e, value) =>{
                            formik.setFieldValue(
                              "select_initiative",
                              value
                            )
                            value==='Other' ? setShowInitiativeInput(true) : setShowInitiativeInput(false)
                          }
                          }
                          label="Select Initiative"
                          classes={{ container: classes.textAreaContainer2 }}
                        />
                      </Grid>
                      {showInitiativeInput && (
                      <Grid style={{ width: "100%" }}>
                        <CeroInput
                          required
                          id="initiative"
                          name="initiative"
                          placeholder="Enter Initiative"
                          value={formik.values.initiative}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          disabled= {formik.values.select_initiative && formik.values.select_initiative !== 'Other'}
                          error={
                            formik.touched.initiative &&
                            formik.errors.initiative
                          }
                          classes={{ container: classes.textAreaContainer }}
                        />
                      </Grid>
                        )}

                    </Grid>
                    <Box style={{ marginBottom: "30px" }}>
                      <CeroButton
                        buttonText="Save"
                        className={classes.saveButton}
                        onClick={() => {
                          setShow(false);
                          onSaveInitiatives();
                        }}
                        disabled={(!formik.dirty || !formik.isValid || (formik.values.select_initiative === 'Other' && !formik.values.initiative)) }
                      />
                      <CeroButton
                        buttonText="Cancel"
                        className={classes.cancelButton}
                        onClick={() => {
                          setShow(false);
                        }}
                      />
                    </Box>
                  </Grid>
                )}
                {!show && (
                  <Grid>
                    <CeroButton
                      buttonText="Add Initiatives"
                      className={classes.button}
                      onClick={() => {
                        setShow(true);
                        formik.setFieldValue(
                          "select_initiative",
                          null
                        );
                      }}
                      disabled={readOnlyMode}
                    />
                  </Grid>
                )}
              </Box>
            )}
          </div>
        </Paper>
      </Container>
    </DashboardLayout>
  );
};

export default Initiatives;
