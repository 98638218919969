import React, { useEffect } from "react";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CeroDropdown from "../../../components/CeroDropdown";
import { useSnackbar } from "notistack";
import { sampleYear } from "../../../constants";
import {
  Grid,
  Typography,
  Container,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  TableContainer,
  Paper,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "./styles";
import CeroButton from "../../../components/CeroButton";
import clsx from "clsx";
import * as Yup from "yup";
import {
  clearUpdateBoardDiversityStatus,
  getAssessmentPeriod, getEmission, updateBoardDiversity,
} from "../../../redux/actions";
import { STATUS } from "../../../redux/constants";
import DashboardLayout from "../../../layouts/DashboardLayout";

const EditBoardDiversity = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const facilitiesData = useSelector(
    (state) => state.listings.listFacilities.data
  );
  const assessmentData = useSelector(
    (state) => state.assessment.assessmentPeriod.data
  );

  const updateEmissionData = useSelector(
    (state) => state.emission.updateBoardDiversity
  );

  const emissionData = useSelector(
    (state) => state.emission.emissionDetails.data
  );

  const pathNameArr = pathname.split("/");
  const emissionType = pathNameArr[pathNameArr.length - 2];
  const emissionId = pathNameArr[pathNameArr.length - 1];

  const onCancel = () => {
    navigate('/emissions/' + emissionType);
};

  const dropdownOptions = {
    facilitiesList: facilitiesData
      ? facilitiesData.map((item) => ({ key: item.id, value: item.name }))
      : [],
    assessmentOptions: Array.isArray(assessmentData)
      ? assessmentData.map((item) => ({ key: item.period, value: item.period }))
      : [],
  };

  const validationSchema = Yup.object({
    facility_id: Yup.mixed().required("Facility is required"),
    // assessment_period: Yup.mixed().required('Field is required'),
    year: Yup.mixed().required("Year is required"),
    board_member_by_gender: Yup.object({
      total: Yup.number()
        .typeError("Must be a number")
        .min(0, "Cannot be less than 0"),
      male: Yup.number()
        .typeError("Must be a number")
        .min(0, "Cannot be less than 0"),
      female: Yup.number()
        .typeError("Must be a number")
        .min(0, "Cannot be less than 0"),
    }).test(
      "gender-total-validation",
      "Gender totals do not match",
      function (value) {
        const { total, male, female, other } = value;
        return total === male + female + other;
      }
    ),
    board_member_by_age: Yup.object({
      total: Yup.number()
        .typeError("Must be a number")
        .min(0, "Cannot be less than 0"),
      under_30: Yup.number()
        .typeError("Must be a number")
        .min(0, "Cannot be less than 0"),
      between_30_50: Yup.number()
        .typeError("Must be a number")
        .min(0, "Cannot be less than 0"),
    }).test(
      "age-total-validation",
      "Age totals do not match",
      function (value) {
        const { total, under_30, between_30_50, over_50 } = value;
        return total === under_30 + between_30_50 + over_50;
      }
    ),
  });

  const formik = useFormik({
    initialValues: {
      facility_id: emissionData?.facility_id || "",
      assessment_period: emissionData?.assessment_period || "",
      year:emissionData?.year ||  "",
      board_member_by_gender: { total: emissionData?.member_by_gender?.total || "", male: emissionData?.member_by_gender?.male ||"", female: emissionData?.member_by_gender?.female ||"", other: emissionData?.member_by_gender?.other ||"" },
      board_member_by_age: {
        total: emissionData?.member_by_age?.total ||"",
        under_30: emissionData?.member_by_age?.under_30 || "",
        between_30_50: emissionData?.member_by_age?.between_30_50 || "",
        over_50: emissionData?.member_by_age?.over_50 || "",
      },
  },
    validationSchema: validationSchema,
    // onSubmit: (values) => console.log(values),
    onSubmit: (values, { resetForm }) => {},
  });

  console.log('updateEmissionData', updateEmissionData)

  const handleSubmit = () => {
    const requestData = {
      facility_id: formik.values.facility_id,
      assessment_period: formik.values.assessment_period,
      year: formik.values.year,
      board_member_by_gender: {
        total: Number(formik.values.board_member_by_gender.total),
        male: Number(formik.values.board_member_by_gender.male),
        female: Number(formik.values.board_member_by_gender.female),
        other: Number(formik.values.board_member_by_gender.other),
      },
      board_member_by_age: {
        total: Number(formik.values.board_member_by_age.total),
        under_30: Number(formik.values.board_member_by_age.under_30),
        between_30_50: Number(formik.values.board_member_by_age.between_30_50),
        over_50: Number(formik.values.board_member_by_age.over_50),
      },
      save: true,
      id:emissionId
    };
    dispatch(updateBoardDiversity(requestData));
  };

  useEffect(() => {
    dispatch(getEmission({ emissionType, emissionId }));
}, [emissionType, emissionId, dispatch]);

  useEffect(() => {
    if (formik.values.facility_id && formik.values.year) {
      dispatch(
        getAssessmentPeriod(formik.values.year, formik.values.facility_id)
      );
    }
  }, [formik.values.year, formik.values.facility_id, dispatch]);

  useEffect(() => {
    const genderTotal = Number(formik.values.board_member_by_gender.total) || 0;
    const male = Number(formik.values.board_member_by_gender.male) || 0;
    const female = Number(formik.values.board_member_by_gender.female) || 0;
    const otherGender = Math.max(genderTotal - male - female, 0);

    formik.setFieldValue("board_member_by_age.total", genderTotal.toString());
    formik.setFieldValue(
      "board_member_by_gender.other",
      otherGender.toString()
    );

    const under_30 = Number(formik.values.board_member_by_age.under_30) || 0;
    const between_30_50 =
      Number(formik.values.board_member_by_age.between_30_50) || 0;
    const over_50 = Math.max(genderTotal - under_30 - between_30_50, 0);
    formik.setFieldValue("board_member_by_age.over_50", over_50.toString());
  }, [
    formik.values.board_member_by_gender,
    formik.values.board_member_by_age.under_30,
    formik.values.board_member_by_age.between_30_50,
    formik.setFieldValue,
  ]);

  useEffect(() => {
    if (updateEmissionData.status === STATUS.SUCCESS) {
      enqueueSnackbar("Board Diversity updated successfully", {
        variant: "success",
      });
      onCancel();
      // dispatch(resetAddBoardDiversityStatus())
      dispatch(clearUpdateBoardDiversityStatus())
    } else if (updateEmissionData.status === STATUS.ERROR) {
      enqueueSnackbar(
        updateEmissionData.message.message ||
          "We couldn't process your request. Please try again later.",
        { variant: "error" }
      );
      dispatch(clearUpdateBoardDiversityStatus())
      // dispatch(resetAddBoardDiversityStatus())
    }
  }, [updateEmissionData, dispatch, enqueueSnackbar]);

  useEffect(() => {
    const errorMessages = Object.values(formik.errors);
    if (formik.submitCount > 0 && errorMessages.length > 0) {
      enqueueSnackbar(errorMessages[0], { variant: "error" });
    }
  }, [formik.errors, formik.submitCount, enqueueSnackbar]);

  useEffect(() => {
    if (emissionData) {
      
          // formik.values.facility_id = emissionData.facility_id || "";
          formik.setFieldValue('facility_id', emissionData.facility_id);
          formik.setFieldValue('assessment_period', emissionData.assessment_period || "");
          formik.setFieldValue('year', emissionData.year || "");
          formik.setFieldValue('board_member_by_gender' , {
            total: emissionData.member_by_gender?.total || "",
            male: emissionData.member_by_gender?.male || "",
            female: emissionData.member_by_gender?.female || "",
            other: emissionData.member_by_gender?.other || "",
          });
          formik.setFieldValue('board_member_by_age', {
            total: emissionData.member_by_age?.total || "",
            under_30: emissionData.member_by_age?.under_30 || "",
            between_30_50: emissionData.member_by_age?.between_30_50 || "",
            over_50: emissionData.member_by_age?.over_50 || "",
          })
    }
      }, [emissionData]);

  const renderDropdown = (id, label, options, selectedValue, setFieldValue) => (
    <CeroDropdown
      id={id}
      label={label}
      fullWidth
      options={options}
      onChange={({ target }) => setFieldValue(id, target.value)}
      selectedValue={selectedValue}
    />
  );

  const getNestedErrorAndTouched = (name, formik) => {
    const keys = name.split(".");
    let touched = formik.touched;
    let error = formik.errors;

    for (let key of keys) {
      if (touched) {
        touched = touched[key];
      }
      if (error) {
        error = error[key];
      }
    }

    return { touched, error };
  };

  const renderInputCell = (name, placeholder) => {
    const { error, touched } = getNestedErrorAndTouched(name, formik);

    return (
      <TableCell>
        <input
          className={classes.input}
          placeholder={placeholder}
          name={name}
          type="text"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur} // Track when the field has been touched
          value={formik.getFieldProps(name).value || ""} // Use Formik's getFieldProps to correctly handle nested values
        />
        {/* Display error message if field has been touched and there is an error */}
        {touched && error ? (
          <div style={{ color: "red", fontSize: "10px" }}>{error}</div>
        ) : null}
      </TableCell>
    );
  };

  const renderTable = (title, inputs) => (
    <TableContainer component={Paper} className={clsx(classes.tableContainer)}>
      <Table stickyHeader>
        <TableHead>
          <TableRow className={classes.tableHeader}>
            <TableCell
              className={clsx(classes.headerCell)}
              style={{ width: "40%" }}
            >
              {title}
            </TableCell>
            {inputs.map((input, index) => (
              <TableCell
                key={index}
                className={clsx(classes.headerCell)}
                style={{ width: "15%", alignItems: "center" }}
              >
                {input.header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell
              className={clsx(classes.headerCell)}
              style={{ width: "30%" }}
            >
              <Typography variant="body2">
                Number of Board Members
              </Typography>
            </TableCell>
            {inputs.map((input, index) =>
              renderInputCell(input.name, input.placeholder)
            )}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <>
    <DashboardLayout>
      <div className={classes.backContainer} onClick={() => navigate(-1)}>
        <ArrowBackIosIcon /> Back
      </div>
      <Container className={classes.container}>
        <form onSubmit={formik.handleSubmit}>
          <Typography variant="h6">Board Diversity</Typography>
          <Grid
            className={classes.filterContainer}
            container
            columnSpacing={2}
            alignItems="center"
            wrap="nowrap"
          >
            <Grid item xs={3}>
              {renderDropdown(
                "assessment_period",
                "Assessment Period",
                dropdownOptions.assessmentOptions,
                formik.values.assessment_period,
                formik.setFieldValue
              )}
            </Grid>
            <Grid item xs={3}>
              {renderDropdown(
                "year",
                "Year",
                sampleYear(),
                formik.values.year,
                formik.setFieldValue
              )}
            </Grid>
            <Grid item xs={3}>
              {renderDropdown(
                "facility_id",
                "Facility",
                dropdownOptions.facilitiesList,
                formik.values.facility_id,
                formik.setFieldValue
              )}
            </Grid>
          </Grid>
          <Typography variant="h6" className={classes.sectionHeader}>
            Board Diversity By Gender
          </Typography>
          {renderTable("Board Diversity By Gender", [
            {
              header: "Total",
              name: "board_member_by_gender.total",
              placeholder: "Enter",
            },
            {
              header: "Male",
              name: "board_member_by_gender.male",
              placeholder: "Enter",
            },
            {
              header: "Female",
              name: "board_member_by_gender.female",
              placeholder: "Enter",
            },
            {
              header: "Other",
              name: "board_member_by_gender.other",
              placeholder: "Enter",
            },
          ])}
          <Typography variant="h6" className={classes.sectionHeader}>
            Board Diversity By Age Group
          </Typography>
          {renderTable("Board Diversity By Age Group", [
            {
              header: "Total",
              name: "board_member_by_age.total",
              placeholder: "Enter",
            },
            {
              header: "Under 30",
              name: "board_member_by_age.under_30",
              placeholder: "Enter",
            },
            {
              header: "Between 30 and 50",
              name: "board_member_by_age.between_30_50",
              placeholder: "Enter",
            },
            {
              header: "Above 50",
              name: "board_member_by_age.over_50",
              placeholder: "Enter",
            },
          ])}
          <CeroButton
            className={classes.buttonPrimary}
            // disabled={!formik.dirty || !formik.isValid}
            onClick={handleSubmit}
            buttonText="Update"
          />
          <CeroButton
            className={classes.buttonSecondary}
            onClick={formik.handleReset}
            buttonText="Clear"
          />
        </form>
      </Container>
    </DashboardLayout>
    </>
  );
};

export default EditBoardDiversity;
