import { createActions } from "redux-actions";

import { ActionTypes } from "../constants/actions";

export const {
  getAllReports,
  getReportDetails,
  deleteReport,
  resetReportStatus,
  createReport,
  updateReport,
  assignReport,
  generateAuditedReport,
  downloadAuditedReport,
  getStakeholderReportsList,
  getStakeholderAssignedReports,
  getStakeholderAuditedReports,
  getStakeholderTopicsSummary,
  getStakeholderTopicsData,
  getCumulativeTopics,
  getStakeholderCumulativeData,
  getReportListTopics,
  getReportEmissionDetails,
  getReportBlob,
  getNftUri,
  viewCertificate
} = createActions({
  [ActionTypes.GET_ALL_REPORTS]: (payload) => payload,
  [ActionTypes.GET_REPORT_DETAILS]: (id) => ({ id }),
  [ActionTypes.DELETE_REPORT]: (id) => ({ id }),
  [ActionTypes.RESET_REPORT_STATUS]: () => {},
  [ActionTypes.CREATE_REPORT]: (payload) => payload,
  [ActionTypes.UPDATE_REPORT]: (payload) => payload,
  [ActionTypes.GENERATE_AUDITED_REPORT]: (payload) => payload,
  [ActionTypes.DOWNLOAD_AUDITED_REPORT]: (payload) => payload,
  [ActionTypes.ASSIGN_REPORT]: (id) => ({ id }),
  [ActionTypes.GET_STAKEHOLDER_REPORTS_LIST]: (companyId, year) => ({
    companyId,
    year,
  }),
  [ActionTypes.GET_STAKEHOLDER_ASSIGNED_REPORTS]: (companyId, reportId) => ({
    companyId,
    reportId,
  }),
  [ActionTypes.GET_STAKEHOLDER_AUDITED_REPORTS]: (companyId, year) => ({
    companyId,
    year,
  }),
  [ActionTypes.GET_STAKEHOLDER_TOPICS_SUMMARY]: (companyId, type, payload) => ({
    companyId,
    type,
    payload,
  }),
  [ActionTypes.GET_STAKEHOLDER_TOPICS_DATA]: (companyId, topic_type, year) => ({
    companyId,
    topic_type,
    year,
  }),
  [ActionTypes.GET_CUMULATIVE_TOPICS]: (
    emissionType,
    year,
    isAuditor,
    facilities,
    period
  ) => ({ emissionType, year, isAuditor, facilities, period }),
  [ActionTypes.GET_STAKEHOLDER_CUMULATIVE_DATA]: (
    companyId,
    reportId,
    type
  ) => ({ companyId, reportId, type }),
  [ActionTypes.GET_REPORT_LIST_TOPICS]: (
    emissionType,
    filter,
    isAuditor,
    company,
    facilities,
    period,
    country,
    distribution
  ) => ({
    emissionType,
    filter,
    isAuditor,
    company,
    facilities,
    period,
    country,
    distribution
  }),
  [ActionTypes.GET_REPORT_EMISSION_DETAILS]: (emission_type, emission_id, distribution, classification) => ({
    emission_type,
    emission_id,
    distribution,
    classification
  }),
  [ActionTypes.GET_REPORT_BLOB]: (id) => ({ id }),
  [ActionTypes.GET_NFT_URI]: (payload) => payload,
  [ActionTypes.VIEW_CERTIFICATE]: (payload) => payload
});
