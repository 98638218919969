import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Link,
  Typography,
} from "@mui/material";

import useStyles from "./styles";
import DashboardLayout from "../../../layouts/DashboardLayout";
import { useEffect, useState } from "react";
import CeroTable from "../../../components/CeroTable";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { listInitiative } from "../../../redux/actions";
import { STATUS } from "../../../redux/constants";
import { sampleYear } from "../../../constants";
import CeroDropdown from "../../../components/CeroDropdown";

export const initiativesColumns = [
  {
    columnKey: "initiative_id",
    columnId: "initiative_id",
    columnHeader: "Initiative ID",
    width: "20%",
  },
  {
    columnKey: "initiative",
    columnId: "initiative",
    columnHeader: "Initiatives",
    width: "50%",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "Tracking",
    width: "20%",
  },
];

const InitiativeTracking = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [filterYear, setYear] = useState();
  const [facility, setFacility] = useState();
  const [country, setCountry] = useState();

  const initiativeList = useSelector(
    (state) => state.goalSettings.listInitiative.data
  );

  const initiativeListStatus = useSelector(
    (state) => state.goalSettings.listInitiative.status
  );

  const countryData = useSelector(
    (state) => state.listings.getOrganizationCountry.data.country_data
  );

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };

  const handleYearChange = (event) => {
    setYear(event.target.value);
  };

  const handleFacilityChange = (event) => {
    setFacility(event.target.value);
  };

  const countryOptions = Object.keys(countryData).map((item) => ({
    key: countryData[item],
    value: item,
  }));

  const facilitiesData = useSelector(
    (state) => state.listings.listFacilities.data
  );

  const facilitiesList = facilitiesData.map((item) => ({
    key: item.id,
    value: item.name,
  }));

  useEffect(() => {
    dispatch(listInitiative(filterYear, country, facility));
  }, [dispatch, filterYear, facility, country]);

  const onSelectData = (Id) => {
    navigate(`details/${Id}`);
  };

  const getInitiativesList = () => {
    if (!Array.isArray(initiativeList)) {
      return [];
    }

    return initiativeList.map((item) => ({
      ...item,
      action: (
        <Link
          className={classes.link}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onSelectData(item.id);
          }}
        >
          Click here
        </Link>
      ),
    }));
  };

  return (
    <DashboardLayout>
      <Container className={classes.container}>
        <Grid
          className={classes.filterContainer}
          container
          columnSpacing={2}
          alignItems="center"
          wrap="nowrap"
        >
          <Grid item xs={3} className={classes.yearFilter}>
            <CeroDropdown
              id="year"
              label="Year"
              fullWidth
              options={sampleYear()}
              onChange={handleYearChange}
              selectedValue={filterYear}
              classes={{container:classes.filterComponent}}
            />
          </Grid>
          <Grid item xs={3}>
            <CeroDropdown
              id="country"
              label="Country"
              fullWidth
              options={countryOptions}
              onChange={handleCountryChange}
              selectedValue={country}
              classes={{container:classes.filterComponent}}
            />
          </Grid>
          <Grid item xs={3}>
            <CeroDropdown
              id="facility"
              label="Facility"
              fullWidth
              options={facilitiesList}
              onChange={handleFacilityChange}
              selectedValue={facility}
              classes={{container:classes.filterComponent}}
            />
          </Grid>
        </Grid>
        {initiativeListStatus === STATUS.SUCCESS ? (
          <Container className={classes.tableContainer}>
            <CeroTable
              columns={initiativesColumns}
              data={getInitiativesList()}
              hasMore={false}
              loading={false}
              classes={{ tableContainer: classes.tableInnerContainer }}
            />
          </Container>
        ) : (
          <Box className={classes.loader}>
            <Typography variant="h7" component="span">
              {initiativeListStatus === STATUS.RUNNING ? (
                <CircularProgress />
              ) : initiativeListStatus === STATUS.ERROR ? (
                "We couldn't process your request. Please try again later"
              ) : (
                ""
              )}
            </Typography>
          </Box>
        )}
      </Container>
    </DashboardLayout>
  );
};

export default InitiativeTracking;
