import React, { useEffect } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Container, Grid, Typography, Box } from "@mui/material";
import { useSnackbar } from "notistack";
import { sampleYear } from "../../../constants";
import { STATUS } from "../../../redux/constants";
import {
  getAssessmentPeriod,
  resetAddCombustionStatus,
  getEmissionInputFormat,
  addEmployeeTrainingHours,
} from "../../../redux/actions";
import CeroAutoComplete from "../../../components/CeroAutoComplete";
import CeroButton from "../../../components/CeroButton";
import CeroSelect from "../../../components/CeroSelect";
import CeroInput from "../../../components/CeroInput";
import useStyles from "./styles";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CeroDate from "../../../components/CeroDate";
import { addEmployeeHours } from "./schema";

const AddEmployeeTrainingHour = (props) => {
  const { onCancel } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const facilitiesData = useSelector(
    (state) => state.listings.listFacilities.data
  );

  const addDataStatus = useSelector(
    (state) => state.emission.addEmployeeTrainingHours
  );

  const facilitiesList = facilitiesData.map((item) => ({
    key: item.id,
    value: item.name,
  }));

  const yearList = sampleYear().map((item) => ({
    id: item.key,
    label: item.value,
  }));

  const currentYear = new Date().getFullYear().toString()

  const formik = useFormik({
    initialValues: {
      facility: "",
      year: currentYear,
      assessmentPeriod: "",
      trainingTitle: "",
      trainingDetails: "",
      totalTrainingHours: 0,
      femaleTrainingHours: 0,
      maleTrainingHours: 0,
      date: "",
      trainingMethods: "",
    },
    validationSchema: addEmployeeHours,
    onSubmit: () => {},
  });

  useEffect(() => {
    if (addDataStatus.status === STATUS.SUCCESS) {
      enqueueSnackbar("Employee Training hours data added successfully", {variant: "success",});
      dispatch(resetAddCombustionStatus());
      onCancel();
    } else if (addDataStatus.status === STATUS.ERROR) {
      enqueueSnackbar(
        addDataStatus.message.message || "We couldn't process your request. Please try again later.", {variant: "error",}
      );
    }
  }, [addDataStatus, dispatch, enqueueSnackbar, onCancel]);

  useEffect(() => {
    const { facility, year } = formik.values;
    if (facility && year) {
      dispatch(getAssessmentPeriod(year, facility));
    }
  }, [formik.values.facility, formik.values.year, dispatch]);

  useEffect(() => {
    const { facility, year, assessmentPeriod } = formik.values;
    if (facility && year && assessmentPeriod) {
      dispatch(getEmissionInputFormat("training_hours_per_employee", assessmentPeriod, year, facility));
    }
  }, [formik.values.facility, formik.values.year, formik.values.assessmentPeriod, dispatch]);

  const assessmentData = useSelector(
    (state) => state.assessment.assessmentPeriod.data
  );

  const assessmentOptions = Array.isArray(assessmentData)
    ? assessmentData.map((item) => ({
        key: item.period,
        value: item.period,
      }))
    : [];

  const onAddData = () => {
    const requestData = {
      facility_id: formik.values.facility,
      year: formik.values.year,
      assessment_period : formik.values.assessmentPeriod,
      training_title :formik.values.trainingTitle,
      training_details :formik.values.trainingDetails,
      total_training_hours :formik.values.totalTrainingHours,
      female_training_hours :formik.values.femaleTrainingHours,
      male_training_hours :formik.values.maleTrainingHours,
      date :formik.values.date.toISOString().split('T')[0],
      training_methods :formik.values.trainingMethods,
      save: true,
    };
    sessionStorage.setItem('year', requestData.year)
    dispatch(addEmployeeTrainingHours(requestData));
  };

  const TrainingMethods = [
    { key: "on the job training", value: "On the job Training" },
    { key: "online courses", value: "Online courses" },
    { key: "workshops", value: "Workshops" },
  ];


  return (
    <>
      <div
        className={classes.backContainer}
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowBackIosIcon />
        Back
      </div>
      <Container className={classes.container}>
        <Box className={classes.innerContainer}>
          <Typography className={classes.title} variant="h6" component="div">
            Training hours per employee
          </Typography>
          <Box className={classes.topContainer}>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column" lg={6} md={6} xs={4}>
                <CeroSelect
                  required
                  id="facility"
                  name="facility"
                  label="Facility"
                  fullWidth
                  options={facilitiesList}
                  selectedValue={formik.values.facility}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.facility && formik.errors.facility}
                />
              </Grid>
              <Grid item container direction={"column"} lg={6} md={6} xs={4}>
                <CeroAutoComplete
                  id="year"
                  label="Assessment Year"
                  onChange={(e, value) =>
                    formik.setFieldValue("year", value.id)
                  }
                  onBlur={formik.handleBlur}
                  error={formik.errors.year}
                  options={yearList}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={formik.values.year}
                />
              </Grid>
              <Grid item container direction="column" lg={6} md={6} xs={4}>
                <CeroSelect
                  required
                  id="assessmentPeriod"
                  name="assessmentPeriod"
                  label="Assessment Period"
                  fullWidth
                  options={assessmentOptions}
                  selectedValue={formik.values.assessmentPeriod}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.assessmentPeriod &&
                    formik.errors.assessmentPeriod
                  }
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column"  lg={12} md={12} xs={12}>
                <CeroInput 
                name="trainingTitle" 
                label="Training Title" 
                id="trainingTitle"
                value={formik.values.trainingTitle}
                fullWidth
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.trainingTitle && formik.errors.trainingTitle}
                classes={{ container: classes.textAreaContainer }}/>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column"  lg={12} md={12} xs={12}>
                <CeroInput 
                required
                name="trainingDetails" 
                label="Training Details" 
                id="trainingDetails"
                value={formik.values.trainingDetails}
                fullWidth
                multiline
                rows="3"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.trainingDetails && formik.errors.trainingDetails}
                classes={{ container: classes.textAreaContainer }}/>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column"  lg={6} md={6} xs={12}>
                <CeroDate
                                  name="date"
                                  value={formik.values.date}
                                  label="Training Date"
                                  assessmentPeriod={formik.values.assessmentPeriod}
                                  year={formik.values.year}
                                  onChange={(date) => formik.setFieldValue("date", date)}
                                  error={formik.touched.date && formik.errors.date}
                              />
              </Grid>
              <Grid item container direction="column"  lg={6} md={6} xs={12}>
                <CeroSelect
                    required
                    id="trainingMethods"
                    name="trainingMethods"
                    label="Training Methods"
                    options={TrainingMethods}
                    selectedValue={formik.values.trainingMethods}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column"  lg={6} md={6} xs={12}>
                <CeroInput 
                name="totalTrainingHours" 
                label="Total Number of training hours to employees" 
                id="totalTrainingHours"
                value={formik.values.totalTrainingHours}
                fullWidth
                type="number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.totalTrainingHours && formik.errors.totalTrainingHours}
                classes={{ container: classes.textAreaContainer }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column"  lg={6} md={6} xs={12}>
                <CeroInput 
                name="femaleTrainingHours" 
                label="Total Number of training hours to female employees" 
                id="femaleTrainingHours"
                value={formik.values.femaleTrainingHours}
                fullWidth
                type="number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.femaleTrainingHours && formik.errors.femaleTrainingHours}
                classes={{ container: classes.textAreaContainer }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column"  lg={6} md={6} xs={12}>
                <CeroInput 
                name="maleTrainingHours" 
                label="Total Number of training hours to male employees" 
                id="maleTrainingHours"
                value={formik.values.maleTrainingHours}
                fullWidth
                type="number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.maleTrainingHours && formik.errors.maleTrainingHours}
                classes={{ container: classes.textAreaContainer }}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box className={classes.buttonContainer}>
          <CeroButton
            buttonText="Add Data"
            className={classes.buttonPrimary}
            onClick={() => onAddData()}
            disabled={!formik.isValid || !formik.dirty}
          />
          <CeroButton
            buttonText="Clear"
            variant="outlined"
            className={classes.buttonSecondary}
            onClick={formik.resetForm}
          />
        </Box>
      </Container>
    </>
  );
};

export default AddEmployeeTrainingHour;
