import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CeroTable from "../../../components/CeroTable";
import useStyles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { getRoleBasedAggregate } from "../../../redux/actions";
import DashboardLayout from "../../../layouts/DashboardLayout";
import { Container, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { STATUS } from "../../../redux/constants";
import { getCookie } from "../../../services/cookie";

export const TableColumns = [
  {
    columnKey: "userType",
    columnId: "userType",
    columnHeader: "User Type",
  },
  {
    columnKey: "totalUsers",
    columnId: "totalUsers",
    columnHeader: "Total Number of Users",
  },
  {
    columnKey: "activeSessions",
    columnId: "activeSessions",
    columnHeader: "Active Sessions",
  },
];

const ActiveSessionsTable = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { companyId, countryName, id } = useParams();
  const { onLoadMore } = props;
  const facilityName = getCookie('facility_name')
  const usersData = useSelector(
    (state) => state.superAdmin.getRoleBasedAggregate.data
  );
  const userDataStatus = useSelector(
    (state) => state.superAdmin.getRoleBasedAggregate.status
  );

  useEffect(() => {
    dispatch(getRoleBasedAggregate(companyId, id));
  }, [dispatch, companyId, id]);

  const isActive = false
  const onSelectUser = (row) => {
    navigate(
      `/companies/${companyId}/country/${countryName}/facilities/${id}/${row.user}/${isActive}`
    );
  };

  const getUserDetails = () => {
    return Object.keys(usersData).map((userType) => ({
      userType: userType.replace(/_/g, " "),
      user: userType,
      totalUsers: usersData[userType].total_user || 0,
      activeSessions: usersData[userType].active_session || 0,
    }));
  };

  return (
    <DashboardLayout>
      <div
        className={classes.backContainer}
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowBackIosIcon />
        Back
      </div>
      <Container className={classes.container}>
        <Typography variant="h6">
          <span style={{cursor: "pointer"}} onClick={()=>navigate(-1)}>{countryName}</span> > <span style={{fontWeight: "600", textTransform: "capitalize"}}>
            {facilityName}</span></Typography>
        {userDataStatus === STATUS.SUCCESS && (
          <CeroTable
            columns={TableColumns}
            data={getUserDetails()}
            hasMore={false}
            loading={false}
            loadMore={onLoadMore}
            onSelectRow={onSelectUser}
            classes={{ tableContainer: classes.tableContainer,
              tableBodyCell: classes.tableBodyCell
             }}
          />
        )}
      </Container>
    </DashboardLayout>
  );
};

export default ActiveSessionsTable;
