import { Box, Container, Grid, Stack, Switch, Typography } from "@mui/material";
import CeroButton from "../../../components/CeroButton";
import useStyles from "./styles";
import { getCookie } from "../../../services/cookie";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CeroInput from "../../../components/CeroInput";
import DashboardLayout from "../../../layouts/DashboardLayout";
import CeroSelect from "../../../components/CeroSelect";
import CeroAutoComplete from "../../../components/CeroAutoComplete";
import CeroChipSelect from "../../../components/CeroChipSelect";
import AddIcon from "@mui/icons-material/Add";
import { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditActionButtons from "./EditActionButtons";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { editUser, editUserStakeholder, getOrganizationCountry, getUserDetails, proxyLoginAccessSuperadmin, resetProxyLoginAccessSuperadmin, resetUserStatus } from "../../../redux/actions";
import CeroPopUp from "../../../components/CeroPopUp";
import WarningIcon from "../../../assets/icons/WarningIcon.png";
import CloseIcon from "@mui/icons-material/Close";
import { useSnackbar } from "notistack";
import { STATUS } from "../../../redux/constants";

const EditUser = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [deletePopup, setDeletePopup] = useState(false);
  const [roleDeletePopup, setRoleDeletePopup] = useState(false);
  const [deleteRow, setDeleteRow] = useState(null);
  const [deleteCountryIndx, setDeleteCountryIndx] = useState("");
  const [countryName, setCountryName] = useState("")
  const [proxyChangePopup ,setProxyChangePopup] = useState(false)

  const queryParams = new URLSearchParams(window.location.search);
  const status = queryParams.get("status");
  const userData = useSelector((state) => state.users.userDetails.data);
  const editStatus = useSelector((state) => state.users.editUser);
  const countryFacilityDataObject = {};
  const countryFacilityData = useSelector(
    (state) => state.listings.getOrganizationCountry.data.country_facility_data
  );

  const facilities = useSelector(
    (state) => state.listings.getOrganizationCountry.data.country_facility_data
  );

  const editUserStakeholderStatus = useSelector((state)=>state.users.editUserStakeholder)
  
  const mapFacilities = (countryCode, facilityIds) => {
    if (Array.isArray(facilityIds)) {
    const facilities = countryFacilityData[countryCode];
    return facilities?facilityIds?.map(id => {
      for (const [name, facilityId] of Object.entries(facilities)) {
        if (facilityId === id) {
          return name;
        }
      }
      return id; 
    }):{};
    } else {
      return []; 
    }
  };

  
  const updateUserData = (userData) => {
    if (!userData.role) {
      return userData; 
    }

    const updatedUserData = { ...userData };
    const roles = Object.keys(updatedUserData?.role);
  
    roles.forEach(role => {
      updatedUserData.role[role] = updatedUserData.role[role].map(item => ({
        ...item,
        facilities: mapFacilities(item.country_code, item.facilities)
      }));
    });
  
    return updatedUserData;
  };
  
  const newUserData = updateUserData(userData);  
  const proxyLoginStatus = useSelector((state) => state.users.proxyLoginAccessSuperadmin);
  const [proxyToggleValue ,setProxyToggleValue] = useState(userData.enable_proxy_login)

  const countries = useSelector(
    (state) =>
      state.listings.getOrganizationCountry.data.country_code_corresponding_name
  );
  for (const key in countryFacilityData) {
    Object.assign(countryFacilityDataObject, countryFacilityData[key]);
  }
  const [facilitites, setFacility] = useState(countryFacilityDataObject);

  const roles = [
    { key: "admin", value: "Business Admin" },
    { key: "approver", value: "Business Approver" },
    { key: "facility_manager", value: "Business Facility Manager" },
    { key: "sustainability_manager", value: "Business Sustainability Manager" },
    { key: "business_user", value: "Business User" },
    { key: "read_only_user", value: "Stakeholder" },
  ];

  const [formData, setFormData] = useState({
    email: "",
    name: "",
    roles: [],
    roleType: "",
  });

  const [initialFormData, setInitialFormData] = useState({    
    email: "",
    name: "",
    roles: [],
    roleType: "",
  });

  const initialData = {
    email: userData.email || "",
    name: userData.name || "",
    roles: Object.keys(userData.role || {}).map((roleKey) => ({
      role: roleKey,
      countries: (userData.role[roleKey] || []).map((country) => ({
        country_code: country.country_code || "",
        country_name: country.country_name || "",
        facilities: country.facilities || "",
      })),
    })),
    roleType: userData.stakeholder_type || "",
  };

  useEffect(() => {
    if (userData) {
      const initialData = {
        email: userData.email || "",
        name: userData.name || "",
        roles: Object.keys(userData.role || {}).map((roleKey) => ({
          role: roleKey,
          countries: (userData.role[roleKey] || []).map((country) => ({
            country_code: country.country_code || "",
            country_name: country.country_name || "",
            facilities: country.facilities || "",
          })),
        })),
        roleType: userData.stakeholder_type || "",
      };
      setFormData(initialData);
      setInitialFormData(initialData)
    }
  }, [userData]);

  const areObjectsEqual = (formData, initialFormData) => {
    return JSON.stringify(formData) === JSON.stringify(initialFormData);
}

const [updateDisabled, setUpdateDisabled] = useState(true)

useEffect(() => {
const isEqual = areObjectsEqual(formData, initialData);

const roleFlag = formData.roles.every(roleEntry => (roleEntry.role.trim() !== "") && (['business_user', 'facility_manager', 'sustainability_manager', 'approver'].includes(roleEntry.role.trim())?roleEntry.countries?.[0]?.facilities?.length>0:true));
setUpdateDisabled((formData.roles.length===initialData.roles.length && isEqual || !roleFlag))
}, [formData, updateDisabled]);


const countryOptions = countries && Object.entries(countries)?.map(
  ([countryCode, countryName]) => ({
    id: countryCode,
    label: countryName,
  })
);

  const handleRoleTypeChange = (e) => {
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      roleType: value,
    }));
  };

  
  const facilitiesOptions = (countryCode) => {
    if (countryCode && countryFacilityData && countryFacilityData[countryCode]) {
      return Object.entries(countryFacilityData[countryCode]).map(([label, id]) => ({
        id,
        label,
      }));
    } else {
      return [];
    }
  };

  const facilitiesObject = (countryCode) =>
    countryCode && facilitiesOptions(countryCode).reduce((acc, { id, label }) => {
      acc[label] = id;
      return acc;
    }, {});

  // Function to handle changes in form inputs
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Function to handle role change
  const handleRoleChange = (index, value) => {
    const newRoles = [...formData.roles];
    newRoles[index].role = value;
    setFormData((prevState) => ({
      ...prevState,
      roles: newRoles,
    }));

  };

  // Function to handle country change
  const handleCountryChange = (e, value, roleIndex, countryIndex) => {
    const newRoles = [...formData.roles];
    newRoles[roleIndex].countries[countryIndex] = {
      ...newRoles[roleIndex].countries[countryIndex],
      country_code: value ? value.id : "",
      country_name: value ? value.label : "",
      facilities: [],
    };
  
    setFormData((prevState) => ({
      ...prevState,
      roles: newRoles,
    }));

  };
  
  const invertedFacilityMapping = {};
  Object.keys(facilities).forEach(country => {
    Object.keys(facilities[country]).forEach(name => {
      invertedFacilityMapping[facilities[country][name]] = name;
    });
  });
  
  // Function to handle facility change
  const handleFacilityChange = (value, roleIndex, countryIndex) => {
    const newRoles = [...formData.roles];
    newRoles[roleIndex].countries[countryIndex] = {
      ...newRoles[roleIndex].countries[countryIndex],
      facilities: value,
    };
  
    setFormData((prevState) => ({
      ...prevState,
      roles: newRoles,
    }));
  };
  
  const handleAddRole = () => {
    const newRole = {
      role: "", 
      countries: [
        {
          country_code: "",
          country_name: "",
          facilities: [],
        },
      ],
    };

    setFormData((prevState) => ({
      ...prevState,
      roles: [...prevState.roles, newRole],
    }));
  };

  // Function to add a new country with facilities under a specific role
  const addCountryFacility = (roleIndex) => {
    const newCountryFacility = {
      country_code: "",
      country_name: "",
      facilities: [],
    };
  
    const newRoles = [...formData.roles];
    newRoles[roleIndex] = {
      ...newRoles[roleIndex],
      countries: [...newRoles[roleIndex].countries, newCountryFacility],
    };
  
    setFormData((prevState) => ({
      ...prevState,
      roles: newRoles,
    }));
  };

  useEffect(() => {
    setTimeout(()=> {
      dispatch(getUserDetails(id));

    }, 2000)
  }, [id, proxyLoginStatus, dispatch]);


  useEffect(() => {
    if (id) {
      dispatch(getUserDetails(id));
    }
    dispatch(getOrganizationCountry())
  }, [id, proxyLoginStatus, dispatch]);

  const onUpdateData = () => {
    const requestData = {
      email: formData.email,
      name: formData.name,
      role: formData.roles.reduce((acc, roleObj) => {
        const { role, countries } = roleObj;
        acc[role] = countries.map((country) => ({
          country_code: country.country_code,
          facilities: country.facilities.map(
            (facility) => facilitites[facility]
          ),
        }));
        return acc;
      }, {}),
      stakeholder_type: formData.roleType,
    };
    if(formData.roles.some((role) => role.role === "read_only_user")){
      dispatch(editUserStakeholder(id, requestData))
    }else{
    dispatch(editUser(id, requestData)); 
    }
  };

  const handleDeleteCountryFacility = (roleIndex, countryIndex) => {
    const newRoles = [...formData.roles];
    newRoles[roleIndex].countries.splice(countryIndex, 1);

    setFormData((prevState) => ({
      ...prevState,
      roles: newRoles,
    }));
    enqueueSnackbar("Country and its facilities deleted !", {
      variant: "success",
      autoHideDuration: 3000,
    });
    setDeleteRow(null);
    setDeleteCountryIndx("");
  };

  const deleteRole = (roleIndex) => {
    const newRoles = [...formData.roles];
    newRoles.splice(roleIndex, 1);

    setFormData((prevState) => ({
      ...prevState,
      roles: newRoles,
    }));
    enqueueSnackbar("Role deleted !", {
      variant: "success",
      autoHideDuration: 3000,
    });
    setDeleteRow(null);
  };

  const getDeleteContent = () => {
    return (
      <>
        <CloseIcon
          onClick={() => setDeletePopup(false)}
          className={classes.closeIcon}
        />
        <Grid
          container
          spacing={2}
          alignItems="center"
          className={classes.deleteBox}
        >
          <Grid item xs={2} className={classes.icon}>
            <img src={WarningIcon} alt="Logo" width="40" height="40" />
          </Grid>
          <Grid item xs={10}>
            <Typography variant="h6">Delete Country & Facility</Typography>
            <Typography>
              Are you sure you want to{" "}
              <span style={{ fontWeight: 500 }}>DELETE</span> the country "{countryName}" and
              its facilities?
            </Typography>
          </Grid>
        </Grid>
        <div
          className={classes.buttonContainer}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <CeroButton
            className={classes.buttonPrimary}
            variant="contained"
            onClick={() => {
              setDeletePopup(false);
              handleDeleteCountryFacility(deleteRow, deleteCountryIndx);
              setCountryName("")
            }}
            buttonText="Confirm"
          />
          <CeroButton
            className={classes.buttonSecondary}
            variant="outlined"
            onClick={() => setDeletePopup(false)}
            buttonText="Cancel"
          />
        </div>
      </>
    );
  };

  const roleDeleteContent = () => {
    return (
      <>
        <CloseIcon
          onClick={() => setRoleDeletePopup(false)}
          className={classes.closeIcon}
        />
        <Grid
          container
          spacing={2}
          alignItems="center"
          className={classes.deleteBox}
        >
          <Grid item xs={2} className={classes.icon}>
            <img src={WarningIcon} alt="Logo" width="40" height="40" />
          </Grid>
          <Grid item xs={10}>
            <Typography variant="h6">Delete Role</Typography>
            <Typography>
              Are you sure you want to{" "}
              <span style={{ fontWeight: 500 }}>DELETE</span> this role?
            </Typography>
          </Grid>
        </Grid>
        <div
          className={classes.buttonContainer}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <CeroButton
            className={classes.buttonPrimary}
            variant="contained"
            onClick={() => {
              setRoleDeletePopup(false);
              deleteRole(deleteRow);
            }}
            buttonText="Confirm"
          />
          <CeroButton
            className={classes.buttonSecondary}
            variant="outlined"
            onClick={() => setDeletePopup(false)}
            buttonText="Cancel"
          />
        </div>
      </>
    );
  };

  const proxyChangeContent = () => {
    return(
      <>
            <Typography style={{ textAlign: "center"}}>
              Are you sure you want to{" "}
              <span style={{ fontWeight: 500 }}>{userData.enable_proxy_login !== true ? "Allow" : "Deny"}</span> Proxy Login
            </Typography>
        <div
          className={classes.buttonContainer}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <CeroButton
            className={classes.yesButton}
            variant="contained"
            onClick={() => {
              setProxyChangePopup(false);
              handleToggleStatus(id, proxyToggleValue)
              setProxyToggleValue(userData.enable_proxy_login)
            }}
            buttonText="Yes"
          />
          <CeroButton
            className={classes.noButton}
            variant="outlined"
            onClick={() => setProxyChangePopup(false)}
            buttonText="No"
          />
        </div>
      </>
    )
  }


  const handleToggleStatus = (id, status) => {
    const requestData = {
      id: id,
      status: status,
    };
    dispatch(proxyLoginAccessSuperadmin(requestData));
  };

  useEffect(() => {
    if (proxyLoginStatus.status === STATUS.SUCCESS) {
      setTimeout(() => {
        enqueueSnackbar(proxyLoginStatus.data.message, {
          variant: "success",
          autoHideDuration: 3000,
        });
      }, 3000);
      dispatch(resetProxyLoginAccessSuperadmin());
    } else if (proxyLoginStatus.status === STATUS.ERROR) {
      setTimeout(() => {
        enqueueSnackbar(proxyLoginStatus.message.message, {
          variant: "error",
          autoHideDuration: 1000,
        });
      }, 1000);
      dispatch(resetProxyLoginAccessSuperadmin());
    }
  }, [proxyLoginStatus, enqueueSnackbar, dispatch]);

  
  useEffect(() => {
    if (editStatus.status === STATUS.SUCCESS || editUserStakeholderStatus.status === STATUS.SUCCESS) {
      enqueueSnackbar(editStatus.data.message || "User details updated successfully", {
        variant: "success",
        autoHideDuration: 3000,
      });
      dispatch(resetUserStatus());
      navigate("/users")
    } else if (editStatus.status === STATUS.ERROR || editUserStakeholderStatus.status === STATUS.ERROR) {
      enqueueSnackbar(editStatus.message? editStatus.message.message:editUserStakeholderStatus.message.message, {
        variant: "error",
        autoHideDuration: 1000,
      });
    }
  }, [editStatus, editUserStakeholderStatus, enqueueSnackbar, dispatch]);

  const mapFacilityIdsToNames = (index, countryIndex) => {
    const countryData = formData.roles[index]?.countries[countryIndex];
    const selectedFacilities = formData.roles[index]?.countries[countryIndex]?.facilities;
    return selectedFacilities.map(id => facilities[countryData.country_code][id]);
  };

  const roleTypeOptions = [
    { key: "internal", value: "Internal" },
    { key: "external", value: "External" },
  ];

  const filterRoles = (currentRoleIndex) => {
    const selectedRoles = formData.roles.map((r, index) => {
      if (index !== currentRoleIndex) {
        return r.role;
      }
    });
    const filteredRoles = roles.filter((role) => !selectedRoles.includes(role.key) && role.key !== "read_only_user")
    return filteredRoles
  };

  const getUserStatus = (status) => {
    if(status === "Active"){
      return <Typography className={classes.activeStatus}>Active</Typography>
    }else if(status === "Locked"){
      return <Typography className={classes.lockedStatus}>Locked</Typography>
    }else if(status === "Invited"){
      return <Typography className={classes.invitedStatus}>Invited</Typography>
    }else {
      return <Typography className={classes.inactiveStatus}>In active</Typography>
    }
  }

  const isAdmin = formData.roles.some((role) => role.role === "admin")
  const userDetails = getCookie('user_details');
  const userDetailsObj = JSON.parse(userDetails);
  const editEmail = userDetailsObj.email;  

  return (
    <DashboardLayout>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          className={classes.backContainer}
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBackIosIcon />
          Back
        </div>
        <EditActionButtons userId={id} status={status} isAdmin={isAdmin} userEmail={userData.email}/>
      </div>
      <Container className={classes.container}>
        <Box className={classes.innerContainer}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography className={classes.title} variant="h6" component="div">
              Edit User
            </Typography>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                className={classes.title}
                variant="subtitle1"
                component="div"
              >
                Status:
              </Typography>
              {getUserStatus(status)}
            </div>
          </div>

          <Box>
          <div style={{ display:"flex", width: "85%"}}>
                <CeroInput
                  required
                  id="email"
                  name="email"
                  label="Email"
                  value={formData.email}
                  onChange={handleInputChange}
                  classes={{ container: classes.textAreaContainer }}
                  disabled
                />
                <CeroInput
                  required
                  id="name"
                  name="name"
                  label="Name"
                  value={formData.name}
                  onChange={handleInputChange}
                  classes={{ container: classes.textAreaContainer }}
                />
            </div>

            {formData.roles.map((roleBox, index) => (
              <Box key={index} className={classes.roleBox}>
                <Grid container spacing={2} display="flex">
                {roleBox.role.includes("sustainability_manager") && (
                  <>
                  <div className={classes.roleActions}>
                    <Typography  style={{ fontWeight: "bold", marginRight: 10 }}>Proxy Login:</Typography>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography
                      style={{ fontWeight: !userData.enable_proxy_login ? "500" : "normal" }}
                      className={classes.statusText}
                      >
                        Deny
                      </Typography>
                      <Box
                        onClick={(e) => {
                          e.stopPropagation();
                          setProxyChangePopup(true)
                          setProxyToggleValue(!userData.enable_proxy_login)
                        }}
                      >
                        <Switch
                          checked={userData?.enable_proxy_login}
                          className={classes.switchColor}
                          inputProps={{ "aria-label": "Toggle Status" }}
                          onChange={(e) => {
                            setProxyChangePopup(true)
                            setProxyToggleValue(e.target.checked)
                          }}
                        />
                      </Box>
                      <Typography
                      style={{ fontWeight: userData.enable_proxy_login ? "500" : "normal" }}
                      className={classes.statusText}
                      >
                        Allow
                      </Typography>
                    </Stack></div>
                    </>)}
                  {!roleBox.role.includes("read_only_user") && (editEmail !== formData.email) && <CeroButton
                    buttonText="Delete Role"
                    className={classes.roleDeletebtn}
                    onClick={() => {
                      setRoleDeletePopup(true);
                      setDeleteRow(index);
                      }}
                  />}
                  </Grid>
                  <Grid container spacing={2} display="flex">
                  <Grid item xs={6}>
                    <CeroSelect
                      required
                      id={`role-${index}`}
                      name={`role-${index}`}
                      label="Role"
                      fullWidth
                      options={!roleBox.role.includes("read_only_user") ? filterRoles(index) : roles} 
                      selectedValue={roleBox.role}
                      onChange={(e) => handleRoleChange(index, e.target.value)}
                      classes={{ root: classes.select }}
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.roleTypeGrid}>
                        {roleBox.role.includes("read_only_user") && (
                          <CeroSelect
                            required
                            id={`roleType`}
                            name={`roleType`}
                            label="Role Type"
                            fullWidth
                            options={roleTypeOptions}
                            selectedValue={formData.roleType} 
                            onChange={handleRoleTypeChange} 
                            classes={{ root: classes.roleSelect }}
                          />
                        )}
                      </Grid>
                </Grid>
                {!roleBox.role.includes("admin") && roleBox.countries.map((country, countryIndex) => (
                  <Box key={countryIndex}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <CeroAutoComplete
                            id={`country-${index}-${countryIndex}`}
                            name={`roles[${index}].countries[${countryIndex}].country_code`}
                            label="Country"
                            fullWidth
                            // options={Object.keys(countryFacilityData).map(
                            //   (countryCode) => ({
                            //     id: countryCode,
                            //     label: countries[countryCode] || countryCode,
                            //   })
                            // )}
                            options={Object.keys(countryFacilityData)
                              ?.filter((countryCode) => 
                                !roleBox.countries
                                  .filter((_, i) => i !== countryIndex)
                                  .map((c) => c.country_code)
                                  .includes(countryCode)
                              ) 
                              .map((countryCode) => ({
                                id: countryCode,
                                label: countries[countryCode] || countryCode,
                              }))
                            }
                            onChange={(e, value) =>
                              handleCountryChange(e, value, index, countryIndex)
                            }
                            value={country.country_name || ""}
                            classes={{ root: classes.autoComplete }}
                            isOptionEqualToValue={(option, value) =>
                              option.label === value
                            }
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <CeroChipSelect
                            label="Facility"
                            formControlClass={classes.multiSelect}
                            optionsde={facilitiesObject(
                              formData.roles[index]?.countries[countryIndex]
                                ?.country_code
                            ) || {}}
                            selected={
                              Array.isArray(formData.roles[index]?.countries[countryIndex]?.facilities)
                                ? formData.roles[index]?.countries[countryIndex]?.facilities
                                : []
                            }
                            handleChange={(e) =>
                              handleFacilityChange(e.target.value, index, countryIndex)
                            }
                            classes={{
                              justCheckSelected: classes.justCheckSelected,
                              label: classes.label,
                            }}
                            shrink={false}
                            disabled={
                              !formData.roles[index]?.countries[countryIndex]
                                ?.country_code
                            }
                            showSelectAll={false}
                            sortOption={true}
                          />
                        </Grid>
                      </Grid>
                      <DeleteOutlineOutlinedIcon
                        className={classes.icon}
                        onClick={() => {
                          setDeletePopup(true);
                          setDeleteRow(index);
                          setCountryName(country.country_name)
                          setDeleteCountryIndx(countryIndex);
                        }}
                      />
                    </div>
                  </Box>
                ))}
                {!roleBox.role.includes("admin") && 
                roleBox.countries.length < Object.keys(countryFacilityData).length &&
                <div
                  className={classes.addRole}
                  onClick={() => addCountryFacility(index)}
                >
                  <AddIcon />
                  <Typography>Add Country and Facilities</Typography>
                </div>}
              </Box>
            ))}
            
            {!formData.roles.some((role) => role.role === "read_only_user") && filterRoles(roles).length !== 0 &&
          <div className={classes.addRole} onClick={() => handleAddRole()}>
              <AddIcon />
              <Typography>Add Role</Typography>
            </div>}
          </Box>
        </Box>
        <Box className={classes.buttonContainer}>
          <CeroButton
            buttonText="Update"
            className={classes.buttonPrimary}
            onClick={() => onUpdateData()}
            disabled={updateDisabled}
          />
          <CeroButton
            buttonText="Cancel"
            variant="outlined"
            className={classes.buttonSecondary}
            onClick={()=>{setFormData(initialFormData);}}
          />
          <CeroPopUp
            primaryPopUp={{
              open: deletePopup,
              onClose: () => setDeletePopup(false),
              content: getDeleteContent(),
              header: { title: "" },
            }}
            classes={{ dialog: classes.dialog }}
          />
          <CeroPopUp
            primaryPopUp={{
              open: roleDeletePopup,
              onClose: () => setRoleDeletePopup(false),
              content: roleDeleteContent(),
              header: { title: "" },
            }}
            classes={{ dialog: classes.dialog }}
          />
          <CeroPopUp
            primaryPopUp={{
              open: proxyChangePopup,
              onClose: () => setProxyChangePopup(false),
              content: proxyChangeContent(),
              header: { title: "" },
            }}
            showCloseButton={true}
            classes={{ dialog: classes.dialog }}
          />
        </Box>
      </Container>
    </DashboardLayout>
  );
};

export default EditUser;
