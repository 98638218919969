import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Tab,
  Tabs,
  Box,
  Link,
} from "@mui/material";
import DashboardLayout from "../../layouts/DashboardLayout";
import profileIcon from "../../assets/icons/PROFILEICON.png";
import CeroButton from "../../components/CeroButton";
import { useNavigate } from "react-router-dom";
import useStyles from "./styles";
import { useSelector } from "react-redux";
import {
  getUserCompanyDetails,
  stakeholderCompanyDetails,
} from "../../redux/actions";
import { rolesEnum } from "../../layouts/DashboardLayout/pages";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { getCookie } from "../../services/cookie";

const Profile = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const companyData = useSelector((state) => state.account.companyDetails.data);
  const stakeholderCompany = useSelector(
    (state) => state.dashboard.companyDetails.data
  );
  const userInfo = useSelector((state) => state.auth.userInfo);
  const [selectedTab, setSelectedTab] = useState("profile-info");
  const role = getCookie("role");
  const companyId = getCookie("companyId");
  const readOnlyMode = getCookie("read_only_mode") === "disable_edit";
  const navigateToPage = (path) => {
    navigate(path);
  };
  const isAdmin = !userInfo.role.includes(rolesEnum.AUDITOR);
  const [showData, setShowData] = useState(false);

  const toggleDataVisibility = () => {
    setShowData(!showData);
  };

  var roles = undefined;
  if (userInfo) {
    if (userInfo.role.lenght == 1) roles = userInfo.role[0];
    else console.log(userInfo.role);
    roles = userInfo?.role.map((item) => item.replace("_", " "));
  }

  useEffect(() => {
    if (role !== "super_admin") {
      if (role === "read_only_user") {
        dispatch(stakeholderCompanyDetails(companyId));
      } else {
        dispatch(getUserCompanyDetails(isAdmin ? "business" : "auditor"));
      }
    }
  }, [dispatch]);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <DashboardLayout>
      <div
        className={classes.backContainer}
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowBackIosIcon />
        Back
      </div>
      <Container className={classes.container}>
        <Card className={classes.tabsCard}>
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Profile Info" value="profile-info" />
            {role !== "super_admin" && (
              <Tab label="Data & Privacy" value="data-privacy" />
            )}
          </Tabs>
        </Card>
        {selectedTab === "profile-info" && (
          <Card className={classes.card}>
            <CardContent>
              <Grid className={classes.subContent}>
                <img src={profileIcon} alt="Logo" className={classes.image} />
                <Typography className={classes.profileHead}>
                  {userInfo?.name}
                </Typography>
              </Grid>
              <Grid container className={classes.details}>
                <Grid
                  container
                  spacing={2}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography className={classes.profileKey}>
                      Designation:
                    </Typography>
                    {roles.slice(0, roles.length - 1).map((_, index) => (
                      <Typography
                        key={index}
                        className={classes.profileDesignationValues}
                      />
                    ))}
                    {role !== "super_admin" && (
                      <>
                        <Typography className={classes.profileKey}>
                          Company:
                        </Typography>
                        <Typography className={classes.profileKey}>
                          Phone:
                        </Typography>
                      </>
                    )}
                    <Typography className={classes.profileKey}>
                      Email:
                    </Typography>
                  </Grid>
                  <Grid item>
                    {roles.map((role, index) => (
                      <Typography
                        key={index}
                        className={classes.profileDesignationValues}
                      >
                        {role}
                      </Typography>
                    ))}
                    {role !== "super_admin" && (
                      <>
                        <Typography className={classes.profileValues}>
                          {companyData?.name
                            ? companyData?.name
                            : stakeholderCompany?.name}
                        </Typography>
                        <Typography className={classes.profileValues}>
                          {companyData?.phone
                            ? companyData.phone
                            : stakeholderCompany?.phone}
                        </Typography>
                      </>
                    )}
                    <Typography className={classes.profileValues}>
                      {userInfo?.email}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
                <Grid className={classes.subContent}>
                  <CeroButton
                    buttonText="Edit Information"
                    className={classes.button}
                    onClick={() => navigateToPage("/settings")}
                    disabled={readOnlyMode}
                  />
                </Grid>
            </CardContent>
          </Card>
        )}
        {selectedTab === "data-privacy" && (
          <Card className={classes.dataPrivacyCard}>
            <Typography variant="h6">Personal Data Overview</Typography>
            <Typography variant="body1">
              At CeroEd, we are committed to protecting your privacy and
              ensuring the security of your personal data. As part of our
              dedication to transparency and compliance with the General Data
              Protection Regulation (GDPR), we have implemented measures to
              safeguard your data and uphold your rights under the GDPR. To{" "}
              {showData ? "Hide" : "View"} your personal data,{" "}
              <Link
                onClick={toggleDataVisibility}
                style={{ cursor: "pointer" }}
              >
                Click here
              </Link>
            </Typography>
            {showData && (
              <Box className={classes.dataBox}>
                <Typography variant="h6" className={classes.title}>
                  Personal Data
                </Typography>
                <Grid spacing={2} className={classes.dataCard}>
                  <Grid item xs={6} className={classes.dataTitle}>
                    <Typography>Name</Typography>
                  </Grid>
                  <Grid item xs={6} className={classes.dataValue}>
                    <Typography>{userInfo?.name}</Typography>
                  </Grid>
                </Grid>
                <Grid spacing={2} className={classes.dataCard}>
                  <Grid item xs={6} className={classes.dataTitle}>
                    <Typography>Email</Typography>
                  </Grid>
                  <Grid item xs={6} className={classes.dataValue}>
                    <Typography>{userInfo?.email}</Typography>
                  </Grid>
                </Grid>
                <Grid spacing={2} className={classes.dataCard}>
                  <Grid item xs={6} className={classes.dataTitle}>
                    <Typography>Phone Number</Typography>
                  </Grid>
                  <Grid item xs={6} className={classes.dataValue}>
                    <Typography>
                      {companyData?.phone
                        ? companyData.phone
                        : stakeholderCompany?.phone}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Card>
        )}
      </Container>
    </DashboardLayout>
  );
};

export default Profile;
