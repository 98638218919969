import { createActions } from 'redux-actions';

import { ActionTypes } from '../constants/actions';

export const {
  addSuperAdmin,
  editSuperAdmin,
  deleteSuperAdmin,
  getSuperadminList,
  getSuperadmin,
  resetSuperAdminStatus,
  updateCompanySubscription,
  createBusinessAdmin,
  resetCreateBusinessStatus,
  updateBusinessAdminStatus,
  createSubscriptionPlan,
  clearSubscriptionStatus,
  getSubscriptionDetailsSuperadmin,
  getAllSubscriptionPlansSuperadmin,
  getSuperAdminReport,
  getSuperAdminOrganizationCountry,
  getSuperadminTickets,
  getSuperadminTicketDetails,
  addSuperadminResponse,
  closeSuperadminTicket,
  getAdminReportDetails,
  generateAnnouncement,
  updateAnnouncement,
  getAnnouncement,
  listAnnouncement,
  generateBlockchainCertificate,
  getNftList,
  getNftDetails,
  getCountryFacilityAggregate,
  getRoleBasedAggregate
} = createActions({
  [ActionTypes.ADD_SUPER_ADMIN]: (email, name) => ({ email, name }),
  [ActionTypes.EDIT_SUPER_ADMIN]: (userId, email, name) => ({
    userId,
    email,
    name,
  }),
  [ActionTypes.DELETE_SUPER_ADMIN]: (userId) => ({ userId }),
  [ActionTypes.GET_SUPERADMIN_LIST]: () => ({}),
  [ActionTypes.GET_SUPERADMIN]: (userId) => ({ userId }),
  [ActionTypes.RESET_SUPER_ADMIN_STATUS]: () => ({}),
  [ActionTypes.UPDATE_COMPANY_SUBSCRIPTION]: (companyId, data) => ({
    companyId,
    data,
  }),
  [ActionTypes.CREATE_BUSINESS_ADMIN]: (companyId, data) => ({
    companyId,
    data,
  }),
  [ActionTypes.RESET_CREATE_BUSINESS_STATUS]: () => ({}),
  [ActionTypes.UPDATE_BUSINESS_ADMIN_STATUS]: (userId, status) => ({
    userId,
    status,
  }),
  [ActionTypes.CREATE_SUBSCRIPTION_PLAN]: (
    companyId,
    duration,
    subscriptionId,
    data
  ) => ({ companyId, duration, subscriptionId, data }),
  [ActionTypes.CLEAR_SUBSCRIPTION_STATUS]: () => ({}),
  [ActionTypes.GET_SUBSCRIPTION_DETAILS_SUPERADMIN]: (payload) => ({ payload }),
  [ActionTypes.GET_ALL_SUBSCRIPTION_PLANS_SUPERADMIN]: (companyId) => ({
    companyId,
  }),
  [ActionTypes.GET_SUPER_ADMIN_REPORT]: (companyId, year, country, facility) => ({
    companyId, year, country, facility
  }),
  [ActionTypes.GET_SUPER_ADMIN_ORGANIZATION_COUNTRY]: (companyId) => ({
    companyId
  }),
  [ActionTypes.GET_SUPERADMIN_TICKETS]: (payload) => ({ payload }),
  [ActionTypes.GET_SUPERADMIN_TICKET_DETAILS]: (ticketId) => ({ ticketId }),
  [ActionTypes.ADD_SUPERADMIN_RESPONSE]: (ticketId, response) => ({ ticketId, response}),
  [ActionTypes.CLOSE_SUPERADMIN_TICKET]: (ticketId) => ({ ticketId }),
  [ActionTypes.GET_ADMIN_REPORT_DETAILS]: (companyId, countryCode, facilityId, year) => ({ companyId, countryCode, facilityId, year }),
  [ActionTypes.GENERATE_ANNOUNCEMENT]: (requestData) => ({ requestData }),
  [ActionTypes.UPDATE_ANNOUNCEMENT]: (requestData) => ({ requestData }),
  [ActionTypes.GET_ANNOUNCEMENT]: (announcementId) => ({ announcementId }),
  [ActionTypes.LIST_ANNOUNCEMENT]: () => ({ }),
  [ActionTypes.GENERATE_BLOCKCHAIN_CERTIFICATE]: (payload) => ( payload ),
  [ActionTypes.GET_NFT_LIST]: (payload) => ( payload ),
  [ActionTypes.GET_NFT_DETAILS]: (payload) => ( payload ),
  [ActionTypes.GET_COUNTRY_FACILITY_AGGREGATE]: (companyId, countryCode, facilityId) => ({ companyId, countryCode, facilityId }),
  [ActionTypes.GET_ROLE_BASED_AGGREGATE]: (companyId, facilityId) => ({ companyId, facilityId}),
});
