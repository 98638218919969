import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Grid,
  Typography,
  Box,
} from "@mui/material";
import useStyles from "./styles";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CeroItemPair from "../../../../../components/CeroItemPair";
import CeroInfoPair from "../../../../../components/CeroInfoPair";

const ManagementDiversity = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const distribution = queryParams.get("distribution");
  const isGender = distribution === "gender";
  const {
    emissionData,
  } = props;

  return (
    <>
      <div
        className={classes.backContainer}
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowBackIosIcon />
        Back
      </div>
      <Container className={classes.container}>
        <Box className={classes.innerContainer}>
          <Box className={classes.header}>
            <Typography variant="h6" component="div">
              Management Diversity by {isGender ? "Gender" : "Age Group"}
            </Typography>
          </Box>
          <Box className={classes.actionBox}>
            <CeroInfoPair
              title="Status:"
              value={emissionData.status ? emissionData.status : "NA"}
              classes={{
                container: classes.statusContainer,
                title: classes.statusTitle,
                value:
                  emissionData.status === "In Progress"
                    ? classes.Inprogress
                    : classes[emissionData?.status],
              }}
            />
          </Box>
          <Box className={classes.topContainer}>
            <Grid
              container
              direction={"row"}
              wrap="nowrap"
              justifyContent={"space-between"}
              display={"contents"}
              spacing={12}
            >
              <Grid item container direction={"column"} display={"contents"} xs={10}>
                <CeroItemPair
                  title="Facility:"
                  value={
                    emissionData.facility_name
                      ? emissionData.facility_name
                      : "NA"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.supplierScreenTitle,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Period:"
                  value={
                    emissionData.assessment_period
                      ? emissionData.assessment_period
                      : "NA"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.supplierScreenTitle,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Year:"
                  value={emissionData.year ? emissionData.year : "NA"}
                  classes={{
                    container: classes.infoContainer,
                    title: classes.supplierScreenTitle,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title={`Percentage of Board Members ${
                    isGender ? "Identifying as Male" : "under 30 years"
                  }:`}
                  value={
                    emissionData.percentage_of_employee1
                      ? `${emissionData.percentage_of_employee1}%`
                      : "NA"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.supplierScreenTitle,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title={`Percentage of Management Members ${
                    isGender ? "Identifying as Female" : "between 30 & 50 years"
                  }`}
                  value={
                    emissionData.percentage_of_employee2
                      ? `${emissionData.percentage_of_employee2}%`
                      : "NA"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.supplierScreenTitle,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title={`Percentage of Management Members ${
                    isGender ? "Identifying as Others" : "above 50 years"
                  }:`}
                  value={
                    emissionData.percentage_of_employee3
                      ? `${emissionData.percentage_of_employee3}%`
                      : "NA"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.supplierScreenTitle,
                    value: classes.value,
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default ManagementDiversity;
