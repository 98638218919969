import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Paper,
  Typography,
  Grid,
} from "@mui/material";
import { getEthicalBehaviourGraph } from "../../../redux/actions";
import useStyles from "./styles";
import { getCookie } from "../../../services/cookie";

const EthicalBehaviourGraph = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const role = getCookie("role");
  const companyId = getCookie("companyId");
  const parentRef = useRef(null); // Ref to access the parent div
  const [parentWidth, setParentWidth] = useState(0); // State for width
  const [xsValue, setXsValue] = useState(12);
  const values = useSelector(
    (state) => state.dashboard.getEthicalBehaviourGraph.data
  );

  useEffect(() => {
    !props.refreshContent && dispatch(getEthicalBehaviourGraph(props.filter,role,companyId));
  }, [dispatch, props.filter]);

  useEffect(() => {
    if (!parentRef.current) {
      console.warn("parentRef is not set yet.");
      return;
    }
  
    const observer = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const width = entry.contentRect.width;
        console.log("Observed width:", width); // Debugging log
        setParentWidth(width); // Update the width
      }
    });
    
    observer.observe(parentRef.current);
    console.log("ResizeObserver is observing parentRef");
  
    return () => {
      observer.disconnect();
      console.log("ResizeObserver disconnected");
    };
  }, []);

  // Update xs value based on the width
  useEffect(() => {
    if (parentWidth < 200) {
      setXsValue(12); // Full width for small screens
    } else if (parentWidth < 530) {
      setXsValue(6); // Adjusted xs for medium screens
    } else {
      setXsValue(3); // Adjusted xs for large screens
    }
  }, [parentWidth, props.resize]);

  return (
    <Paper className={classes.container}>
      <Typography className={classes.graphTitle}>
        Ethical Behaviour
      </Typography>
      <Grid ref={parentRef} container spacing={2} display="flex" style={{height: "100%"}}>
        <Grid item xs={xsValue} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <div className={xsValue==6? classes.circleAdjusted : classes.circle} style={{ borderColor: "#6FBFBA"}}>
            <Typography variant="h5" className={classes.value}>{values?.number_of_operation}</Typography>
          </div>
          <Typography variant="caption">Operations Assessed</Typography>
        </Grid>
        <Grid item xs={xsValue} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <div className={xsValue==6? classes.circleAdjusted : classes.circle} style={{ borderColor: "#846BB5"}}>
            <Typography variant="h5" className={classes.value}>{values?.number_of_incident}</Typography>
          </div>
          <Typography variant="caption">
           Incidents Reported
          </Typography>
        </Grid>
        <Grid item xs={xsValue} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <div className={xsValue==6? classes.circleAdjusted : classes.circle} style={{ borderColor: "#FFC757"}}>
            <Typography variant="h5" className={classes.value}>{values?.number_of_training_conducted}</Typography>
          </div>
          <Typography variant="caption">
           Number of Trainings
          </Typography>
        </Grid>
        <Grid item xs={xsValue} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <div className={xsValue==6? classes.circleAdjusted : classes.circle} style={{ borderColor: "#6FBFBA"}}>
            <Typography variant="h5" className={classes.value}>{values?.number_of_legal_action}</Typography>
          </div>
          <Typography variant="caption">
           Legal Actions
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default EthicalBehaviourGraph;
