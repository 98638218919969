import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  IconButton,
  Chip,
  Typography,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import CreateIcon from "@mui/icons-material/CreateOutlined";
import {BoardDiversityByAgeGroupColumns, BoardDiversityByGenderColumns} from "../../EmissionTable/TableColumns";
import CeroTable from "../../../../components/CeroTable";
import useStyles from "./styles";
import { STATUS } from "../../../../redux/constants";
import { useDispatch, useSelector } from "react-redux";
import { deleteEmissions, resetEmissionDelete } from "../../../../redux/actions";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useSnackbar } from "notistack";
import EmissionDeletePrompt from "../../../EmissionDeletePrompt";

const BoardDiversityTable = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {
    emissionType,
    emissionData,
    onLoadMore,
    dataStatus,
    isAuditor,
    company,
    editOff,
    reportTable,
    isOnlyAdmin,
    isDeleteActionRequired
  } = props;

  const role = useSelector((state) => state.auth.role);
  const deleteEmissionData = useSelector(
    (state) => state.emission.deleteEmissions
  );
  const [displayWarning, setDisplayWarning] = useState(false);
  const [emissionId, setEmissionId] = useState(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const onConfirmDelete = () => {
    const requestData = {
      id: emissionId,
    };
    dispatch(deleteEmissions(requestData));
  };
  useEffect(() => {
    if (deleteEmissionData.status === STATUS.SUCCESS) {
      enqueueSnackbar(
        "Topic deleted successfully",
        {
          variant: "success",
        }
      );
      dispatch(resetEmissionDelete());
      
    } else if (deleteEmissionData.status === STATUS.ERROR) {
      enqueueSnackbar("We couldn't process your request. Please try again later.", { variant: "error" });
      dispatch(resetEmissionDelete());
    }
    setDisplayWarning(false)
  }, [deleteEmissionData, enqueueSnackbar,  dispatch]);
  const onSelectEmissionData = (rowData) => {
    const currentFilter = `?distribution=gender`
    isAuditor
      ? navigate(
        `/emissions/${emissionType}/details/${rowData.id}/?company=${company}/&distribution=gender`
      )
      : navigate(`/emissions/${emissionType}/details/${rowData.id}${currentFilter}`)
  };
  
  const onSelectData = (rowData) => {
    // let ageFilter = rowData.age;
    // if(rowData.age === "Under 30"){
    //   ageFilter = "under_30";
    // }else if(rowData.age === "Between 30 and 50"){
    //   ageFilter = "between_30_50"
    // }else{
    //   ageFilter = "over_50"
    // }
    const currentFilter = `?distribution=age`
    isAuditor
      ? navigate(
        `/emissions/${emissionType}/details/${rowData.id}/?company=${company}&distribution=age`
      )
      : navigate(`/emissions/${emissionType}/details/${rowData.id}${currentFilter}`)
  };

  const onEditEmissionData = (e, id) => {
    e.stopPropagation();
    e.preventDefault();
    navigate(`/business/emissions/board_diversity/${id}`);
  }; 

  const getStatus = (status) => {
    if (status === 'approved') {
      return reportTable ? <Tooltip title="Approved" arrow><Chip className={classes.signalApproved}/></Tooltip>
        : <Typography className={classes.approved}>Approved</Typography>;
    } else if (status === 'submitted') {
        return reportTable ? <Tooltip title="Submitted" arrow><Chip className={classes.signalPending}/></Tooltip>
          : <Typography className={classes.pending}>Pending Approval</Typography>;
    } else if (status === 'audited') {
        return reportTable ? <Tooltip title="Audited" arrow><Chip className={classes.signalAudited}/></Tooltip>
          : <Typography className={classes.audited}>Audited</Typography>;
    } else if (status === 'pending') {
        return reportTable ? <Tooltip title="Pending Approval" arrow><Chip className={classes.signalPending}/></Tooltip>
          : <Typography className={classes.pending}>Pending Approval</Typography>;
    }else if (status === 'added') {
        return reportTable ? <Tooltip title="Added" arrow><Chip className={classes.signalAdded}/></Tooltip>
          : <Typography className={classes.added}>Added</Typography>;
    }else if (status === 'assigned') {
      return reportTable ? <Tooltip title="Assigned" arrow><Chip className={classes.signalPending}/></Tooltip>
        : <Typography className={classes.pending}>Pending Approval</Typography>;
    }else if (status === 'verified') {
      return reportTable ? <Tooltip title="Verified" arrow><Chip className={classes.signalVerified}/></Tooltip>
        : <Typography className={classes.verified}>Verified</Typography>;
    }else if (status === 'review') {
      return reportTable ? <Tooltip title="Review" arrow><Chip className={classes.signalPending}/></Tooltip>
        : <Typography className={classes.review}>Review</Typography>;
    }else if (status === 'Awaiting approval') {
      return reportTable ? <Tooltip title="Awaiting approval" arrow><Chip className={classes.signalPending}/></Tooltip>
        : <Typography className={classes.pending}>Pending Approval</Typography>;
    }else if (status === 'pending_approval') {
      return reportTable ? <Tooltip title="Pending approval" arrow><Chip className={classes.signalPending}/></Tooltip>
        : <Typography className={classes.pending}>Pending approval</Typography>;
    }
  else {
        return reportTable ? <Tooltip title="" arrow><Chip className={classes.signalPending}/></Tooltip>
         : <Typography className={classes.pending}>{status}</Typography>;
    } 
  };
  
  const getGenderDistribution = () =>
    emissionData?.based_on_gender?.map((item) => ({
      ...item,
      year: item.month ? `${item.month}/${item.year}` : `${item.year}`,
      male: `${item.male}%`,
      female: `${item.female}%`,
      other: `${item.other}%`,
      status: getStatus(item.status),
      action: (
        <Box className={classes.actionContainer}>
          {item.is_able_to_update === true &&
            !editOff && isOnlyAdmin &&(
              <IconButton
                className={classes.editIcon}
                onClick={(e) => onEditEmissionData(e, item.id)}
              >
                <CreateIcon />
              </IconButton>
            )}
            {isDeleteActionRequired && 
            ((!["audited", "reviewed"].includes(item.status) && (role.includes('sustainability_manager'))) || role.includes('proxy_sustainability_manager')) &&
              <IconButton
              className={classes.editIcon}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setEmissionId(item.id);
                setDisplayWarning(true)}}
            >
              <DeleteOutlineIcon />
            </IconButton>}
        </Box>
      ),
    }));

    const getByAgeDistribution = () =>
    emissionData?.based_on_age?.map((item) => ({
      ...item,
      year: item.month ? `${item.month}/${item.year}` : `${item.year}`,
      under_30: `${item.under_30}%`,
      between_30_50: `${item.between_30_50}%`,
      over_50: `${item.over_50}%`,
      status: getStatus(item.status),
      action: (
        <Box className={classes.actionContainer}>
          {item.status === "added" &&
            !editOff && isOnlyAdmin &&(
              <IconButton
                className={classes.editIcon}
                onClick={(e) => onEditEmissionData(e, item.id)}
              >
                <CreateIcon />
              </IconButton>
            )}
            {isDeleteActionRequired && 
            (
              (item.status === "added" && role.includes('business_user')) || 
              (
                !["audited", "reviewed"].includes(item.status) && 
                (role.includes('sustainability_manager') || role.includes('proxy_sustainability_manager'))
              )
            ) &&             
            // ((item.status === "added" && role.includes('business_user')) || ((!["audited", "reviewed"].includes(item.status)) && (role.includes('sustainability_manager')) || role.includes('proxy_sustainability_manager'))) &&
              <IconButton
              className={classes.editIcon}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setEmissionId(item.id);
                setDisplayWarning(true)}}
            >
              <DeleteOutlineIcon />
            </IconButton>}
        </Box>
      ),
    }));

  return (
    <>
      {dataStatus === STATUS.SUCCESS ? (
        <Box>
          <Typography variant="h7" component="span">
              <span className={classes.tableTitle}>
              Board Diversity by Gender
              </span>
              </Typography>
          <CeroTable
            columns={BoardDiversityByGenderColumns}
            data={getGenderDistribution}
            hasMore={false}
            loading={false}
            loadMore={onLoadMore}
            onSelectRow={onSelectEmissionData}
            classes={{ tableContainer: classes.tableContainer }}
          />
          <Typography variant="h7" component="span">
              <span className={classes.tableTitle}>
              Board Diversity by Age Group
              </span>
              </Typography>
          <CeroTable
            columns={BoardDiversityByAgeGroupColumns}
            data={getByAgeDistribution}
            hasMore={false}
            loading={false}
            loadMore={onLoadMore}
            onSelectRow={onSelectData}
            classes={{ tableContainer: classes.tableContainer }}
          />
        </Box>
      ) : (
        <Box className={classes.loader}>
          <Typography variant="h7" component="span">
            {dataStatus === STATUS.RUNNING ? (
              <CircularProgress />
            ) : dataStatus === STATUS.ERROR ? (
              "Something went wrong. Please try again later"
            ) : (
              ""
            )}
          </Typography>
        </Box>
      )}
      {displayWarning && (
          <EmissionDeletePrompt
          isOpen={displayWarning}
          onClose={() => setDisplayWarning(false)}
          onConfirm={onConfirmDelete}
          setOpenDeletePrompt = {displayWarning}
        />
        )}
    </>
  );
};

export default BoardDiversityTable;
