import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Paper,
  Typography,
  Grid,
} from "@mui/material";
import { getSocialEngagement } from "../../../redux/actions";
import useStyles from "./styles";
import { getCookie } from "../../../services/cookie";

const SocialEngagementGraph = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const role = getCookie("role");
  const companyId = getCookie("companyId");
  const parentRef = useRef(null); // Ref to access the parent div
  const [parentWidth, setParentWidth] = useState(0); // State for width
  const [xsValue, setXsValue] = useState(12);
  
  const values = useSelector(
    (state) => state.dashboard.getSocialEngagement.data
  );

  useEffect(() => {
    !props.refreshContent && dispatch(getSocialEngagement(props.filter,role,companyId));
  }, [dispatch, props.filter]);

  useEffect(() => {
    if (!parentRef.current) {
      console.warn("parentRef is not set yet.");
      return;
    }
  
    const observer = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const width = entry.contentRect.width;
        console.log("Observed width:", width); // Debugging log
        setParentWidth(width); // Update the width
      }
    });
    
    observer.observe(parentRef.current);
    console.log("ResizeObserver is observing parentRef");
  
    return () => {
      observer.disconnect();
      console.log("ResizeObserver disconnected");
    };
  }, []);

  // Update xs value based on the width
  useEffect(() => {
    if (parentWidth < 200) {
      setXsValue(12); // Full width for small screens
    } else if (parentWidth < 530) {
      setXsValue(6); // Adjusted xs for medium screens
    } else {
      setXsValue(4); // Adjusted xs for large screens
    }
  }, [parentWidth, props.resize]);

  return (
    <Paper className={classes.container}>
      <Typography className={classes.graphTitle}>
        Social Engagement
      </Typography>
      <Grid container ref={parentRef} spacing={2} className={xsValue==6? classes.containerArrangeShort : classes.containerArrange}>
        <Grid item xs={xsValue} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <div className={xsValue==6 ? classes.circleAdjusted: classes.circle}>
            <Typography variant={xsValue==6 ? 'h6': 'h5'} className={classes.value}>{values?.local_commutes?.total_local_communities}</Typography>
          </div>
          <Typography variant="caption" className={classes.captionText}>Local Community Engagements</Typography>
        </Grid>
        <Grid item xs={xsValue} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <div className={xsValue==6 ? classes.circleAdjusted: classes.circle}>
            <Typography variant={xsValue==6 ? 'h6': 'h5'} className={classes.value}>{values?.local_commutes?.total_local_communities_contributions}</Typography>
          </div>
          <Typography variant="caption" className={xsValue==6 ? classes.captionText: classes.captionTextShort}>
           Contributions to Local Communities
          </Typography>
        </Grid>
        <Grid item xs={xsValue} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <div className={xsValue==6 ? classes.circleAdjusted: classes.circle}>
            <Typography variant={xsValue==6 ? 'h6': 'h5'} className={classes.value}>{values?.political_data?.total_political_contributions}</Typography>
          </div>
          <Typography variant="caption" className={classes.captionText}>
           Political Contributions
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SocialEngagementGraph;
