import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Container, Typography, Grid, Box, CircularProgress } from "@mui/material";

import CeroDropdown from "../../components/CeroDropdown";
import { sampleYear } from "../../constants";
import CeroButton from "../../components/CeroButton";
import { rolesEnum } from "../../layouts/DashboardLayout/pages";
import { listTickets, getCompanyList, getTicketDetails } from "../../redux/actions";
import { STATUS } from "../../redux/constants";
import useStyles from "./styles";
import CreateTicketDrawer from "../common/CreateTicketDrawer";
import ListTicketDrawer from "../common/ListTicketsDrawer";
import { getCookie } from "../../services/cookie";
import TicketsTable from "../Tickets/TicketsTable";
import DashboardLayout from "../../layouts/DashboardLayout";

var yearInput = [{ key: 0, value: 'All' }]
yearInput = yearInput.concat(sampleYear())
const ticketTypeOption = [
  {
    key: "all",
    value: "All",
  },
  {
    key: "request",
    value: "Request",
  },
  {
    key: "incident",
    value: "Incident",
  },
];

const ticketStatusOption = [
  {
    key: "all",
    value: "All",
  },
  {
    key: "open",
    value: "Open",
  },
  {
    key: "closed",
    value: "Closed",
  },
];

const SupportTickets = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [year, setYear] = useState(0);
  const [ticketType, setTicketType] = useState("all");
  const [ticketStatus, setTicketStatus] = useState("all");
  const [company, setCompany] = useState("");
  const readOnlyMode = (getCookie("read_only_mode") === "disable_edit")
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isToCreate, setIsToCreate] = useState(false);
  const [showTickets, setShowTickets] = useState(false);

  const userInfo = useSelector((state) => state.auth.userInfo);
  const ticketList = useSelector((state) => state.ticket.listTickets.data);
  const companyList = useSelector((state) => state.company.companyList.data);
  const createTicketDetailsStatus = useSelector((state) => state.ticket.createTicketDetails.status);
  const updateTicketDetailsStatus = useSelector((state) => state.ticket.updateTicketDetails.status);
  const isInternalTicket = window.location.pathname.includes("/tickets")
  const ticketListStatus = useSelector(
    (state) => state.ticket.listTickets.status
  );

  const companyListOption = companyList.map((item) => ({
    key: item.id,
    value: item.name,
  }));

  const isAuditor = userInfo && userInfo.role.includes(rolesEnum.AUDITOR);
  const ticketDetails = useSelector(
    (state) => state.ticket.ticketDetails.data.response
  );
  const ticketId = useSelector(
    (state) => state.ticket.ticketDetails.data.ticket_id
  );
  const onSelectTicket = (selectedRow) => {
    navigate(`id?ticketId=${selectedRow.id}`);
  };

  const onCloseTickets = () => {
    setShowTickets(false);
  };

  const internal = false

  useEffect(() => {
    if (isAuditor) {
        dispatch(listTickets({ year, ticketType, ticketStatus, company , isAuditor }));
    } else {
      dispatch(listTickets({ year, ticketType, ticketStatus, internal , isAuditor}));
    }
  }, [year, ticketType, ticketStatus, dispatch, isAuditor, company, userInfo, internal, createTicketDetailsStatus, updateTicketDetailsStatus]);

  useEffect(() => {
    isAuditor &&
      !company &&
      companyListOption &&
      companyListOption.length &&
      isInternalTicket &&
      setCompany(companyListOption[0].key);
  }, [company, companyListOption, isInternalTicket, isAuditor]);

  useEffect(() => {
    isAuditor && dispatch(getCompanyList());
  }, [dispatch, isAuditor]);
  
  const handleReviewPopup = (item) => {
    setIsToCreate(true);
    setIsDrawerOpen(true);
  }

  const onClickEditIcon = (e, id) => {
    e.stopPropagation();
    e.preventDefault();
    setIsToCreate(false);
    dispatch(getTicketDetails({id}));
    setIsDrawerOpen(true);
  }
      
  return (
    <DashboardLayout>
      <Container className={classes.container}>
        <div className={classes.buttonsContainer}>
        <Grid
          className={classes.filterContainer}
          container
          columnSpacing={2}
          alignItems="center"
          wrap="nowrap"
        >
          {/* {isAuditor && (
            <Grid item xs={3.5}>
              <CeroDropdown
                id="company"
                label="Company"
                fullWidth
                options={companyListOption}
                onChange={({ target }) => setCompany(target.value)}
                selectedValue={company}
                className= {classes.dropdown}
              />
            </Grid>
          )} */}
          <Grid item xs={3.5}>
            <CeroDropdown
              id="year"
              label="Year"
              fullWidth
              options={yearInput}
              onChange={({ target }) => setYear(target.value)}
              selectedValue={year}
              className= {classes.dropdown}
            />
          </Grid>
          <Grid item xs={3.5}>
            <CeroDropdown
              id="ticketType"
              label="Ticket type"
              fullWidth
              options={ticketTypeOption}
              onChange={({ target }) => setTicketType(target.value)}
              selectedValue={ticketType}
              className= {classes.dropdown}
            />
          </Grid>
          <Grid item xs={3.5}>
            <CeroDropdown
              id="ticketStatus"
              label="Ticket status"
              fullWidth
              options={ticketStatusOption}
              onChange={({ target }) => setTicketStatus(target.value)}
              selectedValue={ticketStatus}
              className= {classes.dropdown}
            />
          </Grid>
        </Grid>
        <CeroButton
                buttonText="Create Ticket"
                variant="contained"
                className={classes.buttonPrimary}
                onClick={handleReviewPopup}
                disabled={readOnlyMode}
              /></div>
        {ticketListStatus === STATUS.SUCCESS ? (
            <TicketsTable
              ticketList={ticketList}
              onSelectTicket={onSelectTicket}
              userInfo={userInfo}
              clickEditIcon={onClickEditIcon}
            />
        ) : (
          <Box className={classes.loader}>
            <Typography variant="h7" component="span">
              {ticketListStatus === STATUS.RUNNING ? (
                <CircularProgress />
              ) : ticketListStatus === STATUS.ERROR ? (
                "We couldn't process your request. Please try again later."
              ) : (
                ""
              )}
            </Typography>
          </Box>
        )}
        <CreateTicketDrawer
          isOpen={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          scope="util"
          ticketData={!isToCreate?ticketDetails:null}
          ticketId={!isToCreate?ticketId:null}
        />
        {showTickets && (
          <ListTicketDrawer
            isOpen={showTickets}
            scope="approval"
            onClose={onCloseTickets}
          />
        )}
      </Container>
    </DashboardLayout>
  );
};

export default SupportTickets;
