import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Paper, Stack } from '@mui/material'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'

import { getUserCompanyDetails, resetAccountStatus, stakeholderCompanyDetails, updateCompanyDetails } from '../../../redux/actions'
import { APIEndpoints, STATUS } from '../../../redux/constants'
import { companySchema } from '../schema'
import CeroInput from '../../../components/CeroInput'
import CeroButton from '../../../components/CeroButton'
import {rolesEnum} from '../../../layouts/DashboardLayout/pages'
import CeroAutoComplete from '../../../components/CeroAutoComplete'
import useStyles from './styles'
import { getYears }  from "../../../services/utilityService"
import { useNavigate } from 'react-router-dom'
import { getCookie } from '../../../services/cookie'
import UploadIcon from "@mui/icons-material/Upload";
import axios from 'axios'

const CompanySettingsForm = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const companyData = useSelector(state => state.account.companyDetails.data)
    const updateCompanyData = useSelector(state => state.account.updateCompanyDetails)
    const userInfo = useSelector(state => state.auth.userInfo);
    const isAdmin = !userInfo.role.includes(rolesEnum.AUDITOR);
    const isAuditor = userInfo.role.includes(rolesEnum.AUDITOR);
    const role = getCookie('role');
    const companyId = getCookie('companyId')
    const stakeholderCompany = useSelector(state => state.dashboard.companyDetails.data)
    const permittedUser = role.includes(rolesEnum.AUDITOR) || role.includes(rolesEnum.ADMIN);
    const yearList = getYears().map(item => ({ key: item.id, label: item.label }));

    useEffect(() => {
        if(role === "read_only_user"){
            dispatch(stakeholderCompanyDetails(companyId))
        }else {
         getAlert();
        dispatch(getUserCompanyDetails(isAdmin ? 'business' : 'auditor'))
        }
    }, [dispatch, role])

    const getAlert = () => {
        if (!permittedUser){
            enqueueSnackbar("Only admins have permission to edit company details", { variant: "error" });
          }
    }
    
    useEffect(() => {
        companyForm.setValues({
            name: companyData.name || stakeholderCompany.name || '',
            email: companyData.email || stakeholderCompany.email || '',
            phone: companyData.phone || stakeholderCompany.phone || '',
            website: companyData.website || stakeholderCompany.website || '',
            startYear: companyData.estd_year || stakeholderCompany.estd_year || '',
            goal: companyData.goal || stakeholderCompany.goal || '',
            logo: companyData.company_logo_url || '',
        });
    }, [companyData])

    useEffect(() => {
        if (updateCompanyData.status === STATUS.SUCCESS) {
            enqueueSnackbar('Company Details updated successfully', { variant: 'success' })
            dispatch(resetAccountStatus())
        } else if (updateCompanyData.status === STATUS.ERROR) {
            enqueueSnackbar(updateCompanyData.message?.message, { variant: 'error' })
            dispatch(resetAccountStatus())
        }
    }, [updateCompanyData, dispatch, enqueueSnackbar])

    const companyForm = useFormik({
        initialValues: {
            name: companyData.name || '',
            email: companyData.email || '',
            phone: companyData.phone || '',
            website: companyData.website || '',
            startYear: companyData.estd_year || '',
            goal: companyData.goal || '',
            logo: companyData.company_logo_url || ''
        },
        enableReinitialize: true,
        validationSchema: companySchema,
        onSubmit: (values) => {
        },
    });

    const handleUpdate = () => {
        dispatch(
            updateCompanyDetails(
                isAuditor,
                companyForm.values.name,
                companyForm.values.email,
                companyForm.values.phone,
                companyForm.values.website,
                companyForm.values.startYear,
            )
        );
    }

    const [uploadedImage, setUploadedImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [logoUploaded, setLogoUploaded] = useState(false);
    
    const authToken = getCookie("auth_token_admin");
    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (file) {
          setLoading(true);
          setError(null);
    
          const formData = new FormData();
          formData.append('file', file);
    
          try {
            const response = await axios.put(APIEndpoints.UPLOAD_COMPANY_LOGO, formData, {
              headers: {
                Authorization: `Bearer ${authToken}`,
                'Content-Type': 'multipart/form-data',
              },
            });
    
            if (response.status === 200) {
              setLogoUploaded(true);
              const reader = new FileReader();
              reader.onload = (e) => {
                setUploadedImage(e.target.result);
              };
              reader.readAsDataURL(file);
            } else {
              setError('Failed to upload the file.');
            }
          } catch (err) {
            setError('An error occurred while uploading the file.');
          } finally {
            setLoading(false);
          }
        }
      };
    
      const handleClick = () => {
        document.getElementById('fileInput').click();
      };

  
    return (
      <Paper className={classes.container}>
        {role === "admin" ?
        <Grid container spacing={2} display="flex">
          <Grid item xs={8}>
            <CeroInput
              required
              fullWidth
              id="name"
              label="Name"
              autoFocus
              value={companyForm.values.name}
              onChange={companyForm.handleChange}
              onBlur={companyForm.handleBlur}
              error={companyForm.errors.name}
              disabled={!permittedUser}
            />
            <CeroInput
              required
              fullWidth
              id="email"
              label="Email address"
              autoFocus
              value={companyForm.values.email}
              onChange={companyForm.handleChange}
              onBlur={companyForm.handleBlur}
              error={companyForm.errors.email}
              disabled={!permittedUser}
            />
            <CeroInput
              required
              fullWidth
              id="phone"
              label="Phone Number"
              autoFocus
              value={companyForm.values.phone}
              onChange={companyForm.handleChange}
              onBlur={companyForm.handleBlur}
              error={companyForm.errors.phone}
              disabled={!permittedUser}
            />
            <CeroInput
              fullWidth
              id="website"
              name="website"
              label="Website"
              value={companyForm.values.website}
              onChange={companyForm.handleChange}
              onBlur={companyForm.handleBlur}
              error={companyForm.touched.website && companyForm.errors.website}
              disabled={!permittedUser}
            />
            <CeroAutoComplete
              required
              id="startYear"
              name="startYear"
              label="Established Year"
              value={companyForm.values.startYear?.toString()}
              onChange={(e, value) =>
                companyForm.setFieldValue("startYear", value?.key)
              }
              onBlur={companyForm.handleBlur}
              error={
                companyForm.touched.startYear && companyForm.errors.startYear
              }
              options={yearList}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              disabled={!permittedUser}
            />
          </Grid>
          <Grid
            item
            xs={4}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            {uploadedImage ? (
              <img
                src={uploadedImage}
                alt="Uploaded"
                className={classes.uploadedImage}
              />
            ) : companyForm.values.logo ? (
              <img
                src={companyForm.values.logo}
                alt="Uploaded"
                className={classes.uploadedImage}
              />
            ) : (
              <div className={classes.uploadBox} onClick={handleClick}>
                <UploadIcon />
                <p style={{ fontWeight: 500 }}>
                  Drag & drop file or Browse <br />
                  <span
                    style={{
                      color: "#333333B2",
                      fontSize: 10,
                      fontWeight: 400,
                    }}
                  >
                    Supported format: PNG, JPG, JPEG
                    <br />
                    (Maximum Upload File Size: 5 MB)
                  </span>
                </p>
              </div>
            )}
            <CeroButton
              buttonText={companyData.company_logo_url || logoUploaded ? "Upload New Logo" : "Upload Logo"}
              onClick={handleClick}
              className={classes.logoUploadBtn}
            />
            <input
              id="fileInput"
              type="file"
              accept="image/png, image/jpeg"
              style={{ display: "none" }}
              onChange={handleFileUpload}
            />
            {error && <p style={{ color: "red" }}>{error}</p>}
          </Grid>
        </Grid> :
        <>
        <CeroInput
                required
                fullWidth
                id="name"
                label="Name"
                autoFocus
                value={companyForm.values.name}
                onChange={companyForm.handleChange}
                onBlur={companyForm.handleBlur}
                error={companyForm.errors.name}
                disabled={!permittedUser}
            />
            <CeroInput
                required
                fullWidth
                id="email"
                label="Email address"
                autoFocus
                value={companyForm.values.email}
                onChange={companyForm.handleChange}
                onBlur={companyForm.handleBlur}
                error={companyForm.errors.email}
                disabled={!permittedUser}
            />
            <CeroInput
                required
                fullWidth
                id="phone"
                label="Phone Number"
                autoFocus
                value={companyForm.values.phone}
                onChange={companyForm.handleChange}
                onBlur={companyForm.handleBlur}
                error={companyForm.errors.phone}
                disabled={!permittedUser}
            />
            <CeroInput
                fullWidth
                id="website"
                name="website"
                label="Website"
                value={companyForm.values.website}
                onChange={companyForm.handleChange}
                onBlur={companyForm.handleBlur}
                error={companyForm.touched.website && companyForm.errors.website}
                disabled={!permittedUser}

            />
            <CeroAutoComplete
                required
                id="startYear"
                name="startYear"
                label="Established Year"
                value={companyForm.values.startYear?.toString()}
                onChange={(e, value) => companyForm.setFieldValue('startYear', value?.key)}
                onBlur={companyForm.handleBlur}
                error={companyForm.touched.startYear && companyForm.errors.startYear}
                options={yearList}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                disabled={!permittedUser}
            />
        </>}
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <div>
            <CeroButton
              buttonText="Save"
              onClick={handleUpdate}
              disabled={
                !companyForm.dirty || !companyForm.isValid || !permittedUser
              }
              className={classes.buttonPrimary}
            />
            <CeroButton
              buttonText="Cancel"
              onClick={() => navigate(-1)}
              className={classes.buttonPrimary}
            />
          </div>
        </Stack>
      </Paper>
    );
}

export default CompanySettingsForm;
