import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CeroTable from "../../../../components/CeroTable";
import useStyles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import {
  companyUserDetails,
} from "../../../../redux/actions";
import {
  updateBusinessAdminStatus,
} from "../../../../redux/actions/superAdmin";
import DashboardLayout from "../../../../layouts/DashboardLayout";
import { Box, Container, Stack, Switch, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { getCookie } from "../../../../services/cookie";

export const TotalAdminUsersColumns = [
  {
    columnKey: "name",
    columnId: "name",
    columnHeader: "Name",
  },
  {
    columnKey: "email",
    columnId: "email",
    columnHeader: "Email",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "Action",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
];

export const TotalUsersColumns = [
  {
    columnKey: "name",
    columnId: "name",
    columnHeader: "Name",
    width: "25vw"
  },
  {
    columnKey: "email",
    columnId: "email",
    columnHeader: "Email",
    width: "50vw"
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
    width: "25vw"
  },
];

export const ActiveUsersColumns = [
  {
    columnKey: "name",
    columnId: "name",
    columnHeader: "Name",
  },
  {
    columnKey: "email",
    columnId: "email",
    columnHeader: "Email",
  },
];
export const ActiveUsersAdminColumns = [
  {
    columnKey: "name",
    columnId: "name",
    columnHeader: "Name",
  },
  {
    columnKey: "email",
    columnId: "email",
    columnHeader: "Email",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "Action",
  },
];

const CompanyTotalUserDetails = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userList, setUserList] = useState([]);
  const facilityName = getCookie('facility_name')

  const { companyId, countryName, id, userType, isActive } = useParams();
  const user = userType.replace(/ /g, "_");
  const userDetails = useSelector(
    (state) => state.listings.companyUserDetail.data
  );
  
  useEffect(() => {
    setUserList(userDetails);
  }, [userDetails]);

  useEffect(() => {
    if(companyId && id){
    dispatch(companyUserDetails(companyId, id, user, isActive));
    }
  }, [dispatch, companyId, id, user, isActive]);

  const handleToggleStatus = (id, status) => {
    const updatedUserList = userList.map((item) =>
      item.id === id ? { ...item, lock_status: status } : item
    );
    setUserList(updatedUserList);
    dispatch(updateBusinessAdminStatus(id, status));
  };

  const getTotalUsers = () =>
    userList.map((item) => ({
      ...item,
      name: item.name ? item.name : "",
      email: item.email ? item.email : "",
      facility: item.facility ? item.facility : "",
      status: item.status ? (
        <Typography className={classes.activeStatus}>Active</Typography>
      ) : (
        <Typography className={classes.inActiveStatus}>Inactive</Typography>
      ),
      action: user === 'admin' ?(
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography
            style={{ fontWeight: !item.lock_status ? "bold" : "normal" }}
            className={classes.statusText}
          >
            Unlock
          </Typography>
          <Switch
            checked={item.lock_status}
            className={classes.switchColor}
            inputProps={{ "aria-label": "Toggle Status" }}
            onChange={(e) => handleToggleStatus(item.id, e.target.checked)}
            disabled={!item.lock_status}
          />
          <Typography
            style={{ fontWeight: item.lock_status ? "bold" : "normal" }}
            className={classes.statusText}
          >
            Lock
          </Typography>
        </Stack>
      ):"",
    }));

  const getActiveUsers = () =>
    userList.map((item) => ({
      ...item,
      name: item.name ? item.name : "",
      email: item.email ? item.email : "",
      facility: item.facility ? item.facility : "",
      action: user === 'admin' ?(
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography
            style={{ fontWeight: !item.lock_status ? "bold" : "normal" }}
            className={classes.statusText}
          >
            Unlock
          </Typography>
          <Switch
            checked={item.lock_status}
            className={classes.switchColor}
            inputProps={{ "aria-label": "Toggle Status" }}
            onChange={(e) => handleToggleStatus(item.id, e.target.checked)}
            disabled={!item.lock_status}
          />
          <Typography
            style={{ fontWeight: item.lock_status ? "bold" : "normal" }}
            className={classes.statusText}
          >
            Lock
          </Typography>
        </Stack>
      ):"",
    }));

  return (
    <DashboardLayout>
      <div
        className={classes.backContainer}
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowBackIosIcon />
        Back
      </div>
      <Container className={classes.container}>
        {isActive === "true" ? (
          <>
            <Typography variant="h6">Active {userType}s</Typography>
            <CeroTable
              columns={user === 'admin' ? ActiveUsersAdminColumns : ActiveUsersColumns}
              data={getActiveUsers()}
              hasMore={false}
              loading={false}
              classes={{ tableContainer: classes.tableContainer }}
            />
          </>
        ) : (
          <>
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography variant="h6">{countryName} > <span style={{cursor: "pointer"}} onClick={()=>navigate(-1)}>{facilityName}</span> > <span style={{fontWeight: "600", textTransform: "capitalize"}}>{userType.replace(/_/g, " ")}</span></Typography>
            </Box>
            <CeroTable
              columns={user === 'admin' ? TotalAdminUsersColumns:TotalUsersColumns}
              data={getTotalUsers()}
              hasMore={false}
              loading={false}
              classes={{ tableContainer: classes.tableContainer }}
            />
          </>
        )}
      </Container>
    </DashboardLayout>
  );
};

export default CompanyTotalUserDetails;
