import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, CircularProgress, Container, Typography } from "@mui/material";

import { getNftList, getTotalCompanies } from "../../redux/actions";

import DashboardLayout from "../../layouts/DashboardLayout";
import useStyles from "./styles";
import { getCookie } from "../../services/cookie";
import { STATUS } from "../../redux/constants";
import CeroTable from "../../components/CeroTable";
import { date } from "yup";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const savedPage = {
  pageNumber: 1,
};

const DEFAULT_ITEMS_PER_PAGE = 20;

export const TableColumns = [
    {
        columnKey: "token_number",
        columnId: "token_number",
        columnHeader: "Token Number",
      },
      {
        columnKey: "company_name",
        columnId: "company_name",
        columnHeader: "Company Name",
      },
  {
    columnKey: "audit_number",
    columnId: "audit_number",
    columnHeader: "Audit Reference No",
  },

  {
    columnKey: "date_created",
    columnId: "date_created",
    columnHeader: "Created On",
  },
  {
    columnKey: "view_token",
    columnId: "",
    columnHeader: "View Token",
  },
];

const BlockchainList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const nftList = useSelector((state) => state.superAdmin.getNftList.data);
  const nftListStatus = useSelector(
    (state) => state.superAdmin.getNftList.status
  );

  const getNft = () =>
    nftList.map((item) => ({
      ...item,
      date_created: moment(item.date_created).format("DD-MM-YYYY"),
      view_token: (
        <div
          className={classes.linkButton}
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/blockchain-details/${item._id}`);
          }}
        >
          <u>click here</u>
        </div>
    ),
    }));

  useEffect(() => {
    dispatch(getNftList());
  }, [dispatch]);

  return (
    <DashboardLayout>
      <Container className={classes.container}>
        <CeroTable
          columns={TableColumns}
          data={getNft()}
          hasMore={false}
          loading={false}
          // loadMore={onLoadMore}
          // onSelectRow={company => onSelectCompanyData(company, company.company_id, "company-details", navigate)}
          classes={{ tableContainer: classes.tableContainer }}
        />
        <Typography variant="h7" component="span">
          {nftListStatus === STATUS.RUNNING ? (
            <CircularProgress />
          ) : nftListStatus === STATUS.ERROR ? (
            "We couldn't process your request. Please try again later"
          ) : (
            ""
          )}
        </Typography>
        {/* </Box>} */}
      </Container>
    </DashboardLayout>
  );
};

export default BlockchainList;
