import clsx from "clsx";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useStyles from "./styles";
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const CeroPopUp = (props) => {

  const classes = useStyles();
  return (
    <>
      <Dialog
      open={props.primaryPopUp.open}
      onClose={props.primaryPopUp.onClose}
      className={clsx(classes.dialog, props.dialog, props.classes?.dialog) }
    >
      <DialogTitle className={clsx(classes.headerTitle) }>{props.primaryPopUp.header.title}</DialogTitle>
      {props.showCloseButton && (<IconButton
          aria-label="close"
          onClick={props.primaryPopUp.onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            // color: (theme) => theme.palette.grey[500],
          }}
        >
           <CloseIcon />
        </IconButton>)}
      <DialogContent>
        {props.primaryPopUp.content}
      </DialogContent>
      </Dialog>
    </>
  );
};

export default CeroPopUp;
