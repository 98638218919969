import React from 'react';
import CeroTable from '../../../../../components/CeroTable';
import useStyles from './styles';
import dayjs from 'dayjs';

const AuditTrailTable = (props) => {
  const classes = useStyles();
 const TableColumns = [
  {
    columnKey: 'assigned_on',
    columnId: 'assigned_on',
    columnHeader: 'Date & Time',
    classes: { column: classes.cellContainer }
  },
  {
    columnKey: 'user',
    columnId: 'user',
    columnHeader: 'User',
    classes: { column: classes.cellContainer },
  },
  {
    columnKey: 'action',
    columnId: 'action',
    columnHeader: 'Action',
    classes: { column: classes.cellContainer }
  },
  {
    columnKey: 'comment',
    columnId: 'comment',
    columnHeader: 'Comments',
    classes: { column: classes.cellContainer }
  },
  {
    columnKey: 'ticket',
    columnId: 'ticket',
    columnHeader: 'Tickets',
    classes: { column: classes.cellContainer }
  },
  {
    columnKey: 'status',
    columnId: 'status',
    columnHeader: 'Ticket Status',
    classes: { column: classes.cellContainer }
  },
];
  const { onLoadMore } = props

  const getAuditTrailList = () =>{
    if (!Array.isArray(props.auditTrailsData)) {
      return [];
    }else{
    return props.auditTrailsData.map((item) => ({
    ...item,
      assigned_on: item.assigned_on ? dayjs(item.assigned_on).format('DD MMM YYYY, h:mm A') : '-',
      user: (
        <div>
          <div><b>{item.role ? item.role.toUpperCase() : '-'}</b></div>
          <div>{item.performed_by ? item.performed_by : '-'}</div>
        </div>
      ),
      action: item.action ? item.action : '-',
      comment: item.response ? item.response : 'NA',
      ticket: item.ticket_number ? item.ticket_number : 'NA',
      status: item.status ? item.status : 'NA',
    }));
  }
  };

  return (
    <CeroTable
      columns={TableColumns}
      data={getAuditTrailList()}
      hasMore={false}
      loading={false}
      loadMore={onLoadMore}
      classes={{ tableDataRow: classes.tableRow,
        tableContainer: classes.tableContainer
      }}
    />
  );
};

export default AuditTrailTable;
