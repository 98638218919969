import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Paper,
  Typography,
  Grid,
  Box,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router";
import { STATUS } from "../../redux/constants";
import { useFormik } from "formik";
import CeroInput from "../../components/CeroInput";
import CeroButton from "../../components/CeroButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBack";
import useStyles from "./styles";
import { userDetailsSchema } from "./schema";
import { industryTypes, resetSignup, signupTrade } from "../../redux/actions";
import CeroAutoComplete from "../../components/CeroAutoComplete";

const TradeSignup = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const signupData = useSelector((state) => state.trade.signupTrade);
  const industries = useSelector((state) => state.trade.industryTypes.data);
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const industryTypesData = industries?.map((item) => ({
    key: item.code,
    label: item.name,
  }));

  const formik = useFormik({
    initialValues: {
      name: "",
      company: "",
      industryType: "",
      email: "",
      phone: "",
      address: "",
      website: "",
      password: "",
    },
    validationSchema: userDetailsSchema,
    onSubmit: () => {},
  });

  const onSubmitData = () => {
    const requestData = {
      user_name: formik.values.name,
      company_email: formik.values.email,
      company_phone: formik.values.phone,
      password: formik.values.password,
      company_name: formik.values.company,
      company_address: formik.values.address,
      company_industry_type: formik.values.industryType,
      company_website: formik.values.website,
      save: true,
    };
    dispatch(signupTrade(requestData));
  };

  useEffect(() => {
    dispatch(industryTypes());
  }, [dispatch]);

  useEffect(() => {
    if (signupData?.status === STATUS.SUCCESS) {
      navigate("/trade/signup-response");
      dispatch(resetSignup());
    } else if (signupData?.status === STATUS.ERROR) {
      enqueueSnackbar(signupData?.message, { variant: "error" });
      dispatch(resetSignup());
    }
  }, [signupData?.status, signupData?.message, enqueueSnackbar]);

  return (
    <Grid
      container
      justifyContent="center"
      alignContent="center"
      className={classes.signup}
    >
      <Container component="main" className={classes.container}>
        <Paper variant="outlined" className={classes.signupContainer}>
          <Typography
            component="h1"
            variant="h6"
            fontWeight={600}
            color="#2A6565"
            align="center"
          >
            Sign up
          </Typography>
          <CeroInput
            required
            fullWidth
            label="User Name"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && formik.errors.name}
          />
          <CeroInput
            required
            fullWidth
            label="Company Name"
            name="company"
            value={formik.values.company}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.company && formik.errors.company}
          />
          <CeroAutoComplete
            name="industryType"
            label="Type of industry"
            options={industryTypesData}
            onChange={(e, value) =>
              formik.setFieldValue("industryType", value?.key)
            }
            onBlur={formik.handleBlur}
            error={formik.touched.industryType && formik.errors.industryType}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
          <CeroInput
            required
            fullWidth
            label="Company Email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && formik.errors.email}
          />
          <CeroInput
            required
            fullWidth
            label="Phone Number"
            name="phone"
            value={formik.values.phone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.phone && formik.errors.phone}
          />
          <CeroInput
            required
            fullWidth
            label="Company Address"
            name="address"
            value={formik.values.address}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.address && formik.errors.address}
          />
          <CeroInput
            required
            fullWidth
            name="website"
            label="Company website"
            value={formik.values.website}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.website &&
              formik.errors.website
            }
          />
          <CeroInput
            required
            fullWidth
            label="Password"
            name="password"
            type={showPassword ? "text" : "password"}
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && formik.errors.password}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={togglePasswordVisibility}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            }
          />

          <Box className={classes.buttonsContainer}>
            <CeroButton
              variant="contained"
              onClick={onSubmitData}
              buttonText={"Submit"}
              disabled={!formik.dirty || !formik.isValid}
              className={classes.buttonPrimary}
            />
            <CeroButton
              variant="outlined"
              onClick={() => navigate(-1)}
              buttonText={"Back"}
              startIcon={<ArrowBackIosIcon />}
              className={classes.buttonSecondary}
            />
          </Box>
        </Paper>
      </Container>
    </Grid>
  );
};

export default TradeSignup;
