import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Container, Typography,CircularProgress } from "@mui/material";
import DashboardLayout from "../../layouts/DashboardLayout";
import CeroTable from "../../components/CeroTable";
import { useNavigate } from "react-router-dom";

import { listAssessmentCycle } from "../../redux/actions";
import { STATUS } from "../../redux/constants";
import CreateAssessmentCyclePopup from "./CreateAssessmentPopup";

import useStyles from "./styles";
import CeroButton from "../../components/CeroButton";
import { getCookie } from "../../services/cookie";

export const assessmentDetailsColumn = [
  {
    columnKey: "assessment_year",
    columnId: "assessment_year",
    columnHeader: "Assessment Year",
  },
  {
    columnKey: "country",
    columnId: "country",
    columnHeader: "Country",
  },
  {
    columnKey: "approval_cycle",
    columnId: "approval_cycle",
    columnHeader: "Approval Cycle",
  },
  {
    columnKey: "audit_cycle",
    columnId: "audit_cycle",
    columnHeader: "Audit Cycle",
  },
  {
    columnKey: "gwp_dataset",
    columnId: "gwp_dataset",
    columnHeader: "GWP Dataset",
  },
];

const AssessmentDetails = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const readOnlyMode = (getCookie("read_only_mode") === "disable_edit")

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const assessmentList = useSelector(
    (state) => state.assessment.assessmentList.data
  );
  const assessmentListStatus = useSelector(
    (state) => state.assessment.assessmentList.status
  );
  const countryData = useSelector(state => state.listings.getOrganizationCountry.data.country_data);
  const countryMap = Object.entries(countryData).reduce((acc, [key, value]) => {
    acc[value] = key;
    return acc;
  }, {});

  const getAssessmentList = () =>
    assessmentListStatus === STATUS.SUCCESS ? 
    assessmentList.map((item) => ({
      ...item,
      country: item.country
        ? countryMap[item.country]
        : "-",
      audit_cycle: item.audit_cycle.split("_").join(" "),
      approval_cycle: item.approval_cycle.split("_").join(" ")
    })):[]

  const onSelectAuditSummaryData = (row) => {
    navigate(`details/?year=${row.assessment_year}&country=${countryData[row.country]}&id=${row.id}`);
  };

  useEffect(() => {
    dispatch(listAssessmentCycle());
  }, [dispatch]);

  return (
    <DashboardLayout>
      <Container className={classes.container}>
        <Box className={classes.header}>
          {/* <Typography variant="h7" component="span">
            Assessment Cycles
          </Typography> */}
            <CeroButton
              buttonText="Create assessment cycle"
              className={classes.buttonPrimary}
              onClick={() => setIsDrawerOpen(true)}
              disabled={readOnlyMode}
            />
        </Box>
        {assessmentListStatus === STATUS.SUCCESS ? (
          <CeroTable
            columns={assessmentDetailsColumn}
            data={getAssessmentList()}
            hasMore={false}
            loading={false}
            useExtraStyles={true}
            onSelectRow={onSelectAuditSummaryData}
            classes={{ tableContainer: classes.tableContainer }}
          />
        ) : (
          <Box className={classes.loader}>
            <Typography variant="h7" component="span">
              {assessmentListStatus === STATUS.RUNNING ? (
                <CircularProgress />
              ) : assessmentListStatus === STATUS.ERROR ? (
                "We couldn't process your request. Please try again later."
              ) : (
                ""
              )}
            </Typography>
          </Box>
        )}
      </Container>
      <CreateAssessmentCyclePopup
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      />
    </DashboardLayout>
  );
};

export default AssessmentDetails;
