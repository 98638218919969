import { useEffect, useState } from "react";
import { Box, Container, Grid, Tab, Tabs, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getStakeholderReportsList,
  stakeholderCompanyDetails,
} from "../../redux/actions";
import CeroInfoPair from "../../components/CeroInfoPair";
import companyLogo from "../../assets/icons/company-logo.png";
import useStyles from "./styles";
import Cookies from "js-cookie";
import DashboardLayout from "../../layouts/DashboardLayout";
import AuditedReports from "./AuditedReports";
import AssignedReports from "./AssignedReports";
import { sampleYear } from "../../constants";
import CeroDropdown from "../../components/CeroDropdown";

const StakeholderReports = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const companyId = Cookies.get("companyId");
  const [value, setValue] = useState(0);
  const [filterYear, setYear] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleYearChange = (selectedYear) => {
    const newFilterYear = selectedYear === "All" ? null : selectedYear;

    setYear(newFilterYear);
  };

  useEffect(() => {
    dispatch(stakeholderCompanyDetails(companyId));
  }, [dispatch, companyId]);

  useEffect(() => {
    dispatch(getStakeholderReportsList(companyId, filterYear));
  }, [dispatch, companyId, filterYear]);

  const company = useSelector((state) => state.dashboard.companyDetails.data);
  const auditedReports = useSelector(
    (state) => state.reports.getStakeholderReportsList.data?.audited_report
  );
  const assignedReports = useSelector(
    (state) => state.reports.getStakeholderReportsList.data?.assigned_reports
  );

  return (
    <DashboardLayout>
      <Container className={classes.container}>
        <Box className={classes.infoArea}>
          <Box className={classes.logoContainer}>
            <img src={company.logo || companyLogo} alt="logo" />
            <Typography variant="h2" className={classes.name}>
              {company.name}
            </Typography>
          </Box>
          <Box className={classes.infoSide}>
            <CeroInfoPair
              title="Website:"
              value={company.website}
              classes={{ container: classes.infoContainer }}
            />
            <CeroInfoPair
              title="Phone:"
              value={company.phone}
              classes={{ container: classes.infoContainer }}
            />
            <CeroInfoPair
              title="Address:"
              value={company.address}
              classes={{ container: classes.infoContainer }}
            />
          </Box>
        </Box>
        <Box
          className={classes.tabContainer}
          // sx={{ borderBottom: 1 }}
        >
          <Tabs
            value={value}
            className={classes.tabs}
            onChange={handleChange}
            aria-label="report tabs"
          >
            <Tab label="Audited Reports" id="comments" />
            <Tab label="Assigned Reports" id="files" />
          </Tabs>
        </Box>
      </Container>
      <Grid
        item
        xs={3}
        style={{
          marginLeft: 12,
          maxWidth: 250,
          marginBottom: 10,
          background: "white",
        }}
      >
        <CeroDropdown
          id="year"
          label="Year"
          fullWidth
          options={[{ key: "All", value: "All" }, ...sampleYear()]}
          onChange={({ target }) => handleYearChange(target.value)}
          // selectedValue={filterYear === null ? 'all' : filterYear}
        />
      </Grid>
      <Container className={[classes.container,classes.containerNoPadding]}>
      {value === 0 && (
        <AuditedReports
          auditedReports={auditedReports}
          filterYear={filterYear}
          className={classes.table}
        />
      )}
      {value === 1 && (
        <AssignedReports
          assignedReports={assignedReports}
          filterYear={filterYear}
        />
      )}
      </Container>
    </DashboardLayout>
  );
};

export default StakeholderReports;