import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Paper } from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  registerables as registerablesJS,
} from "chart.js";
import { Line } from "react-chartjs-2";
import _ from "lodash";

import { getAuditTrendGraph } from "../../../redux/actions";

import useStyles from "./styles";
import { getCookie } from "../../../services/cookie";

ChartJS.register(...registerablesJS);
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const AuditTrends = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const role = getCookie("role");

  const auditData = useSelector(
    (state) => state.dashboard.getAuditTrendGraph.data.response
  );

  const auditDataStatus = useSelector(
    (state) => state.dashboard.getAuditTrendGraph.status
  );

  if (role === 'auditor') {
   var userRole = 'auditor';
  } else {
    userRole = 'business';
  }

  useEffect(() => {
    if (role === "auditor") {
      dispatch(getAuditTrendGraph({userRole,filter:{}}));
    } else {
      dispatch(getAuditTrendGraph({userRole,filter:props.filter}));
    }
  }, [dispatch,userRole,role,props.filter]);

  const maxAudits = _.maxBy(auditData, 'total')?.total +2 || 10;

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    aspectRatio: 2,
    plugins: {
      legend: {
        labels: {
          fontColor: "rgba(0,0,0,.4)",
        },
        align: "end",
        position: "bottom",
      },
      title: {
        align: "start",
        display: true,
        text: "Audit trends over the year",
        color: "#23120E",
        font: {
          size: 18,
          family: "Inter",
          weight: 400,
        },
        padding:{
          bottom: 20,
        }
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Number of Audits",
          color: "#4F4F4F",
          font: {
            size: 12,
            family: "Inter",
            weight: "500",
          },
        },
        precision: 0,
        min: 0,
        // max: 10,
        max: maxAudits, 
        ticks: {
          stepSize: 1,
          color: "black",
        },
      },
      x: {
        beginAtZero: true,
        ticks: {
          color: "black",
        },
      },
    },
  };

  const data = {
    labels: _.map(auditData, (item) => item.year),
    datasets: [
      {
        label: "Total Number of Audits",
        data: _.map(auditData, (item) => item.total),
        backgroundColor: "#846BB5",
        pointBackgroundColor: "#D56763",
        borderColor: "#846BB5",
        padding: 10,
        
      },
    ],
  };

  return (
    <Paper className={props.size ? classes.container : classes.containerFixed}>
      {auditData?.length > 0 ? (
        <Line options={options} data={data}/>
      ) :auditDataStatus === "running"? <div style={{ minHeight: 250 }}>Loading...</div>: (
        <div style={{ minHeight: 250 }}>No Data Available</div>
      )}
    </Paper>
  );
};

export default AuditTrends;
