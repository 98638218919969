import React, { Fragment, useEffect, useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  CircularProgress,
  Container,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import useStyles from "./styles";
import Status from "./Status";
import CeroTable from "../../../components/CeroTable";
import { useDispatch, useSelector } from "react-redux";
import {
  getAssignedStakeholders,
  getReadOnlyUserAssignedReports,
  resetRevokeGrantReportAccess,
  revokeGrantReportAccess,
} from "../../../redux/actions";
import { useSnackbar } from "notistack";
import { STATUS } from "../../../redux/constants";
import dayjs from "dayjs";
import { getCookie } from "../../../services/cookie";

const ReadOnlyUsersDetails = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [auditSummary, setAuditSummary] = useState([]);
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const queryParams = new URLSearchParams(window.location.search);
  const stakeholder = queryParams.get("stakeholder");

  const auditSummaryColumns = [
    {
      columnKey: "name",
      columnId: "name",
      columnHeader: "Report Name",
    },
    {
      columnKey: "year",
      columnId: "year",
      columnHeader: "Year",
    },
    // {
    //   columnKey: "status",
    //   columnId: "status",
    //   columnHeader: "Status",
    // },
    {
      columnKey: "created_by_name",
      columnId: "created_by_name",
      columnHeader: "Created by",
    },
    {
      columnKey: "created_ts",
      columnId: "created_ts",
      columnHeader: "Created On",
    },
    {
      columnKey: "action",
      columnId: "action",
      columnHeader: "Access",
    },
  ];

  const rouAssignedReports = useSelector(
    (state) => state.users.getReadOnlyUserAssignedReports.data.report_list
  );
  const grantRevokeStatus = useSelector(
    (state) => state.users.revokeGrantReportAccess
  );
  const stakeholderData = useSelector(
    (state) => state.users.getAssignedStakeholders.data.stakeholders_list
  );
  const stakeholderDataStatus = useSelector(
    (state) => state.users.getReadOnlyUserAssignedReports.status
  );

  const userData = stakeholderData?.filter((obj) => obj.id === id);

  useEffect(() => {
    dispatch(getReadOnlyUserAssignedReports(stakeholder));
  }, [dispatch, stakeholder]);

  useEffect(() => {
    setAuditSummary(rouAssignedReports);
  }, [rouAssignedReports]);
  
  const stakeholderStatus = useSelector(
    (state) => state.users.getAssignedStakeholders.status
  );

  useEffect(() => {
    dispatch(getAssignedStakeholders());
  }, [dispatch]);
  
  useEffect(() => {
    if (grantRevokeStatus.status === STATUS.SUCCESS) {
      dispatch(getReadOnlyUserAssignedReports(stakeholder));
      enqueueSnackbar(grantRevokeStatus.data.message, {
        variant: "success",
        autoHideDuration: 3000,
      });
      dispatch(resetRevokeGrantReportAccess());
    } else if (grantRevokeStatus.status === STATUS.ERROR) {
      dispatch(getReadOnlyUserAssignedReports(stakeholder));
      enqueueSnackbar(grantRevokeStatus.message.message, {
        variant: "error",
        autoHideDuration: 1000,
      });
      dispatch(resetRevokeGrantReportAccess());
    }

    return ()=>dispatch(resetRevokeGrantReportAccess());
  }, [grantRevokeStatus.status, enqueueSnackbar, dispatch]);

  const handleToggleStatus = (id, status) => {
      
    const updatedAuditSummary = auditSummary?.map((item) =>
      item.id === id ? { ...item, status: status } : item
    );
    const requestData = {
      stakeholder_id: stakeholder,
      report_id: id,
      status: status,
    };
    setAuditSummary(updatedAuditSummary);
    dispatch(revokeGrantReportAccess(requestData));
  };

  const getAuditData = () =>
    auditSummary?.map((item) => ({
      ...item,
      name: item.name || "",
      year: item.year || "",
      status: item.status ? "Active" : "Inactive",
      created_by_name: item.created_by_name || "",
      created_ts:
        (item.created_ts &&
          dayjs(item.created_ts).format("DD MMM YYYY HH:mm")) ||
        "",
      action:(
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography
            style={{ fontWeight: !item.status ? "bold" : "normal" }}
            className={classes.statusText}
          >
            Revoke
          </Typography>
          <Switch
            checked={item.status}
            className={
              classes.switchColor
            }
            inputProps={{ "aria-label": "Toggle Status" }}
            onChange={(e) => handleToggleStatus(item.id, e.target.checked)}
          />
          <Typography
            style={{ fontWeight: item.status ? "bold" : "normal" }}
            className={classes.statusText}
          >
            Grant
          </Typography>
        </Stack>
      ),
    }));

  const navigateBack = () => {
    navigate(-1);
  };
  return (
    <DashboardLayout>
      <Container className={classes.container}>
        {/* {auditedReportDetailsStatus === STATUS.SUCCESS || assignedReportDetailsStatus === STATUS.SUCCESS ? ( */}
        <Fragment>
          <Box>
            <Box className={classes.buttonContainer}>
              <div className={classes.backContainer} onClick={navigateBack}>
                <ArrowBackIosIcon />
                Back
              </div>
            </Box>
          </Box>
          {stakeholderStatus === STATUS.SUCCESS ? (<Status details={userData} />) : <></>}
          

          <Container className={classes.container}>
            {stakeholderDataStatus === STATUS.SUCCESS ? (
              <CeroTable
                columns={auditSummaryColumns}
                data={getAuditData}
                hasMore={false}
                loading={false}
                classes={{ tableContainer: classes.tableContainer }}
              />
            ) : (
              <Box className={classes.loader}>
                <Typography variant="h7" component="span">
                  {stakeholderDataStatus === STATUS.RUNNING ? (
                    <CircularProgress />
                  ) : stakeholderDataStatus === STATUS.ERROR ? (
                    "We couldn't process your request. Please try again later"
                  ) : (
                    ""
                  )}
                </Typography>
              </Box>
            )}
          </Container>
        </Fragment>
      </Container>
    </DashboardLayout>
  );
};

export default ReadOnlyUsersDetails;
