import { handleActions } from "redux-actions";
import immutable from "immutability-helper";

import { ActionTypes } from "../constants/actions";
import { STATUS } from "../constants";
import { parseError } from "../../services/client";

export const userState = {
  isAuthenticated: false,
  isNewDomain:false,
  status: STATUS.IDLE,
  proxySustainabilityManagerLogin : STATUS.IDLE,
  tradeLoginArroUser : STATUS.IDLE,
  authParams: {},
  userInfo: {},
  userInfoProxy : {},
  superAdminInfo: {},
  role: "",
  message: "",
  refreshStatus: STATUS.RUNNING,
  logoutStatus: STATUS.IDLE,
  forgot: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  resetPassword: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  changeUserPassword: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  signup: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  userInviteLogin: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  auditSignUp: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  setActiveRole: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  otpInfo: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  autoLogggedOut: false,
  adminOtpInfo: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  adminOtpSubmit: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  adminPasswordSubmit: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  isLoading: false
};

const authActions = {
  auth: handleActions(
    {
      [ActionTypes.USER_LOGIN]: (state, { payload }) =>
        immutable(state, {
          userInfo: { $set: payload },
          status: { $set: STATUS.RUNNING },
        }),
      [ActionTypes.USER_LOGIN_SUCCESS]: (state, { payload, role }) =>
        immutable(state, {
          isAuthenticated: { $set: true },
          userInfo: { $set: payload },
          status: { $set: STATUS.SUCCESS },
          role: { $set: role },
        }),
      [ActionTypes.USER_LOGIN_FAILURE]: (state, { payload }) =>
        immutable(state, {
          isAuthenticated: { $set: false },
          message: { $set: parseError(payload) },
          status: { $set: STATUS.ERROR },
        }),

      [ActionTypes.GET_FORGOT_PASSWORD_OTP]: (state, { payload }) =>
        immutable(state, {
          forgot: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_FORGOT_PASSWORD_OTP_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          forgot: {
            data: { $set: payload },
            status: { $set: STATUS.SUCCESS },
          },
        }),
      [ActionTypes.GET_FORGOT_PASSWORD_OTP_FAILURE]: (state, { payload }) =>
        immutable(state, {
          forgot: {
            status: { $set: STATUS.ERROR },
            message: { $set: payload },
          },
        }),

      [ActionTypes.RESET_PASSWORD]: (state, { payload }) =>
        immutable(state, {
          resetPassword: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.RESET_PASSWORD_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          resetPassword: {
            data: { $set: payload },
            status: { $set: STATUS.SUCCESS },
          },
        }),
      [ActionTypes.RESET_PASSWORD_FAILURE]: (state, { payload }) =>
        immutable(state, {
          resetPassword: {
            status: { $set: STATUS.ERROR },
            message: { $set: payload },
          },
        }),

      [ActionTypes.CHANGE_PASSWORD]: (state, { payload }) =>
        immutable(state, {
          changeUserPassword: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.CHANGE_PASSWORD_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          changeUserPassword: {
            data: { $set: payload },
            status: { $set: STATUS.SUCCESS },
          },
        }),
      [ActionTypes.CHANGE_PASSWORD_FAILURE]: (state, { payload }) =>
        immutable(state, {
          changeUserPassword: {
            status: { $set: STATUS.ERROR },
            message: { $set: payload },
          },
        }),

      [ActionTypes.USER_SIGN_UP]: (state, { payload }) =>
        immutable(state, {
          signup: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.USER_SIGN_UP_SUCCESS]: (state, { payload, role }) =>
        immutable(state, {
          signup: {
            data: { $set: payload },
            status: { $set: STATUS.SUCCESS },
          },
          // isAuthenticated: { $set: true },
          // userInfo: { $set: payload },
          // role: { $set: role }
        }),

      [ActionTypes.USER_SIGN_UP_FAILURE]: (state, { payload }) =>
        immutable(state, {
          signup: {
            status: { $set: STATUS.ERROR },
            message: { $set: payload },
          },
        }),

      [ActionTypes.AUDITOR_SIGN_UP]: (state, { payload }) =>
        immutable(state, {
          auditSignUp: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.AUDITOR_SIGN_UP_SUCCESS]: (state, { payload, role }) =>
        immutable(state, {
          auditSignUp: {
            data: { $set: payload },
            status: { $set: STATUS.SUCCESS },
          },
          // isAuthenticated: { $set: true },
          // userInfo: { $set: payload },
          // role: { $set: role }
        }),

      [ActionTypes.AUDITOR_SIGN_UP_FAILURE]: (state, { payload }) =>
        immutable(state, {
          auditSignUp: {
            status: { $set: STATUS.ERROR },
            message: { $set: payload },
          },
        }),
      [ActionTypes.SET_ACTIVE_ROLE]: (state, { payload }) =>
        immutable(state, {
          setActiveRole: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.SET_ACTIVE_ROLE_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          setActiveRole: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.SET_ACTIVE_ROLE_FAILURE]: (state, { payload }) =>
        immutable(state, {
          setActiveRole: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.RESET_AUTH_STATUS]: (state, { payload }) =>
        immutable(state, {
          forgot: {
            status: { $set: STATUS.IDLE },
            message: {},
          },
          resetPassword: {
            status: { $set: STATUS.IDLE },
            message: {},
          },
          changeUserPassword: {
            status: { $set: STATUS.IDLE },
            message: {},
          },
          signup: {
            status: { $set: STATUS.IDLE },
            message: {},
          },
        }),

      [ActionTypes.REFRESH_TOKEN]: (state) =>
        immutable(state, {
          refreshStatus: { $set: STATUS.RUNNING },
        }),
      [ActionTypes.REFRESH_TOKEN_SUCCESS]: (state) =>
        immutable(state, {
          refreshStatus: { $set: STATUS.SUCCESS },
        }),
      [ActionTypes.REFRESH_TOKEN_FAILURE]: (state) =>
        immutable(state, {
          refreshStatus: { $set: STATUS.ERROR },
        }),

      [ActionTypes.USER_INVITE_LOGIN]: (state) =>
        immutable(state, {
          logoutStatus: { $set: STATUS.RUNNING },
        }),
      [ActionTypes.USER_INVITE_LOGIN_SUCCESS]: (state, { payload, role }) =>
        immutable(state, {
          isAuthenticated: { $set: true },
          userInfo: { $set: payload },
          role: { $set: role },
          userInviteLogin: {
            status: { $set: STATUS.IDLE },
            data: { $set: {} },
          },
        }),
      [ActionTypes.USER_INVITE_LOGIN_FAILURE]: (state, { payload }) =>
        immutable(state, {
          isAuthenticated: { $set: false },
          userInfo: { $set: {} },
          status: { $set: STATUS.IDLE },
          logoutStatus: { $set: STATUS.ERROR },
        }),

      [ActionTypes.USER_LOGOUT]: (state) =>
        immutable(state, {
          logoutStatus: { $set: STATUS.RUNNING },
        }),
      [ActionTypes.USER_LOGOUT_SUCCESS]: (state) =>
        immutable(state, {
          isAuthenticated: { $set: false },
          userInfo: { $set: {} },
          status: { $set: STATUS.IDLE },
          logoutStatus: { $set: STATUS.SUCCESS },
          forgot: {
            status: { $set: STATUS.IDLE },
            data: { $set: {} },
          },
        }),
      [ActionTypes.USER_LOGOUT_FAILURE]: (state, { payload }) =>
        immutable(state, {
          isAuthenticated: { $set: false },
          userInfo: { $set: {} },
          status: { $set: STATUS.IDLE },
          logoutStatus: { $set: STATUS.ERROR },
        }),
      [ActionTypes.USER_AUTO_LOGOUT]: (state, { payload }) =>
        immutable(state, {
          autoLogggedOut: { $set: payload.autoLogggedOut },
        }),
      [ActionTypes.USER_ACTIVE_ROLE]: (state, { payload }) =>
        immutable(state, {
          role: { $set: payload },
        }),

      [ActionTypes.GENERAL_USER_LOGIN]: (state, { payload }) =>
        immutable(state, {
          userInfo: { $set: payload },
          status: { $set: STATUS.RUNNING },
        }),
      [ActionTypes.GENERAL_USER_LOGIN_SUCCESS]: (state, { payload, role }) =>
        immutable(state, {
          isAuthenticated: { $set: true },
          userInfo: { $set: payload },
          status: { $set: STATUS.SUCCESS },
          role: { $set: role },
        }),
      [ActionTypes.GENERAL_USER_LOGIN_FAILURE]: (state, { payload }) =>
        immutable(state, {
          isAuthenticated: { $set: false },
          message: { $set: parseError(payload) },
          status: { $set: STATUS.ERROR },
        }),
      [ActionTypes.SUPER_ADMIN_TWOFA_VALIDATION]: (state, { payload }) =>
        immutable(state, {
          // superAdminInfo: { $set: payload },
          status: { $set: STATUS.RUNNING },
        }),
      [ActionTypes.SUPER_ADMIN_TWOFA_VALIDATION_SUCCESS]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          isAuthenticated: { $set: true },
          userInfo: { $set: payload },
          status: { $set: STATUS.SUCCESS },
        }),
      [ActionTypes.SUPER_ADMIN_TWOFA_VALIDATION_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          isAuthenticated: { $set: false },
          message: { $set: parseError(payload) },
          status: { $set: STATUS.ERROR },
        }),

      [ActionTypes.RESEND_OTP]: (state, { payload }) =>
        immutable(state, {
          otpInfo: {
            status: { $set: STATUS.RUNNING },
            data: { $set: payload },
          },
        }),
      [ActionTypes.RESEND_OTP_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          otpInfo: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.RESEND_OTP_FAILURE]: (state, { payload }) =>
        immutable(state, {
          otpInfo: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),
      [ActionTypes.CLEAR_OTP]: (state, { payload }) =>
        immutable(state, {
          status: { $set: STATUS.RUNNING },
        }),
      [ActionTypes.SWITCH_BACK_TO_SUPER_ADMIN]: (state, { payload }) =>
        immutable(state, {
          userInfo: { $set: payload },
          status: { $set: STATUS.RUNNING },
          refreshStatus: { $set: STATUS.RUNNING },
        }),
      [ActionTypes.SWITCH_BACK_TO_SUPER_ADMIN_SUCCESS]: (
        state,
        { payload, role }
      ) =>
        immutable(state, {
          isAuthenticated: { $set: true },
          userInfo: { $set: payload },
          status: { $set: STATUS.SUCCESS },
          refreshStatus: { $set: STATUS.SUCCESS },
          role: { $set: role },
        }),
      [ActionTypes.SWITCH_BACK_TO_SUPER_ADMIN_FAILURE]: (state, { payload }) =>
        immutable(state, {
          isAuthenticated: { $set: false },
          message: { $set: parseError(payload) },
          status: { $set: STATUS.ERROR },
          refreshStatus: { $set: STATUS.ERROR },
        }),

      [ActionTypes.PROXY_SUSTAINABILITY_MANAGER_LOGIN]: (state, { payload }) =>
        immutable(state, {
          userInfo: { $set: { ...state.userInfo, ...payload } },
          userInfoProxy: { $set: state.userInfo },
          status: { $set: STATUS.RUNNING },
          proxySustainabilityManagerLogin: { $set: STATUS.RUNNING },
          refreshStatus: { $set: STATUS.RUNNING },
        }),
      [ActionTypes.PROXY_SUSTAINABILITY_MANAGER_LOGIN_SUCCESS]: (
        state,
        { payload, role }
      ) =>
        immutable(state, {
          isAuthenticated: { $set: true },
          userInfo: { $set: payload },
          status: { $set: "success" },
          role: { $set: role },
          proxySustainabilityManagerLogin: { $set: "success" },
          refreshStatus: { $set: "idle" },
        }),
      [ActionTypes.PROXY_SUSTAINABILITY_MANAGER_LOGIN_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          // isAuthenticated: { $set: false },
          message: { $set: parseError(payload) },
          status: { $set: STATUS.ERROR },
          userInfo: { $set: state.userInfoProxy },
          userInfoProxy: { $set: userState.userInfoProxy },
          proxySustainabilityManagerLogin: { $set: STATUS.ERROR },
          refreshStatus: { $set: "idle" },
        }),
      [ActionTypes.RESET_AUTHENTICATION]: (state, { payload }) =>
        immutable(state, { $set: userState }),
      
      [ActionTypes.ADMIN_TWOFA_OTP_GENERATE]: (state, { payload }) =>
        immutable(state, {
          adminOtpInfo: {
            status: { $set: STATUS.RUNNING },
            data: { $set: payload },
          },
        }),
      [ActionTypes.ADMIN_TWOFA_OTP_GENERATE_SUCCESS]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          adminOtpInfo: {
            status: { $set: STATUS.SUCCESS },
          },
        }),
      [ActionTypes.ADMIN_TWOFA_OTP_GENERATE_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          adminOtpInfo: {
            status: { $set: STATUS.ERROR },
          },
        }),
      
        [ActionTypes.ADMIN_TWOFA_OTP_SUBMIT]: (state, { payload }) =>
          immutable(state, {
            adminOtpSubmit: {
              status: { $set: STATUS.RUNNING },
              data: { $set: payload },
            },
          }),
        [ActionTypes.ADMIN_TWOFA_OTP_SUBMIT_SUCCESS]: (
          state,
          { payload }
        ) =>
          immutable(state, {
            adminOtpSubmit: {
              status: { $set: STATUS.SUCCESS },
            },
          }),
        [ActionTypes.ADMIN_TWOFA_OTP_SUBMIT_FAILURE]: (
          state,
          { payload }
        ) =>
          immutable(state, {
            adminOtpSubmit: {
              status: { $set: STATUS.ERROR },
              message: { $set: payload },
            },
          }),

          [ActionTypes.ADMIN_PASSWORD_SUBMIT]: (state, { payload }) =>
            immutable(state, {
              adminPasswordSubmit: {
                status: { $set: STATUS.RUNNING },
                data: { $set: payload },
              },
            }),
          [ActionTypes.ADMIN_PASSWORD_SUBMIT_SUCCESS]: (state, { payload, role }) =>
            immutable(state, {
              isAuthenticated: { $set: true },
              userInfo: { $set: payload },
              status: { $set: STATUS.SUCCESS },
              role: { $set: role },
              adminOtpSubmit: {
                status: { $set: STATUS.RUNNING },
              },
            }),
          [ActionTypes.ADMIN_PASSWORD_SUBMIT_FAILURE]: (
            state,
            { payload }
          ) =>
            immutable(state, {
              adminPasswordSubmit: {
                status: { $set: STATUS.ERROR },
                message: { $set: payload },
              },
            }),
      [ActionTypes.RESET_SIGNUP_ACTION]: (state, { payload }) =>
        immutable(state, {
          signup: {
            status: { $set: STATUS.IDLE },
          },
        }),
        [ActionTypes.REDIRECT_CARBON_CREDIT_POOL]: (state, { payload }) =>
          immutable(state, {
              isNewDomain: { $set: true },
            },
          ),
          [ActionTypes.LOGIN_TRADE]: (state, { payload }) =>
          immutable(state, {
            userInfo: { $set: payload },
            status: { $set: STATUS.RUNNING },
          }),
        [ActionTypes.LOGIN_TRADE_SUCCESS]: (state, { payload, role }) =>
          immutable(state, {
            isAuthenticated: { $set: true },
            userInfo: { $set: payload },
            status: { $set: STATUS.SUCCESS },
            role: { $set: role },
          }),
        [ActionTypes.LOGIN_TRADE_FAILURE]: (state, { payload }) =>
          immutable(state, {
            isAuthenticated: { $set: false },
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          }),
          [ActionTypes.TRADE_LOGIN_ARRO_USER]: (state, { payload }) =>
          immutable(state, {
            userInfo: { $set: { ...state.userInfo, ...payload } },
            userInfoProxy: { $set: state.userInfo },
            status: { $set: STATUS.RUNNING },
            tradeLoginArroUser: { $set: STATUS.RUNNING },
            refreshStatus: { $set: STATUS.RUNNING },
          }),
        [ActionTypes.TRADE_LOGIN_ARRO_USER_SUCCESS]: (
          state,
          { payload, role }
        ) =>
          immutable(state, {
            isAuthenticated: { $set: true },
            userInfo: { $set: payload },
            status: { $set: "success" },
            role: { $set: role },
            tradeLoginArroUser: { $set: "success" },
            refreshStatus: { $set: "idle" },
          }),
        [ActionTypes.TRADE_LOGIN_ARRO_USER_FAILURE]: (
          state,
          { payload }
        ) =>
          immutable(state, {
            // isAuthenticated: { $set: false },
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
            userInfo: { $set: state.userInfoProxy },
            userInfoProxy: { $set: userState.userInfoProxy },
            tradeLoginArroUser: { $set: STATUS.ERROR },
          }),
          [ActionTypes.LOADING_START]: (state, { payload }) =>
            immutable(state, {
                isLoading: { $set: true },
              },
            ),
            [ActionTypes.LOADING_END]: (state, { payload }) =>
              immutable(state, {
                  isLoading: { $set: false },
                },
              ),
    },
    userState
  ),
};

export default authActions;
