import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    overflow: "auto",
    position: "relative",
    width: "100%",
    maxWidth: "none",
    marginTop: theme.spacing(5),
  },
  table: {
    borderCollapse: "collapse",
  },
  tableHeader: {
    backgroundColor: "#D0C5CA",
    fontWeight: "bold",
    border: "1px solid #5A404E80",
    textAlign: 'center',
    position: "sticky",
    top: 0,
    zIndex: 5,
  },
  tableCell: {
    border: "1px solid #5A404E80",
    textAlign: 'center',
    margin: "auto",
  },
  noDataWarning: {
    textAlign: "center",
    width: "75%",
  },
}));

export default useStyles;
