import { createActions } from "redux-actions";

import { ActionTypes } from "../constants/actions";

export const {
  listFacilities,
  getIndustryTypes,
  getCountryList,
  listGridRegions,
  getManagerList,
  listAssignee,
  listFramework,
  listTopic,
  companyCountryList,
  companyFacilityList,
  companyUsersList,
  companyFacilityDetails,
  getUserStatistics,
  getUsersList,
  companyUserDetails,
  totalUserDetails,
  getOrganizationCountry,
  getStakeholderCompany,
  getFacilityPeriods,
  getAuditTypes,
  getStates,
  getAssessmentYear
} = createActions({
  [ActionTypes.LIST_FACILITIES]: (countryName,search) => ({countryName,search}),
  [ActionTypes.GET_INDUSTRY_TYPES]: () => ({}),
  [ActionTypes.GET_COUNTRY_LIST]: () => ({}),
  [ActionTypes.LIST_GRID_REGIONS]: (countryId) => ({ countryId }),
  [ActionTypes.GET_MANAGER_LIST]: (role, facility_id) => ({
    role,
    facility_id,
  }),
  [ActionTypes.LIST_ASSIGNEE]: (role) => ({role}),
  [ActionTypes.LIST_FRAMEWORK]: () => ({}),
  [ActionTypes.LIST_TOPIC]: () => ({}),
  [ActionTypes.COMPANY_COUNTRY_LIST]: (companyId) => ({companyId}),
  [ActionTypes.COMPANY_FACILITY_LIST]: (companyId) => ({companyId}),
  [ActionTypes.COMPANY_USERS_LIST]: (companyId, isActive) => ({companyId, isActive}),
  [ActionTypes.COMPANY_FACILITY_DETAILS]: (companyId, countryName) => ({companyId, countryName}),
  [ActionTypes.GET_USER_STATISTICS]: () => ({}),
  [ActionTypes.GET_USERS_LIST]: (isActive) => ({isActive}),
  [ActionTypes.COMPANY_USER_DETAILS]: (companyId, id, userType, isActive) => ({companyId, id, userType, isActive}),
  [ActionTypes.TOTAL_USER_DETAILS]: (userType, isActive) => ({userType, isActive}),
  [ActionTypes.GET_ORGANIZATION_COUNTRY]: () => ({}),
  [ActionTypes.GET_STAKEHOLDER_COMPANY]: () => ({}),
  [ActionTypes.GET_FACILITY_PERIODS]: (year,facility) => ({year,facility}),
  [ActionTypes.GET_AUDIT_TYPES]: (year,status) => ({year,status}),
  [ActionTypes.GET_STATES]: ( country ) => ({country}),
  [ActionTypes.GET_ASSESSMENT_YEAR]: ( countryCode) => ({countryCode}),

});
