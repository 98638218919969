import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  IconButton,
  Chip,
  Typography,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import CreateIcon from "@mui/icons-material/CreateOutlined";
import {BoardDiversityByAgeGroupColumns, BoardDiversityByGenderColumns} from "../../EmissionTable/TableColumns";
import CeroTable from "../../../../../../components/CeroTable";
import useStyles from "./styles";
import { STATUS } from "../../../../../../redux/constants";

const BoardDiversityTable = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {
    emissionType,
    emissionData,
    onLoadMore,
    dataStatus,
    isAuditor,
    company,
    editOff,
    reportTable,
    isOnlyAdmin,
    distribution
  } = props;

  const onSelectEmissionData = (rowData) => {
    const currentFilter = `?distribution=gender`
    isAuditor
      ? navigate(
          `/reports/list-topic/${emissionType}/details/${rowData.id}/?company=${company}`
        )
      : navigate(
          `/reports/list-topic/${emissionType}/details/${rowData.id}${currentFilter}`
        );
  };
  
  const onSelectData = (rowData) => {
    // let ageFilter = "";
    // if(rowData.age === "Under 30"){
    //   ageFilter = "under_30";
    // }else if(rowData.age === "Between 30 and 50"){
    //   ageFilter = "between_30_50"
    // }else{
    //   ageFilter = "over_50"
    // }
    const currentFilter = `?distribution=age`
    isAuditor
      ? navigate(
          `/reports/list-topic/${emissionType}/details/${rowData.id}/?company=${company}`
        )
      : navigate(
          `/reports/list-topic/${emissionType}/details/${rowData.id}${currentFilter}`
        );
  };

  const onEditEmissionData = (e, id) => {
    e.stopPropagation();
    e.preventDefault();
    navigate(`/business/emissions/board_diversity/${id}`);
  }; 

  const getStatus = (status) => {
    if (status === 'approved') {
      return reportTable ? <Tooltip title="Approved" arrow><Chip className={classes.signalApproved}/></Tooltip>
        : <Typography className={classes.approved}>Approved</Typography>;
    } else if (status === 'submitted') {
        return reportTable ? <Tooltip title="Submitted" arrow><Chip className={classes.signalPending}/></Tooltip>
          : <Typography className={classes.pending}>Pending Approval</Typography>;
    } else if (status === 'audited') {
        return reportTable ? <Tooltip title="Audited" arrow><Chip className={classes.signalAudited}/></Tooltip>
          : <Typography className={classes.audited}>Audited</Typography>;
    } else if (status === 'pending') {
        return reportTable ? <Tooltip title="Pending Approval" arrow><Chip className={classes.signalPending}/></Tooltip>
          : <Typography className={classes.pending}>Pending Approval</Typography>;
    }else if (status === 'added') {
        return reportTable ? <Tooltip title="Added" arrow><Chip className={classes.signalAdded}/></Tooltip>
          : <Typography className={classes.added}>Added</Typography>;
    }else if (status === 'assigned') {
      return reportTable ? <Tooltip title="Assigned" arrow><Chip className={classes.signalPending}/></Tooltip>
        : <Typography className={classes.pending}>Pending Approval</Typography>;
    }else if (status === 'review') {
      return reportTable ? <Tooltip title="Review" arrow><Chip className={classes.signalPending}/></Tooltip>
        : <Typography className={classes.review}>Review</Typography>;
    }else if (status === 'verified') {
      return reportTable ? <Tooltip title="Verified" arrow><Chip className={classes.signalVerified}/></Tooltip>
        : <Typography className={classes.verified}>Verified</Typography>;
    }else if (status === 'Awaiting approval') {
      return reportTable ? <Tooltip title="Awaiting approval" arrow><Chip className={classes.signalPending}/></Tooltip>
        : <Typography className={classes.pending}>Pending Approval</Typography>;
    }else if (status === 'pending_approval') {
      return reportTable ? <Tooltip title="Pending approval" arrow><Chip className={classes.signalPending}/></Tooltip>
        : <Typography className={classes.pending}>Pending approval</Typography>;
    }
  else {
        return reportTable ? <Tooltip title="" arrow><Chip className={classes.signalPending}/></Tooltip>
         : <Typography className={classes.pending}>{status}</Typography>;
    } 
  };
  
  const getGenderDistribution = () =>
    emissionData?.map((item) => ({
      ...item,
      year: item.month ? `${item.month}/${item.year}` : `${item.year}`,
      male: `${item.male}%`,
      female: `${item.female}%`,
      other: `${item.other}%`,
      status: getStatus(item.status),
      action: (
        <Box className={classes.actionContainer}>
          {item.status === "added" &&
            !editOff && isOnlyAdmin &&(
              <IconButton
                className={classes.editIcon}
                onClick={(e) => onEditEmissionData(e, item.id)}
              >
                <CreateIcon />
              </IconButton>
            )}
        </Box>
      ),
    }));

    const getByAgeDistribution = () =>
    emissionData?.map((item) => ({
      ...item,
      year: item.month ? `${item.month}/${item.year}` : `${item.year}`,
      under_30: `${item.under_30}%`,
      between_30_50: `${item.between_30_50}%`,
      over_50: `${item.over_50}%`,
      status: getStatus(item.status),
      action: (
        <Box className={classes.actionContainer}>
          {item.status === "added" &&
            !editOff && isOnlyAdmin &&(
              <IconButton
                className={classes.editIcon}
                onClick={(e) => onEditEmissionData(e, item.id)}
              >
                <CreateIcon />
              </IconButton>
            )}
        </Box>
      ),
    }));

  return (
    <>
      {dataStatus === STATUS.SUCCESS ? (
        <Box>
          {distribution === "gender" &&
          <>
          <Typography variant="h7" component="span">
              <span className={classes.tableTitle}>
              Board Diversity by Gender
              </span>
              </Typography>
          <CeroTable
            columns={BoardDiversityByGenderColumns}
            data={getGenderDistribution}
            hasMore={false}
            loading={false}
            loadMore={onLoadMore}
            onSelectRow={onSelectEmissionData}
            classes={{ tableContainer: classes.tableContainer }}
          /></>}
          {distribution === "age" &&
          <>
          <Typography variant="h7" component="span">
              <span className={classes.tableTitle}>
              Board Diversity by Age Group
              </span>
              </Typography>
          <CeroTable
            columns={BoardDiversityByAgeGroupColumns}
            data={getByAgeDistribution}
            hasMore={false}
            loading={false}
            loadMore={onLoadMore}
            onSelectRow={onSelectData}
            classes={{ tableContainer: classes.tableContainer }}
          /></>}
        </Box>
      ) : (
        <Box className={classes.loader}>
          <Typography variant="h7" component="span">
            {dataStatus === STATUS.RUNNING ? (
              <CircularProgress />
            ) : dataStatus === STATUS.ERROR ? (
              "Something went wrong. Please try again later"
            ) : (
              ""
            )}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default BoardDiversityTable;
