import React from "react";
import {FormHelperText, MenuItem} from "@mui/material";
import {FormControl, InputLabel} from "@mui/material";
import {ListItemText, ListItemIcon} from "@mui/material";
import {Select} from "@mui/material";
import {Checkbox} from "@mui/material";
import useStyles from "./styles";
import clsx from "clsx";

const CeroChipSelect = (props) => {
    const classes = useStyles();
    const isAllSelected = props.optionsde && Object.keys(props.optionsde).length > 0 && props.selected.length === Object.keys(props.optionsde).length;
    
    const keyArray =  Object.keys(props.optionsde)
    const optionList = keyArray.length > 0 ? props.sortOption == true ? keyArray.sort((a, b) => a.localeCompare(b)) : keyArray :[]
    
    const shouldShowSelectAll = props.showSelectAll !== undefined
    ? props.showSelectAll
    : true;

  return (
    <FormControl className={props.formControlClass} error={props.error}  
            required={props.required} >
    <InputLabel id={`mutiple-select-label-${props.label}`} className={clsx(classes.label, props.classes?.label)} shrink={props.shrink}>{props.label}</InputLabel>
    <Select
      labelId="mutiple-select-label"
      multiple
      value={props?.selected}
      onChange={props?.handleChange}
      renderValue={props.renderValue || ((selected) => selected.join(", "))}
      className={clsx(classes.justCheck, {
        [classes.justCheckSelected]: props?.selected.length > 0,
      }, props.classes?.justCheckSelected)}
      disabled={props.disabled}
    >
      {shouldShowSelectAll && (<MenuItem
        value="all"
        classes={{
          root: isAllSelected ? classes.selectedAll : ""
        }}
      >
        <ListItemIcon>
          <Checkbox
            classes={{ indeterminate: classes.indeterminateColor }}
            checked={isAllSelected}
            indeterminate={
              props?.selected.length > 0 && props?.selected.length < Object.keys(props.optionsde).length
            }
          />
        </ListItemIcon>
        <ListItemText
          classes={{ primary: classes.selectAllText }}
          primary="Select All"
        />
      </MenuItem>)}
      {props.optionsde && (optionList.map((option) => (
        <MenuItem key={props?.optionsde[option]} value={option}>
          <ListItemIcon>
            <Checkbox className={classes.checkbox} checked={props?.selected.indexOf(option) > -1} />
          </ListItemIcon>
          <ListItemText primary={option} />
        </MenuItem>
      )))}
    </Select>
    {props.error && (
                <FormHelperText>{props.helperText || "This field is required"}</FormHelperText> // Display error message
            )}
  </FormControl>
  );
};

export default CeroChipSelect;