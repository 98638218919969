import React from "react";
import { Grid, FormControlLabel, Checkbox, FormGroup, Typography } from "@mui/material";
import clsx from "clsx";
import useStyles from "./styles";

const CeroQuestionWithCheckbox = (props) => {
  const classes = useStyles();
  const { question, formik, fieldName, checkedField} = props
  const handleCheckboxChange = (value) => {
    const updatedValue = value === formik.values[fieldName] ? null : value;
    formik.setFieldValue(fieldName, updatedValue);
  };

  return (
    <Grid container spacing={2} className={clsx(classes.questionBox, props.classes?.questionBox)}>
      <Grid item xs={6} >
        <Typography variant="body1" style={{ color: "black" }} className={clsx(classes.questionTitle,
        props.classes?.questionTitle,
      )}>{question}</Typography>
      </Grid>
      <Grid item direction="row" xs={6} className={clsx(classes.checkbox, props.classes?.checkbox)} spacing={2}>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox  style={{ color: "#8C2148" }}  checked={formik.values[fieldName] === "Yes" || checkedField === "yes"}
            onChange={() => handleCheckboxChange("Yes")} />}
            label="Yes"
          />
        </FormGroup>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox  style={{ color: "#8C2148" }}  checked={formik.values[fieldName] === "No" || checkedField === "no"}
            onChange={() => handleCheckboxChange("No")} />}
            label="No"
          />
        </FormGroup>
      </Grid>
    </Grid>
  );
};

export default CeroQuestionWithCheckbox;
