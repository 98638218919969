import { useEffect } from "react";
import { Container, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";

import { getApprovalDetails, resetApprovalDetails } from "../../../../redux/actions";
import CeroInfoPair from "../../../../components/CeroInfoPair";
import useStyles from "./styles";
import { getOverallRequestApprovalStatus } from "../../../../redux/actions/approval";

const Status = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const approvalSummaryDetails = useSelector( (state) => state.approval.getOverallRequestApprovalStatus.data );
  console.log('approvalSummaryDetails', approvalSummaryDetails)

  useEffect(() => {
    if(props.year && props.facility && props.auditId)
      dispatch(getOverallRequestApprovalStatus( props.facility, props.year,props.auditId));
    else
      dispatch(resetApprovalDetails())
  }, [props.year, props.facility, props.auditId, dispatch]);

  return (
    <Container className={classes.container}>
      <Grid spacing={2} className={classes.statusContainer}>
        <Grid item xs={6}>
          <CeroInfoPair title="Status" value={approvalSummaryDetails.status} classes={{ container: classes.infoContainer, value: approvalSummaryDetails?.status === "In Progress" ? classes.Inprogress : classes[approvalSummaryDetails?.status?.toLowerCase()], title:classes.title}} />
          <CeroInfoPair title="Audited By" value={approvalSummaryDetails.audited_by ? approvalSummaryDetails.audited_by : "NA"} classes={{ container: classes.infoContainer, title:classes.title}} />
          <CeroInfoPair title="Audited on" value={approvalSummaryDetails.audited_on ? dayjs(approvalSummaryDetails.audited_on).format('DD/MM/YYYY'): 'NA'} classes={{ container: classes.infoContainer, title:classes.title}} />
        </Grid>
        <Grid item xs={6}>
          <CeroInfoPair title="Approval Cycle" value={approvalSummaryDetails.approval_cycle?.split("_").join(" ")} classes={{ container: classes.infoContainer, title:classes.title}} />
          <CeroInfoPair title="Audit Cycle" value={approvalSummaryDetails.audit_cycle?.split("_").join(" ")} classes={{ container: classes.infoContainer, title:classes.title}} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Status;
