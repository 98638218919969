import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import { useDispatch, useSelector } from "react-redux";

import { Grid } from "@mui/material";
import { getCountryFacilityAggregate } from "../../../../redux/actions";
import CeroAutoComplete from "../../../../components/CeroAutoComplete";
import TotalUsersTable from "../../UsersTable/TotalUsersTable";


const CompanyTotalUsers = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [country, setCountry] = useState("");
  const [facility, setFacility] = useState("");
  const [facilities, setFacilities] = useState([]);
  
  const countryFacilityList = useSelector(
    (state) => state.superAdmin.getCountryFacilityAggregate.data
  );
  const countryFacilityData = useSelector(state => state.superAdmin.getOrganizationCountry.data.country_facility_data);

  useEffect(() => {
    if (props.companyId) {
      dispatch(getCountryFacilityAggregate(props.companyId, country, facility));
    }
  }, [dispatch, props.companyId, country, facility]);

  useEffect(() => {
    const facilityList = [];
    for (const countryIns in countryFacilityData) {
      for (const facilityIns in countryFacilityData[countryIns]) {
          facilityList.push({
              id: countryFacilityData[countryIns][facilityIns],
              label: facilityIns
          });
      }
  }
  setFacilities(facilityList)
  }, [countryFacilityData]);

  useEffect(() => {
    const facilityList = [];
        if (country){
            for (const facility in countryFacilityData[country]) {
                facilityList.push({
                    id: countryFacilityData[country][facility],
                    label: facility
                });
        }
        }
        else{
            for (const countryIns in countryFacilityData) {
                for (const facilityIns in countryFacilityData[countryIns]) {
                    facilityList.push({
                        id: countryFacilityData[countryIns][facilityIns],
                        label: facilityIns
                    });
                }
            }
        }
      
  setFacilities(facilityList)
  }, [country, countryFacilityData]);

  const countryData = useSelector(
    (state) => state.superAdmin.getOrganizationCountry.data.country_data
  );
  const countryList = Object.keys(countryData).map((item) => ({
    id: countryData[item],
    label: item,
  }));

  return (
    <>
      <Grid
        className={classes.filterContainer}
        container
        columnSpacing={3}
        alignItems="center"
        wrap="nowrap"
      >
        <Grid item xs={3.5} className={classes.yearFilter}>
          <CeroAutoComplete
            id="country"
            label="Country"
            onChange={(e, item) => setCountry(item ? item.id : null)}
            formControlClass={classes.MultiSelectformControl}
            options={countryList}
          />
        </Grid>
        <Grid item xs={3.5} className={classes.yearFilter}>
          <CeroAutoComplete
            id="facility"
            label="Facility"
            onChange={(e, item) => setFacility(item ? item.id : null)}
            formControlClass={classes.MultiSelectformControl}
            options={facilities}
          />
        </Grid>
      </Grid>
      <TotalUsersTable 
      data={countryFacilityList}
      />
    </>
  );
};

export default CompanyTotalUsers;
