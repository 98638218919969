import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
    width: "100%",
    background: theme.palette.background.white,
    fontSize: 13,
    fontWeight: 500,
    margin: theme.spacing(4, 0),
    position: "relative",
    lineHeight:"1rem",
    borderRadius: 8,
  },
  infoContainer: {
    maxWidth: 400,
    display:"flex !important",
    justifyContent: 'flex-start'
  },
  title:{
    width:"50%"
  },
  waitingIndicationContainer: {
    height: 10,
    width: 30,
    position: "absolute",
    top: theme.spacing(4),
    right: theme.spacing(4),
  },
  waitingIndication: {
    height: 30,
    width: 30,
    cursor: "pointer",
  },
  open:{
    color: theme.palette.status.open,
    textTransform: "capitalize",
  },
  closed:{
    color: theme.palette.status.closed,
    textTransform: "capitalize",
  },
  companyValue:{
    color: '#1D1D1D',
    fontSize: '14px',
    fontWeight: 600,
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
    width: "30px"
  },
  stepsContainer: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 10,
    fontSize: 13,
    gap: 5,
  },
  buttonSecondary: {
    color: theme.palette.Primary.background,
    borderColor: theme.palette.Primary.background,
    textTransform: "capitalize",
    "&:hover": {
      borderColor: theme.palette.Primary.background,
      backgroundColor: theme.palette.Primary.contrastText,
    },
  },
  buttonPrimary: {
    background: theme.palette.Primary.background,
    textTransform: "capitalize",
    height: 40,
    borderColor: theme.palette.Primary.background,
    fontWeight: 400,
    color: theme.palette.background.white,
    "&:hover": {
      backgroundColor: theme.palette.Primary.hoverButton,
      borderColor: theme.palette.Primary.hoverButton,
      color: theme.palette.Primary.light,
    },
  },
  buttonContainer: {
    display: "flex",
    gap: 10,
    marginTop: 8,
  },
  buttonContainerReview: {
    margin: "auto",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(8, 0),
    gap: 12,
  },
  popUp: {
    margin: "auto",
    padding: 20,
  },
  popUpConfirm:{
    margin: "auto",
    maxWidth: 300,
    display: "flex",
    flexDirection: "column",
    gap: 8,
  },
  dialog: {
    width: "40% !important",
    textAlign: "center",
    "& .MuiPaper-root.MuiDialog-paper": {
      maxWidth: "none",
      backgroundColor: "white",
    },
  },
}));

export default useStyles;
