import { Typography} from "@mui/material";
import useStyles from "./styles";
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';
import CeroPopUp from "../../../components/CeroPopUp";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const SignupResponse = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  
  useEffect(() => {
    setIsPopupOpen(true)
  }, [dispatch]);

  const successMessage = () => {
    return (
      <>
        <div className={classes.popupTitle}>
          <Typography variant="h5">ESG Material Topic Survey</Typography>
        </div>
        <TaskAltRoundedIcon className={classes.icon} />
        <Typography variant="h6" className={classes.message}>
          Thank You For Your Submission!
        </Typography>
      </>
    );
  };

  return (
    <CeroPopUp
      primaryPopUp={{
        open: isPopupOpen,
        content: successMessage(),
        header: {},
      }}
      classes={{ dialog: classes.dialog }}
    />
  );
};

export default SignupResponse;
