import { makeStyles } from "@mui/styles";

// @ts-ignore
const useStyles = makeStyles((theme) => ({
  mainContainer:{
    padding: "0 !important",
    '@media (min-width: 1200px)': {
      maxWidth: "none"
    },
  },
  container: {
    padding: theme.spacing(4),
    width: "100%",
    background: theme.palette.background.white,
    fontSize: 13,
    fontWeight: 500,
    borderRadius: 8,
    margin: theme.spacing(5, 0),
    position: "relative",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), -4px 0px 4px rgba(0, 0, 0, 0.05)",
    '@media (min-width: 1200px)': {
      maxWidth: "none"
    },
  },
  box:{
    margin: theme.spacing(0,0,12,0)
  },
  heading:{
    padding: theme.spacing(5,0),
    fontWeight: 600,
  },
  statusHeading:{
    fontWeight: 600,
  },
  infoContainer: {
    display:"flex !important",
    alignItems: 'start', 
    gap: '46px',
    padding: theme.spacing(1, 0),
  },
  statusContainer:{
    display:"flex !important",
    alignItems: 'start', 
    gap: '46px',
    paddingBottom: theme.spacing(5),
  },
  list:{
    listStyleType: "none",
  },
  title:{
    width:"170px",
    marginRight: theme.spacing(5),
    fontWeight: "500 !important",
  },
  value:{
    fontWeight: 500,
    whiteSpace: "break-spaces",
  },
  workerSafetyTitle:{
    width:"340px",
    marginRight: theme.spacing(5),
    fontWeight: 500,
  },
  discriminationTitle:{
    width:"50%",
    marginRight: theme.spacing(10),
    fontWeight: 500,
    whiteSpace: "nowrap"
  },
  supplierScreenTitle:{
    width:"500px",
    marginRight: theme.spacing(5),
    fontWeight: 500,
    whiteSpace: "nowrap"
  },
  supplierValues:{
    fontWeight: 500,
  },
  antiCorruptionRiskTitle:{
    width:"80%",
    marginRight: theme.spacing(10),
    fontWeight: 500,
  },
  amountDistribution:{
    padding: '10px',
    maxWidth: '50%',
    display: 'flex',
    flexDirection: 'row',
    flexDirection: 'column',
  },
  actionBox:{
    position: "absolute",
    right: "20%",
    top: "20%"
  },
  icon: {
    color: theme.palette.Primary.background,
    fontSize: "xx-large",
    "&:hover": {
      background: "white",
      borderRadius: "50%",
    },
  },
  statusTitle:{
    width: "10px !important",
    fontSize: 16,
  },
  approved: {
    color: theme.palette.status.approved,
    fontWeight: 600,
    fontSize: 16,
  },
  assigned: {
    color: theme.palette.status.pendingApproval,
    fontWeight: 600,
    fontSize: 16,
  },
  audited: {
    color: theme.palette.status.audited,
    fontWeight: 600,
    fontSize: 16,
  },
  verified:{
    color: theme.palette.status.verified,
    fontWeight: 600,
    fontSize: 16,
  },
  submitted: {
    color: theme.palette.status.pendingApproval,
    fontWeight: 600,
    fontSize: 16,
  },
  added: {
    color: theme.palette.status.added,
    fontWeight: 600,
    fontSize: 16,
  },
  review: {
    color: theme.palette.status.review,
    fontWeight: 600,
    fontSize: 16,
  },
  Inprogress: {
    color: theme.palette.status.pendingApproval,
    fontWeight: 600,
    fontSize: 16,
    whiteSpace: 'nowrap',
  },
  commonTitle:{
    width: "80%",
    fontWeight: 500,
    whiteSpace: "nowrap",
    marginRight: theme.spacing(4),
  },
  supplierHumanTitle:{
    width:"400px",
    marginRight: theme.spacing(5),
    fontWeight: 500,
  },
  diversityTitle:{
    width: "350px",
    marginRight: theme.spacing(5),
    fontWeight: 500,
  },
  subsidiesTitle:{
    width: "350px",
    marginRight: theme.spacing(5),
    fontWeight: 500,
    whiteSpace:"break-spaces !important",
  },
  antiCorruptionDisclosure:{
    width: "350px",
    marginRight: theme.spacing(5),
    fontWeight: 500,
    whiteSpace:"break-spaces !important",
  },
  programTitle: {
    width: "250px",
    marginRight: theme.spacing(5),
    fontWeight: 500,
  },
  trainingTitle: {
    width: "350px",
    marginRight: theme.spacing(5),
    fontWeight: 500,
  },
  subsidiesHeading:{
    padding: "20px 0px",
    fontWeight: 600,
    whiteSpace: "break-spaces",
    width: "250px",
  },
  trainingAndProgramAction: {
    position: "absolute",
    right: "5%",
    top: "5%",
  },
  approveButton:{
    background: theme.palette.Primary.light,
    textTransform: "capitalize",
    color: theme.palette.Primary.background,
    fontWeight: 600,
    boxShadow: "none",
    marginTop: theme.spacing(2),
    borderRadius: 8,
  },
  actionButtons:{
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      transform: "scale(1.1)"
    },
    "&:hover $approveButton": {
      backgroundColor: theme.palette.Primary.hoverButton,
      color: theme.palette.Primary.light, 
    },
  },
  valueMaxWidth:{
    fontWeight: 500,
    whiteSpace: "break-spaces",
    maxWidth: '415px',
    textAlign: 'justify'
  },
  valueMaxWidthExtra:{
    fontWeight: 500,
    whiteSpace: "break-spaces",
    maxWidth: '550px',
    textAlign: 'justify'
  },
  salaryRatioAction: {
    position: "absolute",
    right: "5%",
    top: "10%",
  },
  supplierHumanRights:{
    width: "450px",
    marginRight: theme.spacing(5),
    fontWeight: 500,
    whiteSpace:"break-spaces !important",
  },
}));

export default useStyles;
