import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Grid,
  Typography,
  Box,
} from "@mui/material";
import useStyles from "./styles";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CeroItemPair from "../../../../../components/CeroItemPair";
import CeroInfoPair from "../../../../../components/CeroInfoPair";

const EmployeeHealthSafety = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {
    emissionData,
  } = props;

  return (
    <>
      <div
        className={classes.backContainer}
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowBackIosIcon />
        Back
      </div>
      <Container className={classes.container}>
        <Box className={classes.innerContainer}>
          <Box className={classes.header}>
            <Typography variant="h6" component="div">
              Employee health safety incident record
            </Typography>
          </Box>
          <Box className={classes.actionBox}>
            <CeroInfoPair
              title="Status:"
              value={emissionData.status ? emissionData.status : "NA"}
              classes={{
                container: classes.statusContainer,
                title: classes.statusTitle,
                value:
                  emissionData.status === "In Progress"
                    ? classes.Inprogress
                    : classes[emissionData?.status],
              }}
            />
          </Box>
          <Box className={classes.topContainer}>
            <Grid
              container
              direction={"row"}
              wrap="nowrap"
              justifyContent={"space-between"}
              display={"contents"}
              spacing={12}
            >
              <Grid item container direction={"column"} display={"contents"} xs={10}>
                <CeroItemPair
                  title="Facility:"
                  value={
                    emissionData.facility_name
                      ? emissionData.facility_name
                      : "NA"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.title,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Period:"
                  value={
                    emissionData.assessment_period
                      ? emissionData.assessment_period
                      : "NA"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.title,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Year:"
                  value={emissionData.year ? emissionData.year : "NA"}
                  classes={{
                    container: classes.infoContainer,
                    title: classes.title,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Date:"
                  value={emissionData.date ? emissionData.date : "NA"}
                  classes={{
                    container: classes.infoContainer,
                    title: classes.title,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Type of Incident:"
                  value={
                    emissionData.type_of_incident
                      ? emissionData.type_of_incident
                      : "NA"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.title,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="No of affected:"
                  value={emissionData.affected ? emissionData.affected : "NA"}
                  classes={{
                    container: classes.infoContainer,
                    title: classes.title,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Severity of Incident:"
                  value={
                    emissionData.severity_of_incident
                      ? emissionData.severity_of_incident
                      : "NA"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.title,
                    value: classes.value,
                  }}
                />

                <CeroItemPair
                  title="Impact on work:"
                  value={
                    emissionData.impact_on_work
                      ? emissionData.impact_on_work
                      : "NA"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.title,
                    value: classes.value,
                  }}
                />

                <CeroItemPair
                  title="Medical Treatment Provided:"
                  value={
                    emissionData.medical_treatment_provided
                      ? emissionData.medical_treatment_provided
                      : "NA"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.title,
                    value: classes.value,
                  }}
                />

                <CeroItemPair
                  title="Details:"
                  value={emissionData.details ? emissionData.details : "NA"}
                  classes={{
                    container: classes.infoContainer,
                    title: classes.title,
                    value: classes.value,
                  }}
                />

                <CeroItemPair
                  title="Corrective Action:"
                  value={
                    emissionData.corrective_action
                      ? emissionData.corrective_action
                      : "NA"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.title,
                    value: classes.value,
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default EmployeeHealthSafety;
