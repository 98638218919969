import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { useSnackbar } from "notistack";
import { Container, Typography, Box, CircularProgress, Stack } from "@mui/material";
import DashboardLayout from "../../../layouts/DashboardLayout";
import CeroInput from "../../../components/CeroInput";
import CeroButton from "../../../components/CeroButton";
import Status from "../../Tickets/Status";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import {
  addResponse,
  closeTicket,
  getTicketDetails,
  resetTicketStatus,
} from "../../../redux/actions";
import { STATUS } from "../../../redux/constants";

import useStyles from "./styles";
import CeroCommentCell from "../../../components/CeroCommentCell";
import { getCookie } from "../../../services/cookie";
import { rolesEnum } from "../../../layouts/DashboardLayout/pages";

const SupportTicketDetails = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("ticketId");
  const companyId = queryParams.get("companyId");
  const readOnlyMode = (getCookie("read_only_mode") === "disable_edit")
  const [comment, setComment] = useState("");
  const userInfo = useSelector((state) => state.auth.userInfo);
  const isAuditor = userInfo && userInfo.role.includes(rolesEnum.AUDITOR);

  const ticketDetails = useSelector(
    (state) => state.ticket.ticketDetails.data.response
  );
  const ticketDetailsStatus = useSelector(
    (state) => state.ticket.ticketDetails.status
  );
  const closeTicketDetailsStatus = useSelector(
    (state) => state.ticket.closeTicketDetails.status
  );
  const closeTicketDetailsError = useSelector(
    (state) => state.ticket.closeTicketDetails.message
  );
  const deleteTicketDetailsStatus = useSelector(
    (state) => state.ticket.deleteTicketDetails.status
  );
  const deleteTicketDetailsError = useSelector(
    (state) => state.ticket.deleteTicketDetails.message
  );
  const addCommentStatus = useSelector(
    (state) => state.ticket.addResponse.status
  );

  const onAddComment = () => {
    dispatch(addResponse({ id, response: comment, companyId, isAuditor }));
  };

  const onCloseTicket = () => {
    dispatch(closeTicket({ id, companyId ,isAuditor }));
    isAuditor &&  dispatch(closeTicket({ id , isAuditor}));
  };

  const isCommentLoading = addCommentStatus === STATUS.RUNNING;
  const isCloseLoading = closeTicketDetailsStatus === STATUS.RUNNING;

  useEffect(() => {
    if (addCommentStatus === STATUS.SUCCESS) {
      enqueueSnackbar("Comment added successfully", { variant: "success" });
      setComment("");
      dispatch(resetTicketStatus());
      dispatch(getTicketDetails({ id, companyId , isAuditor}));
    } else if (addCommentStatus === STATUS.ERROR) {
      enqueueSnackbar("We couldn't process your request. Please try again later.", { variant: "error" });
      dispatch(resetTicketStatus());
    }
  }, [addCommentStatus, enqueueSnackbar, dispatch, id, isAuditor, companyId]);

  useEffect(() => {
    if (closeTicketDetailsStatus === STATUS.SUCCESS) {
      enqueueSnackbar("Ticket closed successfully", { variant: "success" });
      dispatch(resetTicketStatus());
      navigate("/support-tickets");
    } else if (closeTicketDetailsStatus === STATUS.ERROR) {
      enqueueSnackbar(closeTicketDetailsError.message || "We couldn't process your request. Please try again later.", { variant: "error" });
      dispatch(resetTicketStatus());
    }
  }, [closeTicketDetailsStatus, enqueueSnackbar, dispatch, navigate, closeTicketDetailsError.message]);

  useEffect(() => {
    if (deleteTicketDetailsStatus === STATUS.SUCCESS) {
      enqueueSnackbar("Ticket deleted successfully", { variant: "success" });
      dispatch(resetTicketStatus());
      navigate("/support-tickets");
    } else if (deleteTicketDetailsStatus === STATUS.ERROR) {
      enqueueSnackbar(deleteTicketDetailsError.message || "We couldn't process your request. Please try again later.", { variant: "error" });
      dispatch(resetTicketStatus());
    }
  }, [deleteTicketDetailsStatus, enqueueSnackbar, dispatch, navigate, deleteTicketDetailsError.message]);

  useEffect(() => {
    id && dispatch(getTicketDetails({ id, companyId ,isAuditor}));
    id && isAuditor && dispatch(getTicketDetails({ id , isAuditor}));
  }, [dispatch, id, companyId, isAuditor] );
  const navigateBack = () => {
    navigate(-1);
  };

  return (
    <DashboardLayout>
      <Container className={classes.container}>
        <Stack direction="row" justifyContent="space-between">
          <div className={classes.backContainer} onClick={navigateBack}>
            <ArrowBackIosIcon />
            Back
          </div>
          <Box>
            {ticketDetails?.status !== "closed" && (
                <CeroButton
                  buttonText={isCloseLoading ? "Closing..." : "Close ticket"}
                  className={clsx(classes.buttonPrimary)}
                  onClick={onCloseTicket}
                  disabled={readOnlyMode}
                  // disabled={isCloseLoading}
                  // variant="outlined"
                />
              )}
          </Box>
        </Stack>
        {ticketDetailsStatus === STATUS.SUCCESS ? (
          <Fragment>
            <Box className={classes.header}>
              <Typography variant="h6" component="span">
                {`Title: ${ticketDetails.title}`}
              </Typography>
            </Box>
            <Status ticketDetails={ticketDetails} userInfo={userInfo} />
            {ticketDetails.responses.length > 0 && (
              <><Typography
              variant="body1"
              component="span"
              className={classes.comment}
            >
              Comments
            </Typography>
            <Box className={classes.detailsContainer}>
              <Box className={classes.descriptionContainer}>
                <Typography variant="h6" component="span">
                  {ticketDetails.ticket_owner_name}
                </Typography>
                {ticketDetails.responses && ticketDetails.responses.length 
                  ? ticketDetails.responses.map((comment) => (
                      <CeroCommentCell
                        key={comment.response.id}
                        name={comment.response_user_name}
                        msg={comment.response}
                        time={comment.created_on}
                      />
                    ))
                  : ""}
              </Box>
            </Box>
            </>)}
            {ticketDetails?.status !== "closed" && (
                <Box className={classes.commentBox}>
                  <CeroInput
                    classes={{ container: classes.textArea }}
                    rows={3}
                    multiline
                    id="comment"
                    name="comment"
                    label="Comment"
                    placeholder="Please type your comment"
                    value={comment}
                    fullWidth
                    onChange={({ target }) => setComment(target.value)}
                    disabled={readOnlyMode}
                  />
                  <CeroButton
                    buttonText={
                      isCommentLoading ? "Commenting..." : "Add comment"
                    }
                    className={clsx(classes.buttonPrimary)}
                    onClick={onAddComment}
                    disabled={!comment || isCommentLoading || readOnlyMode}
                  />
                </Box>
              )}
          </Fragment>
        ) : (
          <Box className={classes.loader}>
            <Typography variant="h7" component="span">
              {ticketDetailsStatus === STATUS.RUNNING ? (
                <CircularProgress />
              ) : ticketDetailsStatus === STATUS.ERROR ? (
                "We couldn't process your request. Please try again later."
              ) : (
                ""
              )}
            </Typography>
          </Box>
        )}
      </Container>
    </DashboardLayout>
  );
};

export default SupportTicketDetails;
