import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Paper,
  Typography,
  Grid,
} from "@mui/material";
import { getEconomicGraph } from "../../../redux/actions";
import useStyles from "./styles";
import { getCookie } from "../../../services/cookie";

const EconomicGraph = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const role = getCookie("role");
  const companyId = getCookie("companyId");
  const values = useSelector(
    (state) => state.dashboard.getEconomicGraph.data
  );

  useEffect(() => {
    !props.refreshContent && dispatch(getEconomicGraph(props.filter,role,companyId));
  }, [dispatch, props.filter]);

  return (
    <Paper className={classes.container}>
      <Typography className={classes.graphTitle}>
        Economic & Financial Disclosure
      </Typography>
      <Grid container spacing={2} display="flex" style={{height: "100%"}}>
        <Grid item xs={6} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <div className={classes.circle} style={{ borderColor: "#846BB5"}}>
            <Typography variant="h5" className={classes.value}>{values?.total_number_infrastructure}</Typography>
          </div>
          <Typography variant="caption">Infrastructure Investments</Typography>
        </Grid>
        <Grid item xs={6} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <div className={classes.circle} style={{ borderColor: "#FFC757"}}>
            <Typography variant="h5" className={classes.value}>{values?.financial_assistant_received}</Typography>
          </div>
          <Typography variant="caption">
            Financial Assisstance Recieved
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default EconomicGraph;
