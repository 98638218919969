import React, { useEffect } from "react";
import clsx from "clsx";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Container, Grid, Typography, Box } from "@mui/material";
import { useSnackbar } from "notistack";
import { STATUS } from "../../../redux/constants";
import { schemeValidation } from "./schema";
import {
  addEconomicImpact,
  getAssessmentPeriod,
  resetAddCombustionStatus,
} from "../../../redux/actions";
import CeroButton from "../../../components/CeroButton";
import CeroInput from "../../../components/CeroInput";
import useStyles from "./styles";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CeroSelect from "../../../components/CeroSelect";
import CeroAutoComplete from "../../../components/CeroAutoComplete";
import { sampleYear } from "../../../constants";
import CeroQuestionWithCheckbox from "../../../components/CeroQuestionWithCheckbox";

const AddEconomicImpact = (props) => {
  const { onCancel } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const addEmissionData = useSelector(
    (state) => state.emission.addEconomicImpact
  );

  const currentYear = new Date().getFullYear().toString()

  const formik = useFormik({
    initialValues: {
      facility: "",
      year: currentYear,
      assessmentPeriod: "",
      significantInfrastructure: "",
      infrastructureDetails: "",
      investmentLength: "",
      natureOfInvestment: "",
      anyDirectImpacts: "",
      detailsOfDirect: "",
      anyIndirectImpacts: "",
      detailsOfIndirect: "",
      measuresTaken: "",
    },
    validationSchema: schemeValidation,
    onSubmit: () => {},
  });

  const facilitiesData = useSelector(
    (state) => state.listings.listFacilities.data
  );

  const facilitiesList = facilitiesData.map((item) => ({
    key: item.id,
    value: item.name,
  }));

  const assessmentData = useSelector(
    (state) => state.assessment.assessmentPeriod.data
  );

  const assessmentOptions = Array.isArray(assessmentData)
    ? assessmentData.map((item) => ({
        key: item.period,
        value: item.period,
      }))
    : [];

  const yearList = sampleYear().map((item) => ({
    id: item.key,
    label: item.value,
  }));

  useEffect(() => {
    const { facility, year } = formik.values;
    if (facility && year) {
      dispatch(getAssessmentPeriod(year, facility, 'economic_impact'));
    }
  }, [formik.values.facility, formik.values.year, dispatch]);

  useEffect(() => {
    if (addEmissionData.status === STATUS.SUCCESS) {
      enqueueSnackbar("Economic Impact added successfully", {
        variant: "success",
      });
      dispatch(resetAddCombustionStatus());
      onCancel();
    } else if (addEmissionData.status === STATUS.ERROR) {
      enqueueSnackbar(
        addEmissionData.message.message ||
          "We couldn't process your request. Please try again later.",
        { variant: "error" }
      );
      dispatch(resetAddCombustionStatus());
    }
  }, [addEmissionData, dispatch, enqueueSnackbar, onCancel]);

  const onAddEconomicImpact= () => {
    const requestData = {
      facility_id: formik.values.facility,
      assessment_period: formik.values.assessmentPeriod,
      year: formik.values.year,
      any_significant_infrastructure: formik.values.significantInfrastructure,
      nature_of_investments: formik.values.natureOfInvestment,
      infrastructure_investment: formik.values.infrastructureDetails,
      length_of_investments: formik.values.investmentLength,
      any_direct_impact_on_communities: formik.values.anyDirectImpacts,
      details_of_direct_impact: formik.values.detailsOfDirect,
      any_indirect_impact: formik.values.anyIndirectImpacts,
      details_of_indirect_impact: formik.values.detailsOfIndirect,
      migration_for_negative_impact: formik.values.measuresTaken,
      save: true,
    };
    sessionStorage.setItem('year', requestData.year)
    dispatch(addEconomicImpact(requestData));
  };

  const investmentNature = [
    { key: "Commercial", value: "Commercial" },
    { key: "In-Kind", value: "In-Kind" },
    { key: "Pro bono", value: "Pro bono" },
    { key: "Others", value: "Others"}
  ];

  const investmentLength = [
    { key: "1 Year", value: "1 Year" },
    { key: "2 Years", value: "2 Years" },
    { key: "3 Years", value: "3 Years" },
    { key: "4 Years", value: "4 Years" },
    { key: "5 Years", value: "5 Years" },
    { key: "10 Years", value: "10 Years" },
    { key: "Others", value: "Others"}
  ];

  const handleAnswerChange = (fieldName, answer) => {
    formik.handleChange({ target: { name: fieldName, value: answer } });
  };

  return (
    <>
      <div
        className={classes.backContainer}
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowBackIosIcon />
        Back
      </div>
      <Container className={classes.container}>
        <Box className={classes.innerContainer}>
          <Typography className={classes.title} variant="h6" component="div">
            Add Economic Impact
          </Typography>
          <Box>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column" md={6} xs={12}>
                <CeroSelect
                  required
                  id="facility"
                  name="facility"
                  label="Facility"
                  fullWidth
                  options={facilitiesList}
                  selectedValue={formik.values.facility}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.facility && formik.errors.facility}
                />
              </Grid>
              <Grid item container direction={"column"} md={6} xs={12}>
                <CeroAutoComplete
                  required
                  id="year"
                  label="Assessment Year"
                  onChange={(e, value) =>
                    formik.setFieldValue("year", value.id)
                  }
                  onBlur={formik.handleBlur}
                  error={formik.errors.year}
                  options={yearList}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={formik.values.year}
                />
              </Grid>
              <Grid item container direction="column" md={6} xs={12}>
                <CeroSelect
                  required
                  id="assessmentPeriod"
                  name="assessmentPeriod"
                  label="Assessment Period"
                  fullWidth
                  options={assessmentOptions}
                  selectedValue={formik.values.assessmentPeriod}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.assessmentPeriod &&
                    formik.errors.assessmentPeriod
                  }
                />
              </Grid>
            </Grid>
            <CeroQuestionWithCheckbox
             required
              question="Do you have any significant Infrastructure investment."
              formik={formik}
              fieldName="significantInfrastructure"
              onChange={handleAnswerChange}
              classes={{ questionBox: classes.questionBox }}
            />
            {formik.values.significantInfrastructure === "Yes" &&
            <CeroInput
              id="infrastructureDetails"
              name="infrastructureDetails"
              label="Enter the details of infrastructure investments"
              fullWidth
              multiline
              rows="2"
              value={formik.values.infrastructureDetails}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.infrastructureDetails && formik.errors.infrastructureDetails}
              classes={{ container: classes.textAreaContainer }}
            />}
            {formik.values.significantInfrastructure === "Yes" &&
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column" md={6} xs={12}>
                <CeroSelect
                  id="natureOfInvestment"
                  name="natureOfInvestment"
                  label="Nature of Investment"
                  fullWidth
                  options={investmentNature}
                  selectedValue={formik.values.natureOfInvestment}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.natureOfInvestment && formik.errors.natureOfInvestment}
                />
              </Grid>
              <Grid item container direction={"column"} md={6} xs={12}>
              <CeroSelect
                  id="investmentLength"
                  name="investmentLength"
                  label="Length of Investment"
                  fullWidth
                  options={investmentLength}
                  selectedValue={formik.values.investmentLength}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.investmentLength && formik.errors.investmentLength}
                />
              </Grid>
              <Grid item container direction="column" md={6} xs={12}>
              </Grid>
            </Grid>
            }
            <CeroQuestionWithCheckbox
             required
              question="Does it have any direct impacts on communities and local economies?"
              formik={formik}
              fieldName="anyDirectImpacts"
              onChange={handleAnswerChange}
              classes={{ questionBox: classes.questionBox }}
            />
            {formik.values.anyDirectImpacts === "Yes" &&
            <CeroInput
              id="detailsOfDirect"
              name="detailsOfDirect"
              label="If yes, provide details for positive and negative impact"
              fullWidth
              multiline
              rows="2"
              value={formik.values.detailsOfDirect}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.detailsOfDirect && formik.errors.detailsOfDirect}
              classes={{ container: classes.textAreaContainer }}
            />}
            <CeroQuestionWithCheckbox
             required
              question="Does it have any indirect impacts on communities and local economies?"
              formik={formik}
              fieldName="anyIndirectImpacts"
              onChange={handleAnswerChange}
              classes={{ questionBox: classes.questionBox }}
            />
            {formik.values.anyIndirectImpacts === "Yes" &&
            <CeroInput
              id="detailsOfIndirect"
              name="detailsOfIndirect"
              label="If yes, provide details for positive and negative impact"
              fullWidth
              multiline
              rows="2"
              value={formik.values.detailsOfIndirect}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.detailsOfIndirect && formik.errors.detailsOfIndirect}
              classes={{ container: classes.textAreaContainer }}
            />
            }
            <CeroInput
              required
              id="measuresTaken"
              name="measuresTaken"
              label="What are the potential mitigation measures taken for negative impacts"
              fullWidth
              multiline
              rows="3"
              value={formik.values.measuresTaken}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.measuresTaken && formik.errors.measuresTaken}
              classes={{ container: classes.textAreaContainer }}
            />
          </Box>
        </Box>
        <Box className={classes.buttonContainer}>
          <CeroButton
            buttonText="Add Data"
            disabled={!formik.dirty || !formik.isValid}
            className={clsx(classes.button, classes.buttonPrimary)}
            onClick={onAddEconomicImpact}
          />
          <CeroButton
            buttonText="Clear"
            variant="outlined"
            className={classes.buttonSecondary}
            onClick={formik.resetForm}
          />
        </Box>
      </Container>
    </>
  );
};

export default AddEconomicImpact;
