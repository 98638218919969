import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container, Typography, Box, CircularProgress } from "@mui/material";

import DashboardLayout from "../../layouts/DashboardLayout";
import CeroButton from "../../components/CeroButton";
import CreateReportDrawer from "./CreateReportDrawer";
import { getAllReports, listFramework, listTopic } from "../../redux/actions";
import { STATUS } from "../../redux/constants";
import Filter from "./Filter";

import useStyles from "./styles";
import { getCookie } from "../../services/cookie";
import ReportTable from "./ReportTable";

export const auditSummaryColumns = [
  {
    columnKey: "name",
    columnId: "name",
    columnHeader: "Report Name",
    width : '15vw',
  },
  {
    columnKey: "country",
    columnId: "country",
    columnHeader: "Country",
    width : '10vw',
  },
  {
    columnKey: "facility",
    columnId: "facility",
    columnHeader: "Facility",
    width : '10vw',
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
    width : '10vw',
  },
  {
    columnKey: "period",
    columnId: "period",
    columnHeader: "Period",
    width : '10vw',
  },
  {
    columnKey: "created_by_name",
    columnId: "created_by_name",
    columnHeader: "Created by",
    width : '10vw',
  },
  {
    columnKey: "created_ts",
    columnId: "created_ts",
    columnHeader: "Created on",
    width : '15vw',
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
    width : '15vw',
  },
];

const Reports = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const role = getCookie('role');
  const readOnlyMode = (getCookie("read_only_mode") === "disable_edit")
  
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [filter, setFilter] = useState({});

  const reportsList = useSelector((state) => state.reports.reportsList.data);
  const reportsListStatus = useSelector(
    (state) => state.reports.reportsList.status
  );
  const frameworkList = useSelector(
    (state) => state.listings.frameworkList.data
  );

  const onSelectAuditSummaryData = (row) => {
    navigate(`details/${row.id}`);
  }; 

  useEffect(() => {
    dispatch(getAllReports(filter));
  }, [dispatch, filter]);

  useEffect(() => {
    dispatch(listTopic());
    dispatch(listFramework());
  }, [dispatch]);

  return (
    <DashboardLayout>
      <Container className={classes.container}>
        <Box className={classes.buttonContainer}>
          <Filter frameworkList={frameworkList} setFilter={setFilter}/>
          {(role === 'sustainability_manager' || role === "proxy_sustainability_manager") && <CeroButton
            buttonText="Create report"
            className={classes.buttonPrimary}
            onClick={() => setIsDrawerOpen(true)}
            disabled={readOnlyMode}
          />}
        </Box>

        {reportsListStatus === STATUS.SUCCESS ? (
          <Container className={classes.tableContainer}>
            <ReportTable
              columns={auditSummaryColumns}
              data={reportsList}
              onSelectRow={onSelectAuditSummaryData}
            />
          </Container>
        ) : (
          <Box className={classes.loader}>
            <Typography variant="h7" component="span">
              {reportsListStatus === STATUS.RUNNING
                ? <CircularProgress/>
                : reportsListStatus === STATUS.ERROR
                ? "We couldn't process your request. Please try again later"
                : ""}
            </Typography>
          </Box>
        )}
        <CreateReportDrawer
          isOpen={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
        />
      </Container>
    </DashboardLayout>
  );
};

export default Reports;
