import React, { useEffect } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CeroDropdown from "../../../components/CeroDropdown";
import { useSnackbar } from "notistack";
import { sampleYear } from "../../../constants";
import {
  Grid,
  Typography,
  Container,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  TableContainer,
  Paper,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "./styles";
import CeroButton from "../../../components/CeroButton";
import clsx from "clsx";
import * as Yup from "yup";
import {
  addBoardDiversity,
  getAssessmentPeriod,
  resetAddBoardDiversityStatus,
} from "../../../redux/actions";
import { STATUS } from "../../../redux/constants";

const AddBoardDiversity = (props) => {
  const { onCancel } = props;
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const facilitiesData = useSelector(
    (state) => state.listings.listFacilities.data
  );
  const assessmentData = useSelector(
    (state) => state.assessment.assessmentPeriod.data
  );

  const addEmissionData = useSelector(
    (state) => state.emission.addBoardDiversity
  );

  const dropdownOptions = {
    facilitiesList: facilitiesData
      ? facilitiesData.map((item) => ({ key: item.id, value: item.name }))
      : [],
    assessmentOptions: Array.isArray(assessmentData)
      ? assessmentData.map((item) => ({ key: item.period, value: item.period }))
      : [],
  };

  const validationSchema = Yup.object({
    facility_id: Yup.mixed().required("Facility is required"),
    // assessment_period: Yup.mixed().required('Field is required'),
    year: Yup.mixed().required("Year is required"),
    board_member_by_gender: Yup.object({
      total: Yup.number()
        .typeError("Must be a number")
        .min(0, "Cannot be less than 0"),
      male: Yup.number()
        .typeError("Must be a number")
        .min(0, "Cannot be less than 0"),
      female: Yup.number()
        .typeError("Must be a number")
        .min(0, "Cannot be less than 0"),
      other: Yup.number()
        .typeError("Must be a number")
        .min(0, "Cannot be less than 0"),
    }).test(
      "gender-total-validation",
      "Gender totals do not match",
      function (value) {
        const { total, male, female, other } = value;
        return total === male + female + other;
      }
    ),
    board_member_by_age: Yup.object({
      total: Yup.number()
        .typeError("Must be a number")
        .min(0, "Cannot be less than 0"),
      under_30: Yup.number()
        .typeError("Must be a number")
        .min(0, "Cannot be less than 0"),
      between_30_50: Yup.number()
        .typeError("Must be a number")
        .min(0, "Cannot be less than 0"),
      over_50: Yup.number()
        .typeError("Must be a number")
        .min(0, "Cannot be less than 0"),
    }).test(
      "age-total-validation",
      "Age totals do not match",
      function (value) {
        const { total, under_30, between_30_50, over_50 } = value;
        return total === under_30 + between_30_50 + over_50;
      }
    ),
  });
  const currentYear = new Date().getFullYear().toString()

  const formik = useFormik({
    initialValues: {
      facility_id: "",
      assessment_period: "",
      year: currentYear,
      board_member_by_gender: { total: "", male: "", female: "", other: "" },
      board_member_by_age: {
        total: "",
        under_30: "",
        between_30_50: "",
        over_50: "",
      },
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {},
  });

  useEffect(() => {
    const { facility_id, year } = formik.values;
    if (facility_id && year) {
      dispatch(getAssessmentPeriod(year, facility_id, 'board_diversity'));
    }
  }, [formik.values.facility_id, formik.values.year, dispatch]);

  const handleSubmit = () => {
    const requestData = {
      facility_id: formik.values.facility_id,
      assessment_period: formik.values.assessment_period,
      year: formik.values.year,
      board_member_by_gender: {
        total: Number(formik.values.board_member_by_gender.total),
        male: Number(formik.values.board_member_by_gender.male),
        female: Number(formik.values.board_member_by_gender.female),
        other: Number(formik.values.board_member_by_gender.other),
      },
      board_member_by_age: {
        total: Number(formik.values.board_member_by_age.total),
        under_30: Number(formik.values.board_member_by_age.under_30),
        between_30_50: Number(formik.values.board_member_by_age.between_30_50),
        over_50: Number(formik.values.board_member_by_age.over_50),
      },
      save: true,
    };
    sessionStorage.setItem('year', requestData.year)
    dispatch(addBoardDiversity(requestData));
  };

  useEffect(() => {
    const genderTotal = Number(formik.values.board_member_by_gender.total) || 0;
    const male = Number(formik.values.board_member_by_gender.male) || 0;
    const female = Number(formik.values.board_member_by_gender.female) || 0;
    const otherGender = Math.max(genderTotal - male - female, 0);

    formik.setFieldValue("board_member_by_age.total", genderTotal.toString());
    formik.setFieldValue(
      "board_member_by_gender.other",
      otherGender.toString()
    );

    const under_30 = Number(formik.values.board_member_by_age.under_30) || 0;
    const between_30_50 =
      Number(formik.values.board_member_by_age.between_30_50) || 0;
    const over_50 = Math.max(genderTotal - under_30 - between_30_50, 0);
    formik.setFieldValue("board_member_by_age.over_50", over_50.toString());
  }, [
    formik.values.board_member_by_gender,
    formik.values.board_member_by_age.under_30,
    formik.values.board_member_by_age.between_30_50,
    formik.setFieldValue,
  ]);

  useEffect(() => {
    if (addEmissionData.status === STATUS.SUCCESS) {
      enqueueSnackbar("Board Diversity added successfully", {
        variant: "success",
      });
      dispatch(resetAddBoardDiversityStatus());
      onCancel();
    } else if (addEmissionData.status === STATUS.ERROR) {
      enqueueSnackbar(
        addEmissionData.message.message ||
          "We couldn't process your request. Please try again later.",
        { variant: "error" }
      );
      dispatch(resetAddBoardDiversityStatus());
    }
  }, [addEmissionData, dispatch, enqueueSnackbar, onCancel]);

  useEffect(() => {
    const errorMessages = Object.values(formik.errors);
    if (formik.submitCount > 0 && errorMessages.length > 0) {
      // Display the first error message or customize as needed
      enqueueSnackbar(errorMessages[0], { variant: "error" });
    }
  }, [formik.errors, formik.submitCount, enqueueSnackbar]);

  const renderDropdown = (id, label, options, selectedValue, setFieldValue) => (
    <CeroDropdown
      id={id}
      label={label}
      fullWidth
      options={options}
      onChange={({ target }) => setFieldValue(id, target.value)}
      selectedValue={selectedValue}
      value={ id=== "year" ? currentYear : ""}
    />
  );

  const getNestedErrorAndTouched = (name, formik) => {
    const keys = name.split(".");
    let touched = formik.touched;
    let error = formik.errors;

    for (let key of keys) {
      if (touched) {
        touched = touched[key];
      }
      if (error) {
        error = error[key];
      }
    }

    return { touched, error };
  };

  const renderInputCell = (name, placeholder) => {
    const { error, touched } = getNestedErrorAndTouched(name, formik);

    return (
      <TableCell>
        <input
          className={classes.input}
          placeholder={placeholder}
          name={name}
          type="text"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.getFieldProps(name).value || ""}
        />
        {touched && error ? (
          <div style={{ color: "red", fontSize: "10px" }}>{error}</div>
        ) : null}
      </TableCell>
    );
  };

  const renderTable = (title, inputs) => (
    <TableContainer component={Paper} className={clsx(classes.tableContainer)}>
      <Table stickyHeader>
        <TableHead>
          <TableRow className={classes.tableHeader}>
            <TableCell
              className={clsx(classes.headerCell)}
              style={{ width: "25%" }}
            >
              {title}
            </TableCell>
            {inputs.map((input, index) => (
              <TableCell
                key={index}
                className={clsx(classes.headerCell)}
              >
                {input.header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell
              className={clsx(classes.headerCell)}
              style={{ width: "25%" }}
            >
              <Typography variant="body2">Number of Board Members</Typography>
            </TableCell>
            {inputs.map((input, index) =>
              renderInputCell(input.name, input.placeholder)
            )}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <>
      <div className={classes.backContainer} onClick={() => navigate(-1)}>
        <ArrowBackIosIcon /> Back
      </div>
      <Container className={classes.container}>
        <form onSubmit={formik.handleSubmit}>
          <Typography variant="h6">Board Diversity</Typography>
          <Grid
            className={classes.filterContainer}
            container
            gap={10}
            justifyContent="flex-start"
            alignItems="center"
            wrap="nowrap"
          >
            <Grid item xs={8}>
              {renderDropdown(
                "year",
                "Assessment Year",
                sampleYear(),
                formik.values.year,
                formik.setFieldValue
              )}
            </Grid>
            <Grid
              item
              xs={8}
            >
              {renderDropdown(
                "facility_id",
                "Facility",
                dropdownOptions.facilitiesList,
                formik.values.facility_id,
                formik.setFieldValue
              )}
            </Grid>
            <Grid
              item
              xs={8}
            >
              {renderDropdown(
                "assessment_period",
                "Assessment Period",
                dropdownOptions.assessmentOptions,
                formik.values.assessment_period,
                formik.setFieldValue
              )}
            </Grid>
          </Grid>
          <Typography variant="h6" className={classes.sectionHeader}>
            Board Diversity By Gender
          </Typography>
          {renderTable("Board Diversity By Gender", [
            {
              header: "Total",
              name: "board_member_by_gender.total",
              placeholder: "Enter",
            },
            {
              header: "Male",
              name: "board_member_by_gender.male",
              placeholder: "Enter",
            },
            {
              header: "Female",
              name: "board_member_by_gender.female",
              placeholder: "Enter",
            },
            {
              header: "Other",
              name: "board_member_by_gender.other",
              placeholder: "Enter",
              disabled: "true",
            },
          ])}
          <Typography variant="h6" mt={3} className={classes.sectionHeader}>
            Board Diversity By Age Group
          </Typography>
          {renderTable("Board Diversity By Age Group", [
            {
              header: "Total",
              name: "board_member_by_age.total",
              placeholder: "Enter",
              disabled: "true", ////////////-------------------------------line added
            },
            {
              header: "Under 30",
              name: "board_member_by_age.under_30",
              placeholder: "Enter",
            },
            {
              header: "Between 30 and 50",
              name: "board_member_by_age.between_30_50",
              placeholder: "Enter",
            },
            {
              header: "Above 50",
              name: "board_member_by_age.over_50",
              placeholder: "Enter",
              disabled: "true",
            },
          ])}
          <CeroButton
            className={classes.buttonPrimary}
            disabled={!formik.dirty || !formik.isValid}
            onClick={handleSubmit}
            buttonText="Add data"
          />
          <CeroButton
            className={classes.buttonSecondary}
            onClick={formik.handleReset}
            buttonText="Clear"
          />
        </form>
      </Container>
    </>
  );
};

export default AddBoardDiversity;
