import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Container } from "@mui/material";

import DashboardLayout from "../../../layouts/DashboardLayout";
import { clearEmissionList } from "../../../redux/actions";
import { sampleYearArray } from "../../../constants";
import { rolesEnum } from "../../../layouts/DashboardLayout/pages";
import useStyles from "./styles";
import ESGTable from "../ESGTable";

const savedPage = {
  pageNumber: 1,
};

const DEFAULT_ITEMS_PER_PAGE = 20;

const EmissionEsg = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const selectedMonth = queryParams.get("month");
  const selectedFacility = queryParams.get("facility");
  const selectedYear = queryParams.get("year");
  const role = useSelector((state) => state.auth.role);
  const isAuditor = role === rolesEnum.AUDITOR;
  const [emissionType, setEmissionType] = useState();

  const [filter, setFilter] = useState({
    month: selectedMonth,
    year: selectedYear || sampleYearArray[0].key,
    facility_id: selectedFacility,
  });
  useEffect(() => {
    onLoadMore(DEFAULT_ITEMS_PER_PAGE, 1);
    return () => {
      clearEmissionList();
    };
  }, [filter]);

  const onLoadMore = (limit = DEFAULT_ITEMS_PER_PAGE, pageNumber) => {
    const filterRequest = {
      limit,
      skip: ((pageNumber || (savedPage.pageNumber || 0) + 1) - 1) * limit,
      ...filter,
    };
    if (!filterRequest.facility_id) {
      delete filterRequest.facility_id;
    }
    if (emissionType) {
      let url = `/emissions/${emissionType}?year=${filter.year}`;    
      if (filter.month) {
        url += `&month=${filter.month}`;
      }    
      if (filter.facility_id) {
        url += `&facility=${filter.facility_id}`;
      }    
      navigate(url);
    } 
  };
  
  const [tableShow, setTableShow] = useState(false);
  const onApplyFilter = (filter) => {
    setTableShow(filter.tableShow);
    setFilter(filter);
  };

  return (
    <DashboardLayout>
      <Container className={classes.container}>
        <ESGTable
          filter={filter}
          setEmissionType={(type) => setEmissionType(type)}
          onAddData={() => navigate(`/emissions/add/${emissionType}`)}
          onApplyFilter={onApplyFilter}
          isDisabled={isAuditor}
        />
      </Container>
    </DashboardLayout>
  );
};

export default EmissionEsg;