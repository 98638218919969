import { handleActions } from 'redux-actions'
import immutable from 'immutability-helper'

import { ActionTypes } from '../constants/actions';
import { STATUS } from '../constants'
import { parseError } from '../../services/client';
// import { parseError } from '../../services/client';

export const approvalState = {
    approvalSummaryList: {
        data: [],
        status: STATUS.IDLE,
        message: ''
    },
    approvalDetails: {
        data: {},
        status: STATUS.IDLE,
        message: ''
    },
    approvalMonthlyDetails: {
        data: [],
        status: STATUS.IDLE,
        message: ''
    },
    approvalMonthlySummary: {
        data: {},
        status: STATUS.IDLE,
        message: ''
    },
    submitApproval: {
        data: {},
        status: STATUS.IDLE,
        message: ''
    },
    requestApproval: {
        data: {},
        status: STATUS.IDLE,
        message: ''
    },
    approveRequest: {
        data: {},
        status: STATUS.IDLE,
        message: ''
    },
    approvalStatus:{
        data: {},
        status: STATUS.IDLE,
        message: ''
    },
    roleBasedApproval:{
        data: {},
        status: STATUS.IDLE,
        message: ''
    },
    submitMonthlyApproval:{
        data: {},
        status: STATUS.IDLE,
        message: ''
    },
    assignMonthlyApproval:{
        data: {},
        status: STATUS.IDLE,
        message: ''
    },
    approveMonthlyApproval:{
        data: {},
        status: STATUS.IDLE,
        message: ''
    },
    pendingApprovals:{
        data: {},
        status: STATUS.IDLE,
        message: ''
    },
    reviewComments:{
        data: {},
        status: STATUS.IDLE,
        message: ''
    },
    updateAuditTypes:{
        data: {},
        status: STATUS.IDLE,
        message: ''
    },
    approvalEmission:{
        data: {},
        status: STATUS.IDLE,
        message: ''
    },
    getTopicBasedApproval:{
        data: {},
        status: STATUS.IDLE,
        message: ''
    },
    assignToSubmit: {
        data: {},
        status: STATUS.IDLE,
        message: ''
    },
    pendingApprovalEmission:{
        data: {},
        status: STATUS.IDLE,
        message: ''
    },
    getApprovedData:{
        data: {},
        status: STATUS.IDLE,
        message: ''
    },
    getOffsetIotDataDetails:{
        data: {},
        status: STATUS.IDLE,
        message: ''
    },
    getOverallApprovalStatus:{
        data: {},
        status: STATUS.IDLE,
        message: ''
    },
    getOverviewApprovalStatus:{
        data: {},
        status: STATUS.IDLE,
        message: ''
    },
    getOverallRequestApprovalStatus:{
        data: {},
        status: STATUS.IDLE,
        message: ''
    },
    reviewOffsetData:{
        data: {},
        status: STATUS.IDLE,
        message: ''
    },
    approveOffsetData:{
        data: {},
        status: STATUS.IDLE,
        message: ''
    },
    submitOffsetData:{
        data: {},
        status: STATUS.IDLE,
        message: ''
    },
    getOffsetApprovalStatus:{
        data: {},
        status: STATUS.IDLE,
        message: ''
    }
}

const approvalActions = {
    approval: handleActions(
        {
            [ActionTypes.GET_APPROVAL_SUMMARY]: (state, { payload }) =>
                immutable(state, {
                    approvalSummaryList: {
                        status: { $set: STATUS.RUNNING }
                    }
                }),
            [ActionTypes.GET_APPROVAL_SUMMARY_SUCCESS]: (state, { payload }) =>
                immutable(state, {
                    approvalSummaryList: {
                        status: { $set: STATUS.SUCCESS },
                        data: { $set: payload }
                    }
                }),
            [ActionTypes.GET_APPROVAL_SUMMARY_FAILURE]: (state, { payload }) =>
                immutable(state, {
                    approvalSummaryList: {
                        status: { $set: STATUS.ERROR },
                        message:  { $set: parseError(payload) },
                    }
                }),
            [ActionTypes.RESET_APPROVAL_SUMMARY]: (state, { payload }) =>
            immutable(state, {
                approvalSummaryList: {
                    status: { $set: STATUS.IDLE },
                    data:  { $set: [] },
                }
            }),
                
            [ActionTypes.GET_APPROVAL_DETAILS]: (state, { payload }) =>
                immutable(state, {
                    approvalDetails: {
                        status: { $set: STATUS.RUNNING }
                    }
                }),
            [ActionTypes.GET_APPROVAL_DETAILS_SUCCESS]: (state, { payload }) =>
                immutable(state, {
                    approvalDetails: {
                        status: { $set: STATUS.SUCCESS },
                        data: { $set: payload }
                    }
                }),
            [ActionTypes.GET_APPROVAL_DETAILS_FAILURE]: (state, { payload }) =>
                immutable(state, {
                    approvalDetails: {
                        status: { $set: STATUS.ERROR },
                        message: { $set: payload }
                    }
                }),
            [ActionTypes.RESET_APPROVAL_DETAILS]: (state, { payload }) =>
            immutable(state, {
                approvalDetails: {
                    status: { $set: STATUS.IDLE },
                    data: { $set: {} }
                }
            }),

            [ActionTypes.GET_APPROVAL_MONTHLY_DETAILS]: (state, { payload }) =>
                immutable(state, {
                    approvalMonthlyDetails: {
                        status: { $set: STATUS.RUNNING }
                    }
                }),
            [ActionTypes.GET_APPROVAL_MONTHLY_DETAILS_SUCCESS]: (state, { payload }) =>
                immutable(state, {
                    approvalMonthlyDetails: {
                        status: { $set: STATUS.SUCCESS },
                        data: { $set: payload }
                    }
                }),
            [ActionTypes.GET_APPROVAL_MONTHLY_DETAILS_FAILURE]: (state, { payload }) =>
                immutable(state, {
                    approvalMonthlyDetails: {
                        status: { $set: STATUS.ERROR },
                        message:  { $set: parseError(payload) },
                    }
                }),

            [ActionTypes.GET_APPROVAL_MONTHLY_SUMMARY]: (state, { payload }) =>
                immutable(state, {
                    approvalMonthlySummary: {
                        status: { $set: STATUS.RUNNING }
                    }
                }),
            [ActionTypes.GET_APPROVAL_MONTHLY_SUMMARY_SUCCESS]: (state, { payload }) =>
                immutable(state, {
                    approvalMonthlySummary: {
                        status: { $set: STATUS.SUCCESS },
                        data: { $set: payload }
                    }
                }),
            [ActionTypes.GET_APPROVAL_MONTHLY_SUMMARY_FAILURE]: (state, { payload }) =>
                immutable(state, {
                    approvalMonthlySummary: {
                        status: { $set: STATUS.ERROR },
                        message:  { $set: parseError(payload) },
                    }
                }),

                [ActionTypes.GET_APPROVAL_TOPIC_STATUS]: (state, { payload }) =>
                immutable(state, {
                    approvalStatus: {
                        status: { $set: STATUS.RUNNING }
                    }
                }),
            [ActionTypes.GET_APPROVAL_TOPIC_STATUS_SUCCESS]: (state, { payload }) =>
                immutable(state, {
                    approvalStatus: {
                        status: { $set: STATUS.SUCCESS },
                        data: { $set: payload }
                    }
                }),
            [ActionTypes.GET_APPROVAL_TOPIC_STATUS_FAILURE]: (state, { payload }) =>
                immutable(state, {
                    approvalStatus: {
                        status: { $set: STATUS.ERROR },
                        message:  { $set: parseError(payload) },
                    }
                }),

                [ActionTypes.ROLE_BASED_APPROVAL]: (state, { payload }) =>
                immutable(state, {
                    roleBasedApproval: {
                        status: { $set: STATUS.RUNNING }
                    }
                }),
            [ActionTypes.ROLE_BASED_APPROVAL_SUCCESS]: (state, { payload }) =>
                immutable(state, {
                    roleBasedApproval: {
                        status: { $set: STATUS.SUCCESS },
                        data: { $set: payload }
                    }
                }),
            [ActionTypes.ROLE_BASED_APPROVAL_FAILURE]: (state, { payload }) =>
                immutable(state, {
                    roleBasedApproval: {
                        status: { $set: STATUS.ERROR },
                        message:  { $set: parseError(payload) },
                    }
                }),

                [ActionTypes.SUBMIT_MONTHLY_APPROVAL]: (state, { payload }) =>
                immutable(state, {
                    submitMonthlyApproval: {
                        status: { $set: STATUS.RUNNING }
                    }
                }),
            [ActionTypes.SUBMIT_MONTHLY_APPROVAL_SUCCESS]: (state, { payload }) =>
                immutable(state, {
                    submitMonthlyApproval: {
                        status: { $set: STATUS.SUCCESS },
                        data: { $set: payload }
                    }
                }),
            [ActionTypes.SUBMIT_MONTHLY_APPROVAL_FAILURE]: (state, { payload }) =>
                immutable(state, {
                    submitMonthlyApproval: {
                        status: { $set: STATUS.ERROR },
                        message:  { $set: parseError(payload) },
                    }
                }),

                [ActionTypes.ASSIGN_MONTHLY_APPROVAL]: (state, { payload }) =>
                immutable(state, {
                    assignMonthlyApproval: {
                        status: { $set: STATUS.RUNNING }
                    }
                }),
            [ActionTypes.ASSIGN_MONTHLY_APPROVAL_SUCCESS]: (state, { payload }) =>
                immutable(state, {
                    assignMonthlyApproval: {
                        status: { $set: STATUS.SUCCESS },
                        data: { $set: payload }
                    }
                }),
            [ActionTypes.ASSIGN_MONTHLY_APPROVAL_FAILURE]: (state, { payload }) =>
                immutable(state, {
                    assignMonthlyApproval: {
                        status: { $set: STATUS.ERROR },
                        message:  { $set: parseError(payload) },
                    }
                }),

                [ActionTypes.APPROVE_MONTHLY_APPROVAL]: (state, { payload }) =>
                immutable(state, {
                    approveMonthlyApproval: {
                        status: { $set: STATUS.RUNNING }
                    }
                }),
            [ActionTypes.APPROVE_MONTHLY_APPROVAL_SUCCESS]: (state, { payload }) =>
                immutable(state, {
                    approveMonthlyApproval: {
                        status: { $set: STATUS.SUCCESS },
                        data: { $set: payload }
                    }
                }),
            [ActionTypes.APPROVE_MONTHLY_APPROVAL_FAILURE]: (state, { payload }) =>
                immutable(state, {
                    approveMonthlyApproval: {
                        status: { $set: STATUS.ERROR },
                        message:  { $set: parseError(payload) },
                    }
                }),

                [ActionTypes.PENDING_APPROVALS]: (state, { payload }) =>
                immutable(state, {
                    pendingApprovals: {
                        status: { $set: STATUS.RUNNING }
                    }
                }),
            [ActionTypes.PENDING_APPROVALS_SUCCESS]: (state, { payload }) =>
                immutable(state, {
                    pendingApprovals: {
                        status: { $set: STATUS.SUCCESS },
                        data: { $set: payload }
                    }
                }),
            [ActionTypes.PENDING_APPROVALS_FAILURE]: (state, { payload }) =>
                immutable(state, {
                    pendingApprovals: {
                        status: { $set: STATUS.ERROR },
                        message:  { $set: parseError(payload) },
                    }
                }),

                [ActionTypes.ADD_REVIEW_COMMENTS]: (state, { payload }) =>
                immutable(state, {
                    reviewComments: {
                        status: { $set: STATUS.RUNNING }
                    }
                }),
            [ActionTypes.ADD_REVIEW_COMMENTS_SUCCESS]: (state, { payload }) =>
                immutable(state, {
                    reviewComments: {
                        status: { $set: STATUS.SUCCESS },
                        data: { $set: payload }
                    }
                }),
            [ActionTypes.ADD_REVIEW_COMMENTS_FAILURE]: (state, { payload }) =>
                immutable(state, {
                    reviewComments: {
                        status: { $set: STATUS.ERROR },
                        message:  { $set: parseError(payload) },
                    }
                }),

                [ActionTypes.SUBMIT_APPROVAL]: (state, { payload }) =>
                    immutable(state, {
                    submitApproval: {
                        status: { $set: STATUS.RUNNING },
                    },
                    }),
                [ActionTypes.SUBMIT_APPROVAL_SUCCESS]: (state, { payload }) =>
                    immutable(state, {
                    submitApproval: {
                        status: { $set: STATUS.SUCCESS },
                        data: { $set: payload },
                    },
                    approvalMonthlySummary: {
                        data: {
                        $set: {
                            ...state.approvalMonthlySummary.data,
                            status: payload.status
                        },
                        },
                        status: { $set: STATUS.SUCCESS },
                    },
                    approvalMonthlyDetails: {
                        data: {
                        $set: {
                            ...state.approvalMonthlyDetails.data,
                            actions: {
                            $set: {
                                ...state.approvalMonthlySummary.data.actions,
                                perform_submission: false,
                            },
                            },
                        },
                        },
                        status: { $set: STATUS.SUCCESS },
                    },
                    }),
                [ActionTypes.SUBMIT_APPROVAL_FAILURE]: (state, { payload }) =>
                    immutable(state, {
                    submitApproval: {
                        status: { $set: STATUS.ERROR },
                        message:  { $set: parseError(payload) },
                    },
                    }),

                [ActionTypes.REQUEST_APPROVAL]: (state, { payload }) =>
                    immutable(state, {
                    requestApproval: {
                        status: { $set: STATUS.RUNNING },
                    },
                    }),
                [ActionTypes.REQUEST_APPROVAL_SUCCESS]: (state, { payload }) =>
                    immutable(state, {
                    requestApproval: {
                        status: { $set: STATUS.SUCCESS },
                        data: { $set: payload },
                    },
                    approvalMonthlySummary: {
                        data: {
                        $set: {
                            ...state.approvalMonthlySummary.data,
                            status: payload.status
                        },
                        },
                        status: { $set: STATUS.SUCCESS },
                    },
                    approvalMonthlyDetails: {
                        data: {
                        $set: {
                            ...state.approvalMonthlyDetails.data,
                            actions: {
                            $set: {
                                ...state.approvalMonthlySummary.data.actions,
                                perform_submission: false,
                            },
                            },
                        },
                        },
                        status: { $set: STATUS.SUCCESS },
                    },
                    }),
                [ActionTypes.REQUEST_APPROVAL_FAILURE]: (state, { payload }) =>
                    immutable(state, {
                    requestApproval: {
                        status: { $set: STATUS.ERROR },
                        message:  { $set: parseError(payload) },
                    },
                    }),

                [ActionTypes.APPROVE_REQUEST]: (state, { payload }) =>
                    immutable(state, {
                    approveRequest: {
                        status: { $set: STATUS.RUNNING },
                    },
                    }),
                [ActionTypes.APPROVE_REQUEST_SUCCESS]: (state, { payload }) =>
                    immutable(state, {
                    approveRequest: {
                        status: { $set: STATUS.SUCCESS },
                        data: { $set: payload },
                    },
                    approvalMonthlySummary: {
                        data: {
                        $set: {
                            ...state.approvalMonthlySummary.data,
                            status: payload.status
                        },
                        },
                        status: { $set: STATUS.SUCCESS },
                    },
                    approvalMonthlyDetails: {
                        data: {
                        $set: {
                            ...state.approvalMonthlyDetails.data,
                            actions: {
                            $set: {
                                ...state.approvalMonthlySummary.data.actions,
                                perform_submission: false,
                            },
                            },
                        },
                        },
                        status: { $set: STATUS.SUCCESS },
                    },
                    }),
                [ActionTypes.APPROVE_REQUEST_FAILURE]: (state, { payload }) =>
                    immutable(state, {
                    approveRequest: {
                        status: { $set: STATUS.ERROR },
                        message: { $set: parseError(payload) },
                    },
                    }),

                [ActionTypes.RESET_APPROVAL_DATA]: (state, { payload }) =>
                    immutable(state, {
                    submitApproval: {
                        status: { $set: STATUS.IDLE },
                        message: { $set: "" },
                    },
                    requestApproval: {
                        status: { $set: STATUS.IDLE },
                        message: { $set: "" },
                    },
                    approveRequest: {
                        status: { $set: STATUS.IDLE },
                        message: { $set: "" },
                    },
                    roleBasedApproval:{
                        status: { $set: STATUS.IDLE },
                        message: { $set: "" },
                    },
                    submitMonthlyApproval:{
                        status: { $set: STATUS.IDLE },
                        message: { $set: "" },
                    },
                    assignMonthlyApproval:{
                        status: { $set: STATUS.IDLE },
                        message: { $set: "" },
                    },
                    approveMonthlyApproval:{
                        status: { $set: STATUS.IDLE },
                        message: { $set: "" },
                    },
                    reviewComments:{
                        status: { $set: STATUS.IDLE },
                        message: { $set: "" },
                    },
                    assignToSubmit:{
                        status: { $set: STATUS.IDLE },
                        message: { $set: "" },
                    }
                    }),

                    [ActionTypes.RESET_DATA_APPROVED]: (state, { payload }) =>
                    immutable(state, {
                    getApprovedData:{
                        status: { $set: STATUS.IDLE },
                        message: { $set: "" },
                    }
                    }),
                [ActionTypes.UPDATE_AUDIT_TYPES]: (state, { payload }) =>
                    immutable(state, {
                        updateAuditTypes: {
                            status: { $set: STATUS.RUNNING }
                        }
                    }),
                [ActionTypes.UPDATE_AUDIT_TYPES_SUCCESS]: (state, { payload }) =>
                    immutable(state, {
                        updateAuditTypes: {
                            status: { $set: STATUS.SUCCESS },
                            data: { $set: payload }
                        }
                    }),
                [ActionTypes.UPDATE_AUDIT_TYPES_FAILURE]: (state, { payload }) =>
                    immutable(state, {
                        updateAuditTypes: {
                            status: { $set: STATUS.ERROR },
                            message:  { $set: parseError(payload) },
                        }
                    }),

                    [ActionTypes.GET_APPROVAL_EMISSION]: (state, { payload }) =>
                    immutable(state, {
                        approvalEmission: {
                            status: { $set: STATUS.RUNNING }
                        }
                    }),
                [ActionTypes.GET_APPROVAL_EMISSION_SUCCESS]: (state, { payload }) =>
                    immutable(state, {
                        approvalEmission: {
                            status: { $set: STATUS.SUCCESS },
                            data: { $set: payload }
                        }
                    }),
                [ActionTypes.GET_APPROVAL_EMISSION_FAILURE]: (state, { payload }) =>
                    immutable(state, {
                        approvalEmission: {
                            status: { $set: STATUS.ERROR },
                            message:  { $set: parseError(payload) },
                        }
                    }),

                    [ActionTypes.GET_TOPIC_BASED_APPROVAL]: (state, { payload }) =>
                    immutable(state, {
                        getTopicBasedApproval: {
                            status: { $set: STATUS.RUNNING }
                        }
                    }),
                [ActionTypes.GET_TOPIC_BASED_APPROVAL_SUCCESS]: (state, { payload }) =>
                    immutable(state, {
                        getTopicBasedApproval: {
                            status: { $set: STATUS.SUCCESS },
                            data: { $set: payload }
                        }
                    }),
                [ActionTypes.GET_TOPIC_BASED_APPROVAL_FAILURE]: (state, { payload }) =>
                    immutable(state, {
                        getTopicBasedApproval: {
                            status: { $set: STATUS.ERROR },
                            message:  { $set: parseError(payload) },
                        }
                    }),

                    [ActionTypes.ASSIGN_TO_SUBMIT]: (state, { payload }) =>
                    immutable(state, {
                        assignToSubmit: {
                            status: { $set: STATUS.RUNNING }
                        }
                    }),
                [ActionTypes.ASSIGN_TO_SUBMIT_SUCCESS]: (state, { payload }) =>
                    immutable(state, {
                        assignToSubmit: {
                            status: { $set: STATUS.SUCCESS },
                            data: { $set: payload }
                        }
                    }),
                [ActionTypes.ASSIGN_TO_SUBMIT_FAILURE]: (state, { payload }) =>
                    immutable(state, {
                        assignToSubmit: {
                            status: { $set: STATUS.ERROR },
                            message:  { $set: parseError(payload) },
                        }
                    }),

                    [ActionTypes.PENDING_APPROVAL_EMISSION]: (state, { payload }) =>
                    immutable(state, {
                        pendingApprovalEmission: {
                            status: { $set: STATUS.RUNNING }
                        }
                    }),
                [ActionTypes.PENDING_APPROVAL_EMISSION_SUCCESS]: (state, { payload }) =>
                    immutable(state, {
                        pendingApprovalEmission: {
                            status: { $set: STATUS.SUCCESS },
                            data: { $set: payload }
                        }
                    }),
                [ActionTypes.PENDING_APPROVAL_EMISSION_FAILURE]: (state, { payload }) =>
                    immutable(state, {
                        pendingApprovalEmission: {
                            status: { $set: STATUS.ERROR },
                            message:  { $set: parseError(payload) },
                        }
                    }),

                    [ActionTypes.GET_APPROVED_DATA]: (state, { payload }) =>
                    immutable(state, {
                        getApprovedData: {
                            status: { $set: STATUS.RUNNING }
                        }
                    }),
                [ActionTypes.GET_APPROVED_DATA_SUCCESS]: (state, { payload }) =>
                    immutable(state, {
                        getApprovedData: {
                            status: { $set: STATUS.SUCCESS },
                            data: { $set: payload }
                        }
                    }),
                [ActionTypes.GET_APPROVED_DATA_FAILURE]: (state, { payload }) =>
                    immutable(state, {
                        getApprovedData: {
                            status: { $set: STATUS.ERROR },
                            message:  { $set: parseError(payload) },
                        }
                    }),
                    [ActionTypes.GET_OFFSET_IOT_DATA_DETAILS]: (state, { payload }) =>
                        immutable(state, {
                            getOffsetIotDataDetails: {
                                status: { $set: STATUS.RUNNING }
                            }
                        }),
                    [ActionTypes.GET_OFFSET_IOT_DATA_DETAILS_SUCCESS]: (state, { payload }) =>
                        immutable(state, {
                            getOffsetIotDataDetails: {
                                status: { $set: STATUS.SUCCESS },
                                data: { $set: payload }
                            }
                        }),
                    [ActionTypes.GET_OFFSET_IOT_DATA_DETAILS_FAILURE]: (state, { payload }) =>
                        immutable(state, {
                            getOffsetIotDataDetails: {
                                status: { $set: STATUS.ERROR },
                                message:  { $set: parseError(payload) },
                            }
                        }),
                    [ActionTypes.GET_OVERALL_APPROVAL_STATUS]: (state, { payload }) =>
                        immutable(state, {
                            getOverallApprovalStatus: {
                                status: { $set: STATUS.RUNNING }
                            }
                        }),
                    [ActionTypes.GET_OVERALL_APPROVAL_STATUS_SUCCESS]: (state, { payload }) =>
                        immutable(state, {
                            getOverallApprovalStatus: {
                                status: { $set: STATUS.SUCCESS },
                                data: { $set: payload }
                            }
                        }),
                    [ActionTypes.GET_OVERALL_APPROVAL_STATUS_FAILURE]: (state, { payload }) =>
                        immutable(state, {
                            getOverallApprovalStatus: {
                                status: { $set: STATUS.ERROR },
                                message:  { $set: parseError(payload) },
                            }
                        }),
                    [ActionTypes.GET_OVERVIEW_APPROVAL_STATUS]: (state, { payload }) =>
                        immutable(state, {
                            getOverviewApprovalStatus: {
                                status: { $set: STATUS.RUNNING }
                            }
                        }),
                    [ActionTypes.GET_OVERVIEW_APPROVAL_STATUS_SUCCESS]: (state, { payload }) =>
                        immutable(state, {
                            getOverviewApprovalStatus: {
                                status: { $set: STATUS.SUCCESS },
                                data: { $set: payload }
                            }
                        }),
                    [ActionTypes.GET_OVERVIEW_APPROVAL_STATUS_FAILURE]: (state, { payload }) =>
                        immutable(state, {
                            getOverviewApprovalStatus: {
                                status: { $set: STATUS.ERROR },
                                message:  { $set: parseError(payload) },
                            }
                        }),
                    [ActionTypes.GET_OVERALL_REQUEST_APPROVAL_STATUS]: (state, { payload }) =>
                        immutable(state, {
                            getOverallRequestApprovalStatus: {
                                status: { $set: STATUS.RUNNING }
                            }
                        }),
                    [ActionTypes.GET_OVERALL_REQUEST_APPROVAL_STATUS_SUCCESS]: (state, { payload }) =>
                        immutable(state, {
                            getOverallRequestApprovalStatus: {
                                status: { $set: STATUS.SUCCESS },
                                data: { $set: payload }
                            }
                        }),
                    [ActionTypes.GET_OVERALL_REQUEST_APPROVAL_STATUS_FAILURE]: (state, { payload }) =>
                        immutable(state, {
                            getOverallRequestApprovalStatus: {
                                status: { $set: STATUS.ERROR },
                                message:  { $set: parseError(payload) },
                            }
                        }),
                    [ActionTypes.REVIEW_OFFSET_DATA]: (state, { payload }) =>
                        immutable(state, {
                            reviewOffsetData: {
                                status: { $set: STATUS.RUNNING }
                            }
                        }),
                    [ActionTypes.REVIEW_OFFSET_DATA_SUCCESS]: (state, { payload }) =>
                        immutable(state, {
                            reviewOffsetData: {
                                status: { $set: STATUS.SUCCESS },
                                data: { $set: payload }
                            }
                        }),
                    [ActionTypes.REVIEW_OFFSET_DATA_FAILURE]: (state, { payload }) =>
                        immutable(state, {
                            reviewOffsetData: {
                                status: { $set: STATUS.ERROR },
                                message:  { $set: parseError(payload) },
                            }
                        }),
                        [ActionTypes.APPROVE_OFFSET_DATA]: (state, { payload }) =>
                            immutable(state, {
                                approveOffsetData: {
                                    status: { $set: STATUS.RUNNING }
                                }
                            }),
                        [ActionTypes.APPROVE_OFFSET_DATA_SUCCESS]: (state, { payload }) =>
                            immutable(state, {
                                approveOffsetData: {
                                    status: { $set: STATUS.SUCCESS },
                                    data: { $set: payload }
                                }
                            }),
                        [ActionTypes.APPROVE_OFFSET_DATA_FAILURE]: (state, { payload }) =>
                            immutable(state, {
                                approveOffsetData: {
                                    status: { $set: STATUS.ERROR },
                                    message:  { $set: parseError(payload) },
                                }
                            }),
                        [ActionTypes.SUBMIT_OFFSET_DATA]: (state, { payload }) =>
                            immutable(state, {
                                submitOffsetData: {
                                    status: { $set: STATUS.RUNNING }
                                }
                            }),
                        [ActionTypes.SUBMIT_OFFSET_DATA_SUCCESS]: (state, { payload }) =>
                            immutable(state, {
                                submitOffsetData: {
                                    status: { $set: STATUS.SUCCESS },
                                    data: { $set: payload }
                                }
                            }),
                        [ActionTypes.SUBMIT_OFFSET_DATA_FAILURE]: (state, { payload }) =>
                            immutable(state, {
                                submitOffsetData: {
                                    status: { $set: STATUS.ERROR },
                                    message:  { $set: parseError(payload) },
                                }
                            }),
                            [ActionTypes.GET_OFFSET_APPROVAL_STATUS]: (state, { payload }) =>
                                immutable(state, {
                                    getOffsetApprovalStatus: {
                                        status: { $set: STATUS.RUNNING }
                                    }
                                }),
                            [ActionTypes.GET_OFFSET_APPROVAL_STATUS_SUCCESS]: (state, { payload }) =>
                                immutable(state, {
                                    getOffsetApprovalStatus: {
                                        status: { $set: STATUS.SUCCESS },
                                        data: { $set: payload }
                                    }
                                }),
                            [ActionTypes.GET_OFFSET_APPROVAL_STATUS_FAILURE]: (state, { payload }) =>
                                immutable(state, {
                                    getOffsetApprovalStatus: {
                                        status: { $set: STATUS.ERROR },
                                        message:  { $set: parseError(payload) },
                                    }
                                }),
        },
        approvalState
    )
}

export default approvalActions;
