import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import PermissionDenied from "../pages/PermissionDenied";
import SessionTimeOut from "../Hooks/sessionTimeOut";

const TradePrivateRoute = ({ children, redirectTo, rolesAllowed = [] }) => {
  let isAuthenticated = useSelector(
    (state) => state.auth.userInfo.access_token
  );
  const userRole = useSelector((state) => state.auth.userInfo.role);
  const isAllowed = userRole
    ? rolesAllowed.some((role) => userRole.includes(role))
    : "";

  return (
    <SessionTimeOut>
      {isAuthenticated ? (
        isAllowed ? (
          children
        ) : (
          <PermissionDenied />
        )
      ) : (
        <Navigate to={ redirectTo} />
      )}
    </SessionTimeOut>
  );
};

export default TradePrivateRoute;
