import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
    height: "100%",
    borderRadius: 8,
    "@media (min-width: 1200px)": {
      maxWidth: "none",
    },
  },
  tableContainer: {
    padding: 0,
    width: "100%",
    maxHeight: "calc(100% - 100px)",
    boxShadow: "none",
    background: theme.palette.background.white,
    overflow: "auto",
    marginBottom: theme.spacing(5),
    borderRadius: 8,
  },
  pending:{
    color: theme.palette.status.pendingApproval,
    fontWeight: 500,
    fontSize: 14
  },
  completed:{
    color: theme.palette.status.approved,
    fontWeight: 500,
    fontSize: 14
  },
}));

export default useStyles;
