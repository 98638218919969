import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Grid, Typography, Box, Tooltip } from "@mui/material";
import { useSnackbar } from 'notistack';
import { months, sampleYear } from "../../../constants";
import { STATUS } from "../../../redux/constants";
import { addMobileCombustionValidation } from './schema';
import { addMobileCombustion, getMobileCombustionInputs, resetAddCombustionStatus } from '../../../redux/actions';
import CeroAutoComplete from '../../../components/CeroAutoComplete';
import CeroButton from '../../../components/CeroButton';
import CeroSelect from '../../../components/CeroSelect';
import CeroInput from '../../../components/CeroInput';
import useStyles from "./styles";
import DownloadIcon from '@mui/icons-material/Download';
import EmissionUploadDrawer from '../../EmissionList/EmissionUploadDrawer';
import { useNavigate } from 'react-router-dom';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CeroItemPair from '../../../components/CeroItemPair';
import FileUpload from '../FileUpload';

const AddMobileCombustionForm = (props) => {
    const { onCancel, onDownload, onUpload, onValidate, uploadDisabled } = props
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const isCalculateDone = useSelector(state => state.emission.addMobileCombustion.isCalculateDone)
    const facilitiesData = useSelector(state => state.listings.listFacilities.data);
    const activityTypesData = useSelector(state => state.emission.mobileCombustionInputs.data.activity_types);
    const fuelSourceData = useSelector(state => state.emission.mobileCombustionInputs.data.fuel_sources);
    const customFuelSourceData = useSelector(state => state.emission.mobileCombustionInputs.data.custom_fuel_sources);
    const vehicleTypeData = useSelector(state => state.emission.mobileCombustionInputs.data.vehicle_types);
    const fuelUnitData = useSelector(state => state.emission.mobileCombustionInputs.data.units);
    const addEmissionData = useSelector(state => state.emission.addMobileCombustion)
    const currentYear = new Date().getFullYear().toString()
    const [selectedFile, setSelectedFile] = useState(null);

    const formik = useFormik({
        initialValues: {
            facility: '',
            year: currentYear,
            month: '',
            activityType: '',
            fuelSource: '',
            vehicleType: '',
            amountOfFuel: null,
            fuelUnit: ''
        },
        validationSchema: addMobileCombustionValidation,
        onSubmit: () => { },
    });

    const selectedUnitType = activityTypesData.find(item => item.id === formik.values.activityType)
    const emittedSource = ["jet fuel", "aviation gasoline"]
    const filteredUnitData = fuelUnitData.filter(unit => unit?.type === selectedUnitType?.unit_type)
    // const selectedActivity = activityTypesData.find(item => item. === formik.values.activityType)
    const emittedVehicles = ["gasoline agricultural equipment", "gasoline ships and boats", "other gasoline non-road vehicles", "diesel agricultural equipment", "diesel ships and boats", "other diesel non-road vehicles"]
    const filteredVehicleType = vehicleTypeData.filter(vehicle => vehicle.fuel_source_id === formik.values.fuelSource ).filter(vehicle=> 
        selectedUnitType?.name.toLowerCase() === 'distance activity' ? 
            !(emittedVehicles.includes(vehicle.name.toLowerCase())) : true)
    // console.log(justChk, "check");

    const facilitiesList = facilitiesData.map(item => ({ key: item.id, value: item.name }));
    const activityType = activityTypesData.map(item => ({ key: item.id, value: item.name }));
    const fuelUnits = filteredUnitData.map(unit => ({ key: unit.name, value: unit.name }))
    const vehicleTypes = filteredVehicleType.map(item => ({ key: item.id, value: item.name }));
    const fuelSource = fuelSourceData.filter(source=> 
        selectedUnitType?.name.toLowerCase() === 'distance activity' ? 
            !(emittedSource.includes(source.name.toLowerCase())) : true).map(item => ({ key: item.id, value: item.name }));
    const customFuelSource = customFuelSourceData.map(item => ({ key: item.id, value: item.name }));
    const yearList = sampleYear().map(item => ({ id: item.key, label: item.value }));

    useEffect(() => {
        dispatch(getMobileCombustionInputs('mobile_combustion'))
        return () => { dispatch(resetAddCombustionStatus()) }
    }, [dispatch])

    useEffect(() => {
        if (addEmissionData.status === STATUS.SUCCESS) {
            enqueueSnackbar('Mobile combustion added successfully', { variant: 'success' });
            dispatch(resetAddCombustionStatus())
            onCancel();
        } else if (addEmissionData.status === STATUS.ERROR) {
            enqueueSnackbar(addEmissionData.message.message || "We couldn't process your request. Please try again later.", { variant: 'error' });
        }
    }, [addEmissionData, dispatch, enqueueSnackbar, onCancel])

    const onCalculate = () => {
        const requestData = {
            facility_id: formik.values.facility,
            emission_type: formik.values.emissionType,
            year: formik.values.year,
            month: formik.values.month,
            activity_type_id: formik.values.activityType,
            fuel_source_id: formik.values.fuelSource,
            vehicle_type_id: formik.values.vehicleType,
            amount: parseFloat(formik.values.amountOfFuel),
            unit: formik.values.fuelUnit,
            save: false
        }
        dispatch(addMobileCombustion(requestData))
    };

    const onAddMobileCombustionData = () => {
        const requestData = {
            facility_id: formik.values.facility,
            emission_type: formik.values.emissionType,
            year: formik.values.year,
            month: formik.values.month,
            activity_type_id: formik.values.activityType,
            fuel_source_id: formik.values.fuelSource,
            vehicle_type_id: activityType.find((item) => item.key === formik.values.activityType)?.value !== 'Custom emission factor'
                ? formik.values.vehicleType : null,
            amount: parseFloat(formik.values.amountOfFuel),
            unit: formik.values.fuelUnit,
            save: true,
            file:selectedFile
        }
        sessionStorage.setItem('year', requestData.year)
        dispatch(addMobileCombustion(requestData))
    };

   const [isOpenEmissionTypeUpload, setIsOpenEmissionTypeUpload] = useState(false);
   const onCloseDrawerUpload = () => {
    setIsOpenEmissionTypeUpload(false);
  };
    const handleFileUpload = (file) => {
        setSelectedFile(file);
        // dispatch(uploadEmissionAttachement("emissionId", file));
    };
    
    const handleFileRemove = () => {
        setSelectedFile(null);
    };
  const info = () => {
    return(
        <div>
        <Typography variant='body2'>Fuel use: Mobile combustion by the usage of fuel for mobile operations such as transport.<br/>
        Distance activity: Distance travelled by the usage of fuel</Typography>
        </div>
    )
  } 

    return (
        <>
      <div className={classes.backContainer}>
      <div className={classes.backButtonDiv} onClick={()=>{navigate(-1)}}>
        <ArrowBackIosIcon />
        Back
        </div>
        <div className={classes.templateButtonDiv}>
        <CeroButton
              buttonText="Bulk Data Upload"
              className={classes.bulkButton}
              startIcon={<FileUploadIcon />}
              onClick={() => setIsOpenEmissionTypeUpload(true)}
            />
            <CeroButton
              buttonText="Download Template"
              className={classes.bulkButton}
              startIcon={<DownloadIcon />}
              onClick={onDownload}
            />
            </div>
      </div>
        <Container className={classes.container}>
            <Box className={classes.innerContainer}>
                <Typography className={classes.title} variant="h6" component="div" >Add Mobile Combustion</Typography>
                <Box className={classes.topContainer}>
                    <Grid container direction='row' wrap='nowrap' justifyContent='space-between' spacing={3}>
                        <Grid item container direction='column' md={6} xs={4}>
                            <CeroSelect
                                required
                                id="facility"
                                name="facility"
                                label="Facility"
                                fullWidth
                                options={facilitiesList}
                                selectedValue={formik.values.facility}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.facility && formik.errors.facility}
                            />
                            <CeroSelect
                                required
                                id="month"
                                name="month"
                                label="Month"
                                fullWidth
                                options={months}
                                selectedValue={formik.values.month}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.month && formik.errors.month}
                            />
                            <CeroSelect
                                required
                                id="fuelSource"
                                name="fuelSource"
                                label="Fuel Source"
                                fullWidth
                                options={fuelSource.length ? activityType.find((item) => item.key === formik.values.activityType)?.value !== 'Custom emission factor' ? fuelSource : customFuelSource : [{ name: 'select' }]}
                                selectedValue={formik.values.fuelSource}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.fuelSource && formik.errors.fuelSource}
                                classes={{ input: classes.input,
                                    container: classes.selectContainer,
                                }}
                            />
                            <CeroInput
                                required
                                id="amountOfFuel"
                                name="amountOfFuel"
                                label="Amount of Fuel"
                                value={formik.values.amountOfFuel}
                                fullWidth
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.amountOfFuel && formik.errors.amountOfFuel}
                                type="number"
                            />
                        </Grid>
                        <Grid item container direction={'column'} md={6} xs={4}>
                            <CeroSelect
                                required
                                id="activityType"
                                name="activityType"
                                label="Activity Type"
                                fullWidth
                                options={activityType}
                                selectedValue={formik.values.activityType}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.activityType && formik.errors.activityType}
                            />
                            <CeroAutoComplete
                                id="year"
                                name="year"
                                label="Assessment Year"
                                onChange={(e, value) => formik.setFieldValue('year', value.id)}
                                onBlur={formik.handleBlur}
                                error={formik.touched.year && formik.errors.year}
                                options={yearList}
                                value={formik.values.year}
                            />
                            {activityType.find((item) => item.key === formik.values.activityType)?.value !== 'Custom emission factor' && (
                                <CeroSelect
                                    required
                                    id="vehicleType"
                                    name="vehicleType"
                                    label="Vehicle Type"                    
                                    fullWidth
                                    options={vehicleTypes}
                                    selectedValue={formik.values.vehicleType}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.vehicleType && formik.errors.vehicleType}
                                    classes={{ input: classes.input,
                                        container: classes.selectContainer,
                                        paperRoot: classes.paperRoot
                                    }}
                                />)}
                            <CeroSelect
                                required
                                id="fuelUnit"
                                name="fuelUnit"
                                label="Fuel Unit"
                                fullWidth
                                options={fuelUnits || [{ name: 'select' }]}
                                selectedValue={formik.values.fuelUnit}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.fuelUnit && formik.errors.fuelUnit}
                                sortOption={true}
                            />
                        </Grid>
                        <Grid item container direction='column' md={6} xs={4}>
                        <span className={classes.info}>
                                <Tooltip title={info()}  classes={{ popper: classes.tooltip }} arrow placement="right-start">
                                <InfoOutlinedIcon className={classes.infoIcon}/></Tooltip>
                            </span>
                        </Grid>
                    </Grid>
                    <CeroButton
                    buttonText="Calculate"
                    className={classes.buttonPrimary}
                    onClick={onCalculate}
                    disabled={!formik.dirty || !formik.isValid}
                    />
                   <CeroButton
                   buttonText="Clear"
                   variant="outlined"
                   className={classes.buttonSecondary}
                   onClick={formik.resetForm}
                   />
                   {isCalculateDone && <Box className={classes.bottomContainer}>
                    <Typography variant="h6" component="h6" className={classes.previewTitle}>Emission Preview</Typography>
                    <Grid container direction='row' wrap='nowrap' justifyContent='space-between' spacing={8}>
                        <Grid item container direction='column' xs={12} md={6}>
                            <CeroItemPair title={<>CO<sub>2</sub></>} value={`${addEmissionData.data.co2} tonnes`} classes={{container : classes.infoContainer, title: classes.emissionTitle, value: classes.value }}/>
                            <CeroItemPair title={<>CH<sub>4</sub></>} value={`${addEmissionData.data.ch4} tonnes`} classes={{container : classes.infoContainer, title: classes.emissionTitle, value: classes.value}} />
                            <CeroItemPair title={<>BioFuel CO<sub>2</sub></>} value={`${addEmissionData.data.biofuel_co2} tonnes`} classes={{container : classes.infoContainer, title: classes.emissionTitle, value: classes.value}}/>
                        </Grid>
                        <Grid className={classes.secondResultContainer} item container direction='column' xs={6}>
                            <CeroItemPair title={<>CO<sub>2</sub>e</>} value={`${addEmissionData.data.co2e} tonnes`} classes={{container : classes.infoContainer, title: classes.emissionTitle, value: classes.value}} />
                            <CeroItemPair title={<>N<sub>2</sub>O</>} value={`${addEmissionData.data.n2o} tonnes`} classes={{container : classes.infoContainer, title: classes.emissionTitle, value: classes.value}} />
                            <CeroItemPair title={<>EF</>} value={<>{addEmissionData.data.ef} kgCO<sub>2</sub>e/unit</>} classes={{container : classes.infoContainer, title: classes.emissionTitle, value: classes.value}} />
                        </Grid>
                    </Grid>
                </Box>}
                </Box>
                <FileUpload onFileUpload={handleFileUpload} onFileRemove={handleFileRemove} />
            </Box>
            <Box className={classes.buttonContainer}>
                <CeroButton
                    buttonText="Add Data"
                    disabled={!isCalculateDone || addEmissionData.status === STATUS.RUNNING}
                    className={classes.buttonPrimary}
                    onClick={() => onAddMobileCombustionData(formik.values)} />

                {/* <div className={classes.buttonContainer}>
                <CeroButton
                   buttonText="Bulk Data Upload"
                   className={classes.bulkButton}
                   startIcon={<FileUploadIcon />}
                   onClick={() => setIsOpenEmissionTypeUpload(true)}
                />
                <CeroButton
                    buttonText="Download Template"
                    className={classes.bulkButton}
                    startIcon={<DownloadIcon />}
                    onClick={onDownload}
                  />
                </div> */}
            </Box>
            <EmissionUploadDrawer
              isOpen={isOpenEmissionTypeUpload}
              onClose={onCloseDrawerUpload}
              onUpload={onUpload}
              onValidate={onValidate}
              uploadDisabled={uploadDisabled}
            />
        </Container >
        </>
    )
}

export default AddMobileCombustionForm;
