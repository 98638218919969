import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../../layouts/DashboardLayout";
import { Box, Container, Typography } from "@mui/material";
import useStyles from "./styles";
import CeroTable from "../../../../components/CeroTable";
import { useDispatch, useSelector } from "react-redux";
import { resetCreateQuestionsStatus, sendReminder, trackSurvey } from "../../../../redux/actions";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CeroPopUp from "../../../../components/CeroPopUp";
import CeroButton from "../../../../components/CeroButton";
import CloseIcon from "@mui/icons-material/Close";
import { STATUS } from "../../../../redux/constants";
import { useSnackbar } from "notistack";

const ProgressTable = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const [reminderPopup, setReminderPopup] = useState(false);
  const [reminderId, setReminderId] = useState(null);
  const surveyList = useSelector((state) => state.materiality.trackSurvey.data);
  const reminderStatus = useSelector((state) => state.materiality.sendReminder);

  const surveyTrackColumns = [
    {
      columnKey: "name",
      columnId: "name",
      columnHeader: "Name",
      width: "30vw"
    },
    {
      columnKey: "email",
      columnId: "email",
      columnHeader: "Email",
      width: "30vw"
    },
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
      width: "20vw"
    },
    {
      columnKey: "action",
      columnId: "action",
      columnHeader: "Action",
      width: "20vw"
    },
  ];

  useEffect(() => {
    id && dispatch(trackSurvey(id));
  }, [dispatch]);

  const onSendReminder =() => {
    reminderId && dispatch(sendReminder(reminderId))
  }

  useEffect(() => {
    if (reminderStatus?.status === STATUS.SUCCESS) {
      enqueueSnackbar(reminderStatus?.data?.message, { variant: "success", autoHideDuration: 3000 });
      dispatch(resetCreateQuestionsStatus());
      setReminderPopup(false)
      dispatch(trackSurvey(id))
    } else if (reminderStatus?.status === STATUS.ERROR) {
      enqueueSnackbar(
        reminderStatus?.data?.message ||
          "We couldn't process your request. Please try again later.",
        { variant: "error", autoHideDuration: 3000  }
      );
      dispatch(resetCreateQuestionsStatus());
    }
  }, [
    reminderStatus,
    enqueueSnackbar,
    dispatch,
  ]);

  const getConfirmation = () => {
    return (
      <>
        <CloseIcon
          onClick={()=>setReminderPopup(false)}
          className={classes.closeIcon}
        />
        <Typography variant="h6" fontWeight={600}>Send Reminder</Typography>
        <Typography className={classes.popupContent}>
          Are you sure you want to send reminder email to this Email ID?
        </Typography>
        <Box className={classes.buttonContainer}>
          <CeroButton
            buttonText="Confirm"
            className={classes.buttonPrimary}
            onClick={onSendReminder}
          />
          <CeroButton
            buttonText="Cancel"
            variant="outlined"
            className={classes.buttonSecondary}
            onClick={() => setReminderPopup(false)}
          />
        </Box>
      </>
    );
  };

  const getStatus = (status) => {
    if (status === "pending") {
      return <Typography className={classes.pending}>Pending</Typography>;
    } else if (status === "completed") {
      return <Typography className={classes.completed}>Completed</Typography>;
    }
  };

  const showReminder = (id) => {
    setReminderPopup(true);
    setReminderId(id)
  }

  const viewSurvey = (surveyId) => {
    navigate(`response/${surveyId}`)
  }
  
  const getAction = (status, id) => {
    if (status === "pending") {
      return (
        <Typography
          className={classes.reminder}
          onClick={()=>showReminder(id)}
        >
          Send Reminder
        </Typography>
      );
    } else if (status === "completed") {
      return <Typography className={classes.view} onClick={()=>viewSurvey(id)}>View Survey</Typography>;
    }
  };

  const getData = () => {
    if (!Array.isArray(surveyList)) {
      return [];
    }

    return surveyList?.map((item) => ({
      ...item,
      status: getStatus(item.status),
      action: getAction(item.status, item.id),
    }));
  };

  return (
    <DashboardLayout>
      <Container className={classes.container}>
        <div
          className={classes.backContainer}
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBackIosIcon />
          Back
        </div>
        <CeroTable
          columns={surveyTrackColumns}
          data={getData}
          hasMore={false}
          loading={false}
          useExtraStyles={true}
          classes={{ tableContainer: classes.tableContainer }}
        />
        <CeroPopUp
          primaryPopUp={{
            open: reminderPopup,
            onClose: () => setReminderPopup(false),
            content: getConfirmation(),
            header: { title: "" },
          }}
          classes={{ dialog: classes.dialog }}
        />
      </Container>
    </DashboardLayout>
  );
};

export default ProgressTable;
