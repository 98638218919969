import { handleActions } from "redux-actions";
import immutable from "immutability-helper";

import { ActionTypes } from "../constants/actions";
import { STATUS } from "../constants";
import { parseError } from "../../services/client";

export const reportsState = {
  reportsList: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  reportDetails: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  deleteReport: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  // downloadReport: {
  //   data: [],
  //   status: STATUS.IDLE,
  //   message: "",
  // },
  createReport: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  updateReport: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  assignReport: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  generateAuditedReport: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  downloadAuditedReport: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getStakeholderReportsList: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getStakeholderAssignedReports: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getStakeholderAuditedReports: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getStakeholderTopicsSummary: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getStakeholderTopicsData: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getCumulativeTopics: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getStakeholderCumulativeData: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  reportEmissionList: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  reportEmissionDetails: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  getReportBlob: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  getNftUri: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  viewCertificate: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
};

const reportsActions = {
  reports: handleActions(
    {
      [ActionTypes.GET_ALL_REPORTS]: (state, { payload }) =>
        immutable(state, {
          reportsList: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_ALL_REPORTS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          reportsList: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_ALL_REPORTS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          reportsList: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.GET_REPORT_DETAILS]: (state, { payload }) =>
        immutable(state, {
          reportDetails: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_REPORT_DETAILS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          reportDetails: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_REPORT_DETAILS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          reportDetails: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.DELETE_REPORT]: (state, { payload }) =>
        immutable(state, {
          deleteReport: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.DELETE_REPORT_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          deleteReport: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.DELETE_REPORT_FAILURE]: (state, { payload }) =>
        immutable(state, {
          deleteReport: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),
      [ActionTypes.CREATE_REPORT]: (state, { payload }) =>
        immutable(state, {
          createReport: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.CREATE_REPORT_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          createReport: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.CREATE_REPORT_FAILURE]: (state, { payload }) =>
        immutable(state, {
          createReport: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.UPDATE_REPORT]: (state, { payload }) =>
        immutable(state, {
          updateReport: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.UPDATE_REPORT_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          updateReport: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.UPDATE_REPORT_FAILURE]: (state, { payload }) =>
        immutable(state, {
          updateReport: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.ASSIGN_REPORT]: (state, { payload }) =>
        immutable(state, {
          assignReport: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.ASSIGN_REPORT_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          assignReport: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.ASSIGN_REPORT_FAILURE]: (state, { payload }) =>
        immutable(state, {
          assignReport: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),
      [ActionTypes.GENERATE_AUDITED_REPORT]: (state, { payload }) =>
        immutable(state, {
          generateAuditedReport: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GENERATE_AUDITED_REPORT_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          generateAuditedReport: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GENERATE_AUDITED_REPORT_FAILURE]: (state, { payload }) =>
        immutable(state, {
          generateAuditedReport: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),
      [ActionTypes.DOWNLOAD_AUDITED_REPORT]: (state, { payload }) =>
        immutable(state, {
          downloadAuditedReport: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.DOWNLOAD_AUDITED_REPORT_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          downloadAuditedReport: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.DOWNLOAD_AUDITED_REPORT_FAILURE]: (state, { payload }) =>
        immutable(state, {
          downloadAuditedReport: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.GET_STAKEHOLDER_REPORTS_LIST]: (state, { payload }) =>
        immutable(state, {
          getStakeholderReportsList: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_STAKEHOLDER_REPORTS_LIST_SUCCESS]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          getStakeholderReportsList: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_STAKEHOLDER_REPORTS_LIST_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          getStakeholderReportsList: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),
      [ActionTypes.GET_STAKEHOLDER_ASSIGNED_REPORTS]: (state, { payload }) =>
        immutable(state, {
          getStakeholderAssignedReports: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_STAKEHOLDER_ASSIGNED_REPORTS_SUCCESS]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          getStakeholderAssignedReports: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_STAKEHOLDER_ASSIGNED_REPORTS_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          getStakeholderAssignedReports: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),
      [ActionTypes.GET_STAKEHOLDER_AUDITED_REPORTS]: (state, { payload }) =>
        immutable(state, {
          getStakeholderAuditedReports: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_STAKEHOLDER_AUDITED_REPORTS_SUCCESS]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          getStakeholderAuditedReports: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_STAKEHOLDER_AUDITED_REPORTS_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          getStakeholderAuditedReports: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),
      [ActionTypes.GET_STAKEHOLDER_TOPICS_SUMMARY]: (state, { payload }) =>
        immutable(state, {
          getStakeholderTopicsSummary: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_STAKEHOLDER_TOPICS_SUMMARY_SUCCESS]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          getStakeholderTopicsSummary: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_STAKEHOLDER_TOPICS_SUMMARY_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          getStakeholderTopicsSummary: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),
      [ActionTypes.GET_STAKEHOLDER_TOPICS_DATA]: (state, { payload }) =>
        immutable(state, {
          getStakeholderTopicsData: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_STAKEHOLDER_TOPICS_DATA_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          getStakeholderTopicsData: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_STAKEHOLDER_TOPICS_DATA_FAILURE]: (state, { payload }) =>
        immutable(state, {
          getStakeholderTopicsData: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.GET_CUMULATIVE_TOPICS]: (state, { payload }) =>
        immutable(state, {
          getCumulativeTopics: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_CUMULATIVE_TOPICS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          getCumulativeTopics: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_CUMULATIVE_TOPICS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          getCumulativeTopics: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),
      [ActionTypes.GET_STAKEHOLDER_CUMULATIVE_DATA]: (state, { payload }) =>
        immutable(state, {
          getStakeholderCumulativeData: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_STAKEHOLDER_CUMULATIVE_DATA_SUCCESS]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          getStakeholderCumulativeData: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_STAKEHOLDER_CUMULATIVE_DATA_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          getStakeholderCumulativeData: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.RESET_REPORT_STATUS]: (state, { payload }) =>
        immutable(state, {
          deleteReport: {
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
            data: { $set: [] },
          },
          // downloadReport: {
          //   status: { $set: STATUS.IDLE },
          //   message: { $set: "" },
          //   data: { $set: [] },
          // },
          createReport: {
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
            data: { $set: [] },
          },
          updateReport: {
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
            data: { $set: [] },
          },
        }),
      [ActionTypes.GET_REPORT_LIST_TOPICS]: (state, { payload }) =>
        immutable(state, {
          reportEmissionList: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_REPORT_LIST_TOPICS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          reportEmissionList: {
            data: { $set: payload },
            status: { $set: STATUS.SUCCESS },
          },
        }),
      [ActionTypes.GET_REPORT_LIST_TOPICS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          reportEmissionList: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),
      [ActionTypes.CLEAR_REPORT_LIST_TOPICS]: (state, { payload }) =>
        immutable(state, {
          reportEmissionList: {
            data: { $set: [] },
            status: { $set: STATUS.IDLE },
          },
        }),
      [ActionTypes.GET_REPORT_EMISSION_DETAILS]: (state, { payload }) =>
        immutable(state, {
          reportEmissionDetails: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_REPORT_EMISSION_DETAILS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          reportEmissionDetails: {
            data: { $set: payload },
            status: { $set: STATUS.SUCCESS },
          },
        }),
      [ActionTypes.GET_REPORT_EMISSION_DETAILS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          reportEmissionDetails: {
            message: { $set: parseError(payload) },
            status: { $set: STATUS.ERROR },
          },
        }),

        [ActionTypes.GET_REPORT_BLOB]: (state, { payload }) =>
          immutable(state, {
            getReportBlob: {
              status: { $set: STATUS.RUNNING },
            },
          }),
        [ActionTypes.GET_REPORT_BLOB_SUCCESS]: (state, { payload }) =>
          immutable(state, {
            getReportBlob: {
              data: { $set: payload },
              status: { $set: STATUS.SUCCESS },
            },
          }),
        [ActionTypes.GET_REPORT_BLOB_FAILURE]: (state, { payload }) =>
          immutable(state, {
            getReportBlob: {
              message: { $set: parseError(payload) },
              status: { $set: STATUS.ERROR },
            },
          }),

          [ActionTypes.GET_NFT_URI]: (state, { payload }) =>
          immutable(state, {
            getNftUri: {
              status: { $set: STATUS.RUNNING },
            },
          }),
        [ActionTypes.GET_NFT_URI_SUCCESS]: (state, { payload }) =>
          immutable(state, {
            getNftUri: {
              data: { $set: payload },
              status: { $set: STATUS.SUCCESS },
            },
          }),
        [ActionTypes.GET_NFT_URI_FAILURE]: (state, { payload }) =>
          immutable(state, {
            getNftUri: {
              message: { $set: parseError(payload) },
              status: { $set: STATUS.ERROR },
            },
          }),

          [ActionTypes.VIEW_CERTIFICATE]: (state, { payload }) =>
            immutable(state, {
              viewCertificate: {
                status: { $set: STATUS.RUNNING },
              },
            }),
          [ActionTypes.VIEW_CERTIFICATE_SUCCESS]: (state, { payload }) =>
            immutable(state, {
              viewCertificate: {
                data: { $set: payload },
                status: { $set: STATUS.SUCCESS },
              },
            }),
          [ActionTypes.VIEW_CERTIFICATE_FAILURE]: (state, { payload }) =>
            immutable(state, {
              viewCertificate: {
                message: { $set: parseError(payload) },
                status: { $set: STATUS.ERROR },
                data: { $set: "" },
              },
            }),
    },
    reportsState
  ),
};

export default reportsActions;
