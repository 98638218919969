import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Grid, Typography, Box, Tooltip } from "@mui/material";
import { useSnackbar } from 'notistack';

import { STATUS } from "../../../redux/constants";
import { sampleYear, months } from "../../../constants";
import { addPurchasedElectricityValidation } from './schema';
import { addPurchasedElectricity, resetAddCombustionStatus, getEmissionInputFormat } from '../../../redux/actions';

import CeroAutoComplete from '../../../components/CeroAutoComplete';
import CeroButton from '../../../components/CeroButton';
import CeroSelect from '../../../components/CeroSelect';
import CeroInput from '../../../components/CeroInput';
import CeroInfoPair from '../../../components/CeroInfoPair';
import useStyles from "./styles";
import DownloadIcon from '@mui/icons-material/Download';
import EmissionUploadDrawer from '../../EmissionList/EmissionUploadDrawer';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FileUpload from '../FileUpload';

const AddPurchasedElectricityForm = (props) => {
    const { onCancel, onDownload, onUpload, onValidate, uploadDisabled } = props
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const [typesOfEmissionFactors, setTypesOfEmissionFactors] = useState([]);

    const isCalculateDone = useSelector(state => state.emission.addPurchasedElectricity.isCalculateDone)
    const facilitiesData = useSelector(state => state.listings.listFacilities.data);
    const addEmissionData = useSelector(state => state.emission.addPurchasedElectricity);
    const emissionInputs = useSelector(state => state.emission.emissionInputs.data);
    const currentYear = new Date().getFullYear().toString()

    const formik = useFormik({
        initialValues: {
            facility: '',
            year: currentYear,
            month: '',
            calculationApproach: '',
            typeOfEmissionFactor: '',
            unit: '',
            amount: null,
            customEmissionType: '',
        },
        validationSchema: addPurchasedElectricityValidation,
        onSubmit: () => { },
    });

    const facilitiesList = facilitiesData.map(item => ({ key: item?.id, value: item?.name }));
    const calculationApproach = (emissionInputs.calculation_approaches || []).map(item => ({ key: item?.id, value: item?.name }));
    const units = (emissionInputs.units || []).map(item => ({ key: item?.name, value: item?.name }));
    const customEmissionTypes = (emissionInputs.custom_types_of_emissions || []).filter(item => item.scope_activity === (emissionInputs.calculation_approaches || []).find(item => item.id === formik.values.calculationApproach)?.scope_activity)
    .map(item => ({ key: item?.id, value: item?.name }));
    const yearList = sampleYear().map(item => ({ id: item.key, label: item.value }));

    const [selectedFile, setSelectedFile] = useState(null);

    useEffect(() => {
        dispatch(getEmissionInputFormat('purchased_electricity'))
        return () => { dispatch(resetAddCombustionStatus()) }
    }, [dispatch])

    useEffect(() => {
        const selectedTypesOfEmissionFactors = (emissionInputs.types_of_emission_factors || [])
            .filter(item => item.calculation_approach_id === formik.values.calculationApproach)
            .map(item => ({ key: item?.id, value: item?.name }));
        setTypesOfEmissionFactors(selectedTypesOfEmissionFactors)
    }, [formik.values.calculationApproach, emissionInputs.types_of_emission_factors])

    useEffect(() => {
        if (addEmissionData.status === STATUS.SUCCESS) {
            enqueueSnackbar('Purchased electricity added successfully', { variant: 'success' });
            dispatch(resetAddCombustionStatus())
            onCancel('purchased_electricity');
        } else if (addEmissionData.status === STATUS.ERROR) {
            enqueueSnackbar(addEmissionData.message.message || "We couldn't process your request. Please try again later.", { variant: 'error' });
        }
    }, [addEmissionData, enqueueSnackbar, dispatch, onCancel])

    const handleFileUpload = (file) => {
        setSelectedFile(file);
        // dispatch(uploadEmissionAttachement("emissionId", file));
    };
    
    const handleFileRemove = () => {
        setSelectedFile(null);
    };

    const onCalculate = () => {
        const requestData = {
            facility_id: formik.values.facility,
            calculation_approach_id: formik.values.calculationApproach,
            year: formik.values.year,
            month: formik.values.month,
            unit: formik.values.unit + '',
            amount: parseFloat(formik.values.amount),
            custom_type_emission_id: typesOfEmissionFactors.find((item) => item.key === formik.values.typeOfEmissionFactor)?.value === 'Custom emission factor'
                ? formik.values.customEmissionType : null,
            save: false
        }
        sessionStorage.setItem('year', requestData.year)
        dispatch(addPurchasedElectricity(requestData))
    };

    const onAddPurchasedElectricity = () => {
        const requestData = {
            facility_id: formik.values.facility,
            calculation_approach_id: formik.values.calculationApproach,
            year: formik.values.year,
            month: formik.values.month,
            unit: formik.values.unit + '',
            amount: parseFloat(formik.values.amount),
            custom_type_emission_id: typesOfEmissionFactors.find((item) => item.key === formik.values.typeOfEmissionFactor)?.value === 'Custom emission factor'
                ? formik.values.customEmissionType : null,
            save: true,
            file:selectedFile
        }
        dispatch(addPurchasedElectricity(requestData))
    };

    const [isOpenEmissionTypeUpload, setIsOpenEmissionTypeUpload] = useState(false);
    const onCloseDrawerUpload = () => {
    setIsOpenEmissionTypeUpload(false);
    };

    return (
        <>
    <div className={classes.backContainer}>
    <div className={classes.backButtonDiv} onClick={()=>{navigate(-1)}}>
            <ArrowBackIosIcon />
            Back
            </div>
            <div className={classes.templateButtonDiv}>
            <CeroButton
                buttonText="Bulk Data Upload"
                className={classes.bulkButton}
                startIcon={<FileUploadIcon />}
                onClick={() => setIsOpenEmissionTypeUpload(true)}
                />
                <CeroButton
                buttonText="Download Template"
                className={classes.bulkButton}
                startIcon={<DownloadIcon />}
                onClick={onDownload}
                />
                </div>
    </div>
        <Container className={classes.container}>
            <Box className={classes.innerContainer}>
                <Typography className={classes.title} variant="h6" component="div">Add Purchased Electricity</Typography>
                <Box className={classes.topContainer}>
                    <Grid container direction='row' wrap='nowrap' justifyContent='space-between' spacing={8}>
                        <Grid item container direction='column' md={6} xs={12}>
                            <CeroSelect
                                required
                                id="facility"
                                key="facility"
                                name="facility"
                                label="Facility"
                                fullWidth
                                options={facilitiesList}
                                selectedValue={formik.values.facility || ''}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.facility && formik.errors.facility}
                            />
                            <CeroAutoComplete
                                id="year"
                                label="Assessment Year"
                                onChange={(e, value) => formik.setFieldValue('year', value.id)}
                                onBlur={formik.handleBlur}
                                error={formik.touched.year && formik.errors.year}
                                options={yearList}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                value={formik.values.year}
                            />
                            <CeroInput
                                required
                                id="amount"
                                key="amount"
                                name="amount"
                                label="Amount"
                                value={formik.values.amount || ''}
                                fullWidth
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.amount && formik.errors.amount}
                                type="number"
                            />
                        </Grid>
                        <Grid item container direction={'column'} md={6} xs={12}>
                            <CeroSelect
                                required
                                id="calculationApproach"
                                name="calculationApproach"
                                label="Calculation Approach"
                                fullWidth
                                options={calculationApproach}
                                selectedValue={formik.values.calculationApproach || ''}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.calculationApproach && formik.errors.calculationApproach}

                            />
                            <CeroSelect
                                required
                                id="month"
                                name="month"
                                label="Month"
                                fullWidth
                                options={months}
                                selectedValue={formik.values.month || ''}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.month && formik.errors.month}
                            />
                            <CeroSelect
                                required
                                id="unit"
                                key="unit"
                                name="unit"
                                label="Unit"
                                fullWidth
                                options={units}
                                selectedValue={formik.values.unit || ''}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.unit && formik.errors.unit}
                                sortOption={true}
                            />
                            {typesOfEmissionFactors.find((item) => item.key === formik.values.typeOfEmissionFactor)?.value === 'Custom emission factor' && (<CeroSelect
                                required
                                id="customEmissionType"
                                key="customEmissionType"
                                name="customEmissionType"
                                label="Custom Emission Type"
                                fullWidth
                                options={customEmissionTypes}
                                selectedValue={formik.values.customEmissionType || ''}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.customEmissionType && formik.errors.customEmissionType}
                            />)}
                        </Grid>
                        <Grid item container direction='column' md={6} xs={4}>
                        <span className={classes.info}>
                            <Tooltip title="Purchased electricity location based refers to the electricity consumed
                            by an entity that is sourced from a geographic location." classes={{ popper: classes.tooltip }}arrow placement="right-start">
                            <InfoOutlinedIcon className={classes.infoIcon}/></Tooltip>
                        </span>
                        </Grid>
                    </Grid>
                    <CeroButton
                        buttonText="Calculate"
                        className={classes.buttonPrimary}
                        onClick={onCalculate}
                        disabled={!formik.dirty || !formik.isValid}
                    />
                    <CeroButton
            buttonText="Clear"
            variant="outlined"
            className={classes.buttonSecondary}
            onClick={formik.resetForm}
          />
          {isCalculateDone && <Box className={classes.bottomContainer}>
                    <Typography variant="h6" component="h6" className={classes.previewTitle}>Emission Preview</Typography>
                    <Grid container direction='row' wrap='nowrap' justifyContent='space-between' spacing={8}>
                        <Grid item container direction='column' xs={12} md={6}>
                            <CeroInfoPair title={<>CO<sub>2</sub></>} value={`${addEmissionData.data.co2} tonnes`} />
                            <CeroInfoPair title={<>CH<sub>4</sub></>} value={`${addEmissionData.data.ch4} tonnes`} />
                            <CeroInfoPair title={<>CO<sub>2</sub>e</>} value={`${addEmissionData.data.co2e} tonnes`} />
                        </Grid>
                        <Grid className={classes.secondResultContainer} item container direction='column' xs={6}>
                            <CeroInfoPair title={<>N<sub>2</sub>O</>} value={`${addEmissionData.data.n2o} tonnes`} />
                            <CeroInfoPair title={<>EF</>} value={<>{addEmissionData.data.ef} kgCO<sub>2</sub>e/unit</>} />
                        </Grid>
                    </Grid>
                </Box>}
                </Box>
                <FileUpload onFileUpload={handleFileUpload} onFileRemove={handleFileRemove} />
            </Box>
            <Box className={classes.buttonContainer}>
                <CeroButton
                    buttonText="Add Data"
                    disabled={!isCalculateDone || addEmissionData.status === STATUS.RUNNING}
                    className={classes.buttonPrimary}
                    onClick={() => onAddPurchasedElectricity(formik.values)} />
                            <div>

                {/* <CeroButton
                   buttonText="Bulk Data Upload"
                   className={classes.bulkButton}
                   startIcon={<FileUploadIcon />}
                   onClick={() => setIsOpenEmissionTypeUpload(true)}
                />
                <CeroButton
                    buttonText="Download Template"
                    className={classes.bulkButton}
                    startIcon={<DownloadIcon />}
                    onClick={onDownload}
                  /> */}
                  </div>
            </Box>
            <EmissionUploadDrawer
                isOpen={isOpenEmissionTypeUpload}
                onClose={onCloseDrawerUpload}
                onUpload={onUpload}
                onValidate={onValidate}
                uploadDisabled={uploadDisabled}
            />
        </Container>
        </>
    )
}

export default AddPurchasedElectricityForm;
