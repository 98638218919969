import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Paper, Typography } from "@mui/material";
import {
  getBoardManagementGraph,
} from "../../../redux/actions";
import useStyles from "./styles";
import { getCookie } from "../../../services/cookie";

const ManagementDiversityGraph = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const role = getCookie("role");
  const companyId = getCookie("companyId");
  const diversityValues = useSelector(
    (state) => state.dashboard.getBoardManagementGraph.data.management_diversity 
  );

  useEffect(() => {
    !props.refreshContent && dispatch(getBoardManagementGraph(props.filter,role,companyId));
  }, [dispatch, props.filter]);

  return (
    <Paper className={classes.container}>
      <div className={classes.header}>
        <Typography className={classes.graphTitle}>Management Diversity</Typography>
        <Typography className={classes.subtitle}>Management Diversity by Gender</Typography>
        <div className={classes.box}>
          <div
            className={classes.bar}
            style={{
              backgroundColor: "rgba(132, 107, 181, 0.8)",
            }}
          >
            <Typography className={classes.value}>
              {diversityValues?.gender
                ?.male || 0}
              %
            </Typography>
          </div>
          <div
            className={classes.bar}
            style={{
              backgroundColor: "rgba(111, 191, 186, 0.8)",
            }}
          >
            <Typography className={classes.value}>
              {diversityValues?.gender
                ?.female || 0}
              %
            </Typography>
          </div>
          <div
            className={classes.bar}
            style={{
              backgroundColor: "rgba(255, 199, 87, 0.8)",
            }}
          >
            <Typography className={classes.value}>
              {diversityValues?.gender
                ?.other || 0}
              %
            </Typography>
          </div>
        </div>
        <div className={classes.caption}>
          <Typography variant="caption">% Male</Typography>
          <Typography variant="caption">% Female</Typography>
          <Typography variant="caption">% Non-Binary</Typography>
        </div>
        <Typography className={classes.subtitle}>Management Diversity by Age-Group</Typography>
        <div className={classes.box}>
          <div
            className={classes.bar}
            style={{
              backgroundColor: "rgba(132, 107, 181, 0.8)",
            }}
          >
            <Typography className={classes.value}>
              {diversityValues?.age
                ?.under_30 || 0}
              %
            </Typography>
          </div>
          <div
            className={classes.bar}
            style={{
              backgroundColor: "rgba(111, 191, 186, 0.8)",
            }}
          >
            <Typography className={classes.value}>
              {diversityValues?.age
                ?.between_30_50 || 0}
              %
            </Typography>
          </div>
          <div
            className={classes.bar}
            style={{
              backgroundColor: "rgba(255, 199, 87, 0.8)",
            }}
          >
            <Typography className={classes.value}>
              {diversityValues?.age
                ?.over_50 || 0}
              %
            </Typography>
          </div>
        </div>
        <div className={classes.caption}>
          <Typography variant="caption">Under 30</Typography>
          <Typography variant="caption">Between 30-50</Typography>
          <Typography variant="caption">Above 50</Typography>
        </div>
      </div>
    </Paper>
  );
};

export default ManagementDiversityGraph;
