import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
    width: "100%",
    height: "95%",
    borderRadius: 8,
    boxShadow:
      "0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), -4px 0px 4px rgba(0, 0, 0, 0.05)",
  },
  graphTitle: {
    fontSize: 18,
    fontWeight: 400,
  },
  largeBox: {
    width: "100%",
    height: "100px", 
    backgroundColor: theme.palette.background.gray,
    marginBottom: theme.spacing(2),
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  scopeBox:{
    width: "95%",
    backgroundColor: theme.palette.background.gray,
    height: "90px",
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  subTitle:{
    textAlign: "center",
    fontWeight: 600,
    padding: theme.spacing(2,0),
  },
  value:{
    fontWeight: 400,
  },
  unit:{
    fontWeight: 400,
    fontSize: 12
  }
}));

export default useStyles;
