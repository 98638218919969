import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import DashboardLayout from "../../layouts/DashboardLayout";
import tradeProfileIcon from "../../assets/icons/tradeProfileIcon.png";
import CeroButton from "../../components/CeroButton";
import { useNavigate } from "react-router-dom";
import useStyles from "./styles";
import { useSelector } from "react-redux";
import {
  getTradeAccount
} from "../../redux/actions";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const TradeProfile = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.trade.getTradeAccount.data);

  useEffect(() => {
    dispatch(getTradeAccount());
  }, [dispatch]);

  return (
    <DashboardLayout classes={{ childContainer: classes.childContainer }}>
      <div
        className={classes.backContainer}
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowBackIosIcon />
        Back
      </div>
      <Container className={classes.container}>
        <Card className={classes.card}>
          <CardContent>
            <Grid className={classes.subContent}>
              <img src={tradeProfileIcon} alt="Logo" className={classes.image} />
              <Typography className={classes.profileHead}>
                {userInfo?.user_details?.name}
              </Typography>
            </Grid>
            <Grid container className={classes.details}>
              <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  <Typography className={classes.profileKey}>
                    Company:
                  </Typography>
                  <Typography className={classes.profileKey}>Email:</Typography>
                  <Typography className={classes.profileKey}>Phone:</Typography>
                  <Typography className={classes.profileKey}>
                    User Type:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.profileValues}>
                    {userInfo?.user_details?.company}
                  </Typography>
                  <Typography className={classes.profileValues}>
                    {userInfo?.user_details?.email}
                  </Typography>
                  <Typography className={classes.profileValues}>
                    {userInfo?.user_details?.phone}
                  </Typography>
                  <Typography className={classes.profileValues}>
                    {userInfo?.user_details?.user_type}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid className={classes.subContent}>
              <CeroButton
                buttonText="Edit Information"
                className={classes.button}
                onClick={() => navigate("/trade-profile/settings")}
              />
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </DashboardLayout>
  );
};

export default TradeProfile;
