import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    margin: 0,
    "@media (min-width: 1200px)": {
      maxWidth: "none",
    },
  },
  tableContainer: {
    width: "100%",
    margin: "auto",
    marginTop: theme.spacing(5),
    height: "auto",
    "@media (max-width: 599px)": {
      maxHeight: "calc(100vh - 100px)", 
    },
    "@media (min-width: 600px) and (max-width: 1199px)": {
      maxHeight: "calc(100vh - 140px)", 
    },
    "@media (min-width: 1200px)": {
      maxHeight: "calc(100vh - 200px)", 
    },
  },
  backContainer: {
    color: theme.palette.Primary.background,
    textTransform: "capitalize",
    display: "flex",
    paddingBottom: 20,
    alignItems: "center",
    cursor: "pointer",
  },
  activeStatus: {
    color: theme.palette.status.closed,
    fontWeight: 500,
    fontSize: 14,
  },
  inActiveStatus: {
    color: "#E81111",
    fontWeight: 500,
    fontSize: 14,
  },
  buttonPrimary: {
    background: theme.palette.Primary.background,
    textTransform: "capitalize",
    height: 35,
    borderColor: theme.palette.Primary.background,
    marginRight: 15,
    fontWeight: 400,
    color: theme.palette.background.white,
    "&:hover": {
      backgroundColor: theme.palette.Primary.hoverButton,
      borderColor: theme.palette.Primary.hoverButton,
      color: theme.palette.Primary.light,
    },
  },
  switchColor: {
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "white",
    },
    "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
      backgroundColor: theme.palette.status.closed,
      opacity: 1.5,
    },
  },
  inactiveSwitchColor: {
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "grey",
    },
    "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
      backgroundColor: "grey",
      opacity: 1.5,
    },
  },
  statusText: {
    fontSize: 10,
  },
}));

export default useStyles;