import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { useSnackbar } from "notistack";
import { useFormik } from "formik";
import { assignReportSchema } from "./schema";
import CeroSideSheetDrawer from "../../../../components/CeroSideSheetDrawer";
import useStyles from "./styles";
import CeroInput from "../../../../components/CeroInput";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { companyUserDetails, createBusinessAdmin, resetCreateBusinessStatus } from "../../../../redux/actions";
import { STATUS } from "../../../../redux/constants";

const AddAdmin = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { companyId,user, isActive } = useParams();
  const createBusinessAdminStatus = useSelector(state=>state.superAdmin.createBusinessAdmin);

  const formik = useFormik({
    initialValues: {
      name:"",
      email: ""
    },
    validationSchema: assignReportSchema,
    onSubmit: () => {},
  });

  const onSubmit = ()=>{
    dispatch(createBusinessAdmin(companyId,formik.values))
  }

  useEffect(() => {
    if (createBusinessAdminStatus.status === STATUS.SUCCESS) {
        enqueueSnackbar('Business admin added successfully', { variant: 'success' });
        dispatch(resetCreateBusinessStatus())
       props.onClose(false)
       dispatch(companyUserDetails(companyId, user, isActive));
    } else if (createBusinessAdminStatus.status === STATUS.ERROR) {
        enqueueSnackbar(createBusinessAdminStatus.message, { variant: 'error' });
    }
}, [createBusinessAdminStatus, enqueueSnackbar, dispatch])

  const getPrimaryPaymentDrawer = () => {
    return (
      <Box className={classes.mainContainer}>
        <CeroInput
          required
          id="email"
          name="email"
          label="Email address"
          fullWidth
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.email}
        />
        <CeroInput
          required
          id="name"
          name="name"
          label="Enter name"
          fullWidth
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.name}
        />
      </Box>
    );
  };

  return (
    <CeroSideSheetDrawer
      primaryDrawer={{
        drawerOpen: props.isOpen,
        onClose: () => props.onClose(false),
        content: getPrimaryPaymentDrawer(),
        header: { title: "Add Admin" },
        footer: {
          primaryBtnTitle: "Save",
          secondaryBtnTitle: "Cancel",
          primaryBtnAction: onSubmit,
          secondaryBtnAction: () => props.onClose(false),
          disablePrimaryBtn:
            !formik.dirty ||
            !formik.isValid
        },
        classes: {
          drawerContainer: classes.drawerContainer,
          contentArea: classes.drawerContentArea,
        },
      }}
    />
  );
};

export default AddAdmin;
