import { Container, Grid } from "@mui/material";
import dayjs from "dayjs";

import CeroInfoPair from "../../../components/CeroInfoPair";
import useStyles from "./styles";

const Status = (props) => {
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <Grid spacing={2} className={classes.statusContainer}>
        <Grid item xs={6}>
          <CeroInfoPair title="Status" value={props.status} classes={{ container: classes.infoContainer, title:classes.title, 
            value: props.status === "in progress" ? classes.Inprogress : classes[props.status?.toLowerCase() || "default"]}} />
          <CeroInfoPair title="Audit request by" value={props.assignedBy} classes={{ container: classes.infoContainer, title:classes.title}} />
          <CeroInfoPair title="Audit request on" value={props.assignedOn ? dayjs(props.assignedOn).format('DD/MM/YYYY') : '-'} classes={{ container: classes.infoContainer, title:classes.title}} />
          <CeroInfoPair title="Audited by" value={props.auditedBy} classes={{ container: classes.infoContainer, title:classes.title}} />
          {props.auditNumber && <CeroInfoPair title="Audit Number" value={props.auditNumber} classes={{ container: classes.infoContainer, title:classes.title}} />}
        </Grid>
        <Grid item xs={6}>
          <CeroInfoPair title="Audited on" value={props.auditedOn ? dayjs(props.auditedOn).format('DD/MM/YYYY') : '-'} classes={{ container: classes.infoContainer, title:classes.title}} />
          <CeroInfoPair title="Audit Cycle" value={props.auditCycle?.split("_").join(" ")} classes={{ container: classes.infoContainer, title:classes.title}} />
          <CeroInfoPair title="Period" value={props.period} classes={{ container: classes.infoContainer, title:classes.title}} />
          <CeroInfoPair title="No of Tickets" value={props.noOfTickets} classes={{ container: classes.infoContainer, title:classes.title}} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Status;
