import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Container,
  Typography,
  Switch,
  Stack,
  Grid,
  CircularProgress,
  Checkbox,
} from "@mui/material";
import DashboardLayout from "../../layouts/DashboardLayout";
import CeroTable from "../../components/CeroTable";
import {
  getAuditorList,
  resetUpdateAuditorStatus,
  setAuditTopics,
  updateAuditorStatus,
} from "../../redux/actions/audit";
import { STATUS } from "../../redux/constants";
import useStyles from "./styles";
import CeroButton from "../../components/CeroButton";
import AddAuditorDrawer from "./AddAuditorDrawer";
import { useSnackbar } from "notistack";
import { getCookie } from "../../services/cookie";

const AuditorManagement = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isOpenAddAuditor, setOpenAddAuditor] = useState(false);
  const [editAuditorItem, setEditAuditorItem] = useState(null);
  const [auditSummary, setAuditSummary] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const readOnlyMode = (getCookie("read_only_mode") === "disable_edit")

  const auditSummaryColumns = [
    {
      columnKey: "email",
      columnId: "email",
      columnHeader: "Email",
    },
    {
      columnKey: "name",
      columnId: "name",
      columnHeader: "Name",
      width:"15%"
    },
    {
      columnKey: "role",
      columnId: "role",
      columnHeader: "Role",
      width:"10%"
    },
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
      width:"10%"
    },
    {
      columnKey: "action",
      columnId: "action",
      columnHeader: "Action",
    },
    {
      columnKey: "type",
      columnId: "Audit type",
      columnHeader: "Audit type",
    },
  ];

  const auditSummaryData = useSelector((state) => state.audit.auditorList.data);
  const auditSummaryDataStatus = useSelector(
    (state) => state.audit.auditorList.status
  );
  const updateAuditorData = useSelector((state) => state.audit.auditorStatus);
  const auditedTopics = useSelector((state) => state.audit.setAuditTopics);

  useEffect(() => {
    dispatch(getAuditorList());
  }, [dispatch]);

  useEffect(() => {
    setAuditSummary(auditSummaryData);
  }, [auditSummaryData]);

  useEffect(() => {
    if (updateAuditorData.status === STATUS.SUCCESS) {
      dispatch(getAuditorList());
      enqueueSnackbar(updateAuditorData.data.message, {
        variant: "success",
        autoHideDuration: 3000,
      });
      dispatch(resetUpdateAuditorStatus());
    } else if (updateAuditorData.status === STATUS.ERROR) {
      dispatch(getAuditorList());
      enqueueSnackbar(updateAuditorData.message.message, {
        variant: "error",
        autoHideDuration: 1000,
      });
      dispatch(resetUpdateAuditorStatus());
    }
  }, [updateAuditorData, enqueueSnackbar, dispatch]);

  const handleToggleStatus = (id, status) => {
    const updatedAuditSummary = auditSummary.map((item) =>
      item.id === id ? { ...item, status: status } : item
    );
    const requestData = {
      id: id,
      status: status,
    };
    setAuditSummary(updatedAuditSummary);
    dispatch(updateAuditorStatus(requestData));
  };

  
  const handleCheckboxChange = (auditorId, option) => {
    const updatedSelectedOptions = {
      ...selectedOptions,
      [auditorId]: {
        ...selectedOptions[auditorId],
        [option]: !selectedOptions[auditorId]?.[option],
      },
    };
    setSelectedOptions(updatedSelectedOptions);
    const options = updatedSelectedOptions[auditorId];
    const selectedTopics = Object.keys(options)
  .filter(key => options[key]);
    dispatch(setAuditTopics(auditorId, selectedTopics));
  };

  useEffect(() => {
    if (auditedTopics.status === STATUS.SUCCESS) {
      dispatch(getAuditorList())
      enqueueSnackbar(auditedTopics.data.message, {
        variant: "success", autoHideDuration: 3000 });
      dispatch(resetUpdateAuditorStatus())
    } else if (auditedTopics.status === STATUS.ERROR) {
      enqueueSnackbar(
        auditedTopics.message.message ||
          "We couldn't process your request. Please try again later.",
        { variant: "error", autoHideDuration: 3000 });
      dispatch(resetUpdateAuditorStatus())
    }
  }, [auditedTopics, dispatch, enqueueSnackbar]);

  useEffect(() => {
    const initialSelectedOptions = auditSummary.reduce((acc, auditor) => {
      acc[auditor.id] = {
        environmental: auditor.topics ? (auditor.topics.includes('environmental')  || auditor.topics === null): true,
        social: auditor.topics ? (auditor.topics.includes('social') || auditor.topics === null) : true,
        governance: auditor.topics ? (auditor.topics.includes('governance') || auditor.topics === null) : true,
      };
      return acc;
    }, {});
    setSelectedOptions(initialSelectedOptions);
  }, [auditSummary]);
  const getStatus = (status) => {
    const statusText = status ? "Active" : "Inactive";
      return  <Typography className={classes[statusText]}>{statusText}</Typography>;
    }
  const getAuditData = () =>
    auditSummary.map((item) => ({
      ...item,
      email: item.email || "",
      name: item.name || "",
      role: item.role || "",
      status: getStatus(item.status),
      action: (
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography
            style={{ fontWeight: !item.status ? "bold" : "normal" }}
            className={classes.statusText}
          >
            Deactivate
          </Typography>
          <Switch
            checked={item.status}
            className={
                 item.status ? classes.switchColor : classes.switchColorInactive
            }
            inputProps={{ "aria-label": "Toggle Status" }}
            onChange={(e) => handleToggleStatus(item.id, e.target.checked)}
            disabled={readOnlyMode}
          />
          <Typography
            style={{ fontWeight: item.status ? "bold" : "normal" }}
            className={classes.statusText}
          >
            Activate
          </Typography>
        </Stack>
      ),
      type: ( item.status === true && 
        <Grid container spacing={3}>
          <Grid item className={classes.checkboxContainer}>
          <Checkbox
            className={classes.checkbox}
            checked={(item.topics && item.topics.includes('environmental') || item.topics === null) ? true : selectedOptions[item.id]?.environmental}
            onChange={() => handleCheckboxChange(item.id, 'environmental')}
            disabled={true}
          />
          <Typography className={classes.topic}>Environmental</Typography></Grid>
          <Grid item className={classes.checkboxContainer}>
          <Checkbox
            className={classes.checkbox}
            checked={(item.topics && item.topics.includes('social') || item.topics === null) ? true : selectedOptions[item.id]?.social}
            onChange={() => handleCheckboxChange(item.id, 'social')}
            disabled={readOnlyMode}
          />
          <Typography className={classes.topic}>Social</Typography></Grid>
          <Grid item  className={classes.checkboxContainer}>
          <Checkbox
            className={classes.checkbox}
            checked={(item.topics && item.topics.includes('governance') || item.topics === null) ? true :selectedOptions[item.id]?.governance}
            onChange={() => handleCheckboxChange(item.id, 'governance')}
            disabled={readOnlyMode}
          />
          <Typography className={classes.topic}>Governance</Typography></Grid>
        </Grid>
      )
    }));
  

  return (
    <DashboardLayout>
      <Container className={classes.container}>
        <Grid item xs={2.5} className={classes.headerButton}>
          <CeroButton
            buttonText="Add Auditor"
            className={classes.button}
            onClick={() => setOpenAddAuditor(true)}
            disabled={readOnlyMode}
          />
        </Grid>
        {auditSummaryDataStatus === STATUS.SUCCESS ? (
          <CeroTable
            columns={auditSummaryColumns}
            data={getAuditData()}
            hasMore={false}
            loading={false}
            classes={{ tableContainer: classes.tableContainer }}
          />
        ) : (
          <Box className={classes.loader}>
            <Typography variant="h7" component="span">
              {auditSummaryDataStatus === STATUS.RUNNING || auditSummaryDataStatus === STATUS.IDLE ? (
                <CircularProgress />
              ) : auditSummaryDataStatus === STATUS.ERROR ? (
                "We couldn't process your request. Please try again later"
              ) : (
                ""
              )}
            </Typography>
          </Box>
        )}
        {(isOpenAddAuditor || editAuditorItem) && (
          <AddAuditorDrawer
            isOpen={isOpenAddAuditor || editAuditorItem}
            onClose={() => {
              setOpenAddAuditor(false);
              setEditAuditorItem(null);
            }}
            editItem={editAuditorItem}
          />
        )}
      </Container>
    </DashboardLayout>
  );
};

export default AuditorManagement;
