import React, { useEffect, useState } from "react";
import {  useNavigate } from "react-router-dom";
import {
  Box,
  IconButton,
  Chip,
  Typography,
  CircularProgress,
  Tooltip,
  Container,
  Grid,
} from "@mui/material";
import CreateIcon from "@mui/icons-material/CreateOutlined";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { EmployeeHireAndTurnover, EmployeeHireAndTurnoverByAge} from "../../EmissionList/EmissionTable/TableColumns.js";
import CeroTable from "../../../components/CeroTable/index.jsx";
import useStyles from "./style.js";
import { STATUS } from "../../../redux/constants/index.js";
import { ApprovalEmployeeHireAndTurnover, ApprovalEmployeeHireAndTurnoverByAge } from "../../ApprovalStatus/MonthlyFacilityDetails/ApprovalStatusDetails/ApprovalTableColumns.js";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { deleteEmissions, resetEmissionDelete } from "../../../redux/actions/index.js";
import EmissionDeletePrompt from "../../EmissionDeletePrompt/index.jsx";
import { getCookie } from "../../../services/cookie.js";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import CeroButton from "../../../components/CeroButton/index.jsx";
import CeroInfoPair from "../../../components/CeroInfoPair/index.jsx";

const EmployeeHireApproval = (props) => {
const {  handleCallback, handleReviewCallback } = props;
  const classes = useStyles();
  const navigate = useNavigate();
  const {
    emissionType,
    emissionData,
    onLoadMore,
    dataStatus,
    isAuditor,
    company,
    editOff,
    reportTable,
    isOnlyAdmin,
    period,
    isDeleteActionRequired
  } = props;


  const currentPath = window.location.pathname;
  const approvalTable = currentPath.includes('approval') 
  const role = useSelector((state) => state.auth.role);
  const readOnlyMode = (getCookie("read_only_mode") === "disable_edit")
  const deleteEmissionData = useSelector(
    (state) => state.emission.deleteEmissions
  );
  const [displayWarning, setDisplayWarning] = useState(false);
  const [emissionId, setEmissionId] = useState(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const onConfirmDelete = () => {
    const requestData = {
      id: emissionId,
    };
    dispatch(deleteEmissions(requestData));
  };
  const handleIconClick = (itemId) => {
    handleCallback(itemId)
  };
  
  const handleReviewClick = (itemId) => {
    handleReviewCallback(itemId)
  }
  useEffect(() => {
    if (deleteEmissionData.status === STATUS.SUCCESS) {
      enqueueSnackbar(
        "Topic deleted successfully",
        {
          variant: "success",
        }
      );
      dispatch(resetEmissionDelete());
      
    } else if (deleteEmissionData.status === STATUS.ERROR) {
      enqueueSnackbar("We couldn't process your request. Please try again later.", { variant: "error" });
      dispatch(resetEmissionDelete());
    }
    setDisplayWarning(false)
  }, [deleteEmissionData, enqueueSnackbar,  dispatch]);
  
  const onSelectEmissionData = (rowData) => {
    const currentFilter = encodeURI(
      `?${rowData.gender ? `&distribution=${rowData.gender}` : ""}${rowData.employee_classification ? `&classification=${rowData.employee_classification}` : ""}`
    );
    isAuditor
      ? navigate(
        `/emissions/${emissionType}/details/${rowData.id}/?company=${company}&distribution=${rowData.gender}&classification=${rowData.employee_classification}`
      )
      : navigate(`/emissions/${emissionType}/details/${rowData.id}${currentFilter}`)
  };
  
  const onSelectData = (rowData) => {
    let ageFilter = rowData.age;
    if(rowData.age === "Under 30"){
      ageFilter = "under_30";
    }else if(rowData.age === "Between 30 and 50"){
      ageFilter = "between_30_50"
    }else{
      ageFilter = "over_50"
    }
    const currentFilter = encodeURI(
      `?${ageFilter ? `&distribution=${ageFilter}` : ""}${rowData.employee_classification ? `&classification=${rowData.employee_classification}` : ""}`
    );
    isAuditor
      ? navigate(
        `/emissions/${emissionType}/details/${rowData.id}/?company=${company}&distribution=${ageFilter}&classification=${rowData.employee_classification}`
      )
      : navigate(`/emissions/${emissionType}/details/${rowData.id}${currentFilter}`)
  };

  const getStatus = (status) => {
    if (status === 'approved') {
      return reportTable ? <Tooltip title="Approved" arrow><Chip className={classes.signalApproved}/></Tooltip>
        : <Typography className={classes.approved}>Approved</Typography>;
    } else if (status === 'submitted') {
        return reportTable ? <Tooltip title="Submitted" arrow><Chip className={classes.signalPending}/></Tooltip>
          : <Typography className={classes.pending}>Pending Approval</Typography>;
    } else if (status === 'audited') {
        return reportTable ? <Tooltip title="Audited" arrow><Chip className={classes.signalAudited}/></Tooltip>
          : <Typography className={classes.audited}>Audited</Typography>;
    } else if (status === 'pending') {
        return reportTable ? <Tooltip title="Pending Approval" arrow><Chip className={classes.signalPending}/></Tooltip>
          : <Typography className={classes.pending}>Pending Approval</Typography>;
    }else if (status === 'added') {
        return reportTable ? <Tooltip title="Added" arrow><Chip className={classes.signalAdded}/></Tooltip>
          : <Typography className={classes.added}>Added</Typography>;
    }else if (status === 'assigned') {
      return reportTable ? <Tooltip title="Assigned" arrow><Chip className={classes.signalPending}/></Tooltip>
        : <Typography className={classes.pending}>Pending Approval</Typography>;
    }else if (status === 'review') {
      return reportTable ? <Tooltip title="Review" arrow><Chip className={classes.signalPending}/></Tooltip>
        : <Typography className={classes.review}>Review</Typography>;
    }else if (status === 'verified') {
      return reportTable ? <Tooltip title="Verified" arrow><Chip className={classes.signalVerified}/></Tooltip>
        : <Typography className={classes.verified}>Verified</Typography>;
    }else if (status === 'Awaiting approval') {
      return reportTable ? <Tooltip title="Awaiting approval" arrow><Chip className={classes.signalPending}/></Tooltip>
        : <Typography className={classes.pending}>Pending Approval</Typography>;
    }else if (status === 'pending_approval') {
      return reportTable ? <Tooltip title="Pending approval" arrow><Chip className={classes.signalPending}/></Tooltip>
        : <Typography className={classes.pending}>Pending approval</Typography>;
    }
  else {
        return reportTable ? <Tooltip title="" arrow><Chip className={classes.signalPending}/></Tooltip>
         : <Typography className={classes.pending}>{status}</Typography>;
    } 
  };
  const onEditEmissionData = (e, emission) => {
    e.stopPropagation();
    e.preventDefault();
    navigate(`/emissions/edit/employees_hire_and_turnover/${emission}`);
  };
  const getGenderDistribution = (items) =>
  items.gender_distribution?.map((item) => ({
      ...item,
      year: item.month ? `${item.month}/${item.year}` : `${item.year}`,
      status: getStatus(item.status),
      facility: item.facility_name ? item.facility_name : "",
      turnover_rate : item.turnover_rate ? `${item.turnover_rate}%` : "",
      period: period,
      action: (
        !readOnlyMode &&
        <Box className={classes.actionContainer}>
          {item.is_able_to_update === true &&
            !editOff && isOnlyAdmin &&(
              <IconButton
                className={classes.editIcon}
                onClick={(e) => onEditEmissionData(e, item.id)}
              >
                <CreateIcon />
              </IconButton>
            )}
            {isDeleteActionRequired && 
            (
              (item.status === "added" && role.includes('facility_manager')) || 
              (
                !["audited", "reviewed"].includes(item.status) && 
                (role.includes('sustainability_manager') || role.includes('proxy_sustainability_manager'))
              )
            ) &&            
            // ((item.status === "added" && role.includes('facility_manager')) || (!["audited", "reviewed"].includes(item.status) && (role.includes('sustainability_manager')) || role.includes('proxy_sustainability_manager'))) &&
              <IconButton
              className={classes.editIcon}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setEmissionId(item.id);
                setDisplayWarning(true)}}
            >
              <DeleteOutlineIcon />
            </IconButton>}
        </Box>
      ),
    }));

    const getByAgeDistribution = (items) =>
    items?.age_distribution?.map((item) => ({
      ...item,
      year: item.month ? `${item.month}/${item.year}` : `${item.year}`,
      status: getStatus(item.status),
      facility: item.facility_name ? item.facility_name : "",
      turnover_rate : item.turnover_rate ? `${item.turnover_rate}%` : "",
      action: (
        !readOnlyMode &&
        <Box className={classes.actionContainer}>
          {item.status === "added" &&
            !editOff && isOnlyAdmin &&(
              <IconButton
                className={classes.editIcon}
                onClick={(e) => onEditEmissionData(e, item.id)}
              >
                <CreateIcon />
              </IconButton>
            )}
            {isDeleteActionRequired && 
            (
              (item.status === "added" && role.includes('facility_manager')) || 
              (
                !["audited", "reviewed"].includes(item.status) && 
                (role.includes('sustainability_manager') || role.includes('proxy_sustainability_manager'))
              )
            ) && 
            // ((item.status === "added" && role.includes('facility_manager')) || (!["audited", "reviewed"].includes(item.status) && (role.includes('sustainability_manager')) || role.includes('proxy_sustainability_manager'))) &&
              <IconButton
              className={classes.editIcon}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setEmissionId(item.id);
                setDisplayWarning(true)}}
            >
              <DeleteOutlineIcon />
            </IconButton>}
        </Box>
      ),
    }));
    const item = {"status":"approved", "id":"abc"}

    function extractEmissionsData(emissions) {
      const groupedEmissions = {};
    
      // Helper function to group emissions by ID and type (gender_distribution, age_distribution)
      function groupById(data, type) {
          data.forEach(item => {
              const id = item.id;
              if (!groupedEmissions[id]) {
                  groupedEmissions[id] = {
                      gender_distribution: [],
                      age_distribution: []
                  };
              }
              groupedEmissions[id][type].push(item);
          });
      }
  
      // Process gender_distribution and age_distribution
      if (emissions.gender_distribution) {
          groupById(emissions.gender_distribution, 'gender_distribution');
      }
      
      if (emissions.age_distribution) {
          groupById(emissions.age_distribution, 'age_distribution');
      }
  
      // Convert the grouped emissions to a list of objects
      const result = Object.values(groupedEmissions);
      
      return result;
  }
  const emissionsListMap = extractEmissionsData(emissionData);
  return (
    <>
      {dataStatus === STATUS.SUCCESS ? (
        <Box>
          {emissionsListMap &&
            Array.isArray(emissionsListMap) &&
            emissionsListMap?.map((item, index) => (
            <Container className={classes.maincontainer}>
            <Container  className={classes.container}>
            <Grid container className={classes.HeaderContainer} spacing={2}>
            {true && 
                  <Box className={item?.gender_distribution[0]?.status == 'approved' ? classes.hireRatioAction : classes.hireRatioNoAction }>
                  <CeroInfoPair 
                  title="Status:"
                  value={item?.gender_distribution[0]?.status === "pending_approval" ? "Pending Approval" : item?.gender_distribution[0]?.status ? item?.gender_distribution[0]?.status : "NA"}
                  classes={{
                    container: classes.statusContainer,
                    title: classes.statusTitle,
                    value: item?.gender_distribution[0]?.status === "In Progress" || item?.gender_distribution[0]?.status === "pending_approval" ? classes.Inprogress : classes[item?.gender_distribution[0]?.status + "Status"]
                  }}
                />
                  {true && !readOnlyMode &&<Grid container spacing={2}>
                    <Grid item xs={6}>
                    {(item?.gender_distribution[0]?.status === "approved") && 
                  <div className={classes.actionButtons} onClick={() => handleIconClick(item?.gender_distribution[0]?.id)}>
                  <CheckCircleOutlineOutlinedIcon className={classes.icon} />
                  <CeroButton
                  variant="contained"
                  buttonText="Validate"
                  className={classes.approveButton}/></div>}
                  </Grid>
                  <Grid item xs={6}>
                  {(item?.gender_distribution[0]?.status === "approved") && 
                  <div className={classes.actionButtons} onClick={() => handleReviewClick(item?.gender_distribution[0]?.id)}>
                  <ArrowCircleLeftOutlinedIcon className={classes.icon} />
                  <CeroButton
                  variant="contained"
                  buttonText="Review"
                  className={classes.approveButton}/></div>}
                  </Grid>
                  </Grid>}
                  </Box>
                  }
                  
            <Typography variant="h7" component="span">
              <span className={classes.periodTitle}>
              Period - {item?.gender_distribution[0]?.assessment_period}
              </span>
            </Typography>
            </Grid>
          <Typography variant="h7" component="span">
              <span className={classes.tableSubTitle}>
              Employees Hire And Turnover by Gender
              </span>
              </Typography>
          <CeroTable
            columns={(approvalTable ? ApprovalEmployeeHireAndTurnover : EmployeeHireAndTurnover) || []}
            data={getGenderDistribution(item)}
            hasMore={false}
            loading={false}
            loadMore={onLoadMore}
            onSelectRow={approvalTable ? '#' :onSelectEmissionData}
            classes={{ tableContainer: classes.tableContainer }}
          />
          <Typography variant="h7" component="span">
              <span className={classes.tableSubTitle}>
              Employees Hire And Turnover by Age
              </span>
              </Typography>
          <CeroTable
            columns={(approvalTable ? ApprovalEmployeeHireAndTurnoverByAge : EmployeeHireAndTurnoverByAge) || []}
            data={getByAgeDistribution(item)}
            hasMore={false}
            loading={false}
            loadMore={onLoadMore}
            onSelectRow={approvalTable ? '#' : onSelectData}
            classes={{ tableContainer: classes.tableContainer }}
          />
        </Container>
        </Container>
            ))}
        </Box>

      ) : (
        <Box className={classes.loader}>
          <Typography variant="h7" component="span">
            {dataStatus === STATUS.RUNNING ? (
              <CircularProgress />
            ) : dataStatus === STATUS.ERROR ? (
              "Something went wrong. Please try again later"
            ) : (
              ""
            )}
          </Typography>
        </Box>

      )}
      {displayWarning && (
          <EmissionDeletePrompt
          isOpen={displayWarning}
          onClose={() => setDisplayWarning(false)}
          onConfirm={onConfirmDelete}
          setOpenDeletePrompt = {displayWarning}
        />
        )}
    </>
    
  );
};

export default EmployeeHireApproval;
