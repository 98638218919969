import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Paper, Typography } from "@mui/material";
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  ArcElement,
  registerables as registerablesJS,
} from "chart.js";
import { getEmployeeTurnoverGraph } from "../../../redux/actions";
import useStyles from "./styles";
import { getCookie } from "../../../services/cookie";
import Chart from "chart.js/auto";

ChartJS.register(...registerablesJS);
ChartJS.register(ArcElement, Tooltip, Legend);

const EmployeeTurnoverGraph = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const role = getCookie("role");
  const companyId = getCookie("companyId");
  const turnoverData = useSelector(
    (state) => state.dashboard.getEmployeeTurnoverGraph.data
  );

  useEffect(() => {
    !props.refreshContent && dispatch(getEmployeeTurnoverGraph(props.filter,role,companyId));
  }, [props.filter, dispatch]);

  const genderChartRef = useRef(null);
  const ageGroupChartRef = useRef(null);
  const genderChartInstance = useRef(null);
  const ageGroupChartInstance = useRef(null);

  useEffect(() => {
    if (!turnoverData || Object.keys(turnoverData).length === 0) return;

    if (genderChartInstance.current) {
      genderChartInstance.current.destroy();
    }
    if (ageGroupChartInstance.current) {
      ageGroupChartInstance.current.destroy();
    }

    const genderChartCtx = genderChartRef?.current?.getContext("2d");
    const ageGroupChartCtx = ageGroupChartRef?.current?.getContext("2d");
    const genderLabels = Object.keys(turnoverData.gender).map(label => {
      if (label === 'male') return 'Male';
      if (label === 'female') return 'Female';
      if (label === 'other') return 'Non-binary';
    });
    const ageLabels = Object.keys(turnoverData.age).map(label => {
      if (label === 'under_30') return 'Below 30 years';
      if (label === 'between_30_50') return '30-50 years';
      if (label === 'other_50') return 'Over 50 years';
    });

    const genderData = {
      labels: genderLabels,
      datasets: [
        {
          label: "Employee Turnover",
          data: Object.values(turnoverData.gender),
          backgroundColor: ["#846BB5"], 
          barThickness: 40,
        },
      ],
    };

    genderChartInstance.current = new Chart(genderChartCtx, {
      type: "bar",
      data: genderData,
      options: {
        plugins: { datalabels: { display: false },
        legend: {
          display: true,
          position: "bottom",
          align: "end",
          labels: {
            color: "black",
          },
        },
        callbacks: {
          label: (context) => {
            let label = '';
            if (context.parsed.y !== null) {
              label += context.parsed.y.toLocaleString();
            }
            return label;
          }
        }},
        scales: {
          x: { grid: { display: false },
          title: { display: true, text: "Gender", color: "black", font: { size: 12 } },
          ticks: { color: "black" } },
          y: {
            ticks: { callback: (value) => value ,  color: "black" , },
            title: { display: true, text: "Employee Turnover", color: "black", font: { size: 12 } },
            grid: {
              display: false,
            },
          },
        },
        maintainAspectRatio: true,
        responsive: true,
        layout: { padding: { left: 20, right: 20, bottom: 20 } },
      },
    });

    const ageGroupData = {
      labels: ageLabels,
      datasets: [
        {
          label: "Employee Turnover",
          data: Object.values(turnoverData.age),
          backgroundColor: [ "#FBCE74" ], 
          barThickness: 40,
        },
      ],
    };

    ageGroupChartInstance.current = new Chart(ageGroupChartCtx, {
      type: "bar",
      data: ageGroupData,
      options: {
        plugins: { datalabels: { display: false },
        legend: {
          display: true,
          position: "bottom",
          align: "end",
          labels: {
            color: "black",
          },
        },
        callbacks: {
          label: (context) => {
            let label = '';
            if (context.parsed.y !== null) {
              label += context.parsed.y.toLocaleString();
            }
            return label;
          }
        } },
        scales: {
          x: { grid: { display: false },
          title: { display: true, text: "Age Group", color: "black", font: { size: 12 } },
          ticks: { color: "black" }  },
          y: {
            ticks: { callback: (value) => value,  color: "black" },
            title: { display: true, text: "Employee Turnover", color: "black", font: { size: 12 } },
            grid: {
              display: false,
            },
          },
        },
        maintainAspectRatio: true,
        responsive: true, 
        layout: { padding: { left: 20, right: 20, bottom: 20 } },
      },
    });

    return () => {
      if (genderChartInstance.current) {
        genderChartInstance.current.destroy();
      }
      if (ageGroupChartInstance.current) {
        ageGroupChartInstance.current.destroy();
      }
    };
  }, [turnoverData]);
  return (
    <Paper className={classes.container}>
      {(role === "sustainability_manager" || role === "read_only_user" || role === "proxy_sustainability_manager" )&& (
        <>
          <Typography className={classes.graphTitle}>
            Employee Turnover by Gender & Age Group
          </Typography>
          <Grid spacing={2} display="flex">
            <Grid item xs={6}>
              <div className={classes.chart}>
              <canvas ref={genderChartRef} />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.chart}>
              <canvas ref={ageGroupChartRef} />
              </div>
            </Grid>
          </Grid>
        </>
      )}
    </Paper>
  );
};

export default EmployeeTurnoverGraph;
