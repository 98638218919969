import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import useStyles from "./styles";

const ReportTable = ({ data, columns, onSelectRow }) => {
  const classes = useStyles();
  const noTableRows = () => (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table className={classes.table} stickyHeader>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.columnId} className={classes.tableHeader}>
                {column.columnHeader}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
    <TableRow>
      <TableCell className={classes.noDataWarning} colSpan={columns.length}>
        <Typography>No data</Typography>
      </TableCell>
    </TableRow>
    </TableBody>
    </Table>
    </TableContainer>
  );

  const getStatus = (status) => {
    if (status === "audited") {
      return <Typography className={classes.audited}>Audited</Typography>;
    } else if (status === "unaudited") {
      return <Typography className={classes.unAudited}>Unaudited</Typography>;
    } else if (status === "verified") {
      return <Typography className={classes.verified}>Verified</Typography>;
    }else {
      return <Typography>{status}</Typography>;
    }
  };

  const flattenedData = data.reduce((acc, report) => {
    const { id, name, year, period, created_by_name, created_ts, countries } = report;
  
    let totalFacilities = 0;
    Object.values(countries).forEach((country) => {
      totalFacilities += country.facilities.length;
    });
  
    Object.keys(countries).forEach((country, countryIndex) => {
      const { facilities, status } = countries[country];
      facilities.forEach((facility, facilityIndex) => {
        acc.push({
          id: id,
          name: facilityIndex === 0 && countryIndex === 0 ? name : "",
          country: facilityIndex === 0 ? country : "", 
          facility: facility,
          year: facilityIndex === 0 && countryIndex === 0 ? year : "", 
          period: facilityIndex === 0 && countryIndex === 0 ? period : "", 
          created_by_name:
            facilityIndex === 0 && countryIndex === 0 ? created_by_name : "", 
          created_ts:
            facilityIndex === 0 && countryIndex === 0 ? created_ts : "", 
          status: facilityIndex === 0 ? status : "", 
          nameRowSpan: totalFacilities, 
          countryRowSpan: facilities.length, 
        });
      });
    });
  
    return acc;
  }, []);
  
  return flattenedData?.length > 0 ? (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table className={classes.table} stickyHeader>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.columnId} className={classes.tableHeader}>
                {column.columnHeader}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {flattenedData.map((row, index) => (
            <TableRow key={row.id}
            onClick={() =>
                onSelectRow ? onSelectRow(row) : console.log(row)}>
              {row.name && (
                <TableCell
                  className={classes.tableCell}
                  rowSpan={row.nameRowSpan}
                >
                  {row.name}
                </TableCell>
              )}
              {row.country && (
                <TableCell
                  className={classes.tableCell}
                  rowSpan={row.countryRowSpan}
                >
                  {row.country}
                </TableCell>
              )}
              <TableCell className={classes.tableCell}>
                {row.facility}
              </TableCell>
              {row.year && (
                <TableCell
                  className={classes.tableCell}
                  rowSpan={row.nameRowSpan}
                >
                  {row.year}
                </TableCell>
              )}
              {row.period && (
                <TableCell
                  className={classes.tableCell}
                  rowSpan={row.nameRowSpan}
                >
                  {row.period}
                </TableCell>
              )}
              {row.created_by_name && (
                <TableCell
                  className={classes.tableCell}
                  rowSpan={row.nameRowSpan}
                >
                  {row.created_by_name}
                </TableCell>
              )}
              {row.created_ts && (
                <TableCell
                  className={classes.tableCell}
                  rowSpan={row.nameRowSpan}
                >
                  {dayjs(row.created_ts).format("DD MMM YYYY HH:mm")}
                </TableCell>
              )}
              {row.status && (
                <TableCell
                  className={classes.tableCell}
                  rowSpan={row.countryRowSpan}
                >
                  {getStatus(row.status)}
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    noTableRows()
  );
};

export default ReportTable;
