import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Grid,
  Typography,
  Box,
} from "@mui/material";
import dayjs from "dayjs";
import useStyles from "./styles";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const AgeBasedStatistics = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {
    emissionData,
  } = props;

  return (
    <>
    <div className={classes.backContainer} onClick={()=>{navigate(-1)}}>
      <ArrowBackIosIcon />
      Back
    </div>
    <Container className={classes.container}>
      <Box className={classes.innerContainer}>
        <Box className={classes.header}>
          <Typography variant="h6" component="div">
            Age Based Statistics
          </Typography>
        </Box>
        <Box className={classes.topContainer}>
          <Grid
            container
            direction={"row"}
            wrap="nowrap"
            justifyContent={"space-between"}
            spacing={12}
          >
            <Grid item container direction={"column"} xs={10}>
              <Typography className={classes.previewItem}>
                Content: {emissionData.content}
              </Typography>
              <Typography className={classes.previewItem}>
                Date: {emissionData.date}
              </Typography>
              <Typography className={classes.previewItem}>
                Status: {emissionData.status}
              </Typography>
              <Typography className={classes.previewItem}>
                Audited on: {emissionData.audited_by}
              </Typography>
              <Typography className={classes.previewItem}>
                Audited by:{" "}
                {emissionData.audited_on
                  ? dayjs(emissionData.audited_on).format("DD/MM/YYYY")
                  : ""}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
    </>
  );
};
export default AgeBasedStatistics;
