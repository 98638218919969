import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Grid,
  Typography,
  Box,
} from "@mui/material";

import dayjs from "dayjs";
import useStyles from "./styles";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CeroItemPair from "../../../../../components/CeroItemPair";
import CeroInfoPair from "../../../../../components/CeroInfoPair";

const HumanRightsTraining = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {
    emissionData,
  } = props;

  return (
    <>
      <div
        className={classes.backContainer}
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowBackIosIcon />
        Back
      </div>
      <Container className={classes.container}>
        <Box className={classes.innerContainer}>
          <Box className={classes.header}>
            <Typography variant="h6" component="div">
              Human Rights
            </Typography>
          </Box>
          <Box className={classes.topContainer}>
            <Grid
              container
              direction={"row"}
              wrap="nowrap"
              justifyContent={"space-between"}
              spacing={12}
            >
              <Grid item container direction={"column"} xs={10}>
                <Box className={classes.actionBox}>
                  <CeroInfoPair
                    title="Status:"
                    value={emissionData.status ? emissionData.status : "NA"}
                    classes={{
                      container: classes.statusContainer,
                      title: classes.statusTitle,
                      value:
                        emissionData.status === "In Progress"
                          ? classes.Inprogress
                          : classes[emissionData?.status],
                    }}
                  />
                </Box>
                <CeroItemPair
                  title="Facility:"
                  value={
                    emissionData.facility_name
                      ? emissionData.facility_name
                      : "NA"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.supplierHumanTitle,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Period:"
                  value={
                    emissionData.assessment_period
                      ? emissionData.assessment_period
                      : "NA"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.supplierHumanTitle,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Year:"
                  value={emissionData.year ? emissionData.year : "NA"}
                  classes={{
                    container: classes.infoContainer,
                    title: classes.supplierHumanTitle,
                    value: classes.value,
                  }}
                />
                {/* <CeroItemPair
                  title="Name of Supplier:"
                  value={
                    emissionData.supplier_name
                      ? emissionData.supplier_name
                      : "NA"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.supplierHumanTitle,
                    value: classes.value,
                  }}
                /> */}
                {emissionData.risk_of_child_labor === "Yes" && (
                  <CeroItemPair
                    title="Risk of Child Labour:"
                    value={
                      emissionData.risk_of_child_labor
                        ? emissionData.risk_of_child_labor
                        : "NA"
                    }
                    classes={{
                      container: classes.infoContainer,
                      title: classes.supplierHumanTitle,
                      value: classes.value,
                    }}
                  />
                )}
                <CeroItemPair
                  title="Child Labour risk explanation:"
                  value={
                    emissionData.child_labor_details
                      ? emissionData.child_labor_details
                      : "NA"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.supplierHumanTitle,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Risk of Forced or Compulsory Labour:"
                  value={
                    emissionData.risk_of_compulsory_labor
                      ? emissionData.risk_of_compulsory_labor
                      : "NA"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.supplierHumanTitle,
                    value: classes.value,
                  }}
                />
                {emissionData.risk_of_compulsory_labor === "Yes" && (
                  <CeroItemPair
                    title="Forced Labour risk explanation:"
                    value={
                      emissionData.compulsory_labor_details
                        ? emissionData.compulsory_labor_details
                        : "NA"
                    }
                    classes={{
                      container: classes.infoContainer,
                      title: classes.supplierHumanTitle,
                      value: classes.value,
                    }}
                  />
                )}
                <CeroItemPair
                  title="Incidents and Remediation actions:"
                  value={
                    emissionData.incident_or_action_occurred
                      ? emissionData.incident_or_action_occurred
                      : "NA"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.supplierHumanTitle,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Audited on:"
                  value={
                    emissionData.audited_by ? emissionData.audited_by : "NA"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.supplierHumanTitle,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Audited by:"
                  value={
                    emissionData.audited_on
                      ? dayjs(emissionData.audited_on).format("DD/MM/YYYY")
                      : "NA"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.supplierHumanTitle,
                    value: classes.value,
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default HumanRightsTraining;
