import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Box, CircularProgress, Container, Pagination, Typography } from "@mui/material";

import DashboardLayout from "../../../../layouts/DashboardLayout";
import { clearEmissionList, getReportListTopics } from "../../../../redux/actions";
import { sampleYearArray } from "../../../../constants";
import { rolesEnum } from "../../../../layouts/DashboardLayout/pages";
import EmissionTable from "./EmissionTable";
import useStyles from "./styles";
import { STATUS } from "../../../../redux/constants";
import EmployeeHireTable from "./EmployeeHireTable";
import EmployeesDiversityTable from "./EmployeesDiversityTable";
import BoardDiversityTable from "./BoardDiversity/BoardDiversityTableByGender";
import ManagementDiversityTable from "./ManagementDiversity/ManagementDiversityTable";
import CeroButton from "../../../../components/CeroButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const DEFAULT_ITEMS_PER_PAGE = 20;

const ReportEmissionList = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const { type } = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const selectedMonth = queryParams.get("month");
  const selectedFacility = queryParams.get("facility");
  const selectedYear = queryParams.get("year");
  const company = queryParams.get("company");
  const period = queryParams.get("period");
  const country = queryParams.get("country");
  const distribution = queryParams.get("distribution");
  const emissionData = useSelector((state) => state.reports.reportEmissionList.data.emissions);
  const totalEntries = useSelector(
    (state) => state.reports.reportEmissionList.data.total_count
  );
  const emissionDataStatus = useSelector(
    (state) => state.reports.reportEmissionList.status
  );
  const role = useSelector((state) => state.auth.role);
  const isAuditor = role.includes(rolesEnum.AUDITOR);
  const isOnlyAdmin = (role.includes(rolesEnum.ADMIN) && role.length === 1) || role==="admin";

  const emissionType = type;
  const [page, setPage] = useState(1);
  var x = sessionStorage.getItem("year");
  const [filter, setFilter] = useState({
    month: selectedMonth,
    year: selectedYear || Number(x) || sampleYearArray[0].key,
    facility_id: selectedFacility,
  });

  useEffect(() => {
    !emissionType
      ? navigate("stationary_combustion")
      : onLoadMore(DEFAULT_ITEMS_PER_PAGE, page);
    return () => {
      clearEmissionList();
    };
  }, [emissionType]);

  useEffect(() => {
    onLoadMore(DEFAULT_ITEMS_PER_PAGE, page);
    return () => {
      clearEmissionList();
    };
  }, [filter]);

  const onLoadMore = (limit, pageNumber) => {
    const skip =(pageNumber - 1) * 20;
    const newLimit = DEFAULT_ITEMS_PER_PAGE *  pageNumber;    
    const filterRequest = {
      limit: newLimit,
      skip,
      month: selectedMonth,
      year: selectedYear || Number(x) || sampleYearArray[0].key,
      facility_id: selectedFacility,
      period:period,
      country:country
    };
    if (!filterRequest.facility_id) {
      delete filterRequest.facility_id;
    }
    dispatch(
      getReportListTopics(
        emissionType,
        filterRequest,
        isAuditor,
        company,
        typeof selectedFacility === "string"
          ? selectedFacility === "null"
            ? null
            : selectedFacility.split(",")
          : selectedFacility,
        period,
        country, 
        distribution
      )
    );
  };

  const [tableShow, setTableShow] = useState(false);

  const backToEmission = (tableShow) => {
    setTableShow(tableShow);
  }

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    onLoadMore(DEFAULT_ITEMS_PER_PAGE,newPage);
  };


  const onSelectEmissionData = (emission) => {
    navigate(`/reports/list-topic/${emissionType}/details/${emission.id}`);
    };

  const startItem = (page - 1) * DEFAULT_ITEMS_PER_PAGE + 1;
  const endItem = Math.min(startItem + DEFAULT_ITEMS_PER_PAGE - 1, totalEntries);
const getHeader = () => {
  return (
      <Box className={classes.tableTopic}>
        <Box>
        <CeroButton
          buttonText="Back"
          variant="outlined"
          className={classes.buttonSecondary}
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate(-1)}
        />
        </Box>
      </Box>
  )
}
  return (
    <DashboardLayout>
      <Container className={classes.container}>

      {emissionDataStatus === STATUS.SUCCESS ? (
  <>
    {emissionType === "employees_hire_and_turnover" ? (
      <>{getHeader()}
      <EmployeeHireTable 
        emissionData={emissionData}
        dataStatus={emissionDataStatus}
        onLoadMore={onLoadMore}
        emissionType={emissionType}
        isAuditor={isAuditor}
        company={company}
        backToEmission={backToEmission}
        isOnlyAdmin={!isOnlyAdmin}
        onSelectRow={onSelectEmissionData}
        period={period}
        distribution={distribution}
      /></>
    ) : (
      emissionType === "employees_diversity_and_ratio_of_basic_salary" ? (
        <>{getHeader()}
        <EmployeesDiversityTable 
      emissionData={emissionData}
      dataStatus={emissionDataStatus}
      onLoadMore={onLoadMore}
      emissionType={emissionType}
      onSelectRow={onSelectEmissionData}
      isAuditor={isAuditor}
      company={company}
      backToEmission={backToEmission}
      isOnlyAdmin={!isOnlyAdmin}
      period={period}
      distribution={distribution}
      /></>
      ) : emissionType === "board_diversity" ? (
        <>{getHeader()}
        <BoardDiversityTable 
      emissionData={emissionData}
      dataStatus={emissionDataStatus}
      onLoadMore={onLoadMore}
      emissionType={emissionType}
      isAuditor={isAuditor}
      company={company}
      backToEmission={backToEmission}
      isOnlyAdmin={!isOnlyAdmin}
      onSelectRow={onSelectEmissionData}
      period={period}
      distribution={distribution}
      />
      </>
      ):emissionType === "management_diversity" ? (
        <>{getHeader()}
        <ManagementDiversityTable 
      emissionData={emissionData}
      dataStatus={emissionDataStatus}
      onLoadMore={onLoadMore}
      emissionType={emissionType}
      isAuditor={isAuditor}
      company={company}
      backToEmission={backToEmission}
      isOnlyAdmin={!isOnlyAdmin}
      onSelectRow={onSelectEmissionData}
      period={period}
      distribution={distribution}
      /></>
      ): (
        <>
          <EmissionTable
            emissionData={emissionData}
            dataStatus={emissionDataStatus}
            onLoadMore={onLoadMore}
            emissionType={emissionType}
            isAuditor={isAuditor}
            company={company}
            onSelectRow={onSelectEmissionData}
            backToEmission={backToEmission}
            isOnlyAdmin={!isOnlyAdmin}
          />
          {totalEntries > 0 && (
            <div className={classes.pagination}>
              <Typography variant="body" style={{ fontSize: 12 }}>
                Showing {startItem} to {endItem} of {totalEntries} entries
              </Typography>
              <Pagination
                count={Math.ceil(totalEntries / DEFAULT_ITEMS_PER_PAGE)} 
                page={page}
                color="primary"
                onChange={handlePageChange}
              />
            </div>
          )}
        </>
      )
    )}
  </>
) : (  
  <div className={classes.loading}>
    <CircularProgress />
  </div>
)}

      </Container>
    </DashboardLayout>
  );
};

export default ReportEmissionList;