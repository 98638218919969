import _ from 'lodash'
import { getCookie } from './cookie';

export const getMonth = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December'
}

export const getYears = (start = 1900, end = new Date().getFullYear()) => {
    const years = [];
    for(let i = end; i>= start; i--) {
        years.push({ id: i, label: i});
    }
    return years;
}

export const stringifyQuery = (params = {}, replacer) => {
    if (!_.isFunction(replacer))
      replacer = (key, value) =>
        value != null
          ? _.isArray(value)
            ? value.map(v => ({key, value: v}))
            : {key, value}
          : null
  
    return Object.keys(params)
      .reduce((pairs, key) => pairs.concat(replacer(key, params[key]) || []), [])
      .map(({key, value}) => [key, value].map(encodeURIComponent).join('='))
      .join('&')
  }

export const convertToSnakeCaseParams = (payload) => {
  const returnPayload = {};
  for (const key in payload) {
    if (Object.prototype.hasOwnProperty.call(payload, key)) {
      const snakeCaseKey = key.replace(/[A-Z]/g, (match) => `_${match.toLowerCase()}`);
      returnPayload[snakeCaseKey] = payload[key];
    }
  }

  return returnPayload;
}

export function capitalizeFirstLetterOfEachWord(str) {
  const words = str?.split(' ');

  for (let i = 0; i < words?.length; i++) {
    words[i] = words[i]?.charAt(0)?.toUpperCase() + words[i]?.slice(1);
  }

  return words?.join(' ');
}

export const getPrefixRole = (role) => {
  const roleMapping = {
    super_admin: "admin",
    read_only_user: "stakeholder",
    auditor: "auditor",
    sustainability_manager: "business",
    business_user: "business",
    approver: "business",
    facility_manager: "business",
    proxy_sustainability_manager: "business",
    trade_admin:"trade"
  };
  return roleMapping[role];
};