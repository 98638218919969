import { useEffect, useState } from "react"
import { Container } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"

import DashboardLayout from "../../layouts/DashboardLayout"
import AddFacilityDrawer from "./AddFacilityDrawer"
import FacilitiesHeader from "./FacilitiesHeader"
import FacilitiesTable from "./FacilitiesTable"
import { getCountryList } from '../../redux/actions';
import {listFacilities} from '../../redux/actions';

import useStyles from './styles'

const Facilities = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [countryName, setCountryName] = useState('');
    const [search, setSearch] = useState('');
    const onApplyFilter = (filter) => {
        setCountryName(filter.filterCountry);
        setSearch(filter.searchText);
        
    };

    const facilityList = useSelector(state => state.listings.listFacilities.data)
    
    const countryListData = useSelector(state => state.listings.countryList.data)
    
    const countryList = countryListData.map(item => ({ id: item.code, label: item.name }));

    const [isOpenAddFacility, setOpenAddFacility] = useState(false)
    const [editFacilityItem, setEditFacilityItem] = useState(null)

    const handleDrawerClose = () => {
        setOpenAddFacility(false)
        setEditFacilityItem(null)
    }
    useEffect(() => {
        dispatch(getCountryList())
    }, [dispatch])


    useEffect(() => {
        dispatch(listFacilities(countryName, search))
    }, [dispatch, countryName, search])

    return (
        <DashboardLayout>
            <Container className={classes.container}>
                <FacilitiesHeader countryList={countryList} onAddData={() => setOpenAddFacility(true)} onApplyFilter={onApplyFilter} />
                <FacilitiesTable facilitiesData={facilityList} onClickEdit={(item) => setEditFacilityItem(item)} />
                {(isOpenAddFacility || editFacilityItem) &&
                    <AddFacilityDrawer
                        isOpen={isOpenAddFacility || editFacilityItem}
                        onClose={handleDrawerClose}
                        editItem={editFacilityItem}
                    />
                }
            </Container>
        </DashboardLayout>
    )
}

export default Facilities
