import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";

const TicketStatusChart = ({ apiResponse }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const ticketData = apiResponse;
    const statusInfo = {
      OPEN: { legend: "Open", color: "#846BB5" },
      CLOSED: { legend: "Closed", color: "#6FBFBA" }
    };

    const numberOfDataPoints = ticketData.length;
    const maxBarThickness = 30;
    const minBarThickness = 7;
    const calculatedBarThickness = Math.max(minBarThickness, maxBarThickness - (numberOfDataPoints - 10) * 2);

    const datasets = Object.keys(statusInfo).map((status) => {
      const statusInfoEntry = statusInfo[status] || {
        legend: status,
        color: getRandomColor(),
      };
      return {
        label: statusInfoEntry.legend,
        data: ticketData.map(
          (monthData) => monthData[`count of ${status} statuses`] || 0
        ),
        backgroundColor: statusInfoEntry.color,
        barThickness: calculatedBarThickness,
      };
    });

    function getRandomColor() {
      const letters = "0123456789ABCDEF";
      let color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    }

    const data = {
      labels: ticketData.map((monthData) => monthData.month),
      datasets,
    };
    const maxDataValue = Math.max(...datasets.flatMap(dataset => dataset.data));
    
    const yAxisMax = maxDataValue + 10;
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          title: {
            display: true,
            text: "Month",
            font: {
              weight: "500",
              family: "Inter",
            },
          },
          type: "category",
          grid: {
            display: false,
          },
          ticks: {
            color: "black",
            font: {
              weight: "400",
              family: "Inter",
            },
          },
        },
        y: {
          title: {
            display: true,
            text: "Number of Tickets",
            font: {
              weight: "500",
              family: "Inter",
            },
          },
          grid: {
            display: false,
          },
          ticks: {
            precision: 0,
            color: "black",
            font: {
              weight: "400",
              family: "Inter",
            },
          },
          suggestedMax: yAxisMax,
        },
      },
      plugins: {
        legend: {
          position: "bottom",
          labels: {
            boxWidth: 15,
            padding: 20,
            color: "black",
            font: {
              family: "Inter",
            },
          },
        },
        datalabels: {
          color: "black",
          formatter: (value) => (value !== 0 ? value.toFixed(0) : ''),
          anchor: "end",
          align: "top",
          rotation: "270",
          font: {
            family: "Inter",
          },
        },
      },
      layout: {
        padding: {
          bottom: 20,
        },
      },
    };

    const ctx = chartRef.current.getContext("2d");

    if (chartRef.current.chart) {
      chartRef.current.chart.destroy();
    }

    chartRef.current.chart = new Chart(ctx, {
      type: "bar",
      data: data,
      options: options,
      plugins: [ChartDataLabels],
    });
  }, [apiResponse]);

  return <canvas ref={chartRef} height={286} />;
};

export default TicketStatusChart;
