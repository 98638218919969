import { createActions } from "redux-actions";

import { ActionTypes } from "../constants/actions";

export const {
  listUsers,
  addUser,
  getUserDetails,
  editUser,
  deleteUser,
  resetUserStatus,
  setEmailConfirmed,
  clearEmailConfirmed,
  getAssignedStakeholders,
  updateStakeholderStatus,
  resetUpdateStakeholderStatus,
  editUserStakeholder,
  getReadOnlyUserAssignedReports,
  revokeGrantReportAccess,
  resetRevokeGrantReportAccess,
  showSwitchUserModal,
  proxyLoginAccessSuperadmin,
  resetProxyLoginAccessSuperadmin,
  getUserLogs,
  lockUnlockUser,
  getRecentLogs,
  getSubscriptionDetails,
  resetListUsers,
  walletAddress
} = createActions({
  [ActionTypes.LIST_USERS]: () => ({}),
  [ActionTypes.ADD_USER]: ( requestData ) => ({ requestData }),
  [ActionTypes.GET_USER_DETAILS]: (userId) => ({ userId }),
  [ActionTypes.EDIT_USER]: (userId, requestData) => ({
    userId,
    requestData
  }),
  [ActionTypes.DELETE_USER]: (userId) => ({ userId }),
  [ActionTypes.RESET_USER_STATUS]: () => ({}),
  [ActionTypes.SET_EMAIL_CONFIRMED]: (userId, code, userType) => ({
    userId,
    code,
    userType,
  }),
  [ActionTypes.CLEAR_EMAIL_CONFIRMED_FAILURE]: () => ({}),
  [ActionTypes.GET_ASSIGNED_STAKEHOLDERS]: () => ({}),
  [ActionTypes.UPDATE_STAKEHOLDER_STATUS]: (requestData) => ({ requestData }),
  [ActionTypes.RESET_UPDATE_STAKEHOLDER_STATUS]: () => ({}),
  [ActionTypes.EDIT_USER_STAKEHOLDER]: ( userId, requestData ) => ({ userId, requestData }),
  [ActionTypes.GET_READ_ONLY_USER_ASSIGNED_REPORTS]: (id) => ({ id }),
  [ActionTypes.REVOKE_GRANT_REPORT_ACCESS]: (requestData) => ({ requestData }),
  [ActionTypes.RESET_REVOKE_GRANT_REPORT_ACCESS]: () => ({}),
  [ActionTypes.SHOW_SWITCH_USER_MODAL]: (showSwitchUserModal) => ({
    showSwitchUserModal,
  }),
  [ActionTypes.PROXY_LOGIN_ACCESS_SUPERADMIN]: (userId, status) => ({
    userId,
    status,
  }),
  [ActionTypes.RESET_PROXY_LOGIN_ACCESS_SUPERADMIN]: () => ({}),
  [ActionTypes.GET_USER_LOGS]: (userId) => ({ userId }),
  [ActionTypes.LOCK_UNLOCK_USER]: (userId, status, data) => ({
    userId,
    status,
    data,
  }),
  [ActionTypes.GET_RECENT_LOGS]: (userId) => ({ userId }),
  [ActionTypes.GET_SUBSCRIPTION_DETAILS]: (role,companyId) => ({ role,companyId }),
  [ActionTypes.RESET_LIST_USERS]: () => ({}),
  [ActionTypes.WALLET_ADDRESS]: (walletAddress) => ({ walletAddress }),
});
