import * as yup from "yup";

export const createReportValidation = yup.object({
  name: yup
    .string("Report name is required")
    .required("Report name is required"),
  // framework: yup
  //   .string("Report framework is required")
  //   .required("Please fill ticket framework"),
  year: yup
    .number()
    .typeError('Year must be a valid number')
    .required('Year is required')
    .strict(true),
  topic: yup
    .array()
    .min(1)
    .required("Report topic is required"),
  star_month: yup
    .mixed()
    .nullable(true),
  end_month: yup
    .mixed()
    .nullable(true)
    .test('check-months', 'Duration selection should be completed', function(value) {
      const { start_month } = this.parent;
      return (start_month && value) || (!start_month && !value)
      // return (start_month !== undefined && start_month !== null) || (value !== undefined && value !== null);
    }),
});
