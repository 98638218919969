import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import useStyles from "./styles";
import { useNavigate, useParams } from "react-router-dom";
import { setCookie } from "../../../../services/cookie";

export const columns = [
  {
    columnKey: "country",
    columnId: "country",
    columnHeader: "Country",
    width: "10vw",
  },
  {
    columnKey: "facility",
    columnId: "facility",
    columnHeader: "Facility",
    width: "10vw",
  },
  {
    columnKey: "totalUsers",
    columnId: "totalUsers",
    columnHeader: "Total Users",
    width: "15vw",
  },
];

const TotalUsersTable = ({ data }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { companyId } = useParams();

  const noTableRows = () => (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table className={classes.table} stickyHeader>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.columnId} className={classes.tableHeader}>
                {column.columnHeader}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell
              className={classes.noDataWarning}
              colSpan={columns.length}
            >
              <Typography>No data</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );

  const flattenedData = Object.keys(data).reduce((acc, country) => {
    const facilities = data[country];
    facilities.forEach((facility, index) => {
      acc.push({
        country: country,
        facility: facility.facility_name,
        totalUsers: facility.total_user,
        facilityId: facility.facility_id,
        countryRowSpan: index === 0 ? facilities.length : 0, 
      });
    });
    return acc;
  }, []);

  const onSelectUser = (row) => {    
    setCookie('facility_name', row.facility)    
    navigate(`/companies/${companyId}/country/${row.country}/facilities/${row.facilityId}`)
  }

  return flattenedData.length > 0 ? (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table className={classes.table} stickyHeader>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.columnId} className={classes.tableHeader}>
                {column.columnHeader}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {flattenedData.map((row, index) => (
            <TableRow key={index}>
              {row.countryRowSpan > 0 && (
                <TableCell
                  className={classes.tableCell}
                  rowSpan={row.countryRowSpan}
                >
                  {row.country}
                </TableCell>
              )}
              <TableCell className={classes.tableCell} onClick={()=>onSelectUser(row)}>{row.facility}</TableCell>
              <TableCell className={classes.tableCell} onClick={()=>onSelectUser(row)}>
                {row.totalUsers !== null ? row.totalUsers : ""}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    noTableRows()
  );
};

export default TotalUsersTable;
