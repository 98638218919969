import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    width: "100%",
    maxWidth: "100%",
  },
  childContainer: {
    backgroundColor: "#EDFFF8 !important",
    padding: theme.spacing(0, 2, 0, 5),
  },
  card: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: theme.spacing(4),
    width: "99%",
    gap: 20,
    borderRadius: 8,
    boxShadow:
      "0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), -4px 0px 4px rgba(0, 0, 0, 0.05)",
  },
  cardItem: {
    width: "20%",
    margin: 0,
  },
  cardsContainer: {
    marginBottom: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
  },
  gridContainer: {
    height: "calc(100% - 64px)",
    marginBottom: theme.spacing(5),
    marginTop: theme.spacing(4),
  },
  buttonPrimary: {
    background: theme.palette.trade.button,
    textTransform: "capitalize",
    height: 35,
    borderColor: theme.palette.trade.button,
    marginRight: 15,
    fontWeight: 400,
    color: theme.palette.background.white,
    "&:hover": {
      backgroundColor: theme.palette.trade.header,
      borderColor: theme.palette.trade.header,
      color: theme.palette.Primary.light,
    },
  },
  title: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tableContainer: {
    maxHeight: "calc(100vh - 144px)",
  },
  headerCell: {
    height: 70,
    fontSize: 14,
    fontWeight: 600,
    padding: theme.spacing(1),
    color: "#333333",
    backgroundColor: "#BAC6C6 !important",
    "&:first-child": {
      paddingLeft: theme.spacing(4),
    },
    "&:last-child": {
      paddingRight: theme.spacing(4),
    },
  },
  subTitle:{
    fontWeight: 600,
    margin: theme.spacing(4,0,3,2 ),
  },
  widgetBox: {
    display: "inline-flex",
    alignItems: "center",
    textAlign: "center",
    width: "100%",
    margin: theme.spacing(5,0,5,14),
  },
  valueBox: {
    width: "220px",
    height: "120px",
    backgroundColor: "#D6E7E8",
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  symbol: {
    fontWeight: 600,
    margin: theme.spacing(0, 10),
  },
  companyName:{
    fontWeight: 600,
    padding: theme.spacing(5,0,0,10),
  },
  carbonStatus:{
    fontWeight: 500,
    marginRight: theme.spacing(10),
    paddingRight: theme.spacing(5),
  },
  widgetTitle: {
    fontSize: 14,
    fontWeight: 600,
    marginBottom: theme.spacing(2)
  },
  unit:{
    fontSize: 12,
    marginTop: theme.spacing(2),
  },
  carbonNegative: {
    color: "#E81111",
    fontWeight: 600,
  },
  carbonPositive: {
    color: theme.palette.status.closed,
    fontWeight: 600,
  }
}));

export default useStyles;
