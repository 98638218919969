import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { STATUS } from "../../../redux/constants";
import {
  resetSuperAdminStatus,
  getTotalCompanies,
  getCountryList,
  updateAnnouncement,
  getAnnouncement,
  listAnnouncement,
} from "../../../redux/actions";
import CeroInput from "../../../components/CeroInput";
import useStyles from "./styles";
import CeroSelect from "../../../components/CeroSelect";
import CeroDateTime from "../../../components/CeroDateTime";
import DashboardLayout from "../../../layouts/DashboardLayout";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate, useParams } from "react-router-dom";
import CeroButton from "../../../components/CeroButton";
import { notificationValidation } from "./../AddNotification/schema";
import CeroSearchWithMultiselect from "../../../components/CeroSearchWithMultiselect";

const EditNotification = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const navigate = useNavigate();
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const notificationData = useSelector(
    (state) => state.superAdmin.getAnnouncement.data
  );
  const editNotificationStatus = useSelector(
    (state) => state.superAdmin.updateAnnouncement
  );
  const countryListData = useSelector(
    (state) => state.listings.countryList.data
  );
  const totalCompanies = useSelector(
    (state) => state.company.totalCompanies.data
  );

  useEffect(() => {
    dispatch(getCountryList());
    dispatch(getTotalCompanies());
  }, [dispatch]);

  const countryList = countryListData?.map((item) => ({
    key: item.code,
    value: item.name,
    title: item.name,
  }));

  // const companyListOption = totalCompanies?.map((item) => ({
  //   key: item.company_id,
  //   value: item.company_name,
  //   title: item.company_name,
  // }));

  const uniqueCompanies = [];
  const seenKeys = new Set();
  const seenValues = new Set();
  
  totalCompanies?.forEach((item) => {
    if (!seenKeys.has(item.company_id) && !seenValues.has(item.company_name)) {
      uniqueCompanies.push(item);
      seenKeys.add(item.company_id);
      seenValues.add(item.company_name);
    }
  });
  
  const companyListOption = uniqueCompanies.map((item) => ({
    key: item.company_id,
    value: item.company_name,
    title: item.company_name,
  }));

  const companyNames = companyListOption?.filter(item => notificationData?.company_id?.includes(item.key));
  const countryNames = countryList?.filter(item => notificationData?.country?.includes(item.key));

  useEffect(() => {
    if (notificationData) {
      setSelectedCountry(countryNames);
      setSelectedCompany(companyNames);
    }
  }, [notificationData]);

  const notificationForm = useFormik({
    initialValues: {
      notificationType: notificationData.type_of_notification || "",
      messageType: notificationData.type_of_message || "",
      message: notificationData.message || "",
      startTime: notificationData.start_ts || 0,
      endTime: notificationData.end_ts || 0,
      messageStatus: notificationData.status || "",
      company: notificationData.company_id || [],
      country:  notificationData.country || [],
    },
    validationSchema: notificationValidation,
    enableReinitialize: true,
    onSubmit: () => {},
  });

  
  useEffect(() => {
    if (id) {
      dispatch(getAnnouncement(id));
    } else {
      notificationForm.setValues({
        notificationType: "",
        messageType: "",
        message: "",
        startTime: 0,
        endTime: 0,
        messageStatus: "",
        country: [],
        company: [],
      });
      dispatch(resetSuperAdminStatus());
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (editNotificationStatus.status === STATUS.SUCCESS) {
      enqueueSnackbar("Announcement updated successfully", {
        variant: "success",
        autoHideDuration: 3000,
      });
      dispatch(listAnnouncement());
      dispatch(resetSuperAdminStatus());
      navigate("/notifications");
    } else if (editNotificationStatus.status === STATUS.ERROR) {
      enqueueSnackbar(editNotificationStatus.message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      dispatch(resetSuperAdminStatus());
    }
  }, [editNotificationStatus, navigate, enqueueSnackbar, dispatch]);

  const onUpdateData = () => {
    const requestData = {
      id: id,
      message: notificationForm.values.message,
      country: selectedCountry?.map(country => country.key) || notificationData.country, 
      company_id: selectedCompany?.map(company => company.key) || notificationData.company_id,
      start_ts: notificationForm.values.startTime,
      end_ts: notificationForm.values.endTime,
      status: notificationForm.values.messageStatus,
      type_of_notification: notificationForm.values.notificationType,
      type_of_message: notificationForm.values.messageType,
    };    
    if (id) {
      dispatch(updateAnnouncement(requestData));
    }
  };

  const handleCountryChange = (value) => { 

    setSelectedCountry(value);
    notificationForm.setFieldValue("country", value.map(country => country.key)); 
    notificationForm.setFieldTouched("country", true);
  };

  const companyHandleChange = (value) => {    
    setSelectedCompany(value); 
    notificationForm.setFieldValue("company", value.map(company => company.key));
    notificationForm.setFieldTouched("company", true)
  };

  const messageTypes = [
    { key: "news", value: "News" },
    { key: "outage", value: "Outage" },
    { key: "release_update", value: "Release Update" },
    { key: "emergency", value: "Emergency" },
  ];

  const notificationTypes = [
    { key: "prompt", value: "Prompt" },
    { key: "scroll", value: "Scroll" },
    { key: "status_bar", value: "Status Bar" },
  ];

  const messageStatuses = [
    { key: "enable", value: "Enable" },
    { key: "disable", value: "Disable" },
  ];
  
  const filteredNotificationTypes =
  notificationForm.values.messageType === "news"
    ? notificationTypes.filter((type) => type.key === "scroll")
    : notificationTypes;

    const handleClear = () => {
      setSelectedCountry(countryNames);
      setSelectedCompany(companyNames);
      notificationForm.resetForm();
    }

    
  return (
    <DashboardLayout>
      <div
        className={classes.backContainer}
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowBackIosIcon />
        Back
      </div>
      <Container className={classes.container}>
        <Box className={classes.innerContainer}>
          <Typography className={classes.title} variant="h6" component="div">
            Edit Notification
          </Typography>
          <Box className={classes.topContainer}>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column" md={6} xs={12}>
                <CeroSelect
                  required
                  id="messageType"
                  name="messageType"
                  label="Message Type"
                  fullWidth
                  options={messageTypes}
                  selectedValue={notificationForm.values.messageType}
                  onChange={notificationForm.handleChange}
                  onBlur={notificationForm.handleBlur}
                  error={
                    notificationForm.touched.messageType &&
                    notificationForm.errors.messageType
                  }
                />
              </Grid>
              <Grid item container direction="column" md={6} xs={12}>
                <CeroSelect
                  required
                  id="notificationType"
                  name="notificationType"
                  label="Exhibit Type"
                  fullWidth
                  options={filteredNotificationTypes}
                  selectedValue={notificationForm.values.notificationType}
                  onChange={notificationForm.handleChange}
                  onBlur={notificationForm.handleBlur}
                  error={
                    notificationForm.touched.notificationType &&
                    notificationForm.errors.notificationType
                  }
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={2}
            >
              <Grid item container direction="column" md={12} xs={12}>
                <CeroInput
                  required
                  id="message"
                  name="message"
                  label="Message"
                  fullWidth
                  multiline
                  rows={4}
                  value={notificationForm.values.message}
                  onChange={notificationForm.handleChange}
                  onBlur={notificationForm.handleBlur}
                  error={
                    notificationForm.errors.message &&
                    notificationForm.touched.message
                  }
                  classes={{ container: classes.textAreaContainer }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column" md={6} xs={12}>
              <CeroSearchWithMultiselect
                id="country"
                label="Country"
                formControlClass={classes.multiSelect}
                options={countryList}
                value={selectedCountry}
                onChange={handleCountryChange}
                classes={{
                  label: classes.label,
                  textField: classes.textField
                }}
              />
                 <CeroDateTime
                  useDayjs={true}
                  id="startTime"
                  name="startTime"
                  label="Start Time"
                  fullWidth
                  value={notificationForm.values.startTime || null}
                  onChange={(newValue) =>
                    notificationForm.setFieldValue("startTime", newValue)
                  }
                  error={
                    notificationForm.errors.startTime &&
                    notificationForm.touched.startTime
                  }
                  helperText={
                    notificationForm.touched.startTime &&
                    notificationForm.errors.startTime
                  }
                />
                <CeroSelect
                  required
                  id="messageStatus"
                  name="messageStatus"
                  label="Message Status"
                  fullWidth
                  options={messageStatuses}
                  selectedValue={notificationForm.values.messageStatus}
                  onChange={notificationForm.handleChange}
                  onBlur={notificationForm.handleBlur}
                  error={
                    notificationForm.touched.messageStatus &&
                    notificationForm.errors.messageStatus
                  }
                />
              </Grid>
              <Grid item container direction="column" md={6} xs={12}>
              <CeroSearchWithMultiselect              
                id="company"
                label="Company"
                formControlClass={classes.multiSelect}
                options={companyListOption}
                selected={selectedCompany}
                onChange={companyHandleChange}
                value={selectedCompany}
                classes={{
                  label: classes.label,
                  textField: classes.textField
                }}
              />
                <CeroDateTime
                  useDayjs={true}
                  id="endTime"
                  name="endTime"
                  label="End Time"
                  fullWidth
                  value={notificationForm.values.endTime || null}
                  onChange={(newValue) =>
                    notificationForm.setFieldValue("endTime", newValue)
                  }
                  error={
                    notificationForm.errors.endTime &&
                    notificationForm.touched.endTime
                  }
                  helperText={
                    notificationForm.touched.endTime &&
                    notificationForm.errors.endTime
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box className={classes.buttonContainer}>
          <CeroButton
            buttonText="Update"
            disabled={!notificationForm.dirty || !notificationForm.isValid}
            className={classes.buttonPrimary}
            onClick={onUpdateData}
          />
          <CeroButton
            buttonText="Clear"
            variant="outlined"
            className={classes.buttonSecondary}
            onClick={handleClear}
          />
        </Box>
      </Container>
    </DashboardLayout>
  );
};

export default EditNotification;
