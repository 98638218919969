import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Container, Grid, CircularProgress, Box } from "@mui/material";
import DashboardLayout from "../../layouts/DashboardLayout";
import CeroDropdown from "../../components/CeroDropdown";
import { sampleYear } from "../../constants";
import Status from "./Status";
import MonthlySummaryTable from "./MonthlySummaryTable";
import {
  getApprovalSummary,
  resetApprovalSummary,
} from "../../redux/actions/approval";
import useStyles from "./styles";
import { getFacilityPeriods } from "../../redux/actions/listings";
import { STATUS } from "../../redux/constants";
import CeroTable from "../../components/CeroTable";

export const approvalMonthlySummaryColumns = [
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Assessment Year",
  },
  {
    columnKey: "period",
    columnId: "period",
    columnHeader: "Period",
  },
  {
    columnKey: "facility_name",
    columnId: "facility_name",
    columnHeader: "Facility",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
];

const ApprovalStatus = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [filterYear, setYear] = useState(new Date().getFullYear());
  const [facility, setFacility] = useState("");

  const facilitiesData = useSelector(
    (state) => state.listings.listFacilities.data
  );
  const approvalSummary = useSelector(
    (state) => state.approval.approvalSummaryList.data
  );
  const approvalSummaryStatus = useSelector(
    (state) => state.approval.approvalSummaryList.status
  );
  const periodsData = useSelector(
    (state) => state.listings.getFacilityPeriods.data
  );
  const [auditId, setAudit] = useState("");

  const facilitiesList = facilitiesData.map((item) => ({
    key: item.id,
    value: item.name,
  }));

  const onSelectApprovalSummaryData = (selectedRow) => {
    const currentFilter = encodeURI(
      `?${selectedRow.id ? `&id=${selectedRow.id}` : ""}${
        facility ? `&facility=${facility}` : ""
      }${selectedRow.period ? `&period=${selectedRow.period}` : ""}`
    );
    navigate(`/approval-status/${selectedRow.year}${currentFilter}`);
  };

  useEffect(() => {
    if (!facility) {
      setFacility(facilitiesList?.[0]?.key);
    }
  }, [facilitiesList]);

  useEffect(() => {
    if (facility && filterYear) {
      dispatch(getFacilityPeriods(filterYear, facility));
    }
  }, [filterYear, facility]);

  useEffect(() => {
    setAudit(periodsData?.[0]?.key);
  }, [periodsData, facility]);

  useEffect(() => {
    if (filterYear && facility && auditId)
      dispatch(getApprovalSummary(filterYear, facility, auditId));
    else {
      dispatch(resetApprovalSummary());
    }
  }, [filterYear, facility, auditId]);

  function setData(type, value) {
    if (type == "year") {
      setAudit("");
      setYear(value);
    } else if (type == "facility") {
      setAudit("");
      setFacility(value);
    }
  }

  return (
    <DashboardLayout>
      <Container className={classes.container}>
        <Grid
          className={classes.filterContainer}
          container
          columnSpacing={2}
          alignItems="center"
          wrap="nowrap"
        >
          <Grid item xs={3} style={{ paddingLeft: 0 }}>
            <CeroDropdown
              id="year"
              label="Assessment Year"
              fullWidth
              options={sampleYear()}
              onChange={({ target }) => setData("year", target.value)}
              selectedValue={filterYear}
            />
          </Grid>
          <Grid item xs={3}>
            <CeroDropdown
              id="facility"
              label="Facility"
              fullWidth
              options={facilitiesList}
              onChange={({ target }) => setData("facility", target.value)}
              selectedValue={facility}
            />
          </Grid>
          <Grid item xs={3}>
            <CeroDropdown
              id="auditCycle"
              label="Audit Cycle"
              fullWidth
              options={periodsData}
              onChange={({ target }) => setAudit(target.value)}
              selectedValue={auditId}
            />
          </Grid>
        </Grid>
        <Status
          year={filterYear}
          facility={facility}
          auditId={auditId}
          status={approvalSummary?.status}
        />
        {approvalSummaryStatus === STATUS.SUCCESS ? (
          <MonthlySummaryTable
            summaryData={approvalSummary.monthly_approval_status}
            onSelectApprovalSummaryData={onSelectApprovalSummaryData}
          />
        ) : approvalSummaryStatus === STATUS.RUNNING ? (
          <Box className={classes.loader}>
          <CircularProgress />
          </Box>
        ) : (
          <CeroTable
            columns={approvalMonthlySummaryColumns}
            data={[]}
            hasMore={false}
            loading={false}
            useExtraStyles={true}
            useModifiedHeader={true}     
            classes={{ tableContainer: classes.tableContainer }}
          />
        )}
      </Container>
    </DashboardLayout>
  );
};

export default ApprovalStatus;
