import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Paper,
  Typography,
  Grid,
} from "@mui/material";
import { getDevelopmentTraining } from "../../../redux/actions";
import useStyles from "./styles";
import { getCookie } from "../../../services/cookie";

const DevelopmentTrainingGraph = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const role = getCookie("role");
  const companyId = getCookie("companyId");
  const diversityValues = useSelector(
    (state) => state.dashboard.getDevelopmentTraining.data
  );

  useEffect(() => {
    !props.refreshContent && dispatch(getDevelopmentTraining(props.filter,role,companyId));
  }, [dispatch, props.filter]);

  return (
    <Paper className={classes.container}>
      <Typography className={classes.graphTitle}>
        Development & Training
      </Typography>
      <Grid container spacing={2} display="flex" style={{height: "100%"}}>
        <Grid item xs={6} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <div className={classes.circle} style={{ borderColor: "#6FBFBA"}}>
            <Typography variant="h4" className={classes.value}>{diversityValues?.total_training_conducted}</Typography>
          </div>
          <Typography variant="caption">Trainings conducted</Typography>
        </Grid>
        <Grid item xs={6} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <div className={classes.circle} style={{ borderColor: "#FFC757"}}>
            <Typography variant="h4" className={classes.value}>{diversityValues?.total_training_hours}</Typography>
          </div>
          <Typography variant="caption">
            Trainings Hours per employee
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default DevelopmentTrainingGraph;
