import React, { useEffect } from "react";
import {
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CeroInput from "../../../../../components/CeroInput";
import DashboardLayout from "../../../../../layouts/DashboardLayout";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  getSupplierSurveyTrackQuestions,
  getSupplierSurveyTrackAnswers,
} from "../../../../../redux/actions/supplierScreening";
import useStyles from "./styles";
import PdfFileIconWithDownload from "../../../../../assets/images/Download";

const SupplierViewSurvey = () => {
  const { answerId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  const surveyQuestions = useSelector(
    (state) => state.supplierScreening.getSupplierSurveyTrackQuestions.data
  );
  const surveyAnswers = useSelector(
    (state) => state.supplierScreening.getSupplierSurveyTrackAnswers.data.answer
  );

  useEffect(() => {
    dispatch(getSupplierSurveyTrackAnswers(answerId));
    dispatch(getSupplierSurveyTrackQuestions(answerId));
  }, [dispatch, answerId]);

  const getAnswerByQuestionId = (questionId) => {
    const answers = surveyAnswers?.answer || [];
    const answerObj = answers.find((item) => item.question_id === questionId);
    return answerObj ? answerObj.answer : "";
  };

  const renderQuestion = (question) => {
    const answer = getAnswerByQuestionId(question.id);
    

    switch (question.question_type) {
      case "choose_from_option":
        return (
          <Box display="flex" flexDirection="column" paddingLeft={6}>
         <span style={{fontWeight:"400",fontSize:"16px"}}>{question.question_id && question.question}</span>
            {(question.options || []).map((option, idx) => (
              <FormControlLabel
                key={idx}
                control={
                  <Checkbox
                    className={classes.selectCheck}
                    sx={{
                      color: "#5A404E !important",
                      "&.Mui-checked": { color: "#8C2148 !important" },
                    }}
                    checked={answer.includes(option)}
                    disabled
                  />
                }
                label={option}
                className={classes.label}
                sx={{ paddingLeft: 6 }}
              />
            ))}
          </Box>
        );

      case "multiselect_check_box":
        return (
          <Box display="flex" flexDirection="column" paddingLeft={6}>
            {(question.options || []).map((option, idx) => (
              <FormControlLabel
                key={idx}
                control={
                  <Checkbox
                    className={classes.selectCheck}
                    sx={{
                      color: "#5A404E !important",
                      "&.Mui-checked": { color: "#8C2148 !important" },
                    }}
                    checked={answer.split(", ").includes(option)}
                    disabled
                  />
                }
                label={option}
                className={classes.label}
                sx={{ marginLeft: 3 }}
              />
            ))}
          </Box>
        );

      case "text":
        return (
          <Box display="flex" flexDirection="column" paddingLeft={6}>
            <Box display="flex" alignItems="center" paddingBottom={1}>
              <Typography
                variant="body1"
                sx={{ minWidth: 250, paddingLeft: 3, marginRight: 1 }}
              >
                {question.question}:
              </Typography>
              <CeroInput
                rows={1}
                placeholder={`Enter ${question.question}`}
                value={answer}
                disabled
                sx={{ width: "60%" }}
              />
            </Box>
          </Box>
        );

      case "upload":
        return (
          <Box display="flex" flexDirection="column" paddingLeft={2}>
            <Typography
              fontSize={16}
              fontWeight={400}
              variant="h3"
              paddingBottom={2}
              paddingTop={1.5}
            >
              {question.question}
            </Typography>
            {answer ? (
              <a href={answer} target="_blank" rel="noopener noreferrer" style={{marginTop:"12px",paddingLeft:"6px"}}>
               <PdfFileIconWithDownload/>
              </a>
            ) : (
              <Typography variant="body2" color="textSecondary">
                No file uploaded.
              </Typography>
            )}
          </Box>
        );

      default:
        return null;
    }
  };

  const groupQuestions = (questionsList) => {
    const grouped = [];
    const questionMap = new Map();

    questionsList?.forEach((question) => {
      const { id, question_id } = question;

      if (question_id && questionMap.has(question_id)) {
        questionMap.get(question_id).push(question);
      } else {
        questionMap.set(id, [question]);
      }
    });

    questionMap.forEach((group) => {
      grouped.push(group);
    });

    return grouped;
  };

  const groupedQuestions = groupQuestions(
    surveyQuestions?.supplier_generic_Q_list || []
  );
  const groupedQuestionsIndustry = groupQuestions(
    surveyQuestions?.supplier_industry_Q_list || []
  );

  return (
    <DashboardLayout>
      <Container className={classes.mainContainer}>
        <div
          className={classes.backContainer}
          onClick={() => navigate(-1)}
          style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
        >
          <ArrowBackIosIcon />
          <Typography variant="body1">Back</Typography>
        </div>

        <Paper className={classes.container}>
          <div className={classes.paperTop}>
            <Typography variant="h6" className={classes.title}>
              Supplier Assessment General Questionnaire
            </Typography>
            <Typography variant="body2" className={classes.completed}>
              <span style={{ color: "green" }}>Completed by</span>:{" "}
              {surveyAnswers?.name}
            </Typography>
          </div>

          <div className={classes.questionList}>
            {groupedQuestions.map((group, index) => (
              <Grid key={index} className={classes.gridItem}>
                <Typography
                  fontSize={18}
                  fontWeight={500}
                  variant="h3"
                  paddingLeft={4}
                  paddingBottom={2}
                >
                  {index + 1}. {group[0].question}
                </Typography>
                {group.map((question) => (
                  <Box
                    key={question.question_id || question.id}
                    paddingLeft={4}
                  >
                    {renderQuestion(question)}
                  </Box>
                ))}
              </Grid>
            ))}
          </div>

          <div className={classes.paperTop}>
            <Typography variant="h6" className={classes.title}>
              Supplier Assessment Industry Questionnaire
            </Typography>
          </div>

          <div className={classes.questionList}>
            {groupedQuestionsIndustry.map((group, index) => (
              <Grid key={index} className={classes.gridItem}>
                <Typography
                  fontSize={18}
                  fontWeight={500}
                  variant="h3"
                  paddingLeft={4}
                  paddingBottom={2}
                >
                  {index + 1}. {group[0].question}
                </Typography>
                {group.map((question) => (
                  <Box
                    key={question.question_id || question.id}
                    paddingLeft={4}
                  >
                    {renderQuestion(question)}
                  </Box>
                ))}
              </Grid>
            ))}
          </div>
        </Paper>
      </Container>
    </DashboardLayout>
  );
};

export default SupplierViewSurvey;
