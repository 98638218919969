import { Padding } from '@mui/icons-material';
import React from 'react';

const PdfFileIconWithDownload = () => {
  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <div style={styles.pdfBadge}>PDF</div>
        <div style={styles.fileDetails}>
          <div style={styles.fileName}>ISO45001.pdf</div>
          <div style={styles.fileSize}>95 KB</div>
        </div>
      </div>
      <div style={styles.downloadButton}>
        <span style={styles.downloadIcon}>⬇️</span>
      </div>
      <div style={styles.cornerFold} />
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '160px',
    height: '120px',
    border: '1px solid #ddd',
    borderRadius: '4px',
    backgroundColor: '#f8f8f8',
    padding: '10px',
    position: 'relative',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  pdfBadge: {
    backgroundColor: '#d9534f',
    color: '#fff',
    padding: '5px 10px',
    borderRadius: '3px',
    fontSize: '12px',
    fontWeight: 'bold',
    marginRight: '10px',
  },
  fileDetails: {
    display: 'flex',
    flexDirection: 'column',
  },
  fileName: {
    fontSize: '14px',
    color: '#333',
  },
  fileSize: {
    fontSize: '12px',
    color: '#888',
  },
  downloadButton: {
    width: '40px',
    height: '40px',
    backgroundColor: '#b0b0b0',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center', // Ensures centering
    color: '#fff',
    fontSize: '18px',
    cursor: 'pointer',
  },
  downloadIcon: {
    fontSize: '20px',
    PaddingBottom:"30px"
  },
  cornerFold: {
    position: 'absolute',
    bottom: '0',
    right: '0',
    width: '20px',
    height: '20px',
    backgroundColor: '#d9534f',
    clipPath: 'polygon(100% 0, 0 100%, 100% 100%)',
  },
};

export default PdfFileIconWithDownload;
