import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    tableContainer: {
        maxHeight: '400px', // Example height for the container
        overflow: 'auto',
        marginTop: 20,
        position: 'relative',
        width: '100%', // Ensure container has a defined width
      },
  table: {
    minWidth: 650,
    borderCollapse: 'collapse',
  },
  tableHeader: {
    backgroundColor: '#E5DFE0',
    fontWeight: 'bold',
    border: '1px solid #ddd',
    textAlign: 'center',
    position: 'sticky',
    top: 0, // Fixes the header to the top
    zIndex: 5, // Ensures the header stays above table content
  },
  tableCell: {
    border: '1px solid #ddd',
    textAlign: 'center',
  },
  rowGroup: {
    '&:not(:last-of-type) td': {
      borderBottom: '2px solid #ddd',
    },
  },
  noDataWarningTemp: {
    position: "absolute",
    textAlign: "center",
    width: "75%",
  },
  noDataWarning: {
    textAlign: "center",
    padding: 20,
  },
  
}));

const mergeRows = (data, key) => {
  let lastValue = null;
  let rowspan = 0;

  return data.map((row, index) => {
    if (row[key] !== lastValue) {
      const count = data.slice(index).filter(r => r[key] === row[key]).length;
      rowspan = count;
      lastValue = row[key];
      return { ...row, [`${key}Span`]: rowspan };
    } else {
      return { ...row, [`${key}Span`]: 0 };
    }
  });
};

const TableComponent = ({data}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const noTableRows = () => (
    <TableRow>
      <TableCell colSpan={4} className={classes.noDataWarning}>
        <Typography>No data</Typography>
      </TableCell>
    </TableRow>
  );

  data.sort((a, b) => {
    if (a.country < b.country) return -1;
    if (a.country > b.country) return 1;
    if (a.facility < b.facility) return -1;
    if (a.facility > b.facility) return 1;
    return 0;
  });
  const mergedCountryData = mergeRows(data, 'country');
  const mergedFacilityData = mergeRows(mergedCountryData, 'facility');
  
  return (
        <TableContainer component={Paper} className={classes.tableContainer}>
        <Table className={classes.table} stickyHeader>
            <TableHead>
            <TableRow>
                <TableCell className={classes.tableHeader}>Country</TableCell>
                <TableCell className={classes.tableHeader}>Facility</TableCell>
                <TableCell className={classes.tableHeader}>Assessment Year</TableCell>
                <TableCell className={classes.tableHeader}>Report Count</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {mergedFacilityData.length > 0 ? mergedFacilityData.map((row, index) => (
                <TableRow key={index} className={classes.rowGroup} onClick={() =>
                  navigate(`details/${row.country_code}/${row.facility_id}/${row.year}`)}>
                {row.countrySpan > 0 && (
                    <TableCell className={classes.tableCell} rowSpan={row.countrySpan}>
                    {row.country}
                    </TableCell>
                )}
                {row.facilitySpan > 0 && (
                    <TableCell className={classes.tableCell} rowSpan={row.facilitySpan}>
                    {row.facility}
                    </TableCell>
                )}
                <TableCell className={classes.tableCell}>{row.year}</TableCell>
                <TableCell className={classes.tableCell}>{row.reportCount}</TableCell>
                </TableRow>
            )): noTableRows()}
            </TableBody>
        </Table>
        </TableContainer>
  );
};



export default TableComponent