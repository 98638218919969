import { all, put, call, takeLatest } from "redux-saga/effects";

import { request } from "../../services/client";
import { ActionTypes } from "../constants/actions";
import { APIEndpoints } from "../constants";
import { stringifyQuery, convertToSnakeCaseParams } from "../../services/utilityService";

export function* listFacilities({payload}) {
  try {
    if (payload.countryName){
      payload = convertToSnakeCaseParams(payload)
    }
    const apiEndpoint = `${APIEndpoints.LIST_FACILITIES}?${stringifyQuery(payload)}`
    const response = yield call(request, apiEndpoint, {
      method: "GET",
    });
    yield put({
      type: ActionTypes.LIST_FACILITIES_SUCCESS,
      payload: response.facilities,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.LIST_FACILITIES_FAILURE,
      payload: err.message,
    });
  }
}

export function* getIndustryTypes(action) {
  try {
    const response = yield call(request, APIEndpoints.GET_INDUSTRY_TYPES, {
      method: "GET",
    });
    yield put({
      type: ActionTypes.GET_INDUSTRY_TYPES_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_INDUSTRY_TYPES_FAILURE,
      payload: err.message,
    });
  }
}

export function* getCountryList(action) {
  try {
    const response = yield call(request, APIEndpoints.GET_COUNTRY_LIST, {
      method: "GET",
    });
    yield put({
      type: ActionTypes.GET_COUNTRY_LIST_SUCCESS,
      payload: response.countries,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_COUNTRY_LIST_FAILURE,
      payload: err.message,
    });
  }
}

export function* getAssigneeList(action) {
const {role} = action.payload
  try {
    const response = yield call(request, APIEndpoints.LIST_ASSIGNEE(role), {
      method: "GET",
    });
    yield put({
      type: ActionTypes.LIST_ASSIGNEE_SUCCESS,
      payload: response.assignees,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.LIST_ASSIGNEE_FAILURE,
      payload: err.message,
    });
  }
}

export function* getFrameworkList() {
  try {
    const response = yield call(request, APIEndpoints.LIST_FRAMEWORK, {
      method: "GET",
    });
    yield put({
      type: ActionTypes.LIST_FRAMEWORK_SUCCESS,
      payload: response.frameworks,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.LIST_FRAMEWORK_FAILURE,
      payload: err.message,
    });
  }
}

export function* getTopicList() {
  try {
    const response = yield call(request, APIEndpoints.LIST_TOPIC, {
      method: "GET",
    });
    yield put({
      type: ActionTypes.LIST_TOPIC_SUCCESS,
      payload: response.topics,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.LIST_TOPIC_FAILURE,
      payload: err.message,
    });
  }
}

export function* listGridRegions(action) {
  try {
    const { countryId } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.LIST_GRID_REGIONS(countryId),
      {
        method: "GET",
      }
    );
    yield put({
      type: ActionTypes.LIST_GRID_REGIONS_SUCCESS,
      payload: response.grid_regions,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.LIST_GRID_REGIONS_FAILURE,
      payload: err.message,
    });
  }
}

export function* getManagerList(action) {
  try {
    const { role, facility_id } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.GET_MANAGER_LIST(role),
      {
        method: "GET",
        payload: { facility_id }
      }
    );
    yield put({
      type: ActionTypes.GET_MANAGER_LIST_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_MANAGER_LIST_FAILURE,
      payload: err.message,
    });
  }
}

export function* companyCountryList(action) {
  try {
    const { companyId } = action.payload
    const response = yield call(request, APIEndpoints.COMPANY_COUNTRY_LIST(companyId), {
      method: 'GET',
    })
    yield put({
      type: ActionTypes.COMPANY_COUNTRY_LIST_SUCCESS,
      payload: response
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.COMPANY_COUNTRY_LIST_FAILURE,
      payload: err.message
    })
  }
}

export function* companyFacilityList(action) {
  try {
    const { companyId } = action.payload
    const response = yield call(request, APIEndpoints.COMPANY_FACILITY_LIST(companyId), {
      method: 'GET',
    })
    yield put({
      type: ActionTypes.COMPANY_FACILITY_LIST_SUCCESS,
      payload: response
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.COMPANY_FACILITY_LIST_FAILURE,
      payload: err.message
    })
  }
}

export function* companyUsersList(action) {
  try {
    const { companyId, isActive } = action.payload
    const response = yield call(request, APIEndpoints.COMPANY_USERS_LIST(companyId, isActive), {
      method: 'GET',
    })
    yield put({
      type: ActionTypes.COMPANY_USERS_LIST_SUCCESS,
      payload: response
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.COMPANY_USERS_LIST_FAILURE,
      payload: err.message
    })
  }
}

export function* companyFacilityDetails(action) {
  try {
    const { companyId, countryName } = action.payload
    const response = yield call(request, APIEndpoints.COMPANY_FACILITY_DETAILS(companyId, countryName), {
      method: 'GET',
    })
    yield put({
      type: ActionTypes.COMPANY_FACILITY_DETAILS_SUCCESS,
      payload: response
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.COMPANY_FACILITY_DETAILS_FAILURE,
      payload: err.message
    })
  }
}

export function* getUserStatistics(action) {
  try {
    const { filter } = action.payload
    const response = yield call(request, APIEndpoints.GET_USER_STATISTICS, {
      method: 'GET',
      payload: filter
    })
    yield put({
      type: ActionTypes.GET_USER_STATISTICS_SUCCESS,
      payload: response
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_USER_STATISTICS_FAILURE,
      payload: err.message
    })
  }
}

export function* getUsersList(action) {
  try {
    const { isActive } = action.payload
    const response = yield call(request, APIEndpoints.GET_USERS_LIST(isActive), {
      method: 'GET',
    })
    yield put({
      type: ActionTypes.GET_USERS_LIST_SUCCESS,
      payload: response
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_USERS_LIST_FAILURE,
      payload: err.message
    })
  }
}

export function* companyUserDetails(action) {
  try {
    const { companyId, id, userType, isActive } = action.payload
    const response = yield call(request, APIEndpoints.COMPANY_USER_DETAILS(companyId, id, userType, isActive), {
      method: 'GET',
    })
    yield put({
      type: ActionTypes.COMPANY_USER_DETAILS_SUCCESS,
      payload: response
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.COMPANY_USER_DETAILS_FAILURE,
      payload: err.message
    })
  }
}

export function* totalUserDetails(action) {
  try {
    const { userType, isActive } = action.payload
    const response = yield call(request, APIEndpoints.TOTAL_USER_DETAILS(userType, isActive), {
      method: 'GET',
    })
    yield put({
      type: ActionTypes.TOTAL_USER_DETAILS_SUCCESS,
      payload: response
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.TOTAL_USER_DETAILS_FAILURE,
      payload: err.message
    })
  }
}

export function* getOrganizationCountry({payload}) {
  try {
    // const { companyId } = action.payload
    const response = yield call(request, APIEndpoints.GET_ORGANIZATION_COUNTRY, {
      method: 'GET',
    })
    yield put({
      type: ActionTypes.GET_ORGANIZATION_COUNTRY_SUCCESS,
      payload: response
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_ORGANIZATION_COUNTRY_FAILURE,
      payload: err.message
    })
  }
}
export function* getStakeholderCompany() {
  try {
    const response = yield call(request, APIEndpoints.GET_STAKEHOLDER_COMPANY, {
      method: "GET",
    });
    yield put({
      type: ActionTypes.GET_STAKEHOLDER_COMPANY_SUCCESS,
      payload: response.company_list_response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_STAKEHOLDER_COMPANY_FAILURE,
      payload: err.message,
    });
  }
}

export function* getFacilityPeriods(action) {
  try {
    const { year, facility } = action.payload
    const response = yield call(request, APIEndpoints.GET_FACILITY_PERIODS(year, facility), {
      method: "GET",
    });
    yield put({
      type: ActionTypes.GET_FACILITY_PERIODS_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_FACILITY_PERIODS_FAILURE,
      payload: err.message,
    });
  }
}

export function* getAuditTypes(action) {
  try {
    const { year, status } = action.payload
    const response = yield call(request, APIEndpoints.GET_AUDIT_TYPES(year, status), {
      method: "GET",
    });
    yield put({
      type: ActionTypes.GET_AUDIT_TYPES_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_AUDIT_TYPES_FAILURE,
      payload: err.message,
    });
  }
}

export function* getStates( action ) {
  try {
    const {country} =action.payload
      const response = yield call(request, APIEndpoints.GET_STATES(country), {
          method: 'POST',
          payload: country
      })
    yield put({
      type: ActionTypes.GET_STATES_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_STATES_FAILURE,
      payload: err,
    });
  }
}

export function* getAssessmentYear({payload}) {
  try {
    const response = yield call(request, APIEndpoints.GET_ASSESSMENT_YEAR(payload.countryCode), {
      method: "GET",
    });
    yield put({
      type: ActionTypes.GET_ASSESSMENT_YEAR_SUCCESS,
      payload: response.years,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_ASSESSMENT_YEAR_FAILURE,
      payload: err.message,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.LIST_FACILITIES, listFacilities),
    takeLatest(ActionTypes.GET_INDUSTRY_TYPES, getIndustryTypes),
    takeLatest(ActionTypes.GET_COUNTRY_LIST, getCountryList),
    takeLatest(ActionTypes.LIST_ASSIGNEE, getAssigneeList),
    takeLatest(ActionTypes.LIST_FRAMEWORK, getTopicList),
    takeLatest(ActionTypes.LIST_TOPIC, getFrameworkList),
    takeLatest(ActionTypes.LIST_GRID_REGIONS, listGridRegions),
    takeLatest(ActionTypes.GET_MANAGER_LIST, getManagerList),
    takeLatest(ActionTypes.COMPANY_COUNTRY_LIST, companyCountryList),
    takeLatest(ActionTypes.COMPANY_FACILITY_LIST, companyFacilityList),
    takeLatest(ActionTypes.COMPANY_USERS_LIST, companyUsersList),
    takeLatest(ActionTypes.COMPANY_FACILITY_DETAILS, companyFacilityDetails),
    takeLatest(ActionTypes.GET_USER_STATISTICS, getUserStatistics),
    takeLatest(ActionTypes.GET_USERS_LIST, getUsersList),
    takeLatest(ActionTypes.COMPANY_USER_DETAILS, companyUserDetails),
    takeLatest(ActionTypes.TOTAL_USER_DETAILS, totalUserDetails),
    takeLatest(ActionTypes.GET_ORGANIZATION_COUNTRY, getOrganizationCountry),
    takeLatest(ActionTypes.GET_STAKEHOLDER_COMPANY, getStakeholderCompany),
    takeLatest(ActionTypes.GET_FACILITY_PERIODS, getFacilityPeriods),
    takeLatest(ActionTypes.GET_AUDIT_TYPES, getAuditTypes),
    takeLatest(ActionTypes.GET_STATES, getStates),
    takeLatest(ActionTypes.GET_ASSESSMENT_YEAR, getAssessmentYear),
  ]);
}
