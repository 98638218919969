import { useEffect } from "react";
import { Container, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";

import {
  getNftDetails,
} from "../../../redux/actions";
import CeroInfoPair from "../../../components/CeroInfoPair";
import useStyles from "./styles";
import { useParams } from "react-router-dom";
import DashboardLayout from "../../../layouts/DashboardLayout";
import QRCode from "react-qr-code";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";

const BlockchainDetails = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const nftDetails = useSelector(
    (state) => state.superAdmin.getNftDetails.data
  );
  const navigateBack = () => {
    navigate(-1);
  };

  const transactionHash = nftDetails.transaction_hash || "0xa7b73a98e1aa94ecdd97beac778141e08d37e0628de0fba2f0fcb330798803b4";
  let firstFive = transactionHash.substring(0, 11);
  let lastFour = transactionHash.substring(transactionHash.length - 11);

  useEffect(() => {
    dispatch(getNftDetails(id));
  }, [dispatch]);

  return (
    <DashboardLayout>
       <div className={classes.backContainer} onClick={navigateBack}>
                  <ArrowBackIosIcon />
                  Back
                </div>
      <Container className={classes.container}>
        <Grid spacing={2} className={classes.statusContainer}>
          <Grid item xs={8}>
            <CeroInfoPair
              title="Token ID :"
              value={nftDetails.token_number ? nftDetails.token_number : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.title,
              }}
            />
            <CeroInfoPair
              title="Company Name :"
              value={nftDetails.company_name ? nftDetails.company_name : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.title,
              }}
            />
            <CeroInfoPair
              title="Audit Reference Number :"
              value={nftDetails.audit_number ? nftDetails.audit_number : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.title,
              }}
            />
            <CeroInfoPair
              title="Date Created :"
              value={
                nftDetails.date_created
                  ? dayjs(nftDetails.date_created).format("DD/MM/YYYY")
                  : "NA"
              }
              classes={{
                container: classes.infoContainer,
                title: classes.title,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <QRCode
              value={
                `https://amoy.polygonscan.com/tx/${nftDetails.transaction_hash}`
              }
              size={200}
              bgColor={"#ffffff"}
              fgColor={"#000000"}
              level={"L"}
              includeMargin={true}
            />
            <p style={{ textAlign: "center" }}>
            <a
                href={`https://amoy.polygonscan.com/tx/${nftDetails.transaction_hash}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {firstFive}...{lastFour}
              </a>
            </p>
          </Grid>
        </Grid>
      </Container>
    </DashboardLayout>
  );
};

export default BlockchainDetails;
