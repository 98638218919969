import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Grid,
  Typography,
  Box
} from "@mui/material";
import { useSnackbar } from "notistack";
import { STATUS } from "../../../../../../redux/constants";
import useStyles from "./styles";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { resetAddCombustionStatus } from "../../../../../../redux/actions";
import CeroItemPair from "../../../../../../components/CeroItemPair";
import dayjs from "dayjs";
import CeroInfoPair from "../../../../../../components/CeroInfoPair";

const EmployeeDiversityDetails = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const {  emissionData } = props;

  const deleteEmissionData = useSelector(
    (state) => state.emission.deleteEmissions
  );

  useEffect(() => {
    if (deleteEmissionData.status === STATUS.SUCCESS) {
      enqueueSnackbar("Employee Benefits record deleted successfully",{ variant: "success",});
      dispatch(resetAddCombustionStatus());
    } else if (deleteEmissionData.status === STATUS.ERROR) {
      enqueueSnackbar(deleteEmissionData.message.message || "We couldn't process your request. Please try again later.", { variant: "error" });
      dispatch(resetAddCombustionStatus());
    }
  }, [deleteEmissionData, enqueueSnackbar, dispatch]);

 return (
   <>
     <div
       className={classes.backContainer}
       onClick={() => {
         navigate(-1);
       }}
     >
       <ArrowBackIosIcon />
       Back
     </div>
     <Container className={classes.container}>
       <Box className={classes.innerContainer}>
         <Box className={classes.header}>
           {emissionData.basic_salary_ratio_male === null &&
           emissionData.basic_salary_ratio_female === null ? (
             <Typography variant="h6" component="div">
               Employee Diversity
             </Typography>
           ) : (
             <Typography variant="h6" component="div">
               Ratio of Basic alary
             </Typography>
           )}
         </Box>
         <Box className={classes.topContainer}>
           <Grid
             container
             direction={"row"}
             wrap="nowrap"
             justifyContent={"space-between"}
             display={"contents"}
             spacing={12}
           >
             <Grid
               item
               container
               direction={"column"}
               display={"contents"}
               xs={10}
             >
               <Box className={classes.actionBox}>
                 <CeroInfoPair
                   title="Status:"
                   value={emissionData.status ? emissionData.status : "NA"}
                   classes={{
                     container: classes.statusContainer,
                     title: classes.statusTitle,
                     value:
                       emissionData.status === "In Progress"
                         ? classes.Inprogress
                         : classes[emissionData?.status],
                   }}
                 />
               </Box>
               <CeroItemPair
                 title="Facility:"
                 value={
                   emissionData.facility_name
                     ? emissionData.facility_name
                     : "NA"
                 }
                 classes={{
                   container: classes.infoContainer,
                   title: classes.diversityTitle,
                   value: classes.value,
                 }}
               />
               <CeroItemPair
                 title="Period:"
                 value={
                   emissionData.assessment_period
                     ? emissionData.assessment_period
                     : "NA"
                 }
                 classes={{
                   container: classes.infoContainer,
                   title: classes.diversityTitle,
                   value: classes.value,
                 }}
               />
               <CeroItemPair
                 title="Year:"
                 value={emissionData.year ? emissionData.year : "NA"}
                 classes={{
                   container: classes.infoContainer,
                   title: classes.diversityTitle,
                   value: classes.value,
                 }}
               />
               {emissionData.percentage_of_employee1 === 0 ? (
                 ""
               ) : (
                 <CeroItemPair
                   title={emissionData.percentage_of_employee1_title}
                   value={
                     emissionData.percentage_of_employee1
                       ? `${emissionData.percentage_of_employee1}%`
                       : "NA"
                   }
                   classes={{
                     container: classes.infoContainer,
                     title: classes.diversityTitle,
                     value: classes.value,
                   }}
                 />
               )}
               {emissionData.percentage_of_employee2 === 0 ? (
                 ""
               ) : (
                 <CeroItemPair
                   title={emissionData.percentage_of_employee2_title}
                   value={
                     emissionData.percentage_of_employee2
                       ? `${emissionData.percentage_of_employee2}%`
                       : "NA"
                   }
                   classes={{
                     container: classes.infoContainer,
                     title: classes.diversityTitle,
                     value: classes.value,
                   }}
                 />
               )}
               {emissionData.percentage_of_employee3 === 0 ? (
                 ""
               ) : (
                 <CeroItemPair
                   title={emissionData.percentage_of_employee3_title}
                   value={
                     emissionData.percentage_of_employee3
                       ? `${emissionData.percentage_of_employee3}%`
                       : "NA"
                   }
                   classes={{
                     container: classes.infoContainer,
                     title: classes.diversityTitle,
                     value: classes.value,
                   }}
                 />
               )}
               {emissionData.basic_salary_ratio_male === null ? (
                 ""
               ) : (
                 <CeroItemPair
                   title="Basic Salary Ratio (Male)"
                   value={
                     emissionData.basic_salary_ratio_male
                       ? emissionData.basic_salary_ratio_male
                       : "NA"
                   }
                   classes={{
                     container: classes.infoContainer,
                     title: classes.diversityTitle,
                     value: classes.value,
                   }}
                 />
               )}
               {emissionData.basic_salary_ratio_female === null ? (
                 ""
               ) : (
                 <CeroItemPair
                   title="Basic Salary Ratio (Female)"
                   value={
                     emissionData.basic_salary_ratio_female
                       ? emissionData.basic_salary_ratio_female
                       : "NA"
                   }
                   classes={{
                     container: classes.infoContainer,
                     title: classes.diversityTitle,
                     value: classes.value,
                   }}
                 />
               )}
               <CeroItemPair
                 title="Audited on:"
                 value={
                   emissionData.audited_by ? emissionData.audited_by : "NA"
                 }
                 classes={{
                   container: classes.infoContainer,
                   title: classes.diversityTitle,
                   value: classes.value,
                 }}
               />
               <CeroItemPair
                 title="Audited by:"
                 value={
                   emissionData.audited_on
                     ? dayjs(emissionData.audited_on).format("DD/MM/YYYY")
                     : "NA"
                 }
                 classes={{
                   container: classes.infoContainer,
                   title: classes.diversityTitle,
                   value: classes.value,
                 }}
               />
             </Grid>
           </Grid>
         </Box>
       </Box>
     
     </Container>
   </>
 );
};

export default EmployeeDiversityDetails;
