import { createActions } from 'redux-actions';
import { ActionTypes } from '../constants/actions';

export const {
    getSupplierQuestions,
    createSupplierQuestions,
    getGenericQuestions,
    sendSupplierQuestions,
    resetSupplierScreeningStatus,
    getSupplierSurveyQuestions,
    supplierSurveyResponse,
    getSupplierSurveys,
    getSupplierRespondentList,
    sendRespondentReminder,
    getSupplierSurveyTrackQuestions,
    getSupplierSurveyTrackAnswers,
    resetSendRespondentReminderStatus
} = createActions({
    [ActionTypes.GET_SUPPLIER_QUESTIONS]: (industryId) => ({industryId}),
    [ActionTypes.CREATE_SUPPLIER_QUESTIONS]: (industryId, requestData) => ({industryId, requestData}),
    [ActionTypes.GET_GENERIC_QUESTIONS]: () => ({}),
    [ActionTypes.SEND_SUPPLIER_QUESTIONS]: (senderList) => ({senderList}),
    [ActionTypes.RESET_SUPPLIER_SCREENING_STATUS]: () => {},
    [ActionTypes.GET_SUPPLIER_SURVEY_QUESTIONS]: (answerId) => ({answerId}),
    [ActionTypes.SUPPLIER_SURVEY_RESPONSE]: (answerId, requestData) => ({answerId, requestData}),
    [ActionTypes.GET_SUPPLIER_SURVEYS]: (year) => ({year}),
    [ActionTypes.GET_SUPPLIER_RESPONDENT_LIST]: (id) => ({id}),
    [ActionTypes.SEND_RESPONDENT_REMINDER]: (id, requestData) => ({id, requestData}),
    [ActionTypes.GET_SUPPLIER_SURVEY_TRACK_QUESTIONS]: (id) => ({id}),
    [ActionTypes.GET_SUPPLIER_SURVEY_TRACK_ANSWERS]: (id) => ({id}),
    [ActionTypes.RESET_SEND_RESPONDENT_REMINDER_STATUS]: () => {},
})
