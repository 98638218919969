import React, { useEffect } from "react";
import clsx from "clsx";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Container, Grid, Typography, Box } from "@mui/material";
import { useSnackbar } from "notistack";

import { STATUS } from "../../../redux/constants";
import { schemeValidation } from "./schema";
import {
  getAssessmentPeriod,
  resetAddCombustionStatus,
  updatePoliticalContributions,
} from "../../../redux/actions";
import CeroButton from "../../../components/CeroButton";
import CeroInput from "../../../components/CeroInput";
import useStyles from "./styles";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CeroQuestionWithCheckbox from "../../../components/CeroQuestionWithCheckbox";
import CeroAutoComplete from "../../../components/CeroAutoComplete";
import CeroSelect from "../../../components/CeroSelect";
import { sampleYear } from "../../../constants";

const EditPoliticalContributionsForm = (props) => {
  const { emissionData, onCancel } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const facilitiesData = useSelector(
    (state) => state.listings.listFacilities.data
  );
  const updateEmissionData = useSelector(
    (state) => state.emission.updatePoliticalContributions
  );
  const facilitiesList = facilitiesData.map((item) => ({
    key: item.id,
    value: item.name,
  }));

  const formik = useFormik({
    initialValues: {
      facility: emissionData.facility_id || "",
      year: emissionData.year || "",
      assessmentPeriod: emissionData.assessment_period || "",
      amount: emissionData.amount || 0.0,
      beneficiaryContribution: emissionData.beneficiary_of_contribution || "",
      contributionDescription: emissionData.description_of_contribution || "",
      disclosureExplanation: emissionData.disclosure_explanation || "",
      politicalContributions: emissionData.any_political_contribution || "",
    },
    validationSchema: schemeValidation,
    onSubmit: () => {},
  });

  const assessmentData = useSelector(
    (state) => state.assessment.assessmentPeriod.data
  );

  const assessmentOptions = Array.isArray(assessmentData)
    ? assessmentData.map((item) => ({
        key: item.period,
        value: item.period,
      }))
    : [];

  const yearList = sampleYear().map((item) => ({
    id: item.key,
    label: item.value,
  }));

  useEffect(() => {
    const { facility, year } = formik.values;
    if (facility && year) {
      dispatch(getAssessmentPeriod(year, facility, 'political_contributions', emissionData.id));
    }
  }, [formik.values.facility, formik.values.year, dispatch]);

  useEffect(() => {
    if (updateEmissionData.status === STATUS.SUCCESS) {
      enqueueSnackbar("Political Contributions details updated successfully", {
        variant: "success",
      });
      dispatch(resetAddCombustionStatus());
      onCancel();
    } else if (updateEmissionData.status === STATUS.ERROR) {
      enqueueSnackbar(
        updateEmissionData.message.message || "We couldn't process your request. Please try again later.",
        { variant: "error" }
      );
      dispatch(resetAddCombustionStatus());
    }
  }, [updateEmissionData, dispatch, enqueueSnackbar, onCancel]);

  const onUpdateLocalCommunitiesData = () => {
    const requestData = {
      id: emissionData.id,
      facility_id: formik.values.facility,
      assessment_period: formik.values.assessmentPeriod,
      year: formik.values.year,
      any_political_contribution: formik.values.politicalContributions,
      amount: formik.values.politicalContributions === "Yes" ? formik.values.amount : "0.0",
      beneficiary_of_contribution: formik.values.politicalContributions === "Yes" ? formik.values.beneficiaryContribution : "NA",
      description_of_contribution: formik.values.politicalContributions === "Yes" ? formik.values.contributionDescription : "NA",
      disclosure_explanation: formik.values.politicalContributions === "Yes" ? formik.values.disclosureExplanation : "NA",
      save: true,
    };
    dispatch(updatePoliticalContributions(requestData));
  };

  const handleAnswerChange = (fieldName, answer) => {
    formik.handleChange({ target: { name: fieldName, value: answer } });
  };

  return (
    <>
      <div className={classes.backContainer} onClick={onCancel}>
      <ArrowBackIosIcon />
      Back
      </div>
    <Container className={classes.container}>
      <Box className={classes.innerContainer}>
        <Typography className={classes.title} variant="h6" component="div">
          Edit Political Contributions
        </Typography>
        <Box>
        <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column" md={6} xs={12}>
                <CeroSelect
                  required
                  id="facility"
                  name="facility"
                  label="Facility"
                  fullWidth
                  options={facilitiesList}
                  selectedValue={formik.values.facility}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.facility && formik.errors.facility}
                />
              </Grid>
              <Grid item container direction={"column"} md={6} xs={12}>
                <CeroAutoComplete
                  required
                  id="year"
                  label="Year"
                  onChange={(e, value) =>
                    formik.setFieldValue("year", value.id)
                  }
                  onBlur={formik.handleBlur}
                  error={formik.errors.year}
                  options={yearList}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={formik.values.year}
                />
              </Grid>
              <Grid item container direction="column" md={6} xs={12}>
                <CeroSelect
                  required
                  id="assessmentPeriod"
                  name="assessmentPeriod"
                  label="Assessment Period"
                  fullWidth
                  options={assessmentOptions}
                  selectedValue={formik.values.assessmentPeriod}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.assessmentPeriod &&
                    formik.errors.assessmentPeriod
                  }
                />
              </Grid>
            </Grid>
            <CeroQuestionWithCheckbox
              question="Choose whether your company made political contributions during the reporting period"
              formik={formik}
              fieldName="politicalContributions"
              onChange={handleAnswerChange}
              classes={{ questionBox: classes.questionBox }}
            />
            {formik.values.politicalContributions === "Yes" ? 
            <>
            <Typography variant="body1" style={{padding: "10px 0px"}}>
              If Yes, Provide Details of Contributions:
            </Typography>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column" md={4} xs={6}>
                <CeroInput
                  id="amount"
                  name="amount"
                  label="Total amount of Political Contributions"
                  value={formik.values.amount}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.amount && formik.errors.amount}
                />
              </Grid>
              <Grid item container direction="column" md={8} xs={6}>
                <CeroInput
                  id="beneficiaryContribution"
                  name="beneficiaryContribution"
                  label="Beneficiary of the contribution"
                  fullWidth
                  value={formik.values.beneficiaryContribution}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.beneficiaryContribution && formik.errors.beneficiaryContribution}
                />
              </Grid>
            </Grid>
            <CeroInput
              required
              id="contributionDescription"
              name="contributionDescription"
              label="Description of Contributions"
              fullWidth
              multiline
              rows="2"
              value={formik.values.contributionDescription}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.contributionDescription && formik.errors.contributionDescription}
              classes={{ container: classes.textAreaContainer }}
            />
            <CeroInput
              required
              id="disclosureExplanation"
              name="disclosureExplanation"
              label="Disclosure Explanation"
              fullWidth
              multiline
              rows="2"
              value={formik.values.disclosureExplanation}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.disclosureExplanation && formik.errors.disclosureExplanation}
              classes={{ container: classes.textAreaContainer }}
            />
            </> : ""}
        </Box>
        <Box className={classes.buttonContainer}>
          <CeroButton
            buttonText="Update"
            disabled={!formik.dirty || !formik.isValid}
            className={clsx(classes.button, classes.buttonPrimary)}
            onClick={onUpdateLocalCommunitiesData}
          />
          <CeroButton
            buttonText="Clear"
            variant="outlined"
            className={clsx(classes.button, classes.buttonSecondary)}
            onClick={formik.resetForm}
          />
        </Box>
      </Box>
    </Container>
    </>
  );
};

export default EditPoliticalContributionsForm;
