import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(0, 2),
    height: "100%",
    "@media (min-width: 1200px)": {
      maxWidth: "none",
    },
  },
  tableContainer: {
    maxHeight: "calc(100vh - 120px)",
    marginTop: theme.spacing(5),
    border: "1px solid #5A404E"
  },
  loader: {
    width: "100%",
    height: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  audited: {
    color: theme.palette.Success.main,
    fontWeight: 500,
    fontSize: 14,
  },
  pending: {
    color: theme.palette.status.open,
    fontWeight: 500,
    fontSize: 14,
  },
  default: {
    color: theme.palette.Secondary.main,
    fontWeight: 500,
    fontSize: 14,
  },
  approved: {
    color: theme.palette.icon.blue,
    fontWeight: 500,
    fontSize: 14,
  },
  review: {
    color: theme.palette.status.review,
    fontWeight: 500,
    fontSize: 14,
  },
  verified: {
    color: theme.palette.status.verified,
    fontWeight: 500,
    fontSize: 14,
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  statusText: {
    fontSize: 10,
  },
  button: {
    fontWeight: "600",
    fontSize: "13px",
    textTransform: "capitalize",
    background: theme.palette.Primary.background,
    "&:hover": {
      backgroundColor: theme.palette.Primary.background,
      color: theme.palette.Primary.light,
    },
  },
  headerButton: {
    display: "flex",
    justifyContent: "flex-end",
  },
  switchColor: {
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "white",
    },
    "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
      backgroundColor: theme.palette.status.closed,
      opacity: 1.5,
    },
  },
  container: {
    padding: 0,
    height: "100%",
    width: "100%",
    maxWidth: "100%",
  },
  filterContainer: {
    display: "flex",
    padding: theme.spacing(4),
    "& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl": {
      backgroundColor: theme.palette.background.white,
    },
  },
  topics: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    // padding: theme.spacing(4),
    width: "100%",
    margin: "auto",
  },
  containerBox: {
    boxShadow: "none !important",
    borderRadius: 5,
    height: "fit-content",
    width: "80%",
    margin: "auto",
    zIndex: 1,
    position: "relative",
    marginTop: "-5px",
    "& .MuiPaper-root": {
      boxShadow: "none !important",
    },
  },
  headings: {
    fontWeight: 700,
    fontSize: 20,
    paddingTop: theme.spacing(4),
    whiteSpace: "nowrap",
  },
  headingTitle: {
    color: theme.palette.Primary.background,
    fontWeight: 600,
  },
  button: {
    width: "100%",
    backgroundColor: `${theme.palette.Primary.background}!important`,
    fontStyle: "normal",
    fontWeight: "600!important",
    fontSize: "13px!important",
    textTransform: "capitalize",
  },
  buttonSecondary: {
    color: theme.palette.Primary.background,
    borderColor: theme.palette.Primary.background,
    "&:hover": {
      borderColor: theme.palette.Primary.background,
      backgroundColor: theme.palette.Primary.contrastText,
      border: "2px solid",
    },
    marginRight: theme.spacing(5),
  },
  tabs: {
    zIndex: 1,
    position: "relative",
    "& .MuiTabs-indicator": {
      backgroundColor: theme.palette.login.loginButton,
      display: "none",
    },
    "& .MuiTabs-flexContainer": {
      width: "70%",
      margin: "auto",
      display: "flex",
      justifyContent: "space-between",
      position: "relative",
      zIndex: 2,
      borderRadius: 5,
      backgroundColor: "#E0E0E0",
      borderTop: "1px solid #5A404E",
      borderLeft: "1px solid #5A404E",
      borderRight: "1px solid #5A404E",
      "@media screen and (min-width: 1706px) and (min-height: 377px)": {
        width: "55% !important",
      },
    },
    "& .MuiTab-root": {
      color: theme.palette.text.primary,
    },
    "& .MuiTab-root.Mui-selected": {
      color: `${theme.palette.login.loginButton} !important`,
    },
  },
  tabOption: {
    textTransform: "capitalize",
    fontWeight: 700,
    fontSize: 24,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  mainTitle: {
    padding: 0,
    margin: theme.spacing(0, 0, 10, 20),
  },
  headingText: {
    position: "relative",
  },
  underline: {
    position: "absolute",
    bottom: theme.spacing(-2),
    left: 0,
    width: "100%",
    height: 1,
    background:
      "linear-gradient(90deg, #FFFFFF 0%, #8F3052 30%, #8F3052 70%, #FFFFFF 100%)",
    opacity: 0.5,
  },
  tabTitle: {
    marginLeft: 8,
  },
  tabLabel: {
    display: "flex",
    alignItems: "center",
  },
  subtopicLabel: {
    "& .subtopicTitle": {
      color: theme.palette.text.dark,
    },
    "&:hover .subtopicTitle": {
      color: theme.palette.Primary.background,
      cursor: "pointer",
      fontWeight: 700,
      fontSize: 16,
    },
    "&.clicked .subtopicTitle": {
      color: theme.palette.Primary.background,
      fontWeight: 700,
      fontSize: 16,
    },
    "&:not(:hover) .clicked .subtopicTitle": {
      color: theme.palette.text.dark,
    },
  },
  tabsContainer: {
    position: "relative",
    backgroundColor: theme.palette.text.grey,
  },
  tabBorder: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    height: "3px",
    backgroundColor: "#5A404E",
  },
  bottomBox: {
    borderBottom: "3px solid #5A404E",
    borderLeft: "3px solid #5A404E",
    borderRight: "3px solid #5A404E",
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    padding: theme.spacing(1, 5, 3, 5),
  },
  yearSelection: {
    width: "35%",
    height: "50px",
    "& .MuiBox-root > .MuiAutocomplete-root > .MuiFormControl-root > .Mui-focused":
      {
        color: "#686868",
      },
  },
  contentSection: {
    padding: "4px 20px 12px 20px",
    borderLeft: "3px solid #5A404E",
    borderRight: "3px solid #5A404E",
    borderBottom: "3px solid #5A404E",
    borderBottomLeftRadius: "5px",
    borderBottomRightRadius: "5px",
  },
  inactiveSwitchColor: {
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "grey",
    },
    "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
      backgroundColor: "grey",
      opacity: 1.5,
    },
  },
  switchColorInactive: {
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "white",
    },
    "& .MuiSwitch-track": {
      backgroundColor: "red",
      opacity: 1,
    },
  },
  closeIcon: {
    position: "absolute",
    zIndex: 999,
    right: 40,
    top: 20,
    cursor: "pointer"
  },
  dialog: {
    width: "30%",
    "& .MuiPaper-root.MuiDialog-paper": {
      width: "40vw",
      margin: 80,
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 20,
    paddingBottom: 15,
    width: "100%",
  },
  buttonPrimary: {
    background: theme.palette.Primary.background,
    textTransform: "capitalize",
    fontWeight: 400,
    "&:hover": {
      backgroundColor: theme.palette.Primary.hoverButton,
      color: theme.palette.Primary.light,
    },
    width: "fit-content",
    height: 37,
    fontSize: 14,
  },
  cancelButton: {
    color: theme.palette.Primary.background,
    borderColor: theme.palette.Primary.background,
    textTransform: "capitalize",
    "&:hover": {
      borderColor: theme.palette.Primary.background,
      backgroundColor: theme.palette.Primary.contrastText,
    },
    marginLeft: theme.spacing(5),
  },
  checkboxContainer: {
    display: "flex",
    alignItems: "center",
  },
  checkbox: {
    color: "#8C2148 !important",
    borderWidth: 1,
  },
  textAreaContainer: {
    height: 'fit-content',
    backgroundColor: "white",
    width: "100%",
  },
  popupLabel:{
    width: "10vw", 
    whiteSpace: "nowrap"
  },
  deleteBox:{
    width: "60%",
    margin: `${theme.spacing(4)} auto`,    
  },
  warningButton:{
    background: theme.palette.Primary.background,
    textTransform: "capitalize",
    fontWeight: 400,
    "&:hover": {
      backgroundColor: theme.palette.Primary.hoverButton,
      color: theme.palette.Primary.light,
    },
    width: "fit-content",
    height: 37,
    fontSize: 14,
    display: "flex",
    justifyContent: "center",
    margin: `${theme.spacing(5)} auto`,
  },
  popUp: {
    margin: "auto",
    minHeight:150
  },
}));

export default useStyles;
