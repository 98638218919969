import React from 'react';
import CeroTable from '../../../../components/CeroTable';
import useStyles from './styles';

const BulkValidationTable = ({ data }) => {
  if (data == null) {
    return null; 
  }
  const columnKeys = Object.keys(data);
  
  const dynamicColumns = columnKeys.map((key) => ({
    columnKey: key,
    columnId: key,
    columnHeader: key,
  }));

  const tableData = Object.keys(data[columnKeys[0]]).reduce((filteredRows, index) => {
    if (data.Status[index] !== 'Valid') {
      const rowData = { sector: index };
      columnKeys.forEach((key) => {
        const cellData = data[key][index] != null ? data[key][index] : '';
        if (key === 'Status') {
          rowData[key] = <span style={{color: 'red', fontWeight: 500}}>{cellData}</span>;
        } else {
          rowData[key] = cellData;
        }
      });
      filteredRows.push(rowData);
    }
    return filteredRows;
  }, []);
  
  return (
    <CeroTable
      columns={dynamicColumns}
      data={tableData}
      hasMore={false}
      loading={false}
    />
  );
};

export default BulkValidationTable;
