import * as yup from 'yup';

export const addPurchasedElectricityValidation = yup.object({
    facility: yup
        .string('Facility is required')
        .required('Facility is required'),
    year: yup
        .string('Year is required')
        .required('Year is required'),
    month: yup
        .string('Month is required')
        .required('Month is required'),
    unit: yup
        .string('Unit is required')
        .required('Unit is required'),
    calculationApproach: yup
        .string('Calculation approach is required')
        .required('Calculation approach is required'),
    amount: yup
    .number()
    .typeError('Amount of fuel must be a valid number')
    .required('Amount of fuel is required')
    .strict(true)
});
