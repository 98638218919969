import { MenuItem, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import clsx from "clsx";

import useStyles from "./styles";

const CeroSelect = (props) => {
    
    const classes = useStyles();
    const options = props.options?.length > 0 ? props.sortOption  ? props.options.sort((a, b) => a.value.localeCompare(b.value)) : props.options : [] 
    
    return (
        <Box className={clsx(classes.container, props.classes?.container)} >
            <TextField
                id={props.id}
                name={props.name}
                label={props.label}
                variant={props.variant || "outlined"}
                value={props.selectedValue}
                required={!!props.required}
                disabled={!!props.disabled}
                error={!!props.error}
                onChange={props.onChange}
                onBlur={props.onBlur}
                className={classes.input}
                classes={{ root: props.classes?.root }}
                size={props.size || "medium"}
                SelectProps={{ 
                    classes: {
                        select: props.classes?.select,
                    },
                    // MenuProps:{
                    //     classes: { paper: classes.paperRoot }
                    // }
                    MenuProps: {
                        classes: {
                          paper: `${classes.paperRoot} ${props.classes?.paperRoot}` 
                        }
                      }
                 }}
                 InputProps={{
                    classes: {
                        notchedOutline: classes.notchedOutline, // Customize the style for the TextField outline
                    },
                }}
                select
            >
                <MenuItem className={classes.menuItem} value="">
                    Select option
                </MenuItem>
                
                {options && props.disableItem?.length >0 ? options.map((option) => (
                    <MenuItem className={classes.menuItem} key={option.key} value={option.key} disabled={props.disableItem.includes(option.value)}>
                        {option.value}
                    </MenuItem>
                )) : options.map((option) => (
                    <MenuItem className={classes.menuItem} key={option.key} value={option.key} >
                        {option.value}
                    </MenuItem>
                ))}
            </TextField>
            {!!props.error && <Typography className={clsx(classes.text, classes.error, props.classes?.error)} >{props.error}</Typography>}
        </Box >
    );
};

export default CeroSelect;
