import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, CircularProgress, Container, Typography } from "@mui/material";

import { getCompanyList } from "../../redux/actions";

import DashboardLayout from "../../layouts/DashboardLayout";
import useStyles from "./styles";
import CompanyTable from "./CompanyTable";
import { STATUS } from "../../redux/constants";

const savedPage = {
  // To be used from the api response
  pageNumber: 1,
};

const DEFAULT_ITEMS_PER_PAGE = 20;

const CompanyList = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const companyList = useSelector((state) => state.company.companyList.data);

  const companyListStatus = useSelector(
    (state) => state.company.companyList.status
  );

  useEffect(() => {
    dispatch(getCompanyList());
  }, [dispatch]);

  const onLoadMore = (pageSize = DEFAULT_ITEMS_PER_PAGE, pageNumber) => {
    const filter = {
      emissionFilter: {},
      page: {
        pageSize,
        pageNumber: pageNumber || (savedPage.pageNumber || 0) + 1,
      },
    };
    // api call with filter
  };

  return (
    <DashboardLayout>
      <Container className={classes.container}>
        {companyListStatus === STATUS.SUCCESS ? (
          <CompanyTable companyList={companyList} onLoadMore={onLoadMore} />
        ) : (
          <Box className={classes.loader}>
            <Typography variant="h7" component="span">
              {companyListStatus === STATUS.RUNNING ? (
                <CircularProgress />
              ) : companyListStatus === STATUS.ERROR ? (
                "We couldn't process your request. Please try again later"
              ) : (
                ""
              )}
            </Typography>
          </Box>
        )}
      </Container>
    </DashboardLayout>
  );
};

export default CompanyList;
