import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Grid,
  Typography,
  Box,
} from "@mui/material";
import useStyles from "./styles";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CeroItemPair from "../../../../../components/CeroItemPair";
import CeroInfoPair from "../../../../../components/CeroInfoPair";

const EmployeeTrainingHours = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {
    emissionData,
  } = props;
  return (
    <>
      <div
        className={classes.backContainer}
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowBackIosIcon />
        Back
      </div>
      <Container className={classes.container}>
        <Box className={classes.innerContainer}>
          <Box className={classes.header}>
            <Typography variant="h6" component="div">
              Training Hours per Employee
            </Typography>
          </Box>
          <Box className={classes.actionBox}>
            <CeroInfoPair
              title="Status:"
              value={emissionData.status ? emissionData.status : "NA"}
              classes={{
                container: classes.statusContainer,
                title: classes.statusTitle,
                value:
                  emissionData.status === "In Progress"
                    ? classes.Inprogress
                    : classes[emissionData?.status],
              }}
            />
          </Box>
          <Box className={classes.topContainer}>
            <Grid
              container
              direction={"row"}
              wrap="nowrap"
              justifyContent={"space-between"}
              spacing={12}
              display={"contents"}
            >
              <Grid item container direction={"column"} display={"contents"} xs={10}>
                <CeroItemPair
                  title="Facility:"
                  value={
                    emissionData.facility_name
                      ? emissionData.facility_name
                      : "NA"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.title,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Period:"
                  value={
                    emissionData.assessment_period
                      ? emissionData.assessment_period
                      : "NA"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.title,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Year:"
                  value={emissionData.year ? emissionData.year : "NA"}
                  classes={{
                    container: classes.infoContainer,
                    title: classes.title,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Training Title:"
                  value={
                    emissionData.training_title
                      ? emissionData.training_title
                      : "NA"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.title,
                    value: classes.value,
                  }}
                />

                <CeroItemPair
                  title="Training Details:"
                  value={
                    emissionData.training_details
                      ? emissionData.training_details
                      : "NA"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.title,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Training Date:"
                  value={emissionData.date ? emissionData.date : "NA"}
                  classes={{
                    container: classes.infoContainer,
                    title: classes.title,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Training Methods:"
                  value={
                    emissionData.training_methods
                      ? emissionData.training_methods
                      : "NA"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.title,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Total Number of Training Hours to employees:"
                  value={
                    emissionData.total_training_hours
                      ? emissionData.total_training_hours
                      : "NA"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.title,
                    value: classes.value,
                  }}
                />

                <CeroItemPair
                  title="Total Number of Training Hours to Female employees:"
                  value={
                    emissionData.female_training_hours
                      ? emissionData.female_training_hours
                      : "NA"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.title,
                    value: classes.value,
                  }}
                />

                <CeroItemPair
                  title="Total Number of Training Hours to Male employees:"
                  value={
                    emissionData.male_training_hours
                      ? emissionData.male_training_hours
                      : "NA"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.title,
                    value: classes.value,
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default EmployeeTrainingHours;
