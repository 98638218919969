import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Paper, Typography } from "@mui/material"
import { Chart as ChartJS, Tooltip, Legend, ArcElement, registerables as registerablesJS } from 'chart.js';
import { facilityDistribution, getEsgOverview } from '../../../redux/actions';

import useStyles from './styles'
import EsgOverviewChart from './EsgOverviewChart';
import FacilityDistributionChart from './FacilityDistributionChart';
import { getCookie } from '../../../services/cookie';

ChartJS.register(...registerablesJS)
ChartJS.register(ArcElement, Tooltip, Legend);

const GeographicalChart = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch();

    const role = getCookie('role')
    const facilityData = useSelector(state => state.dashboard.facilityDistribution.data);

    const facilityDataStatus = useSelector(state => state.dashboard.facilityDistribution.status);
    const esgOverviewStatus = useSelector(state => state.dashboard.getEsgOverview.status);

    const esgOverview = useSelector(
        (state) => state.dashboard.getEsgOverview.data.response
      );

      

    useEffect(() => {
        dispatch(getEsgOverview(props.filter));
        dispatch(facilityDistribution(props.filter));

    }, [props.filter, dispatch]);

    return (
      <Paper className={classes.container}>
        {role === "admin" || role === "facility_manager" ? (
          <>
            <Typography className={classes.graphTitle}>
              Facility Distribution
            </Typography>
            <div className={classes.chart}>
              {facilityData.length > 0 ? (
                <FacilityDistributionChart data={facilityData} />
              ) :facilityDataStatus === "running"? <div style={{ minHeight: 250 }}>Loading...</div>: (
                <div style={{ minHeight: 250 }}>No Data Available</div>
              )}
            </div>
          </>
        ) : (
          <>
            <Typography className={classes.graphTitle}>ESG Overview</Typography>
            <div className={classes.doughnutChart}>
              {esgOverview?.length > 0 ? (
                <EsgOverviewChart data={esgOverview} />
              ) :esgOverviewStatus === "running"? <div style={{ minHeight: 250 }}>Loading...</div>: (
                <div style={{ minHeight: 250 }}>No Data Available</div>
              )}
            </div>
          </>
        )}
      </Paper>
    );
}

export default GeographicalChart
