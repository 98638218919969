import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Paper,
  Typography,
} from "@mui/material";
import DashboardLayout from "../../layouts/DashboardLayout";
import useStyles from "./styles";
import CeroItemPair from "../../components/CeroItemPair";
import { getSubscriptionDetails } from "../../redux/actions";
import Cookies from "js-cookie";
import { getCookie } from "../../services/cookie";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const SubscriptionDetails = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();

  const companyId = Cookies.get("companyId");
  const role = getCookie("role");

  const subscriptionInfo = useSelector((state) => state.users.getSubscriptionDetails.data);

  useEffect(() => {
      dispatch(getSubscriptionDetails(role,companyId))
  }, [])


  const convertStr = (str) => {
    
  let parts = str?.split(", ");

  let processedParts = [];
  for (let i = 0; i < parts?.length; i++) {
    if (parts[i].startsWith("Supports Monthly")) {
      processedParts.push(parts[i] + ", " + parts[i + 1] + ", " + parts[i + 2]);
      i += 2;
    } else {
      processedParts.push(parts[i]);
    }
  }
  return processedParts.join("\n");
  }

  return (
    <DashboardLayout>
      <div
        className={classes.backContainer}
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowBackIosIcon />
        Back
      </div>
      <Container component="main" maxWidth="sm">
        <Paper variant="outlined" className={classes.container}>
          <Typography
            component="h1"
            variant="h5"
            className={classes.cardTitle}
            align="left"
          >
            Subscription Details
          </Typography>
          <CeroItemPair
            title="Plan:"
            value={subscriptionInfo.plan || "NA"}
            classes={{
              container: classes.infoContainer,
              title: classes.title,
              value: classes.value,
            }}
          />
          <CeroItemPair
            title="Plan Details:"
            value={convertStr(subscriptionInfo.details) || "NA"}
            classes={{
              container: classes.infoContainer,
              title: classes.title,
              value: classes.planvalue,
            }}
          />
          <CeroItemPair
            title="Start Date:"
            value={subscriptionInfo.subscription_start_date || "NA"}
            classes={{
              container: classes.infoContainer,
              title: classes.title,
              value: classes.value,
            }}
          />
          <CeroItemPair
            title="End Date:"
            value={subscriptionInfo.subscription_end_date || "NA"}
            classes={{
              container: classes.infoContainer,
              title: classes.title,
              value: classes.value,
            }}
          />
          <CeroItemPair
            title="Remarks:"
            value={subscriptionInfo.remarks || "NA"}
            classes={{
              container: classes.infoContainer,
              title: classes.title,
              value: classes.value,
            }}
          />
          {/* <Box className={classes.buttonContainer}>
            <CeroButton
              variant="contained"
              //   onClick={handleSubmit}
              buttonText="Renew"
              className={classes.buttonPrimary}
              //   disabled={}
            />
          </Box> */}
        </Paper>
      </Container>
    </DashboardLayout>
  );
};

export default SubscriptionDetails;
