import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  CircularProgress,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import CeroButton from "../../../../components/CeroButton";
import useStyles from "./styles";
import CeroPopUp from "../../../../components/CeroPopUp";
import UserLogTable from "../../UserTable/UserLogTable";
import CloseIcon from "@mui/icons-material/Close";
import {
  getRecentLogs,
  getUserLogs,
  lockUnlockUser,
  deleteUser,
  resetUserStatus
} from "../../../../redux/actions";
import WarningIcon from "../../../../assets/icons/WarningIcon.png";
import { useNavigate } from "react-router-dom";
import { STATUS } from "../../../../redux/constants";
import { useSnackbar } from "notistack";
import { getCookie } from "../../../../services/cookie";

const EditActionButtons = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const editId = props.userId;
  const status = props.status;
  const navigate = useNavigate()
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [logAction, setLogAction] = useState();
  const [recentLog, setRecentLog] = useState(false);
  const [lockPopup, setLockPopup] = useState(false);
  const [commentPopup, setCommentPopup] = useState(false);
  const [comment, setComment] = useState("");
  const [deletePopup, setDeletePopup] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const userDetails = getCookie('user_details');
  const userDetailsObj = JSON.parse(userDetails);
  const editEmail = userDetailsObj.email;  
  const userEmail = props.userEmail;
  const [disableUser , setDisableUser] = useState(true)

  useEffect(() => {
    if(editEmail === userEmail){
      setDisableUser(false)
    }
    }, [editEmail,userEmail]);

    const logData = useSelector((state) => state.users.getUserLogs.data);
  const recentLogs = useSelector((state) => state.users.getRecentLogs.data);
  const lockStatus = useSelector((state) => state.users.lockUnlockUser);

  useEffect(() => {
    if (editId) {
      dispatch(getUserLogs(editId));
      dispatch(getRecentLogs(editId));
    }
  }, [editId, dispatch]);

  useEffect(() => {
    if (lockStatus.status === STATUS.SUCCESS) { 
      enqueueSnackbar(lockStatus.data.message, {variant: "success", autoHideDuration: 3000});
      navigate(-1)
      dispatch(resetUserStatus())
    } else if (lockStatus.status === STATUS.ERROR) {
      enqueueSnackbar(lockStatus.message.message || "We couldn't process your request. Please try again later.",
        { variant: "error", autoHideDuration: 3000}
      );
      navigate(-1)
      dispatch(resetUserStatus())
    }
  }, [lockStatus, dispatch, enqueueSnackbar]);


  const handleView = (id) => {
    setLogAction(null);
    setRecentLog(false);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleDelete = () => {
    dispatch(deleteUser(editId))
    navigate("/users")
  };

  const userLogs = () => {
    return (
      <>
        <CloseIcon onClick={handleClosePopup} className={classes.closeIcon} />
        <UserLogTable logData={logData} />
      </>
    );
  };

  const userLock = () => {
    const hasRecentLogs = Object.keys(recentLogs).length !== 0;
    return (
      <>
        {hasRecentLogs ? (
          <>
            <CloseIcon
              onClick={() => setLockPopup(false)}
              className={classes.closeIcon}
            />
            {logAction === "Active" ? (
              <Typography style={{ textAlign: "center", paddingTop: 10 }}>
                Do you want to lock the user?
              </Typography>
            ) : Object.keys(recentLogs).length !== 0 ? (
              recentLogs.is_locked_by_admin === true ? (
                !recentLog ? (
                  <>
                    <Typography
                      style={{ paddingTop: 20, whiteSpace: "nowrap" }}
                    >
                      The user has been locked by the admin. Do you want to
                      unlock the user?
                    </Typography>
                    <Typography style={{ color: "grey" }}>
                      To know why the user has been Locked,{" "}
                      <Link onClick={() => setRecentLog(true)}>Click here</Link>
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography style={{ paddingTop: 20 }}>
                      The user has been locked by admin. Do you want to unlock
                      the user?
                    </Typography>
                    <UserLogTable logData={recentLogs?.logs} />
                  </>
                )
              ) : (
                <Typography style={{ paddingTop: 20 }}>
                  The user has been locked due to multiple unsuccessful password
                  attempts. Do you want to unlock the user?
                </Typography>
              )
            ) : (
              <Typography style={{ paddingTop: 20 }}></Typography>
            )}
            <div className={classes.buttonContainerReview}>
              <CeroButton
                className={classes.buttonPrimary}
                variant="contained"
                onClick={() => {
                  setLockPopup(false);
                  setCommentPopup(true);
                }}
                buttonText="Yes"
              />
              <CeroButton
                className={classes.buttonSecondary}
                variant="outlined"
                onClick={() => setLockPopup(false)}
                buttonText="No"
              />
            </div>
          </>
        ) : (
          <CircularProgress />
        )}
      </>
    );
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleSaveComment = () => {
    const status = logAction === "Active" ? "lock" : "unlock";
    let data = {};
    if (recentLog) {
      data = {
        is_locked_by_admin: true,
        comment: comment,
      };
    } else {
      data = {
        is_locked_by_admin: false,
        comment: comment,
      };
    }
    dispatch(lockUnlockUser(editId, status, data));
    setComment("");
    setCommentPopup(false);
  };

  const getComments = () => {
    return (
      <>
        <TextField
          id="outlined-multiline-static"
          multiline
          rows={5}
          placeholder="Comment"
          style={{ width: "100%" }}
          value={comment}
          onChange={handleCommentChange}
          className={classes.textField}
        />
        <div className={classes.textLimit}>
          <div className={classes.noComments}>
            {comment.trim().length === 0 ? "No comments" : ""}
          </div>
          <div className={classes.maxCharacters}>
            {"Maximum 200 characters"}
          </div>
        </div>
        <div className={classes.buttonContainer}>
          <Button
            className={classes.buttonPrimary}
            variant="contained"
            onClick={handleSaveComment}
            disabled={comment.trim().length === 0}
          >
            Save
          </Button>
          <Button
            className={classes.buttonSecondary}
            variant="outlined"
            onClick={() => setCommentPopup(false)}
          >
            Cancel
          </Button>
        </div>
      </>
    );
  };

  const getDeleteContent = () => {
    return (
      <>
        <CloseIcon
          onClick={() => setDeletePopup(false)}
          className={classes.closeIcon}
        />
        <Grid container spacing={2} alignItems="center" className={classes.deleteBox}>
          <Grid item xs={2} className={classes.icon}>
            <img src={WarningIcon} alt="Logo" width="40" height="40" />
          </Grid>
          <Grid item xs ={10}>
            <Typography variant="h6">Delete User</Typography>
            <Typography className={classes.DialogSubtitle}>
              Are you sure you want to DELETE this user?
            </Typography>
          </Grid>
        </Grid>
            <div className={classes.buttonContainer}>
              <CeroButton
                className={classes.buttonPrimary}
                variant="contained"
                onClick={() => {
                  setDeletePopup(false);
                  setCommentPopup(true);
                  handleDelete()
                }}
                buttonText="Confirm"
              />
              <CeroButton
                className={classes.buttonSecondary}
                variant="outlined"
                onClick={() => setDeletePopup(false)}
                buttonText="Cancel"
              />
            </div>
      </>
    );
  };
  return (
    <div>
      <CeroButton
        buttonText="View Logs"
        className={classes.buttonPrimary}
        onClick={(e) => {
          handleView(editId);
          setIsPopupOpen(true);
        }}
      />
      {disableUser && 
      <>
      <CeroButton
        buttonText="Delete User"
        className={classes.buttonPrimary}
        onClick={(e) => setDeletePopup(true)}
      />
      <CeroButton
        buttonText={status === "Active" ? "Lock User" : "Unlock User"}
        className={classes.buttonPrimary}
        onClick={() => {
          setLockPopup(true);
          setLogAction(status);
        }}
      /></>}
      <CeroPopUp
        primaryPopUp={{
          open: isPopupOpen,
          onClose: () => setIsPopupOpen(false),
          content: userLogs(),
          header: { title: "Logs" },
        }}
      />
      <CeroPopUp
        primaryPopUp={{
          open: lockPopup,
          onClose: () => setLockPopup(false),
          content: userLock(),
          header: { title: "" },
        }}
        classes={{ dialog: classes.dialog }}
      />
      <CeroPopUp
        primaryPopUp={{
          open: commentPopup,
          onClose: () => setCommentPopup(false),
          content: getComments(),
          header: { title: "Comments*" },
        }}
        classes={{ dialog: classes.dialog }}
      />
      <CeroPopUp
        primaryPopUp={{
          open: deletePopup,
          onClose: () => setDeletePopup(false),
          content: getDeleteContent(),
          header: { title: "" },
        }}
        classes={{ dialog: classes.dialog }}
      />
    </div>
  );
};

export default EditActionButtons;
