import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Collapse,
  Container,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import useStyles from "./styles";
import {
  reportEmissionTypeData,
} from "../../../../constants";
import CeroTable from "../../../../components/CeroTable";
import EmissionTable from "../EmissionList/EmissionTable";
import HireTable from "../EmissionList/HireAndTurnOver";
import {
  BoardDiversityByAgeColumnsReport,
  BoardDiversityByGenderColumnsReport,
  DiversityByAgeColumnsReport,
  DiversityByGenderColumnsReport,
  DiversityBySalaryColumnsReport,
  EmployeeByAgeColumnsReport,
  EmployeeByGenderColumnsReport,
  ManagementDiversityByAgeColumnsReport,
  ManagementDiversityByGenderColumnsReport,
} from "../../../EmissionList/EmissionTable/TableColumnReport";
import StakeholderPdf from "../../../StakeholderReports/ReportDetails/StakeholderPdf";
import { getCookie } from "../../../../services/cookie";

export default function ReportAccordion(props) {
  const classes = useStyles();
  const approvalTopics = props.approvalData?.map((item) => item.type);
  const role = getCookie("role")
  const showAuditorTableForEmpHire = window.location.pathname.includes("/companies") && role === "auditor"
  const mainTopicListToShow = props.mainTopicListToShow
    ? props.mainTopicListToShow
    : ["environmental", "social", "governance"];
  const [open, setOpen] = useState("");
  const [open1, setOpen1] = useState("");
  const [open2, setOpen2] = useState("");

  const titleColorMap = {
    "In Progress": "#F7931D",
    audited: "#024FE4",
    added: "#7B7B7B",
    approved: "#48742C",
    review: "#D3291D",
    submitted: "#F7931D",
    assigned: "#F7931D",
    verified: "#00FFFF"
  };

  const getBackgroundColor = (title) => {
    const status = props.reportSignals[title.toLowerCase()];
    return props.reportSignals ? (status ? titleColorMap[status] : "") : "";
  };
  const diversityColumnMapping = {
    employees_diversity_and_ratio_of_basic_salary: {
      gender: DiversityByGenderColumnsReport,
      age: DiversityByAgeColumnsReport,
      salary: DiversityBySalaryColumnsReport,
      displayName: "Employee",
    },
    board_diversity: {
      gender: BoardDiversityByGenderColumnsReport,
      age: BoardDiversityByAgeColumnsReport,
      displayName: "Board",
    },
    management_diversity: {
      gender: ManagementDiversityByGenderColumnsReport,
      age: ManagementDiversityByAgeColumnsReport,
      displayName: "Management",
    },
  };
  const getStatusColor = (title) => {
    const matchingSubCategory = props.topicSignals.find(
      (subCategory) => subCategory.sub_category_type === title
    );
    return matchingSubCategory ? titleColorMap[matchingSubCategory.status] : "";
  };

  const getTitle = (title) => {
    const subTopicTitle = props?.topicSignals.find(
      (item) => item.sub_category_type === title
    );
    return subTopicTitle?.status;
  };

  const subTopicsColor = (title) => {
    const status = props.subTopicSignals[title];
    return props.subTopicSignals
      ? status
        ? titleColorMap[status]
        : "#7B7B7B"
      : "#7B7B7B";
  };
  const getColorStatus = (item) => {
    if (item === "approved") {
      return (
        <Tooltip title="Approved" arrow>
          <Chip className={classes.signalApproved} />
        </Tooltip>
      );
    } else if (item === "submitted") {
      return (
        <Tooltip title="Submitted" arrow>
          <Chip className={classes.signalPending} />
        </Tooltip>
      );
    } else if (item === "audited") {
      return (
        <Tooltip title="Audited" arrow>
          <Chip className={classes.signalAudited} />
        </Tooltip>
      );
    } else if (item === "review") {
      return (
        <Tooltip title="Review" arrow>
          <Chip className={classes.signalReview} />
        </Tooltip>
      );
    } else if (item === "added") {
      return (
        <Tooltip title="Added" arrow>
          <Chip className={classes.signalAdded} />
        </Tooltip>
      );
    } else if (item === "assigned") {
      return (
        <Tooltip title="assigned" arrow>
          <Chip className={classes.signalPending} />
        </Tooltip>
      );
    } else if (item === "Awaiting approval") {
      return (
        <Tooltip title="Awaiting approval" arrow>
          <Chip className={classes.signalPending} />
        </Tooltip>
      );
    } else if (item === "In Progress") {
      return (
        <Tooltip title="In Progress" arrow>
          <Chip className={classes.signalPending} />
        </Tooltip>
      );
    }  else if (item === "verified") {
      return (
        <Tooltip title="Verified" arrow>
          <Chip className={classes.signalVerified} />
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title={item} arrow>
          <Chip className={classes.signalAdded} />
          {item}
        </Tooltip>
      );
    }
  };

  const getTableData = (data) => {
    const getEmployeeAge = () => {
      return data.emissionData[0].employee_hire_age.map((item) => ({
        ...item,
        status: getColorStatus(item.status || data.emissionData[0].status),
        type: data.id,
        turn_over : item.turnover_rate,
        distribution: "age",
      }));
    }

    const getEmployeeGender = () => {
      return data.emissionData[0].employee_hire_gender.map((item) => ({
        ...item,
        status: getColorStatus(item.status || data.emissionData[0].status),
        type: data.id,
        turn_over : item.turnover_rate,
        distribution: "gender",
      }));
    };

    const getGenderDistribution = () => {
      return data.emissionData[0].gender.map((item) => ({
        ...item,
        status: getColorStatus(item.status || data.emissionData[0].status),
        type: data.id,
        distribution: "gender",
      }));
    };
    const getAgeDistribution = () => {
      return data.emissionData[0].age.map((item) => ({
        ...item,
        status: getColorStatus(item.status || data.emissionData[0].status),
        type: data.id,
        distribution: "age",
      }));
    };
    const getSalaryDistribution = () => {
      return data.emissionData[0].basic_salary.map((item) => ({
        ...item,
        status: getColorStatus(item.status || data.emissionData[0].status),
        type: data.id,
        distribution: "basic_salary_ratio",
      }));
    };

    return (
      <Box className={classes.lastCard}>
        {[
          "employees_diversity_and_ratio_of_basic_salary",
          "board_diversity",
          "management_diversity",
        ].includes(data.id) ? (
          <>
            <Typography variant="body1" className={classes.subTitle}>
              {diversityColumnMapping[data.id]["displayName"]} Diversity by
              Gender
            </Typography>
            <CeroTable
              columns={diversityColumnMapping[data.id]["gender"]}
              data={getGenderDistribution}
              hasMore={false}
              loading={false}
              onSelectRow={
                props?.isStakeholder
                  ? () => {
                      console.log("");
                    }
                  : props.onSelectData
              }
              classes={{ tableContainer: classes.tableContainer }}
            />
            <Typography variant="body1" className={classes.subTitle}>
              {diversityColumnMapping[data.id]["displayName"]} Diversity by Age
              Group
            </Typography>
            <CeroTable
              columns={diversityColumnMapping[data.id]["age"]}
              data={getAgeDistribution}
              hasMore={false}
              loading={false}
              onSelectRow={
                props?.isStakeholder
                  ? () => {
                      console.log("");
                    }
                  : props.onSelectData
              }
              classes={{ tableContainer: classes.tableContainer }}
            />
            {data.id === "employees_diversity_and_ratio_of_basic_salary" ? (
              <>
                <Typography variant="body1" className={classes.subTitle}>
                  Ratio of Basic Salary
                </Typography>
                <CeroTable
                  columns={diversityColumnMapping[data.id]["salary"]}
                  data={getSalaryDistribution}
                  hasMore={false}
                  loading={false}
                  onSelectRow={
                    props?.isStakeholder
                      ? () => {
                          console.log("");
                        }
                      : props.onSelectData
                  }
                  classes={{ tableContainer: classes.tableContainer }}
                />
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <>
            <Typography variant="body1" className={classes.subTitle}>
              Employee Hire & Turnover by Gender
            </Typography>
             {showAuditorTableForEmpHire ? <CeroTable
              columns={EmployeeByGenderColumnsReport}
              data={getEmployeeGender}
              hasMore={false}
              loading={false}
              onSelectRow={
                props?.isStakeholder
                  ? () => {
                      console.log("");
                    }
                  : props.onSelectData
              }
              classes={{ tableContainer: classes.tableContainer }}
            /> :
            <HireTable
              inputData={data.emissionData[0]}
              type={"gender"}
              onSelectRow={
                props?.isStakeholder
                  ? () => {
                      console.log("");
                    }
                  : props.onSelectData
              }
            />}
            <Typography variant="body1" className={classes.subTitle}>
              Employee Hire & Turnover by Age Group
            </Typography>
               {showAuditorTableForEmpHire ? 
              <CeroTable
              columns={EmployeeByAgeColumnsReport}
              data={getEmployeeAge}
              hasMore={false}
              loading={false}
              onSelectRow={
                props?.isStakeholder
                  ? () => {
                      console.log("");
                    }
                  : props.onSelectData
              }
              classes={{ tableContainer: classes.tableContainer }}
            /> :
            <HireTable
              inputData={data.emissionData[0]}
              type={"age"}
              onSelectRow={
                props?.isStakeholder
                  ? () => {
                      console.log("");
                    }
                  : props.onSelectData
              }
            />}
          </>
        )}
      </Box>
    );
  };
  const selectedEmissionTypeData = reportEmissionTypeData
    .filter((item) => mainTopicListToShow.includes(item.id))
    .map((category) => {
      const filteredSubItems = category.subItems
        .map((subItem) => ({
          ...subItem,
          subItems: subItem.subItems
            .filter((item) => approvalTopics?.includes(item.id))
            .map((subItemData) => {
              const emissionEntry = props.approvalData?.filter(
                (entry) => entry.type === subItemData.id
              );
              return {
                ...subItemData,
                emissionData: emissionEntry || null,
              };
            }),
        }))
        .filter((subItem) => subItem.subItems.length > 0);

      return {
        ...category,
        subItems: filteredSubItems,
      };
    });

  return (
    <Box style={{ marginBottom: 15}}>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
      >
        {props?.isStakeholder && (
          <StakeholderPdf
            Year={props.year}
            facilities={""}
            emission_types={props.topics}
          />
        )}
      </Stack>
      {/* // 1nd start */}
      {selectedEmissionTypeData?.map((topic, index) => (
        <Card
          key={index}
          sx={{
            border: "1px solid #5A404E",
            borderRadius: 0,
          }}
        >
          <CardHeader
            title={topic.title}
            avatar={
              <>
                <Tooltip
                  title={
                    props.reportSignals[topic.title.toLowerCase()] || "No Data"
                  }
                  arrow
                >
                  <Box
                    component="span"
                    sx={{
                      bgcolor: getBackgroundColor(topic.title),
                      width: 16,
                      height: 16,
                      borderRadius: "50%",
                      border: "1px solid black",
                    }}
                  />
                </Tooltip>
              </>
            }
            action={
              <IconButton
                onClick={() =>
                  open === topic.id ? setOpen("") : setOpen(topic.id)
                }
                aria-label="expand"
                size="small"
              >
                {props.reportSignals[topic.title.toLowerCase()] &&
                  (open === topic.id ? <RemoveIcon /> : <AddIcon />)}
              </IconButton>
            }
            sx={{ bgcolor: "#D0C5CA" }}
          ></CardHeader>
          <Collapse in={open === topic.id} timeout="auto" unmountOnExit>
            {props.reportSignals === null ? (
              <CardContent>No Data</CardContent>
            ) : (
              <CardContent className="cardContentReport">
                <Container className="containercard">
                  {/* // 2nd start */}
                  {topic.title === "Environmental" ? (
                    <>
                      {topic.subItems.map((topic, index) => (
                        <Card
                          key={index}
                          sx={{
                            minWidth: "100%",
                            border: "1px solid #5A404E",
                            borderRadius: 0,
                          }}
                        >
                          <CardHeader
                            title={topic.title}
                            avatar={
                              <>
                                <Tooltip title={getTitle(topic.title)} arrow>
                                  <Box
                                    component="span"
                                    sx={{
                                      bgcolor: getStatusColor(topic.title),
                                      width: 16,
                                      height: 16,
                                      borderRadius: "50%",
                                      border: "1px solid #5A404E",
                                    }}
                                  />
                                </Tooltip>
                              </>
                            }
                            action={
                              <IconButton
                                onClick={() =>
                                  open1 === topic.id
                                    ? setOpen1("")
                                    : setOpen1(topic.id)
                                }
                                aria-label="expand"
                                size="small"
                              >
                                {open1 === topic.id ? (
                                  <RemoveIcon />
                                ) : (
                                  <AddIcon />
                                )}
                              </IconButton>
                            }
                            sx={{ bgcolor: "#EAE2E6" }}
                          ></CardHeader>
                          <Collapse
                            in={open1 === topic.id}
                            timeout="auto"
                            unmountOnExit
                          >
                            <CardContent className="cardContentReport">
                              <Container className={classes.containercardTable}>
                                <CeroTable
                                  columns={
                                    props.aggreatedData[topic.id]?.columns || []
                                  }
                                  data={
                                    props.aggreatedData[topic.id]?.data || []
                                  }
                                  hasMore={false}
                                  loading={false}
                                  onSelectRow={
                                    props?.isStakeholder
                                      ? () => {
                                          console.log("");
                                        }
                                      : props.onSelectData
                                  }
                                  classes={{
                                    tableContainer: classes.tableContainer,
                                  }}
                                />
                              </Container>
                            </CardContent>
                          </Collapse>
                        </Card>
                      ))}
                    </>
                  ) : (
                    <>
                      {topic.subItems.map((topic, index) => (
                        <Card
                          key={index}
                          sx={{
                            minWidth: "100%",
                            border: "1px solid #5A404E",
                            borderRadius: 0,
                          }}
                        >
                          <CardHeader
                            title={topic.title}
                            avatar={
                              <>
                                <Tooltip title={getTitle(topic.title)} arrow>
                                  <Box
                                    component="span"
                                    sx={{
                                      bgcolor: getStatusColor(topic.title),
                                      width: 16,
                                      height: 16,
                                      borderRadius: "50%",
                                      border: "1px solid #5A404E",
                                    }}
                                  />
                                </Tooltip>
                              </>
                            }
                            action={
                              <IconButton
                                onClick={() =>
                                  open1 === topic.id
                                    ? setOpen1("")
                                    : setOpen1(topic.id)
                                }
                                aria-label="expand"
                                size="small"
                              >
                                {open1 === topic.id ? (
                                  <RemoveIcon />
                                ) : (
                                  <AddIcon />
                                )}
                              </IconButton>
                            }
                            sx={{ bgcolor: "#EAE2E6" }}
                          ></CardHeader>
                          <Collapse
                            in={open1 === topic.id}
                            timeout="auto"
                            unmountOnExit
                          >
                            <CardContent className="cardContentReport">
                              <Container className="containercard">
                                {/* 3rd level of collapse Need to work on this*/}
                                {topic.subItems.map(
                                  (thirdLevelTopic, thirdIndex) => (
                                    <Card
                                      key={thirdIndex}
                                      sx={{
                                        minWidth: "90%",
                                        borderTop: "1px solid #5A404E",
                                        borderBottom: "1px solid #5A404E",
                                        borderRadius: 0,
                                        backgroundColor: "white",
                                        boxShadow: "none",
                                        paddingLeft: "5%",
                                      }}
                                    >
                                      {
                                        <>
                                          {/* Commented code for third level */}
                                          <CardHeader
                                            title={thirdLevelTopic.title}
                                            avatar={
                                              <>
                                                <Tooltip
                                                  title={
                                                    props?.subTopicSignals[
                                                      thirdLevelTopic.title
                                                    ]
                                                  }
                                                  arrow
                                                >
                                                  <Box
                                                    component="span"
                                                    sx={{
                                                      bgcolor: subTopicsColor(
                                                        thirdLevelTopic.title
                                                      ),
                                                      width: 16,
                                                      height: 16,
                                                      borderRadius: "50%",
                                                      border: "1px solid black",
                                                    }}
                                                  />
                                                </Tooltip>
                                              </>
                                            }
                                            action={
                                              <IconButton
                                                onClick={() =>
                                                  open2 === thirdLevelTopic.id
                                                    ? setOpen2("")
                                                    : setOpen2(
                                                        thirdLevelTopic.id
                                                      )
                                                }
                                                aria-label="expand"
                                                size="small"
                                              >
                                                {open2 ===
                                                thirdLevelTopic.id ? (
                                                  <RemoveIcon />
                                                ) : (
                                                  <AddIcon />
                                                )}
                                              </IconButton>
                                            }
                                            sx={{ bgcolor: "#FFFFFF" }}
                                          ></CardHeader>
                                          <Collapse
                                            in={open2 === thirdLevelTopic.id}
                                            timeout="auto"
                                            unmountOnExit
                                          >
                                            <CardContent className="cardContentReport">
                                              <Container
                                                className={
                                                  classes.containercardTable
                                                }
                                              >
                                                {/* Table for the 3rd level */}
                                                {[
                                                  "employees_diversity_and_ratio_of_basic_salary",
                                                  "employees_hire_and_turnover",
                                                  "board_diversity",
                                                  "management_diversity",
                                                ].includes(
                                                  thirdLevelTopic.id
                                                ) ? (
                                                  <>
                                                    {getTableData(
                                                      thirdLevelTopic
                                                    )}
                                                  </>
                                                ) : (
                                                  <EmissionTable
                                                    emissionData={
                                                      thirdLevelTopic.emissionData
                                                    }
                                                    dataStatus={
                                                      props.emissionDataStatus
                                                    }
                                                    emissionType={
                                                      thirdLevelTopic.id
                                                    }
                                                    headerOff={true}
                                                    editOff={true}
                                                    reportTable={true}
                                                    onSelectRow={
                                                      props?.isStakeholder
                                                        ? () => {
                                                            console.log(
                                                              ""
                                                            );
                                                          }
                                                        : props.onSelectData
                                                    }
                                                  />
                                                )}
                                              </Container>
                                            </CardContent>
                                          </Collapse>
                                        </>
                                      }
                                    </Card>
                                  )
                                )}
                              </Container>
                            </CardContent>
                          </Collapse>
                        </Card>
                      ))}
                    </>
                  )}
                  {/* // 2 end */}
                </Container>
              </CardContent>
            )}
          </Collapse>
        </Card>
      ))}
    </Box>
  );
}
