import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    margin: 0,
  },
  tableContainer: {
    maxHeight: "calc(100vh - 140px)",
    width: "60%",
    margin: "auto",
    marginTop: theme.spacing(5),
  },
  loader: {
    width: "100%",
    height: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  table: {
    borderCollapse: "collapse",
  },
  tableHeader: {
    backgroundColor: "#D0C5CA",
    fontWeight: "bold",
    textAlign: "left",
    position: "sticky",
    top: 0,
    zIndex: 5,
  },
  noDataWarning: {
    textAlign: "center",
    width: "75%",
  },
}));

export default useStyles;
