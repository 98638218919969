import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Container, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DashboardLayout from "../../layouts/DashboardLayout";
import useStyles from "./styles";
import CeroTable from "../../components/CeroTable";
import { getUploadHistory, getUploadHistoryDetails } from "../../redux/actions";
import CeroButton from "../../components/CeroButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CeroPopUp from "../../components/CeroPopUp";
import UploadHistoryDetailsTable from "./UploadHistoryDetailsTable";
import CloseIcon from "@mui/icons-material/Close";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import CsvDownloadIcon from "../../assets/icons/CsvDownloadIcon.png";
import { months } from "../../constants";
import { STATUS } from "../../redux/constants";


const UploadHistoryDetails = () => {
  const generalColumnConfig = [
    {
      columnKey: "date_time",
      columnId: "date_time",
      columnHeader: "Date & Time Added",
      width: "30vw"
    },
    {
      columnKey: "facility",
      columnId: "facility",
      columnHeader: "Facility",
      width: "30vw"
    },
    {
      columnKey: "year",
      columnId: "year",
      columnHeader: "Year",
      width: "30vw"
    },
    {
      columnKey: "url",
      columnId: "url",
      columnHeader: "Data",
      width: "10vw"
    },
  ];

  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { type } = useParams();
  const emissionType = type;
  const [isUploadHistoryPopup, setisUploadHistoryPopup] = useState(false);
  const [selectedBlobUrl, setSelectedBlobUrl] = useState("");
  const [selectedEmissionType, setSelectedEmissionType] = useState(null);
  const queryParams = new URLSearchParams(window.location.search);
  const selectedMonth = queryParams.get("month");
  const selectedFacility = queryParams.get("facility");
  const selectedYear = queryParams.get("year");
  const queryParameters = [];
  if (selectedYear) {
    queryParameters.push(`year=${selectedYear}`);
  }
  if (selectedMonth) {
    queryParameters.push(`month=${selectedMonth}`);
  }
  if (selectedFacility) {
    queryParameters.push(`facility=${selectedFacility}`);
  }
  const filter = queryParameters.join("&");

  const uploadHistory = useSelector(
    (state) => state.emission.uploadHistory.data
  );
  const uploadHistoryStatus = useSelector(
    (state) => state.emission.uploadHistory.status
  );

  const historyDetails = useSelector(
    (state) => state.emission.uploadHistoryDetails?.data?.response?.data
  );

  const tableStatus = useSelector(
    (state) => state.emission.uploadHistoryDetails.status
  );

  useEffect(() => {
    dispatch(getUploadHistory(emissionType, filter));
  }, [dispatch]);

  const handleViewDataClick = (event, blob_url) => {
    event.preventDefault();
    setisUploadHistoryPopup(true);
    setSelectedEmissionType(emissionType);
    setSelectedBlobUrl(blob_url);
    dispatch(getUploadHistoryDetails(emissionType, blob_url));
  };

  const handleAuditTrailButtonClick = () => {
    setisUploadHistoryPopup(false);
  };

  const handleCloseAuditTrailPopup = () => {
    setisUploadHistoryPopup(false);
  };

  const getUploadHistoryTableData = () => {
    const formattedData = historyDetails;
    const rawData = [];
    if (rawData.length === 0) {
      return formattedData;
    }
    const columns = Object.keys(rawData[0]);
    const headerRow = {};
    columns.forEach((column) => {
      headerRow[column] = column;
    });
    formattedData.push(headerRow);
    rawData.forEach((item, index) => {
      const formattedItem = {};
      columns.forEach((column) => {
        formattedItem[column] = item[column];
      });
      formattedData.push(formattedItem);
    });
    return formattedData;
  };

  const handleDownloadExcel = () => {
    const data = getUploadHistoryTableData();
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Upload History");
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const excelBlob = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataExcel = new Blob([excelBlob], { type: fileType });
    const fileName = emissionType.replace(/_/g, " ");
    saveAs(dataExcel, fileName);
  };

  const getAuditTrailPopUp = () => {
    return (
      <>
        <CloseIcon
          onClick={handleCloseAuditTrailPopup}
          className={classes.closeIcon}
        />
        <div className={classes.underline}></div>
        <div className={classes.popupHeader}>
          <Typography variant="h6">
            {emissionType.replace(/_/g, " ")}
          </Typography>
          <img
            src={CsvDownloadIcon}
            alt="Logo"
            width="30"
            height="30"
            onClick={handleDownloadExcel}
          />
        </div>
        {tableStatus === STATUS.SUCCESS ? (
          <UploadHistoryDetailsTable historyDetails={historyDetails} />
        ) : (
          <div className={classes.loading}>
          <CircularProgress />
          </div>
        )}
      </>
    );
  };

  const formatDate = (inputDate) => {
    const [day, monthIndex, year] = inputDate.split('-').map(Number);
  const month = months.find(m => m.key === monthIndex);
  if (!month) {
    return inputDate; 
  }
  const abbreviatedMonth = month.value.slice(0, 3);
  return `${day} ${abbreviatedMonth} ${year}`;
  };

  const formatTimeToAMPM = (timeString) => {
    const [hour, minute, second] = timeString.split(":").map(Number);
    const ampm = hour >= 12 ? "PM" : "AM";
    const formattedHour = hour % 12 || 12;
    return `${formattedHour.toString().padStart(2, "0")}:${minute
      .toString()
      .padStart(2, "0")} ${ampm}`;
  };

  const getUploadHistoryTable = () => {
    if (!Array.isArray(uploadHistory)) {
      return [];
    } else {
      return uploadHistory.map((item) => ({
        ...item,
        date_time:
          item.date_added && item.time_added ? (
              <div>{formatDate(item.date_added)}, {formatTimeToAMPM(item.time_added)}</div>
          ) : (
            "-"
          ),
        facility: item.facility ? item.facility : "-",
        year: item.year ? item.year : "NA",
        month: item.month ? months.find(m => m.key == item.month)?.value : "NA",
        url: item.url ? (
          <a
            href={item.url}
            onClick={(event) => handleViewDataClick(event, item.url)}
            style={{ whiteSpace: "nowrap"}}
          >
            View data
          </a>
        ) : (
          "NA"
        ),
      }));
    }
  };

  const noTableRows = () => (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {generalColumnConfig.map((column) => (
              <TableCell key={column.columnId} className={classes.tableHeader}>
                {column.columnHeader}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell
              colSpan={generalColumnConfig.length} 
              className={classes.noDataWarning}
              align="center" 
            >
              <Typography align="center">No Data</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <DashboardLayout>
      <Container className={classes.container}>
        <Box className={classes.header}>
          <Typography className={classes.tableTitle}>
            {emissionType.replace(/_/g, " ")}: Bulk Data Upload History for last
            10 uploads
          </Typography>
          <CeroButton
            buttonText="Back"
            variant="outlined"
            className={classes.buttonSecondary}
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate(-1)}
          />
        </Box>
        { uploadHistoryStatus === STATUS.RUNNING ?
        (
        <div className={classes.loading}>
          <CircularProgress />
          </div>) 
          :(
         <>
         {uploadHistory?.length !== 0 ? <CeroTable
          columns={generalColumnConfig}
          data={getUploadHistoryTable()}
          hasMore={false}
          loading={false}
          classes={{ tableContainer: classes.tableContainer,
            tableBodyCell: classes.tableBodyCell }}
        /> :     noTableRows()}
        <CeroPopUp
          primaryPopUp={{
            open: isUploadHistoryPopup,
            onClose: { handleAuditTrailButtonClick },
            content: getAuditTrailPopUp(),
            header: { title: "Bulk Data Upload History" },
          }}
          classes={{ dialog: classes.dialog }}
        />
        </>
        )}
      </Container>
    </DashboardLayout>
  );
};

export default UploadHistoryDetails;
