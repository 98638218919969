import React from 'react';
import useStyles from './styles';
import CeroTable from '../../../../components/CeroTable';
import dayjs from 'dayjs';

const UserLogsTable = (props) => {
  const classes = useStyles();
 const TableColumns = [
  {
    columnKey: 'date',
    columnId: 'date',
    columnHeader: 'Date',
    classes: { column: classes.cellContainer }
  },
  {
    columnKey: 'time',
    columnId: 'time',
    columnHeader: 'Time',
    classes: { column: classes.cellContainer },
  },
  {
    columnKey: 'action',
    columnId: 'action',
    columnHeader: 'Action',
    classes: { column: classes.cellContainer }
  },
  {
    columnKey: 'story',
    columnId: 'story',
    columnHeader: 'Comment',
    classes: { column: classes.cellContainer }
  },
];
  const { logData, onLoadMore} = props

  const getLogs = () =>{
    if (!Array.isArray(logData)) {
      return [];
    }else{
    return logData.map((item) => ({
    ...item,
      action: item.action ? item.action : '-',
      comment: item.story ? item.story : 'NA',
      date: item.action_ts ? dayjs(item.action_ts).format("DD/MM/YYYY") : 'NA',
      time: item.action_ts ?  dayjs(item.action_ts).format("hh:mm A") : 'NA',
    }));
  }
  };

  return (
    <CeroTable
      columns={TableColumns}
      data={getLogs()}
      hasMore={false}
      loading={false}
      loadMore={onLoadMore}
      classes={{ tableDataRow: classes.tableRow,
        tableContainer: classes.tableContainer
      }}
    />
  );
};

export default UserLogsTable;
