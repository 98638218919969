import React from "react";
import styles from "./styles";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { Box, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useStyles from "./styles";

const CustomModal = ({ isOpen, onClose, fileUrl, imgUrl, title }) => {
  const classes = useStyles();
  if (!isOpen) return null;

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = "downloaded-image.jpg";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className={classes.overlay}>
      <div className={classes.modal}>
        {/* Apply dark background to this Box and make text/icons white */}
        <Box 
          display={"flex"} 
          justifyContent={"space-between"} 
          alignItems={"center"} 
          bgcolor={"#333"} // Dark background
          color={"#fff"} // White text
          p={2} // Padding to the header
        >
          <Typography variant="h6" className={classes.heading}>
            {title}
          </Typography>

          <Box display={"flex"} justifyContent={"flex-end"} gap={3} mb={2}>
            <ArrowDownwardIcon
              style={{ color: "#fff", cursor: "pointer" }} // White icon
              onClick={handleDownload}
              className={classes.icon}
            />
            <CloseIcon
              style={{ color: "#fff", cursor: "pointer" }} // White icon
              onClick={onClose}
              className={classes.icon}
            />
          </Box>
        </Box>

        <img src={imgUrl} alt="Modal content" style={{ width: "100%" }} />
      </div>
    </div>
  );
};

export default CustomModal;
