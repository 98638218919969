import React, { useEffect } from "react";
import { Box, Container, Paper, Typography } from "@mui/material";
import DashboardLayout from "../../layouts/DashboardLayout";
import useStyles from "./styles";
import CeroTable from "../../components/CeroTable";
import { useDispatch, useSelector } from "react-redux";
import CeroButton from "../../components/CeroButton";
import { getCarbonEmissions, getDashboardWidget } from "../../redux/actions";
import { useNavigate } from "react-router-dom";

const TradeDashboardInternal = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const columns = [
    {
      columnKey: "country_code",
      columnId: "country_code",
      columnHeader: "Country",
    },
    {
      columnKey: "facilities",
      columnId: "facilities",
      columnHeader: "Facilities",
    },
    {
      columnKey: "total_co2e_emissions",
      columnId: "total_co2e_emissions",
      columnHeader: "Total Co2e Emissions (tons)",
    },
    {
      columnKey: "carbon_offsets",
      columnId: "carbon_offsets",
      columnHeader: "Co2e Offset (tons)",
    },
    {
      columnKey: "net_carbon_emission",
      columnId: "net_carbon_emission",
      columnHeader: "Net Carbon emissions (tons)",
    },
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
    },
    {
      columnKey: "action",
      columnId: "action",
      columnHeader: "",
      width: "10vw"
    },
  ];

  const countryEmissionData = useSelector(
    (state) => state.trade.getCarbonEmissions.data
  );

  const widgetData = useSelector(
    (state) => state.trade.getDashboardWidget.data
  );

  useEffect(() => {
    dispatch(getDashboardWidget());
    dispatch(getCarbonEmissions());
  }, [dispatch]);
  
const onSelectData = (action) => {
  if(action === "Carbon Negative"){
    navigate("/trade/sell")
  }else {
    navigate("/trade/buy")

  }
}
  const getCountryEmissionData = () =>
    countryEmissionData.map((item) => ({
      ...item,
      status: <Typography fontSize={14} 
      className={item?.status === "Carbon Negative" ? classes.carbonPositive : classes.carbonNegative}>
        {item.status}
      </Typography>,
      action: 
        <CeroButton
          buttonText={item.status === "Carbon Negative" ? "Sell" : "Buy"}
          className={classes.buttonPrimary}
          onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onSelectData(item.status);
      }}/>
    }));

  return (
    <DashboardLayout classes={{ childContainer: classes.childContainer }}>
      <Container className={classes.container}>
        <Typography className={classes.subTitle}>
          Company Wide Emission Data
        </Typography>
        <Paper className={classes.card}>
          <div className={classes.title}>
            <Typography variant="h4" className={classes.companyName}>
              {widgetData?.company_name}
            </Typography>
            <Typography className={classes.carbonStatus}>
              Current Status: <span className={widgetData?.status === "Carbon Negative" ? classes.carbonPositive : classes.carbonNegative}>
                {widgetData?.status}</span>
            </Typography>
          </div>
            <div
              className={classes.widgetBox}
            >
              <div>
                <Typography className={classes.widgetTitle}>Total Co2e Emissions</Typography>
                <Box className={classes.valueBox}>
                  <Typography variant="h5" fontWeight={600}>{widgetData?.total_emission}</Typography>
                  <Typography className={classes.unit}>metric tons</Typography>
                  </Box>
              </div>
              <Typography variant="h4" className={classes.symbol}>-</Typography>
              <div>
                <Typography className={classes.widgetTitle}>Co2e Offset</Typography>
                <Box className={classes.valueBox}>
                  <Typography variant="h5" fontWeight={600}>{widgetData?.total_offset}</Typography>
                  <Typography className={classes.unit}>metric tons</Typography>
                  </Box>
              </div>
              <Typography variant="h4" className={classes.symbol}>=</Typography>
              <div>
                <Typography className={classes.widgetTitle}>Net Carbon Emissions</Typography>
                <Box className={classes.valueBox}>
                  <Typography variant="h5" fontWeight={600}>{widgetData?.net_offset}</Typography>
                  <Typography className={classes.unit}>metric tons</Typography>
                  </Box>
              </div>
          </div>
        </Paper>
        <Typography className={classes.subTitle}>
          Country Wide Emission Data
        </Typography>
        <CeroTable
          columns={columns}
          data={getCountryEmissionData()}
          hasMore={false}
          loading={false}
          classes={{
            tableContainer: classes.tableContainer,
            headerCell: classes.headerCell,
          }}
        />
      </Container>
    </DashboardLayout>
  );
};

export default TradeDashboardInternal;
