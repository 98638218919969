import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Grid,
  Typography,
  Box,

} from "@mui/material";

import useStyles from "./styles";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CeroItemPair from "../../../../../components/CeroItemPair";
import CeroInfoPair from "../../../../../components/CeroInfoPair";

const PerformanceAndCareerDevelopmentPrograms = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {
    emissionData,
  } = props;
  return (
    <>
      <div
        className={classes.backContainer}
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowBackIosIcon />
        Back
      </div>
      <Container className={classes.container}>
        <Box className={classes.innerContainer}>
          <Box className={classes.header}>
            <Typography variant="h6" component="div">
              Performance And Career Development Programs
            </Typography>
          </Box>
          <Box className={classes.actionBox}>
            <CeroInfoPair
              title="Status:"
              value={emissionData.status ? emissionData.status : "NA"}
              classes={{
                container: classes.statusContainer,
                title: classes.statusTitle,
                value:
                  emissionData.status === "In Progress"
                    ? classes.Inprogress
                    : classes[emissionData?.status],
              }}
            />
          </Box>
          <Box className={classes.topContainer}>
            <Grid
              container
              direction={"row"}
              wrap="nowrap"
              justifyContent={"space-between"}
              display={"contents"}
              spacing={12}
            >
              <Grid item container direction={"column"} display={"contents"} xs={10}>
                <CeroItemPair
                  title="Facility:"
                  value={
                    emissionData.facility_name
                      ? emissionData.facility_name
                      : "NA"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.title,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Period:"
                  value={
                    emissionData.assessment_period
                      ? emissionData.assessment_period
                      : "NA"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.title,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Year:"
                  value={emissionData.year ? emissionData.year : "NA"}
                  classes={{
                    container: classes.infoContainer,
                    title: classes.title,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Type of programs:"
                  value={
                    emissionData.program_type ? emissionData.program_type : "NA"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.title,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Training date:"
                  value={emissionData.date ? emissionData.date : "NA"}
                  classes={{
                    container: classes.infoContainer,
                    title: classes.title,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Training Methods:"
                  value={
                    emissionData.training_method
                      ? emissionData.training_method
                      : "NA"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.title,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Scope of the programs:"
                  value={
                    emissionData.program_scope
                      ? emissionData.program_scope
                      : "NA"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.title,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Transition Assistance:"
                  value={
                    emissionData.transition_assistance
                      ? emissionData.transition_assistance
                      : "NA"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.title,
                    value: classes.value,
                  }}
                />

                <CeroItemPair
                  title="Total number of employee participated:"
                  value={
                    emissionData.employees_participated
                      ? emissionData.employees_participated
                      : "NA"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.title,
                    value: classes.value,
                  }}
                />

                <CeroItemPair
                  title="Total Employee participated percentage:"
                  value={
                    emissionData.employees_participation_percentage
                      ? `${emissionData.employees_participation_percentage} %`
                      : "NA"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.title,
                    value: classes.value,
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default PerformanceAndCareerDevelopmentPrograms;
