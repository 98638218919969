import React, { useEffect, useState } from "react";
import CeroDropdown from "../../../../components/CeroDropdown";
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { sampleYear } from "../../../../constants";
import useStyles from "./styles";
import CeroTable from "../../../../components/CeroTable";
import { getInitiativeDetails, getTasksList, listInitiative } from "../../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import Status from "./ViewInitiativeDetails";

export const tableColums = [
  {
    columnKey: "initiative_id",
    columnId: "initiative_id",
    columnHeader: "Initiative Id",
    width: "20%",
  },
  {
    columnKey: "initiative",
    columnId: "initiative",
    columnHeader: "Initiatives",
    width: "60%",
  },
  {
    columnKey: "details",
    columnId: "details",
    columnHeader: "Details",
    width: "20%",
  },
];


const ViewInitiative = ({ resetInitiativeList }) => {
  const classes = useStyles();
  const [filterYear, setYear] = useState();
  const [facility, setFacility] = useState();
  const [country, setCountry] = useState();
  const [showDetails, setShowDetails] = useState(false);
  const [initiativeId, setInitiativeId] = useState();
  const dispatch = useDispatch();

  const initiativeList = useSelector(
    (state) => state.goalSettings.listInitiative.data
  );

  const initiativeDetails = useSelector(
    (state) => state.goalSettings.getInitiativeDetails.data
  );

  const facilitiesData = useSelector(
    (state) => state.listings.listFacilities.data
  );
  const facilitiesList = facilitiesData.map((item) => ({
    key: item.id,
    value: item.name,
  }));

  const countryData = useSelector(
    (state) => state.listings.getOrganizationCountry.data.country_data
  );
  const countryOptions = Object.keys(countryData).map((item) => ({
    key: countryData[item],
    value: item,
  }));

  const handleYearChange = (event) => {
    setYear(event.target.value);
  };

  const handleFacilityChange = (event) => {
    setFacility(event.target.value);
  };

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };

  const onSelectAuditSummaryData = (row) => {
    // Navigate(`details/${row.id}`);
  };

  const tableData = () => {
    if (!Array.isArray(initiativeList)) {
      return [];
    }

    return initiativeList?.map((item) => ({
      ...item,
      details: (
          <div
            className={classes.linkButton}
            onClick={(e) => {
              e.stopPropagation();
              dispatch(getInitiativeDetails(item.id));
              dispatch(getTasksList(item.id));
              setInitiativeId(item.id)
              setShowDetails(true);
            }}
          >
            <u>click here</u>
          </div>
      ),
    }));
  };

  useEffect(() => {
    dispatch(listInitiative(filterYear, country, facility));
  }, [dispatch, filterYear, facility, country]);

  useEffect(() => {
    resetInitiativeList();
  },[])

  const handleShowDetailsChange = (showDetails) => {
    setShowDetails(showDetails);
  };
  
  const noTableRows = () => (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {tableColums.map((column) => (
              <TableCell key={column.columnId} className={classes.tableHeader}>
                {column.columnHeader}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell
              colSpan={tableColums.length} 
              className={classes.noDataWarning}
              align="center" 
            >
              <Typography align="center">No Data</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
  
  return (
    <Box>
      {showDetails ? (
        <Status initiativeDetails={initiativeDetails} initiativeId={initiativeId} showDetails={showDetails} onShowDetailsChange={handleShowDetailsChange}/>
      ) : (
        <Box>
          <Box className={classes.container}>
            <CeroDropdown
              id="year"
              label="Year"
              fullWidth
              options={sampleYear()}
              onChange={handleYearChange}
              selectedValue={filterYear}
              //   disabled={isDisabled}
            />
            <CeroDropdown
              id="country"
              label="Country"
              fullWidth
              options={countryOptions}
              onChange={handleCountryChange}
              selectedValue={country}
              //   disabled={isDisabled}
            />
            <CeroDropdown
              id="facility"
              label="Facility"
              fullWidth
              options={facilitiesList}
              onChange={handleFacilityChange}
              selectedValue={facility}
              //   disabled={isDisabled}
            />
          </Box>
          {initiativeList?.length !== 0 ? <CeroTable
            columns={tableColums}
            data={tableData}
            hasMore={false}
            loading={false}
            onSelectRow={onSelectAuditSummaryData}
            useExtraStyles={true}
            useModifiedHeader={true}
          />:noTableRows()}
        </Box>
      )}
    </Box>
  );
};

export default ViewInitiative;
