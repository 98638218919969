import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import CeroTable from "../../../components/CeroTable";
import useStyles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { getSuperadminList } from "../../../redux/actions";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import SuperAdminDeletePrompt from "../SuperAdminDeletePrompt";
import { STATUS } from "../../../redux/constants";

export const TableColumns = [
  {
    columnKey: "email",
    columnId: "email",
    columnHeader: "Email",
  },
  {
    columnKey: "name",
    columnId: "name",
    columnHeader: "Name",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
    align: "right",
  },
];

const SuperAdminTable = (props) => {
  const classes = useStyles();
  // const navigate = useNavigate();
  const dispatch = useDispatch();

  const [openDeletePrompt, setOpenDeletePrompt] = useState(false);
  const [deleteSuperadmin, setDeleteSuperadmin] = useState({});
  const superadminList = useSelector(
    (state) => state.superAdmin.superadminList.data
  );

  const superadminListStatus = useSelector(
    (state) => state.superAdmin.superadminList.status
  );

  console.log("superadminListStatus", superadminListStatus);

  useEffect(() => {
    dispatch(getSuperadminList());
  }, [dispatch]);

  const handleDelete = (item) => {
    setOpenDeletePrompt(true);
    setDeleteSuperadmin(item);
  };

  const adminList = () =>
    superadminList.map((item) => {
      const canEditAndDelete = item.status; // Check if status is active

      return {
        ...item,
        email: item.email ? item.email : "",
        name: item.name ? item.name : "",
        status: item.status ? (
          <Typography className={classes.activeStatus}>Active</Typography>
        ) : (
          <Typography className={classes.inActiveStatus}>Inactive</Typography>
        ),
        action: canEditAndDelete ? (
          <>
            <div className={classes.actions}>
              <div
                className={classes.linkButton}
                onClick={(e) => {
                  props.onClickEdit(item.id);
                  e.stopPropagation();
                }}
              >
                Edit
                <EditOutlinedIcon className={classes.icon} />
              </div>
              <div
                className={classes.linkButton}
                onClick={(e) => {
                  handleDelete(item);
                  e.stopPropagation();
                }}
              >
                Delete
                <DeleteOutlineOutlinedIcon className={classes.icon} />
              </div>
            </div>
          </>
        ) : null,
      };
    });

  return (
    <>
      {superadminListStatus === STATUS.SUCCESS ? (
        <CeroTable
          columns={TableColumns}
          data={adminList()}
          hasMore={false}
          loading={false}
          classes={{ tableContainer: classes.tableContainer }}
        />
      ) : (
        <Box className={classes.loader}>
          <Typography variant="h7" component="span">
            {superadminListStatus === STATUS.RUNNING ? (
              <CircularProgress />
            ) : superadminListStatus === STATUS.ERROR ? (
              "We couldn't process your request. Please try again later"
            ) : (
              ""
            )}
          </Typography>
        </Box>
      )}
      <SuperAdminDeletePrompt
        adminUser={deleteSuperadmin}
        isOpen={openDeletePrompt}
        setOpenDeletePrompt={setOpenDeletePrompt}
      />
    </>
  );
};

export default SuperAdminTable;
