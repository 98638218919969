import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { getSuperadminTickets, getTotalCompanies } from "../../redux/actions";
import DashboardLayout from "../../layouts/DashboardLayout";
import useStyles from "./styles";
import { STATUS } from "../../redux/constants";
import TicketsTable from "./TicketsTable";
import CeroDropdown from "../../components/CeroDropdown";
import { sampleYear } from "../../constants";
import CeroAutoComplete from "../../components/CeroAutoComplete";

const DEFAULT_ITEMS_PER_PAGE = 20;

const SuperAdminTickets = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [year, setYear] = useState(0);
  const [ticketType, setTicketType] = useState("all");
  const [ticketStatus, setTicketStatus] = useState("all");
  const [company, setCompany] = useState("");
  const [companyName, setCompanyName] = useState("");
  const tableDataStatus = useSelector(
    (state) => state.superAdmin.getSuperadminTickets.status
  );
  const totalCompanies = useSelector(
    (state) => state.company.totalCompanies.data
  );
  const ticketList = useSelector(
    (state) => state.superAdmin.getSuperadminTickets.data
  );

  useEffect(() => {
    dispatch(getSuperadminTickets({ ticketType, ticketStatus, year, company }));
  }, [dispatch, ticketType, ticketStatus, year, company]);

  const onLoadMore = (pageSize = DEFAULT_ITEMS_PER_PAGE, pageNumber) => {
  };

  var yearInput = [{ key: 0, value: "All" }];
  yearInput = yearInput.concat(sampleYear());
  const ticketTypeOption = [
    {
      key: "all",
      value: "All",
    },
    {
      key: "incident",
      value: "Incident",
    },
    {
      key: "request",
      value: "Request",
    },
    {
      key: "request nft",
      value: "Request NFT",
    },
  ];

  const ticketStatusOption = [
    {
      key: "all",
      value: "All",
    },
    {
      key: "open",
      value: "Open",
    },
    {
      key: "closed",
      value: "Closed",
    },
  ];

  const companyListOption = totalCompanies.map((item) => ({
    id: item.company_id,
    label: item.company_name,
  }));

  const filterOptions = (options, state) => {
    const inputValue = state.inputValue.toLowerCase();
    return options.filter(option =>
      option.label.toLowerCase().startsWith(inputValue)
    );
  };
  
  useEffect(() => {
    dispatch(getTotalCompanies());
  }, [dispatch]);

  return (
    <DashboardLayout>
      <Container className={classes.container}>
        {tableDataStatus === STATUS.SUCCESS ? (
          <>
            <Grid
              className={classes.filterContainer}
              container
              columnSpacing={4}
              alignItems="center"
              wrap="nowrap"
            >
              <Grid item xs={3} className={classes.yearFilter}>
                <CeroDropdown
                  id="year"
                  label="Year"
                  fullWidth
                  options={yearInput}
                  onChange={({ target }) => setYear(target.value)}
                  selectedValue={year}
                  classes={{ input: classes.dropdown }}
                />
              </Grid>
              <Grid item xs={3}>
                <CeroDropdown
                  id="ticketType"
                  label="Ticket type"
                  fullWidth
                  options={ticketTypeOption}
                  onChange={({ target }) => setTicketType(target.value)}
                  selectedValue={ticketType}
                  classes={{ input: classes.dropdown }}
                />
              </Grid>
              <Grid item xs={3}>
                <CeroDropdown
                  id="ticketStatus"
                  label="Ticket status"
                  fullWidth
                  options={ticketStatusOption}
                  onChange={({ target }) => setTicketStatus(target.value)}
                  selectedValue={ticketStatus}
                  classes={{ input: classes.dropdown }}
                />
              </Grid>
              <Grid item xs={3} className={classes.autoComplete}>
                <CeroAutoComplete
                  id="company"
                  label="Company"
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setCompany(newValue.id);
                      setCompanyName(newValue.label);
                    }
                  }}
                  options={companyListOption}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.company_name
                  }
                  onInputChange={(event, newInputValue) => {
                    setCompanyName(newInputValue);
                  }}
                  filterOptions={filterOptions}
                  value={companyName}
                  getOptionLabel={(option) => option.label || ""}
                />
              </Grid>
            </Grid>
            <TicketsTable ticketList={ticketList} onLoadMore={onLoadMore} />
          </>
        ) : (
          <Box className={classes.loader}>
            <Typography variant="h7" component="span">
              {tableDataStatus === STATUS.RUNNING ? (
                <CircularProgress />
              ) : tableDataStatus === STATUS.ERROR ? (
                "We couldn't process your request. Please try again later"
              ) : (
                ""
              )}
            </Typography>
          </Box>
        )}
      </Container>
    </DashboardLayout>
  );
};

export default SuperAdminTickets;
