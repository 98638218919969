import React from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import useStyles from "./styles";
import DashboardUserIcon from "../../../../assets/icons/DashBoardUser.png"
import totalFacilitiesIcon from "../../../../assets/icons/totalFacilitiesIcon.png"
import totalAuditsIcon from "../../../../assets/icons/totalAuditsIcon.png"
import totalTicketsIcon from "../../../../assets/icons/totalTicketsIcon.png"
import totalRecordsIcon from "../../../../assets/icons/totalRecordsIcon.png"
import totalAssessmentIcon from "../../../../assets/icons/totalAssessmentIcon.png"
import totalUsers from "../../../../assets/icons/totalUsers.png"

const StatisticsCard = (props) => {
  
  const classes = useStyles();
  const IconDict = {
    'Total Users': totalUsers,
    'Total Facilities': totalFacilitiesIcon,
    'Total Tickets': totalTicketsIcon,
    'Total Audits': totalAuditsIcon,
    'Audits': totalAuditsIcon,
    'Total Assessment Cycles': totalAssessmentIcon,
    'Total Records': totalRecordsIcon,
    'Audited': totalAssessmentIcon,
    'Pending Audits': totalRecordsIcon,
    'Total Companies': totalFacilitiesIcon
  }

  return (
    <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
        <Grid container spacing={2}>
          <Grid item xs={4} display="flex" alignItems="center" justifyContent="center">
          <img src={IconDict[props.title]?IconDict[props.title]:DashboardUserIcon} height="50" width="50" alt="Logo"/>
          </Grid>
          <Grid item xs={8}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body2" className={classes.title}>{props.title}</Typography>
              </Grid>
              <Grid item xs={12} display="flex" alignItems="center">
              <Typography variant="h6" className={classes.value}>{props.value}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        </CardContent>
    </Card>
  );
};

export default StatisticsCard;
