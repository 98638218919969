import React, { useEffect } from 'react'
import DashboardLayout from '../../../layouts/DashboardLayout';
import { Container, Link, Typography } from '@mui/material';
import useStyles from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { getSupplierSurveys } from '../../../redux/actions/supplierScreening';
import dayjs from 'dayjs';
import CeroTable from '../../../components/CeroTable';
import { useNavigate } from 'react-router-dom';

const SupplierTrackProgress = () => {
  const dispatch = useDispatch();
    const classes = useStyles();
    const navigate = useNavigate();
    const isHistorypage = window.location.pathname.includes("/history")

    const surveyList = useSelector((state)=>state.supplierScreening.getSupplierSurveys.data.survey_list);
    console.log('surveryList', surveyList)

    const progressTrackColumns = [
      {
        columnKey: "name",
        columnId: "name",
        columnHeader: "Survey Name",
        width: "40vw"
      },
      {
        columnKey: "created_ts",
        columnId: "created_ts",
        columnHeader: "Date",
        width: "20vw"
      },
      {
        columnKey: "status",
        columnId: "status",
        columnHeader: "Status",
        width: "20vw"
      },
      {
        columnKey: "action",
        columnId: "action",
        columnHeader: "Action",
        width: "20vw"
      }
    ];


    const getStatus = (status) => {
      if(status === "pending"){
        return <Typography className={classes.pending}>Pending</Typography>
      }else if(status === "completed"){
        return <Typography className={classes.completed}>Completed</Typography>
      }
    }
    const onSelectData = (id) => {
      if(isHistorypage){
        navigate(`/supplier-screening/history/${id}`)
      }else{
      navigate(`/supplier-screening/track-progress/${id}`)
      }
    }

    const getData = () => {
      if (!Array.isArray(surveyList)) {
        return [];
      }

      return surveyList?.map((item) => ({
        ...item,
        created_ts: item.created_ts ? dayjs(item.created_ts).format("DD/MM/YYYY") : "NA",
        status: getStatus(item.status),
        action: <Link onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          onSelectData(item.id);
        }} style={{ cursor: "pointer"}}>View Details</Link>,
      }))
    }

    useEffect(()=>{
      dispatch(getSupplierSurveys(0))
    },[])

    return (
      <DashboardLayout>
      <Container className={classes.container}>
      <CeroTable
          columns={progressTrackColumns}
          data={getData}
          hasMore={false}
          loading={false}
          useExtraStyles={true}      
          classes={{ tableContainer: classes.tableContainer }}
        />
      </Container>
    </DashboardLayout>
      );
}

export default SupplierTrackProgress