import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import DashboardLayout from "../../../layouts/DashboardLayout";
import CeroButton from "../../../components/CeroButton";
import { useNavigate } from "react-router-dom";
import useStyles from "./styles";
import CeroPopUp from "../../../components/CeroPopUp";
import SendQuestions from "./SendQuestions";
import CeroInput from "../../../components/CeroInput";
import { getGenericQuestions } from "../../../redux/actions/supplierScreening";
import CeroTable from "../../../components/CeroTable";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const SupplierScreeningSurvey = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState({});
  const dispatch = useDispatch();

  const sendQuestionnairesStatus = useSelector(
    (state) => state.supplierScreening.getGenericQuestions.status
  );
  
  const generalQuestionsList = useSelector((state) => {
    const data = state.supplierScreening?.getGenericQuestions?.data;
    return Array.isArray(data) ? data : [];
  });

  useEffect(() => {
    dispatch(getGenericQuestions());
  }, [dispatch]);

  const handleCheckboxChange = (event, questionId) => {
    const value = event.target.value;
    setSelectedValues((prevValues) => {
      const questionValues = prevValues[questionId] || [];
      if (questionValues.includes(value)) {
        return {
          ...prevValues,
          [questionId]: questionValues.filter((item) => item !== value),
        };
      } else {
        return {
          ...prevValues,
          [questionId]: [...questionValues, value],
        };
      }
    });
  };

  const groupedQuestions = generalQuestionsList?.reduce((acc, question) => {
    const section = question.section || "Uncategorized";
    if (!acc[section]) {
      acc[section] = [];
    }
    acc[section].push(question);
    return acc;
  }, {});

  let questionNumber = 1;

  return (
    <DashboardLayout>
      <Container className={classes.container}>
        <div className={classes.buttonContainer}>
          <CeroButton
            buttonText="Send Questionnaire to Suppliers"
            variant="contained"
            className={classes.buttonPrimary}
            onClick={() => setOpen(true)}
          />
        </div>
        <Box className={classes.box}>
          <Typography fontWeight={600}>
            SUPPLIER ASSESSMENT GENERAL QUESTIONNAIRE
          </Typography>
          {Object.entries(groupedQuestions)?.map(([sectionTitle, questions]) => (
            <Box key={sectionTitle} mb={4}>
              {questions?.map((question) => {
                if (question.question_id === null) {
                  const currentQuestionNumber = questionNumber;
                  questionNumber += 1;
                  return (
                    <Grid
                      key={question.id}
                      className={
                        question.question_type === "text"
                          ? classes.inputGrid
                          : classes.gridItem
                      }
                    >
                      <Typography
                        fontSize={18}
                        fontWeight={500}
                        variant="body1"
                        className={classes.mainQuestion}
                      >
                        {`${currentQuestionNumber}. ${question.question}`}
                      </Typography>
                      <Box display="flex" flexDirection="column" paddingLeft={6}>
                        {(question.question_type === "choose_from_option" ||
                          question.question_type === "choose_from_topic" ||
                          question.question_type === "multiselect_check_box") &&
                          question.options.map((option, idx) => (
                            <FormControlLabel
                              key={idx}
                              control={
                                <Checkbox
                                  className={classes.selectCheckbox}
                                  checked={
                                    selectedValues[question.id]?.includes(option) || false
                                  }
                                  onChange={(e) =>
                                    handleCheckboxChange(e, question.id)
                                  }
                                  value={option}
                                  sx={{
                                    color: "#5A404E !important",
                                    "&.Mui-checked": {
                                      color: "#8C2148 !important",
                                    },
                                  }}
                                  disabled
                                />
                              }
                              label={option}
                              className={classes.label}
                            />
                          ))}
                        {question.question_type === "text" && (
                          <Box>
                            <CeroInput
                              classes={{ container: classes.inputContainer }}
                              rows={3}
                              placeholder={"Please type your Answer"}
                              id={`answer-${question.id}`}
                              name={`answer-${question.id}`}
                              label="Please type your Answer"
                              multiline
                              disabled
                            />
                          </Box>
                        )}
                      </Box>
                    </Grid>
                  );
                }
                const subQuestionLetter = String.fromCharCode(
                  96 + generalQuestionsList
                    .filter((q) => q.question_id === question.question_id)
                    .indexOf(question) + 1
                );
                return (
                  <Grid key={question.id} paddingLeft={6}>
                    <Typography className={classes.subQuestion}>
                      {`${subQuestionLetter}. ${question.question}`}
                    </Typography>
                    {question.options.map((option, idx) => (
                      <FormControlLabel
                        key={idx}
                        control={
                          <Checkbox
                            className={classes.selectCheckbox}
                            checked={
                              selectedValues[question.id]?.includes(option) || false
                            }
                            onChange={(e) =>
                              handleCheckboxChange(e, question.id)
                            }
                            value={option}
                            sx={{
                              color: "#5A404E !important",
                              "&.Mui-checked": {
                                color: "#8C2148 !important",
                              },
                            }}
                            disabled
                          />
                        }
                        label={option}
                        className={classes.label}
                      />
                    ))}
                    {question.question_type === "text" && (
                        <Box>
                          <CeroInput
                            classes={{container : classes.inputContainer}}
                            rows={2}
                            placeholder={"Please type your Answer"}
                            id={`answer-${question.id}`}
                            name={`answer-${question.id}`}
                            label="Please type your Answer"
                            multiline
                            disabled
                          />
                        </Box>
                      )}
                      {question.question_type === "upload" && (
                        <Box className={classes.upload}>
                          <Button
                            component="label"
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            disabled
                            className={classes.buttonPrimary}
                            startIcon={<CloudUploadIcon />}
                          >
                            Upload files
                          </Button>
                        </Box>
                      )}
                  </Grid>
                );
              })}
            </Box>
          ))}
        </Box>
        <CeroPopUp
          primaryPopUp={{
            open: open,
            onClose: () => setOpen(false),
            content: SendQuestions({ setOpen, sendQuestionnairesStatus }),
            header: { title: "" },
          }}
          classes={{ dialog: classes.popUpdialog }}
        />
      </Container>
    </DashboardLayout>
  );
};

export default SupplierScreeningSurvey;
