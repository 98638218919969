import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    margin: 0,
  },
  tableContainer: {
    maxHeight: "calc(100vh - 140px)",
    width: "60%",
    margin: "auto",
    marginTop: theme.spacing(5),
  },
  loader: {
    width: "100%",
    height: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  filterContainer: {
    marginTop: 20,
  },
  yearFilter: {
    "& .MuiBox-root": {
      height: "42px !important",
      margin: theme.spacing(0.6, 0, 0, 0),
    },
    "& .MuiBox-root > .MuiAutocomplete-root ": {
      background: "white",
    },
    "& .MuiBox-root > .MuiAutocomplete-root > .MuiFormControl-root > .MuiInputBase-root":
      {
        height: 46,
      },
    "& .MuiBox-root > .MuiAutocomplete-root > .MuiFormControl-root > .MuiInputBase-root > input[type=text]":
      {
        paddingBlock: 0,
        paddingInline: 0,
      },
    "& .MuiBox-root > .MuiAutocomplete-root > .MuiFormControl-root > .Mui-focused":
      {
        color: theme.palette.Primary.main,
        marginTop: "0 !important",
      },
    "& .MuiBox-root > .MuiAutocomplete-root > .MuiFormControl-root > .MuiFormLabel-filled":
      {
        color: theme.palette.Primary.main,
        marginTop: "0 !important",
      },
    "& .MuiBox-root > .MuiAutocomplete-root > .MuiFormControl-root > .MuiInputLabel-outlined":
      {
        marginTop: -3,
      },
  },
  ultiSelectformControl: {
    marginTop: theme.spacing(2),
    width: "100%",
    height: 46,
    "& .MuiFormLabel-root": {
      left: theme.spacing(2),
      top: theme.spacing(1),
    },
    background: "white",
    "& .MuiInputBase-formControl.justCheckSelected > fieldset > legend": {
      maxWidth: "100%",
      paddingLeft: 55,
    },
    "& .Mui-focused > fieldset > legend": {
      maxWidth: "100%",
      paddingLeft: 55,
    },
  },
}));

export default useStyles;
