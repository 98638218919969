import {
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import { Box } from "@mui/material";
import { useFormik } from "formik";

import { userDetailsSchema } from "../schema";

import CeroPhoneInput from "../../../components/CeroPhoneInput";
import CeroInput from "../../../components/CeroInput";
import CeroButton from "../../../components/CeroButton";
import useStyles from "./styles";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useState } from "react";
import CeroSelect from "../../../components/CeroSelect";

const UserDetails = (props) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const userDetailsForm = useFormik({
    initialValues: {
      name: props.userDetails.name || "",
      email: props.userDetails.email || "",
      phone: props.userDetails.phone || "",
      password: props.userDetails.password || "",
    },
    validationSchema: userDetailsSchema,
    validateOnMount: true,
    onSubmit: (values) => {},
  });

  const handleNext = () => {
    // navigate("/");

    props.onNext(0, userDetailsForm.values);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const userTypes = [
    {key: "auditor", value: "Auditor"},
    {key: "business", value: "Business"},
  ]

  return (
    <>
      <Typography variant="h6" gutterBottom>
        User details
      </Typography>
      <CeroInput
        required
        fullWidth
        label="Name"
        name="name"
        value={userDetailsForm.values.name}
        onChange={userDetailsForm.handleChange}
        onBlur={userDetailsForm.handleBlur}
        error={userDetailsForm.touched.name && userDetailsForm.errors.name}
        classes={{ container: classes.input }}
      />
      <CeroInput
        required
        fullWidth
        label="Email address"
        name="email"
        value={userDetailsForm.values.email}
        onChange={userDetailsForm.handleChange}
        onBlur={userDetailsForm.handleBlur}
        error={userDetailsForm.touched.email && userDetailsForm.errors.email}
      />
      <CeroPhoneInput
        required
        fullWidth
        label="Contact Number"
        name="phone"
        value={userDetailsForm.values.phone}
        onChange={(value) => userDetailsForm.setFieldValue("phone", value)}
        onBlur={userDetailsForm.handleBlur}
        error={userDetailsForm.touched.phone && userDetailsForm.errors.phone}
        classes={{ container: classes.phoneInput }}
      />
      <CeroInput
        required
        fullWidth
        label="Password"
        name="password"
        type={showPassword ? "text" : "password"}
        value={userDetailsForm.values.password}
        onChange={userDetailsForm.handleChange}
        onBlur={userDetailsForm.handleBlur}
        error={
          userDetailsForm.touched.password && userDetailsForm.errors.password
        }
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={togglePasswordVisibility}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
          </InputAdornment>
        }
        classes={{ container: userDetailsForm.touched.password && userDetailsForm.errors.password ? classes.inputWithError : classes.input }}
      />
      <CeroSelect
                  required
                  id="userType"
                  name="userType"
                  label="User Type"
                  fullWidth
                  options={userTypes}
                  selectedValue={props.userType }
                  onChange={props.handleRoleChange}
                  onBlur={userDetailsForm.handleBlur}
                  classes={{input: classes.userBox}}
                />
      {/* <FormControl className={classes.userBox}>
        <InputLabel
          htmlFor="user-type-select"
          classes={{
            focused: classes.focusedLabel,
          }}
        >
          <Typography className={classes.label}>
            {props.userType ? "" : "Select User Type"}
          </Typography>
        </InputLabel>
        <Select
          value={props.userType || ""}
          onChange={props.handleRoleChange}
          inputProps={{
            name: "user-type",
            id: "user-type-select",
          }}
          className={classes.select}
        >
          <MenuItem value="auditor">Auditor</MenuItem>
          <MenuItem value="business">Business</MenuItem>
        </Select>
      </FormControl> */}
      <Box className={classes.cardFooter}>
        <CeroButton
          variant="contained"
          onClick={handleNext}
          buttonText={"Next"}
          disabled={!userDetailsForm.isValid || !props.userType}
          className={classes.button}
        />
      </Box>
    </>
  );
};

export default UserDetails;
