import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { STATUS } from "../../../redux/constants";
import CeroInput from "../../../components/CeroInput";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useNavigate, useParams } from "react-router-dom";
import { getSupplierSurveyQuestions } from "../../../redux/actions/supplierScreening";
import CeroButton from "../../../components/CeroButton";
import { supplierSurveyResponse } from "../../../redux/actions/supplierScreening";
import { useSnackbar } from "notistack";
import { resetSupplierScreeningStatus } from "../../../redux/actions/supplierScreening";
import axios from "axios";
import { styled } from "@mui/material/styles";
import { APIEndpoints } from "../../../redux/constants";

const SupplierScreeningResponse = () => {
  const classes = useStyles();
  const [selectedValues, setSelectedValues] = useState({});
  const dispatch = useDispatch();
  const { answerId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [textAnswers, setTextAnswers] = useState({});

  const supplierQuestionsList = useSelector((state) => {
    const data = state.supplierScreening.getSupplierSurveyQuestions.data;
    return Array.isArray(data) ? data : [];
  });
  const questionListStatus = useSelector(
    (state) => state.supplierScreening.getSupplierSurveyQuestions.status
  );
  const formStatus = useSelector(
    (state) => state.supplierScreening.supplierSurveyResponse
  );

  const handleCheckboxChange = (event, questionId) => {
    const value = event.target.value;
    setSelectedValues((prevValues) => {
      const questionValues = prevValues[questionId] || [];
      if (questionValues.includes(value)) {
        return {
          ...prevValues,
          [questionId]: questionValues.filter((item) => item !== value),
        };
      } else {
        return {
          ...prevValues,
          [questionId]: [...questionValues, value],
        };
      }
    });
  };

  const handleFileUpload = async (file, questionId) => {
    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await axios.post(
        APIEndpoints.SURVEY_FILE_UPLOAD(answerId, questionId),
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const blobUrl = response.data?.blob_url;
      enqueueSnackbar("File uploaded successfully.", {
        variant: "success",
      });
      if (blobUrl) {
        setTextAnswers((prevAnswers) => ({
          ...prevAnswers,
          [`file-${questionId}`]: blobUrl,
        }));
      }
    } catch (error) {
      enqueueSnackbar("File upload failed. Please try again.", {
        variant: "error",
      });
    }
  };

  const onSubmit = () => {
    const answerList = supplierQuestionsList.map((question) => {
      let answer = "";
      let answer_value = "";
      if (
        question.question_type === "text" ||
        question.question_type === "Text Field"
      ) {
        answer = textAnswers[question.id] || "";
      } else if (
        question.question_type === "choose_from_option" ||
        question.question_type === "Choose from Options"
      ) {
        answer = selectedValues[question.id] || "";

        if (answer === "Other (please specify):") {
          answer_value = textAnswers[`other-${question.id}`] || "";
        }
      } else if (question.question_type === "multiselect_check_box") {
        const selectedOptions = selectedValues[question.id] || [];
        answer = selectedOptions.join(", ");
        if (selectedOptions.includes("Other (please specify):")) {
          answer_value = textAnswers[`other-${question.id}`] || "";
        }
      } else if (question.question_type === "upload") {
        answer = textAnswers[`file-${question.id}`] || "";
      }
      return {
        question_id: question.id,
        answer,
        question_type: question.question_type,
        ...(answer_value && { answer_value }),
      };
    });
    const payload = {
      answer_list: answerList,
    };
    dispatch(supplierSurveyResponse(answerId, payload));
  };

  useEffect(() => {
    if (formStatus.status === STATUS.SUCCESS) {
      navigate("/supplier-assessment/survey-response");
    } else if (formStatus.status === STATUS.ERROR) {
      enqueueSnackbar(
        formStatus.message ||
          "We couldn't process your request. Please try again later.",
        {
          variant: "error",
          autoHideDuration: 3000,
        }
      );
      dispatch(resetSupplierScreeningStatus());
    }
  }, [formStatus, dispatch, enqueueSnackbar]);

  const handleTextInputChange = (questionId, value) => {
    setTextAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: value,
    }));
  };

  const handleSingleSelect = (questionId, selectedOption) => {
    setSelectedValues((prevSelectedValues) => ({
      ...prevSelectedValues,
      [questionId]: selectedOption,
    }));

    if (selectedOption === "Other") {
      setTextAnswers((prevAnswers) => ({
        ...prevAnswers,
        [`other-${questionId}`]: "",
      }));
    }
  };

  useEffect(() => {
    if (answerId) {
      dispatch(getSupplierSurveyQuestions(answerId));
    }
  }, [dispatch, answerId]);

  const groupedQuestions = supplierQuestionsList?.reduce((acc, question) => {
    const section = question.section || "Uncategorized";
    if (!acc[section]) {
      acc[section] = [];
    }
    acc[section].push(question);
    return acc;
  }, {});

  const VisuallyHiddenInput = styled("input")({
    position: "absolute",
    width: "1px",
    height: "1px",
    padding: 0,
    margin: "-1px",
    overflow: "hidden",
    clip: "rect(0,0,0,0)",
    border: 0,
  });

  let questionNumber = 1;
  return (
    <Paper className={classes.container}>
      <Box mb={4} display="flex" justifyContent="space-between" paddingY={2}>
        <Typography fontSize={20} fontWeight={550} variant="h3">
          SUPPLIER ASSESSMENT INDUSTRY SPECIFIC QUESTIONNAIRE
        </Typography>
      </Box>
      {questionListStatus === STATUS.SUCCESS ? (
        <>
          {Object.entries(groupedQuestions)?.map(
            ([sectionTitle, questions]) => (
              <Box key={sectionTitle} mb={4}>
                {questions?.map((question) => {
                  if (question.question_id === null) {
                    const currentQuestionNumber = questionNumber;
                    questionNumber += 1;
                    return (
                      <Grid
                        key={question.id}
                        className={
                          question.question_type === "text"
                            ? classes.inputGrid
                            : classes.gridItem
                        }
                      >
                        <Typography
                          fontSize={18}
                          fontWeight={500}
                          variant="body1"
                          className={classes.mainQuestion}
                        >
                          {`${currentQuestionNumber}. ${question.question}`}
                        </Typography>
                        <Box
                          display="flex"
                          flexDirection="column"
                          paddingLeft={6}
                        >
                          {question.question_type === "multiselect_check_box" &&
                            question.options.map((option, idx) => (
                              <>
                                <FormControlLabel
                                  key={idx}
                                  control={
                                    <Checkbox
                                      className={classes.selectCheckbox}
                                      checked={
                                        selectedValues[question.id]?.includes(
                                          option
                                        ) || false
                                      }
                                      onChange={(e) =>
                                        handleCheckboxChange(e, question.id)
                                      }
                                      value={option}
                                      sx={{
                                        color: "#5A404E !important",
                                        "&.Mui-checked": {
                                          color: "#8C2148 !important",
                                        },
                                      }}
                                    />
                                  }
                                  label={option}
                                  className={classes.label}
                                />
                                {selectedValues[question.id]?.includes(
                                  "Other (please specify):"
                                ) &&
                                  option === "Other (please specify):" && (
                                    <CeroInput
                                      classes={{
                                        container: classes.inputContainer,
                                      }}
                                      variant="outlined"
                                      placeholder="Please type your Answer"
                                      multiline
                                      rows={3}
                                      value={
                                        textAnswers[`other-${question.id}`] ||
                                        ""
                                      }
                                      onChange={(e) =>
                                        handleTextInputChange(
                                          `other-${question.id}`,
                                          e.target.value
                                        )
                                      }
                                      className={classes.input}
                                    />
                                  )}
                              </>
                            ))}

                          {(question.question_type === "choose_from_option" ||
                            question.question_type === "Choose from Options") &&
                            question.options.map((option, idx) => {
                              console.log(
                                "option",
                                option,
                                selectedValues[question.id],
                                question.id
                              );
                              return (
                                <>
                                  <FormControlLabel
                                    key={idx}
                                    control={
                                      <Checkbox
                                        className={classes.selectCheckbox}
                                        checked={
                                          selectedValues[question.id] === option
                                        }
                                        onChange={() =>
                                          handleSingleSelect(
                                            question.id,
                                            option
                                          )
                                        }
                                        value={option}
                                        sx={{
                                          color: "#5A404E !important",
                                          borderWidth: "none",
                                          "&.Mui-checked": {
                                            color: "#8C2148 !important",
                                          },
                                        }}
                                      />
                                    }
                                    label={option}
                                  />
                                  {selectedValues[question.id] ===
                                    "Other (please specify):" &&
                                    option === "Other (please specify):" && (
                                      <TextField
                                        variant="outlined"
                                        placeholder="Please type your Answer"
                                        multiline
                                        rows={3}
                                        value={
                                          textAnswers[`other-${question.id}`] ||
                                          ""
                                        }
                                        onChange={(e) =>
                                          handleTextInputChange(
                                            `other-${question.id}`,
                                            e.target.value
                                          )
                                        }
                                        className={classes.input}
                                      />
                                    )}
                                </>
                              );
                            })}
                          {question.question_type === "upload" && (
                            <Box className={classes.upload}>
                              <Button
                                component="label"
                                role={undefined}
                                variant="contained"
                                tabIndex={-1}
                                className={classes.buttonPrimary}
                                startIcon={<CloudUploadIcon />}
                              >
                                Upload files
                                <VisuallyHiddenInput
                                  type="file"
                                  onChange={(e) => {
                                    const file = e.target.files[0];
                                    if (file) {
                                      handleFileUpload(file, question.id);
                                    }
                                  }}
                                  multiple
                                />
                              </Button>
                            </Box>
                          )}
                          {question.question_type === "text" && (
                            <Box>
                              <CeroInput
                                classes={{ container: classes.inputContainer }}
                                rows={3}
                                placeholder={"Please type your Answer"}
                                id={`answer-${question.id}`}
                                name={`answer-${question.id}`}
                                label="Please type your Answer"
                                value={textAnswers[question.id] || ""}
                                onChange={(e) =>
                                  handleTextInputChange(
                                    question.id,
                                    e.target.value
                                  )
                                }
                                multiline
                              />
                            </Box>
                          )}
                        </Box>
                      </Grid>
                    );
                  }
                  const subQuestionLetter = String.fromCharCode(
                    96 +
                      supplierQuestionsList
                        .filter((q) => q.question_id === question.question_id)
                        .indexOf(question) +
                      1
                  );
                  return (
                    <Grid key={question.id} paddingLeft={6}>
                      <Typography className={classes.subQuestion}>
                        {`${subQuestionLetter}. ${question.question}`}
                      </Typography>
                      {question.options.map((option, idx) => (
                        <FormControlLabel
                          key={idx}
                          control={
                            <Checkbox
                              className={classes.selectCheckbox}
                              checked={
                                selectedValues[question.id]?.includes(option) ||
                                false
                              }
                              onChange={(e) =>
                                handleCheckboxChange(e, question.id)
                              }
                              value={option}
                              sx={{
                                color: "#5A404E !important",
                                "&.Mui-checked": {
                                  color: "#8C2148 !important",
                                },
                              }}
                            />
                          }
                          label={option}
                          className={classes.label}
                        />
                      ))}
                      {question.question_type === "text" && (
                        <Box>
                          <CeroInput
                            classes={{ container: classes.inputContainer }}
                            rows={2}
                            placeholder={"Please type your Answer"}
                            id={`answer-${question.id}`}
                            name={`answer-${question.id}`}
                            label="Please type your Answer"
                            value={textAnswers[question.id] || ""}
                            onChange={(e) =>
                              handleTextInputChange(question.id, e.target.value)
                            }
                            multiline
                          />
                        </Box>
                      )}
                      {question.question_type === "upload" && (
                        <Box className={classes.upload}>
                          <Button
                            component="label"
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            className={classes.buttonPrimary}
                            startIcon={<CloudUploadIcon />}
                          >
                            Upload files
                            <VisuallyHiddenInput
                              type="file"
                              onChange={(e) => {
                                const file = e.target.files[0];
                                if (file) {
                                  handleFileUpload(file, question.id);
                                }
                              }}
                              multiple
                            />
                          </Button>
                        </Box>
                      )}
                    </Grid>
                  );
                })}
              </Box>
            )
          )}
          <div className={classes.buttonSubmitContainer}>
            <CeroButton
              buttonText="Submit Survey"
              className={classes.buttonPrimary}
              onClick={onSubmit}
            />
          </div>
        </>
      ) : (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      )}
    </Paper>
  );
};

export default SupplierScreeningResponse;
