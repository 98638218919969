import { Height, Margin, Padding } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { color } from "d3";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    width: "100%",
    maxWidth: "100%",
  },
  ActionHeader:{
    display:"flex",
    justifyContent: 'flex-end',
    backgroundColor: 'rgba(234, 236, 238, 1)'
  },
  normalIcon:{
    width: '30px', 
    height: '30px',
    padding: 2,
    // fill:"rgba(89, 89, 89, 1)"
},
activeIcon :{
    fill : "#8C2148#595959"
},
iconOuter:{
    padding:5
},
widgetSelector:{
    position: 'absolute',
    width:'350px',
    backgroundColor:'white',
    right:'0px',
    top:'0px',
    padding:'5px',
    overflowY: 'scroll',
    height:"100vh"

},
lineDiv:{
    paddingTop:'5px',
    paddingBottom:'5px',
    borderBottom:"0.2px solid #ddd0d0"
},
lineDivHeader:{
    paddingTop:'5px',
    paddingBottom:'5px',
    borderBottom:"0.2px solid #ddd0d0",
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'space-around',
    justifyContent: 'space-between',
},
lineItem:{
    display:"flex"
},
lineItemText:{
    paddingLeft: '5px',
    paddingTop: '10px',
    fontFamily: theme.typography.fontFamily,
    fontWeight:400,
    fontSize:'17px'
},
addwidgetTypo:{
    fontFamily: theme.typography.fontFamily,
    fontWeight:400,
    fontSize:'20px'
},
widgetcatTypo:{
    fontFamily: theme.typography.fontFamily,
    fontWeight:400,
    fontSize:'14px',
    paddingBottom:"10px"
},
displayDiv:{
    width:'100%',
    // height: '100vh',
    backgroundColor:'#e4e3e3',
    display: 'flex',
    // justifyContent: 'center', // Centers content horizontally
    // alignItems: 'center',
    position: 'relative',
},
noDataDivText:{
  display: 'flex',
  alignItems: 'center',    /* Vertically centers the text */
  paddingLeft: '5px',      /* Adds 5px padding on the left */
  height: '100vh'
},
noDataDivShort:{
    display: 'flex',
    flexWrap: 'wrap',
    gap:'10px',
    maxWidth:'880px',
    alignItems: 'flex-start',
},
noDataDiv:{
    display: 'flex',
    flexWrap: 'wrap',
    gap:'10px',
    maxWidth:"100%",
    alignItems: 'flex-start',
},
noDatatext:{
    fontFamily: theme.typography.fontFamily,
    fontWeight:400,
    fontSize:'48px',
    color:"rgba(35, 18, 14, 0.6)"
},
colorPicker:{
    display: 'flex', 
    alignItems: 'center', 
    border: '1px solid #ccc', 
    borderRadius: '4px' , 
    height: '35px',
    "& .MuiFormControl-root > .MuiInputBase-root > .MuiInputBase-input":{
        border: 'none',
        padding: '5px'
    },
    "& .MuiFormControl-root > .MuiInputBase-root > .MuiOutlinedInput-notchedOutline":{
        border: 'none',
    } 
},
typeSelection:{
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignContent: 'space-between',
    maxWidth: '320px',
    padding: '10px'
},
buttonPrimary: {
    background: theme.palette.Primary.background,
    textTransform: "capitalize",
    width: 100,
    height: 32,
    marginRight: 15,
    fontWeight: 400,
    "&:hover": {
      backgroundColor: theme.palette.Primary.hoverButton,
      color: theme.palette.Primary.light,
    },
  },
  buttonSecondary: {
    color: theme.palette.Primary.background,
    borderColor: theme.palette.Primary.background,
    textTransform: "capitalize",
    width: 100,
    height: 32,
    "&:hover": {
      borderColor: theme.palette.Primary.background,
      backgroundColor: theme.palette.Secondary.hoverButton,
    },
    marginRight: theme.spacing(5),
  },
  buttonContainer:{
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    flexWrap: 'nowrap',
    flexDirection: 'row'
  },
  eachGraphS:{
    position: 'relative',
    width: '100%',
    maxWidth:'fit-content',
    // padding:'10px',
    "& .MuiPaper-root":{
      width: '100%',
    }
  },
  eachGraphF:{
    position: 'relative',
    width: '100%',
    maxWidth:'1240px',
    // maxWidth:'fit-content',
    // padding:'10px',
    "& .MuiPaper-root":{
      width: '100%',
    }
  },
  eachGraphStatistics:{
    position: 'relative',
    width: '100%',
    height: '115px',
    marginRight: '10px',
    // maxWidth: 'fit-content',
    "& .MuiPaper-root":{
      width: '100%',
    }
  },
  eachGraphM:{
    position: 'relative',
    width: '430px',
    height:'435px',
    // maxWidth:'fit-content',
    // padding:'10px',
    "& .MuiPaper-root":{
      width: '430px',
      margin: "0px 0px",
      minHeight:'410px'
    },
    // "& .MuiPaper-root > [class*='chart']":{
    //       minHeight: '270px',
    //     }
  },
  eachGraphL:{
    position: 'relative',
    width: '800px',
    maxWidth:'fit-content',
    // padding:'10px',
    "& .MuiPaper-root":{
      width: '750px',
    },
    "& .MuiPaper-root > .MuiGrid-root > .MuiGrid-root":{
      width: '350px',
    }
  },
  hoverEachGraph:{
    position: 'absolute',
    top: 5,
    left: 5,
    width: '100%',
    height: '95%',
    backgroundColor: 'rgba(227, 227, 227, 0.46)', // semi-transparent overlay
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 1,
    transition: 'opacity 0.3s ease',
    },
    removeIcon:{
        position: 'absolute',
        top: '10px',
        right: '10px',
    },
    prevDiv:{
        paddingTop:'5px',
        paddingBottom:'5px',
        maxWidth:"325px",
        maxHeight:"400px"

    },
    previewChart:{
        margin: 'auto',
        width: "290px",
        height: "auto",
        padding: "10px 10px",
        border: '1px solid #ccc',
        borderRadius: '8px',
        boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.3)',
        backgroundColor: '#fff',
        marginBottom:'10px'
    },
    iconText:{
        display:"flex"
    },
    gridContainer: {
        display: 'grid',
        // gridTemplateColumns: 'repeat(12, 1fr)', // 12-column grid system
        gap: '10px', // Adjusts gap between grid items
      },
      row: {
        display: 'flex',
        gap: '0px',
        alignItems:'flex-start' // Adjusts gap between columns
      },
      rowAdjusted: {
        width:'880px',
        display: 'flex',
        gap: '0px',
        alignItems:'flex-start' // Adjusts gap between columns
      },
      columnType1: {
        flexBasis: '33.33%', // 3 by 3 grid, so 3 columns
        // backgroundColor: 'lightblue',
        // textAlign: 'center',
        padding: '2px',
        // border: '1px solid #ccc',
        maxWidth:"33.33%"
        
      },
      columnType2: {
        flexBasis: '100%', // 2 by 3 grid, so 2 columns
        // backgroundColor: 'lightgreen',
        // textAlign: 'center',
        padding: '2px',
        // border: '1px solid #ccc',
        maxWidth:"100%",
        "& .MuiPaper-root > .MuiGrid-root > .MuiGrid-root":{
      width: '580px',
    },
    "& .MuiPaper-root":{
      minHeight: '345px',  // Example style for containerFixed

    }
      // backgroundColor: 'lightgray', // Example background color
      },
      columnType3: {
        flexBasis: '50%', // 1 by 3 grid, so full width
        // backgroundColor: 'lightcoral',
        // textAlign: 'center',
        padding: '2px',
        // border: '1px solid #ccc',
        maxWidth:"50%",
        "& .MuiPaper-root":{
          minHeight: '400px',
          margin: "0px 0px"
        },
        // "& .MuiPaper-root > [class*='chart']":{
        //   minHeight: '270px',
        // }
      },
      columnType4: {
        flexBasis: '40%', // 1 by 3 grid, so full width
        // backgroundColor: 'lightcoral',
        // textAlign: 'center',
        padding: '2px',
        // border: '1px solid #ccc',
        maxWidth:"40%",
        "& .MuiPaper-root":{
          minHeight: '400px',
          margin: "0px 0px"
        },
      // "& .MuiPaper-root > [class*='chart']":{
      //       minHeight: '270px',
      //     }
        },
      column8: {
        flexBasis: '60%', // 8 out of 12 columns for type 4 and 6
        // backgroundColor: 'lightgreen',
        // textAlign: 'center',
        padding: '2px',
        // border: '1px solid #ccc',
        maxWidth:"60%",
        "& .MuiPaper-root":{
          minHeight: '405px',
          margin: "0px 0px"
        },
      // "& .MuiPaper-root > [class*='chart']":{
      //       minHeight: '270px',
      //     }
      },
      column4: {
        flexBasis: '40%', // 4 out of 12 columns for type 4 and 6
        // backgroundColor: 'lightcoral',
        // textAlign: 'center',
        padding: '2px',
        // border: '1px solid #ccc',
        maxWidth:"40%",
        "& .MuiPaper-root":{
          minHeight: '405px',
          margin: "0px 0px"
        },
      // "& .MuiPaper-root > [class*='chart']":{
      //       minHeight: '270px',
      //     }
      },
      
}
));

export default useStyles;