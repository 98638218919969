import React, { useEffect } from "react";
import clsx from "clsx";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Container, Grid, Typography, Box } from "@mui/material";
import { useSnackbar } from "notistack";
import { STATUS } from "../../../redux/constants";
import { schemeValidation } from "./schema";
import {
  getAssessmentPeriod,
  resetAddCombustionStatus,
  updateSupplierHumanRightsTraining,
} from "../../../redux/actions";
import CeroButton from "../../../components/CeroButton";
import CeroSelect from "../../../components/CeroSelect";
import CeroInput from "../../../components/CeroInput";
import useStyles from "./styles";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CeroAutoComplete from "../../../components/CeroAutoComplete";
import CeroQuestionWithCheckbox from "../../../components/CeroQuestionWithCheckbox";
import { sampleYear } from "../../../constants";

const EditSupplierHumanRightsTrainingForm = (props) => {
  const { emissionData, onCancel } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const updateEmissionData = useSelector(
    (state) => state.emission.updateSupplierHumanRightsTraining
  );

  const formik = useFormik({
    initialValues: {
      facility: emissionData.facility_id || "",
      year: emissionData.year || "",
      assessmentPeriod: emissionData.assessment_period || "",
      supplierName: emissionData.supplier_name || "",
      riskOfChildLabor: emissionData.risk_of_child_labor || "",
      childLaborDetails: emissionData.child_labor_details || "",
      riskOfCompulsoryLabor: emissionData.risk_of_compulsory_labor || "",
      compulsoryLaborDetails: emissionData.compulsory_labor_details || "",
      actionRequired: emissionData.incident_or_action_occurred || "",
    },
    validationSchema: schemeValidation,
    onSubmit: () => {},
  });

  const facilitiesData = useSelector(
    (state) => state.listings.listFacilities.data
  );

  const facilitiesList = facilitiesData.map((item) => ({
    key: item.id,
    value: item.name,
  }));

  const assessmentData = useSelector(
    (state) => state.assessment.assessmentPeriod.data
  );

  const assessmentOptions = Array.isArray(assessmentData)
    ? assessmentData.map((item) => ({
        key: item.period,
        value: item.period,
      }))
    : [];

  const yearList = sampleYear().map((item) => ({
    id: item.key,
    label: item.value,
  }));

  useEffect(() => {
    const { facility, year } = formik.values;
    if (facility && year) {
      dispatch(getAssessmentPeriod(year, facility, 'suppliers_human_rights', emissionData.id));
    }
  }, [formik.values.facility, formik.values.year, dispatch]);

  useEffect(() => {
    if (updateEmissionData.status === STATUS.SUCCESS) {
      enqueueSnackbar("Human Rights Training updated successfully", {
        variant: "success",
      });
      dispatch(resetAddCombustionStatus());
      onCancel();
    } else if (updateEmissionData.status === STATUS.ERROR) {
      enqueueSnackbar(
        updateEmissionData.message.message ||
          "We couldn't process your request. Please try again later.",
        { variant: "error" }
      );
      dispatch(resetAddCombustionStatus());
    }
  }, [updateEmissionData, dispatch, enqueueSnackbar, onCancel]);

  const onUpdateSupplierHumanRightsTraining = () => {
    const requestData = {
      id: emissionData.id,
      facility_id: formik.values.facility,
      year: formik.values.year,
      assessment_period: formik.values.assessmentPeriod,
      supplier_name: formik.values.supplierName,
      is_at_risk_of_child_labor: formik.values.riskOfChildLabor,
      child_labor_details: formik.values.childLaborDetails,
      is_at_risk_for_compulsory_labor: formik.values.riskOfCompulsoryLabor,
      compulsory_labor_details: formik.values.compulsoryLaborDetails,
      incident_or_action_occurred: formik.values.actionRequired,
      save: true,
    };
    dispatch(updateSupplierHumanRightsTraining(requestData));
  };

  const handleAnswerChange = (fieldName, answer) => {
    formik.handleChange({ target: { name: fieldName, value: answer } });
  };

  return (
    <>
      <div className={classes.backContainer} onClick={onCancel}>
        <ArrowBackIosIcon />
        Back
      </div>
      <Container className={classes.container}>
        <Box className={classes.innerContainer}>
          <Typography className={classes.title} variant="h6" component="div">
            Edit Human Rights Training 
          </Typography>
          <Box>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column" md={6} xs={12}>
                <CeroSelect
                  required
                  id="facility"
                  name="facility"
                  label="Facility"
                  fullWidth
                  options={facilitiesList}
                  selectedValue={formik.values.facility}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.facility && formik.errors.facility}
                />
                <CeroInput
                  required
                  id="supplierName"
                  name="supplierName"
                  label="Supplier Name"
                  fullWidth
                  value={formik.values.supplierName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.supplierName && formik.errors.supplierName
                  }
                />
              </Grid>
              <Grid item container direction={"column"} md={6} xs={12}>
                <CeroAutoComplete
                  required
                  id="year"
                  label="Year"
                  onChange={(e, value) =>
                formik.setFieldValue("year", value.id)
                  }
                  onBlur={formik.handleBlur}
                  error={formik.errors.year}
                  options={yearList}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={formik.values.year}
                />
              </Grid>
              <Grid item container direction="column" md={6} xs={12}>
                <CeroSelect
                  required
                  id="assessmentPeriod"
                  name="assessmentPeriod"
                  label="Assessment Period"
                  fullWidth
                  options={assessmentOptions}
                  selectedValue={formik.values.assessmentPeriod}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.assessmentPeriod &&
                    formik.errors.assessmentPeriod
                  }
                />
              </Grid>
            </Grid>
            <CeroQuestionWithCheckbox
              required
              question="Indicate if the supplier is at significant risk for incidents of child labor."
              formik={formik}
              fieldName="riskOfChildLabor"
              onChange={handleAnswerChange}
              classes={{ questionBox: classes.questionBox }}
            />
            {formik.values.riskOfChildLabor === "Yes" && (
              <CeroInput
                id="childLaborDetails"
                name="childLaborDetails"
                label="If Yes, provide an explanation of the risk and the measures taken to address it."
                fullWidth
                multiline
                rows="2"
                value={formik.values.childLaborDetails}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.childLaborDetails &&
                  formik.errors.childLaborDetails
                }
                classes={{ container: classes.textAreaContainer }}
              />
            )}
            <CeroQuestionWithCheckbox
              required
              question="Indicate if the supplier is at significant risk for incidents of forced or compulsory labor."
              formik={formik}
              fieldName="riskOfCompulsoryLabor"
              onChange={handleAnswerChange}
              classes={{ questionBox: classes.questionBox }}
            />
            {formik.values.riskOfCompulsoryLabor === "Yes" && (
              <CeroInput
                id="compulsoryLaborDetails"
                name="compulsoryLaborDetails"
                label="If Yes, provide an explanation of the risk and the measures taken to address it."
                fullWidth
                multiline
                rows="2"
                value={formik.values.compulsoryLaborDetails}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.compulsoryLaborDetails &&
                  formik.errors.compulsoryLaborDetails
                }
                classes={{ container: classes.textAreaContainer }}
              />
            )}
            <CeroInput
              id="actionRequired"
              name="actionRequired"
              label="Capture any incidents and actions taken related to human rights that your organization is aware of."
              fullWidth
              multiline
              rows="2"
              value={formik.values.actionRequired}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.actionRequired && formik.errors.actionRequired
              }
              classes={{ container: classes.textAreaContainer }}
            />
          </Box>
        </Box>
        <Box className={classes.buttonContainer}>
          <CeroButton
            buttonText="Update"
            disabled={!formik.dirty || !formik.isValid}
            className={clsx(classes.button, classes.buttonPrimary)}
            onClick={onUpdateSupplierHumanRightsTraining}
          />
          <CeroButton
            buttonText="Clear"
            variant="outlined"
            className={clsx(classes.button, classes.buttonSecondary)}
            onClick={formik.resetForm}
          />
        </Box>
      </Container>
    </>
  );
};

export default EditSupplierHumanRightsTrainingForm;
